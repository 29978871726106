import React from 'react';
import styled from 'styled-components';
import {FaTimes} from 'react-icons/fa';
import Store from '../../Common/Store.js';
import APICall from '../../Common/APICall.js';
import Utils from '../../Common/Utils';
import Dropzone from '../Common/Dropzone.js';
import * as Style from '../Common/StyledComponents';
import DatePicker from 'react-date-picker';
import HtmlEditor from '../Common/HtmlEditor';
import AlertBox from '../Common/AlertBox';
import CSLLoader from '../Common/CSLLoader';

const Modaltext = styled.div`
    height: auto;
    width: 95%;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: red;
    background-color: #f0f0f0;
    margin-left: 18px;
    padding: 10px;
    margin-top: 16px;
    margin-left: 20px;
`;


const CancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    cursor:pointer;
`;
const SubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7
    color: #ffffff;
    width: auto
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor:pointer;
`;

const SaveButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #223B5A;;
    color: #ffffff;
    width: auto
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor:pointer;
    `;


const UnselectedPill = styled.div`
    background-color: #ededed;
    color: #a6a6a6;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;
const SelectedPill = styled.div`
    background-color: #37ada7;
    color: #ffffff;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;
const CSLDateCover = styled.div`
    margin-top: 20px;
    width: 200px;
    border-radius: 3px;
    border: 1px solid #ffffff;
    box-shadow: 0 0 4px #c4c4c4;
    height: 25px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    `;

class AddFAQModal extends React.Component
{
    state = {
        answer: '',
        query: '',
        faq_answer: '',
        faq_query: '',
        id: 0,
        binFiles: [],
        cur_files_json: null,
        tags:[],
        expire_date:new Date(),
        gc_companies: null,
        is_gc: false,
        cur_gc_id:null,
        isLoaded: false,
        alert_param: null,
        enabled_topics: false,
        topic_id: "0",
        topic: {},
        is_loading: false

    };

    constructor(props) {
        super(props);
    }

    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            //console.log('File_name', files[i].name);
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }
            
        }
        //console.log('files in Modal', files);
        this.setState({binFiles: files});
    }



    closeModal = (event) => {
        event.preventDefault();
        this.props.refreshParentCallback();
    }

    redirecrCarRegister =(event) => {
        event.preventDefault();
        this.props.refreshCarRegister();
    }

    selectOrUnselectTopic = (tagid) => (event) => {
        event.preventDefault();
        let { tags } = this.state;
        tags.forEach((tag) => {
            if (tag.id === tagid) {
                if (tag.selected) {
                    tag.selected = false;
                    tag.styledcomponent = UnselectedPill;
                } else {
                    tag.selected = true;
                    tag.styledcomponent = SelectedPill;
                }
            }
        })

        this.setState({ tags });
    }


    addFAQ = (event) => {
        // if(this.state.faq_query === '') {
        //     alert('ERROR: The field FAQ Query cannot be blank.');
        // }
        let msg = 'This feature is coming soon.'
        let title = 'Alert'
        // alert("This feature is coming soon.");
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.sameAlertremoveHandler, stack: {id: 0}}
        this.setState({alert_param: alert_param})
        return false;
        // let postData = { "command": "add_faq", query_id:this.state.id, faq_query: this.state.faq_query, faq_answer: this.state.faq_answer };
        // console.log('postData', postData);
        // let api = new APICall();
        // api.command(postData, this.processAddFAQ);
    }


    SendforApprovalFAQ(flag){
        let answer = Store.getStoreData('answer');
        let msg = '';
        let title = 'Warning'
        if(this.state.faq_query === '') {
            msg = 'The question cannot be blank.'
            // alert('ERROR: The Query cannot be blank.');
            let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.sameAlertremoveHandler, stack: {id: 0}}
            this.setState({alert_param: alert_param})
            return;
        }
        if(answer === null) {
            msg = 'The answer cannot be blank.';
            // alert('The Answer cannot be blank.');
            let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.sameAlertremoveHandler, stack: {id: 0}}
            this.setState({alert_param: alert_param})
            return;
        }

        let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
        let selectedtags = {};
        this.state.tags.forEach((tag) => {
            if (tag.selected) selectedtags[tag.id.toString()] = tag.name;
        })
        let dateobj = new Date(this.state.expire_date);
        let curmonth = parseInt(dateobj.getMonth());
        let currmonth = curmonth + 1;
        var expiry_date = currmonth >= 10 ? dateobj.getFullYear()+'/'+currmonth+'/' : dateobj.getFullYear()+'/0'+currmonth+'/';
        expiry_date += dateobj.getDate() < 10 ? '0' + dateobj.getDate() : dateobj.getDate();

        if(answer === null){
            answer = this.state.faq_answer;
        }
        let answer_summary = btoa(window.encodeURIComponent(answer));

        Utils.log("this.state", this.state)

        let gc_assoc = Store.getStoreData('gc_assoc');
        let company_id = this.state.cur_gc_id;
        if(this.state.is_gc) {
            company_id = parseInt(gc_assoc[company_id.toString()].parent_id) * 100000 + parseInt(company_id);
        }

        // parseInt(jsondata.CSC_CL_companyID) * 100000 + parseInt(jsondata.gc_company_id);

        let postData = { 
                        command: "add_faq", 
                        question: this.state.faq_query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'"),
                        answer: answer_summary, 
                        bin_files: bin_files,
                        task_id: 0, 
                        assign_to: 0, 
                        tags: selectedtags,
                        car_ref_id: '',
                        company_id: company_id,
                        is_approved: flag,
                        expiry_date: expiry_date
                    };
        if(this.state.enabled_topics) {
            postData['topic'] = this.state.topic;
            postData['topic_value'] = this.state.topic['value'];
        }

        Utils.log('postData', postData);
        let api = new APICall();
        api.indexCommand(postData, this.processSendforApprovalFAQ);
        this.setState({is_loading: true})
    }


    processSendforApprovalFAQ = (result) => {
        let msg = '';
        let title = 'Task Save'
        console.log(result, Store.getStoreData('role'));
        
        if(Store.getStoreData('role') === 'admin_manager' || Store.getStoreData('role') === 'ca_module_admin'){
            if(result.is_approved === 0) {
                msg = 'Your FAQ has been saved.';
                // alert('Your FAQ has been saved.');
                let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
                this.setState({alert_param: alert_param, is_loading: false})
            }else{
                msg = 'Your FAQ has been published.';
                title = 'Alert';
                // alert("Your FAQ has been published.");
                let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
                this.setState({alert_param: alert_param, is_loading: false})
            }
        }else{
            if(result.is_approved === 0) {
                msg = 'Your FAQ has been saved.';
                // alert('Your FAQ has been saved.');
                let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
                this.setState({alert_param: alert_param, is_loading: false})
            }else {
                msg = 'This FAQ has been sent to a manager for approval.';
                title = 'Alert';
                // alert("This FAQ has been sent to a manager for approval.");
                let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
                this.setState({alert_param: alert_param, is_loading: false})
            }
            
        }
        // this.props.refreshFaqRegister();
    }

    handleChange = (event) => {
        this.setState({ [event.target.name] : event.target.value })
    }

    handleChangeCurGc = (evt) => {
        let gc_companies = Store.getStoreData('gc_company');
        let is_gc = false;
        for(let gc of gc_companies) {
            if(parseInt(gc.id) === parseInt(evt.target.value)){
                is_gc = gc.is_gc;
            }
        }
        Utils.log("evt.target.value", evt.target.value);
        this.setState({ cur_gc_id: evt.target.value, is_gc:is_gc});
    }


    componentDidMount() {
        let gc_companies = Store.getStoreData('gc_company');
        let gcs = [];
        for(let g in gc_companies){
            if(gc_companies[g].is_active === 1){
                gcs.push(gc_companies[g])
            }
            // gcs.push(gc_companies[g])
        }


        let cur_gc_id = gcs[0].id;
        let is_gc = gcs[0].is_gc;

        Utils.log("gc_companies", gc_companies);
        Utils.log("cur_gc_id", cur_gc_id);

        let rawtags = Store.getStoreData('tags');
        console.log("rawtags", rawtags);
        let otags = [];
        rawtags.forEach((top) => {
            otags.push({
                name: top.TagName,
                id: top.ID,
                styledcomponent: UnselectedPill,
                selected: false
            });
        })
        let stagnames = [];
        otags.forEach((item) => {
            stagnames.push(item.name);
        })
        stagnames.sort();
        let tags = [];
        for (let i=0; i<stagnames.length; i++) {
            for (let j=0; j<otags.length; j++) {
                if (stagnames[i] === otags[j].name) {
                    tags.push(otags[j]);
                }
            }
        }
        let moduleconfig = Store.getStoreData('moduleconfig');
        let enabled_topics = this.state.enabled_topics;
        let topic = this.state.topic;
        let topic_id = this.state.topic_id;
        let store_topic = Store.getStoreData('topics_exclude_other');
        if(moduleconfig.enabled_topics && store_topic.length > 0){
            enabled_topics = moduleconfig.enabled_topics;
            topic_id = store_topic[0].id;
            topic = store_topic[0];
        }

        let days_after_faq_expire = 0;
        if(moduleconfig.enabled_faq_expiry){
            days_after_faq_expire = parseInt(moduleconfig.days_after_faq_expire);
        }
        let faq_expire_date = new Date(Utils.adddaystoDate(days_after_faq_expire));
        this.setState({ tags: tags, expire_date: faq_expire_date, gc_companies: gcs, cur_gc_id: cur_gc_id, is_gc: is_gc, isLoaded:true, enabled_topics:enabled_topics, topic_id: topic_id, topic: topic});
       
    }
    onExpireDateChange = (date) => {
        console.log("date", date);
        this.setState({ expire_date: date })
    }

    sameAlertremoveHandler = (result,stack) => {
        this.setState({alert_param: null})
    }

    alertremoveHandler = (result, stack) => {
        this.props.refreshFaqRegister()
    }

    handleChangeTopic = (event) => {
        event.preventDefault();
        let topic = this.state.topic; 
        for(let t of Store.getStoreData('topics')) {
            if(t.id===event.target.value){
                topic = t;
            }
        }
        this.setState({ [event.target.name] : event.target.value, topic: topic})
    }

    handleChangeOther = (event) => {
        event.preventDefault();
        let topic = this.state.topic; 
        topic.value = event.target.value;
        this.setState({topic: topic})
    }

    render()
    {
        if(!this.state.isLoaded) {
            return <div>Loading....</div>;
        }
        let contact = Store.getStoreData('contact');
        return (
            <div>
                <Style.ModalContainer>
                    {
                        (() => {
                            if(this.state.is_loading) {
                                return <CSLLoader style={{position: 'absolute'}} />
                            }
                        })()
                    }
                    <Style.ModalHeaderText>Add New FAQ</Style.ModalHeaderText>
                    <Style.ModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></Style.ModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>
                    <Modaltext>Please use this form to add a new FAQ. Before you add a new FAQ, please first make sure that it is not already listed in the FAQ register. You may also want to review the list of all Closed Compliance Advices by <span style={{textDecoration:'underline', color:'#37ada7', fontWeight:'600', cursor:'pointer'}} onClick={this.redirecrCarRegister}>clicking here</span> to see if any of them can be used to write this new FAQ</Modaltext>
                    <Style.ModalBody>
                        <Style.ModalBodyInner>
                            <Style.ModalContainertop>

                                {
                                    (() => {
                                        if(Store.getStoreData('has_gc')) {
                                            return (            

                                                    <div style={{display:"contents"}}>
                                                        <Style.ModalNameLabelDiv>Select a Company</Style.ModalNameLabelDiv>
                                                            <Style.ModalNameInputDiv>
                                                                <Style.MRModalSelect name="cur_gc_id" value={this.state.cur_gc_id} onChange={this.handleChangeCurGc}>
                                                                    {
                                                                        this.state.gc_companies.map((gc) => {
                                                                            return(
                                                                            <option key={gc.id} value={gc.id}>{gc.company_name}</option>
                                                                            );
                                                                        })
                                                                    }
                                                                </Style.MRModalSelect>
                                                            </Style.ModalNameInputDiv>
                                                    </div>)
                                        }
                                    })()
                                }


                                {
                                    (() => {
                                        if(this.state.enabled_topics) {
                                            return(
                                                <div style={{display:"contents"}}>
                                                    <Style.ModalNameLabelDiv>Select a Topic</Style.ModalNameLabelDiv>
                                                        <Style.ModalNameInputDiv>
                                                            <Style.MRModalSelect name="topic_id" value={this.state.topic_id} onChange={this.handleChangeTopic}>
                                                                {
                                                                    Store.getStoreData('topics').map((item) => {
                                                                        {
                                                                            return (
                                                                                <option key={item.id} value={item.id}>{item.topic}</option>
                                                                            );
                                                                        }
                                                                    })
                                                                }
                                                            </Style.MRModalSelect>
                                                        </Style.ModalNameInputDiv>
                                                </div>
                                                )
                                        }
                                    })()
                                }

                                {
                                    (() => {
                                        if(this.state.enabled_topics && this.state.topic_id === "other") {
                                            return <div style={{display:"contents"}}><Style.ModalNameLabelDiv></Style.ModalNameLabelDiv><Style.ModalNameInputDiv><Style.ModalInput type="text" name="other_topic" value={this.state.topic.value.replace(/'+/g,"'")} onChange={this.handleChangeOther} /></Style.ModalNameInputDiv></div>
                                        }
                                    })()

                                }




                                <Style.ModalNameLabelDiv>Question <span style={{color: "#ff2f00"}}>*</span></Style.ModalNameLabelDiv>
                                <Style.ModalNameInputDiv>
                                    <Style.ModalTextArea name="faq_query" defaultValue={this.state.query.replace(/'+/g,"'")} onChange={this.handleChange} />
                                </Style.ModalNameInputDiv>
                                <Style.ModalNameLabelDiv>Answer <span style={{color: "#ff2f00"}}>*</span></Style.ModalNameLabelDiv>
                                <Style.ModalNameInputDiv>
                                     {/*<Style.ModalTextArea name="faq_answer" value={this.state.faq_answer.replace(/'+/g,"'")} onChange={this.handleChange} />*/}
                                     <HtmlEditor style={{width: '98%', padding: "5px"}} content={this.state.faq_answer} />
                                </Style.ModalNameInputDiv>
                                <Style.ModalNameLabelDiv>Attachments</Style.ModalNameLabelDiv>
                                <Style.ModalNameInputDiv>
                                    <Dropzone onFilesAdded={this.filesLoaded} initFiles={null} />
                                </Style.ModalNameInputDiv>
                                <Style.ModalNameLabelDiv>Tags</Style.ModalNameLabelDiv>
                                <Style.ModalNameInputDiv style={{ padding:"0px",marginTop:"20px",backgroundColor:'#fff'}}>
                                    {
                                        this.state.tags.map((tag, index) => {
                                            return (<tag.styledcomponent key={index} onClick={this.selectOrUnselectTopic(tag.id)}>{tag.name}</tag.styledcomponent>);
                                        })
                                    }
                                </Style.ModalNameInputDiv>


                                <Style.ModalNameLabelDiv>Expiry date</Style.ModalNameLabelDiv>
                                <CSLDateCover>
                                    <DatePicker
                                            onChange={this.onExpireDateChange}
                                            clearIcon={null}
                                            calendarIcon={null}
                                            locale={"en-GB"}
                                            value={this.state.expire_date}
                                            />
                                </CSLDateCover>

                            </Style.ModalContainertop>
                        </Style.ModalBodyInner>
                    </Style.ModalBody>
                    <Style.CancelSubmitButtonOuterContainer>
                        <CancelButton onClick={this.closeModal}>Cancel</CancelButton>
                        <SaveButton onClick={this.addFAQ}>Save and Close</SaveButton>
                        {
                            (() => {
                                if(Store.getStoreData('role') === 'admin_manager' || Store.getStoreData('role') === 'ca_module_admin') {
                                    return(<div style={{display:"inline"}}>
                                            <SubmitButton style={{width:"100px"}} onClick={() => this.SendforApprovalFAQ(1)}>Publish</SubmitButton>
                                            </div>);
                                }else if(Store.getStoreData('role') === 'ca') {
                                     return(<SaveButton onClick={() => this.SendforApprovalFAQ(1)}>Create and Send for Approval</SaveButton>)
                                }
                            })()
                        }
                    </Style.CancelSubmitButtonOuterContainer>
                </Style.ModalContainer>
                <AlertBox alertParam = {this.state.alert_param} />
            </div>    
        );
    }
}
export default AddFAQModal;