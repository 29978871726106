let pill_filters = {
          All: {name: 'All', display: 'All', is_active: 0, is_visible: true, count: 0},
          Saved: {name: 'Saved', display: 'Saved', is_active: 0, is_visible: true, count: 0},
          Requested: {name: 'Requested', display: 'Requested', is_active: 0, is_visible: true, count: 0},
          Inprogress: {name: 'Inprogress', display: 'In Progress', is_active: 0, is_visible: true, count: 0},
          Answered: {name: 'Answered', display: 'Answered', is_active: 0, is_visible: true, count: 0},
          AwaitingClarification: {name: 'AwaitingClarification', display: 'Awaiting Clarification', is_active: 0, is_visible: true, count: 0},
          ClarificationProvided: {name: 'ClarificationProvided', display: 'Clarification Provided', is_active: 0, is_visible: true, count: 0},
          Closed: {name: 'Closed', display: 'Closed', is_active: 0, is_visible: true, count: 0},
          awaitingApproval: {name: 'awaitingApproval', display: 'Awaiting Approval', is_active: 0, is_visible: true, count: 0},
          
}

module.exports = pill_filters