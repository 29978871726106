import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store'
import { FaHome, FaCog, FaThLarge, FaBook, FaQuora, FaWrench } from "react-icons/fa";
import { TiArrowBack } from "react-icons/ti";
import * as Style from '../Common/StyledComponents';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faHouseBlank,
	faGear,
	faSquareList,
	faClipboardList,
	faBookBookmark,
	faStamp
} from "@fortawesome/pro-thin-svg-icons";
/*global localStorage*/

const HeadContainer = styled.div`
    display: block;
    width: 100%;
    height: 35px;
    background-color: #ffffff;
    padding-left: 10px;
    padding-right: 10px;
`;
const HomeLogo = styled.div`
    float: left;
    margin-top: 7px;
    font-size: 16px;
    color: #282828;
`;
const HomeText = styled.div`
    float: left;
    margin-top: 7px;
    color: #282828;
    margin-left: 10px;
    font-size: 13px;
`;
const Categories = styled.div`
    float: right;
    font-size: 17px;
    background-color: #ebebeb;
    color: #c4c4c4;
    width: 25px;
    height: 25px;
    margin-left: 5px;
    margin-top: 4px;
    padding-left: 4px;
    padding-top: 3px;
    border-radius: 2px;
`;

class RegisterHeader extends React.Component
{
	redirectTo = (location) => (event) => {
		event.preventDefault();
		let url = "";
		switch(location) {
			case "home":
				url = "/"
				break;
			case "faqregister":
				url = "/faqregister"
				break;
			case "register":
				url = "/"
				break;
			case "approval":
				url = "/approval"
				break
			case "moduleconfig":
				url = "/moduleconfig"
				break;
			default:
				url = "/"
		}
		/*if (location === "moduleconfig") {
		    window.open(url, "car_mc", "width=1400, height=900");
		} else {
		    window.location.assign(url);
		}*/
		window.location.assign(url);
	}

	render()
	{
		let use_site_layout = false
		if(localStorage.getItem('site_layout')!==null){
		  let site_layout = JSON.parse(localStorage.getItem('site_layout'));
		  use_site_layout = site_layout.result.use_site_layout;
		}
		if(use_site_layout) return (<div></div>)
		let role = Store.getStoreData('role')
		return (
			<HeadContainer style={{boxSizing:"border-box"}}>
			    <HomeLogo style={{cursor: "pointer"}} onClick={this.redirectTo('home')}><FontAwesomeIcon icon={faHouseBlank} /></HomeLogo>
			    <HomeText style={{cursor: "pointer"}} onClick={this.redirectTo('home')}><span style={{fontWeight: "600"}}>Compliance Advice Register</span></HomeText>
					{ role === 'admin_manager' &&
						<Categories style={{cursor: "pointer"}} title="Module Config" onClick={this.redirectTo('moduleconfig')}><FontAwesomeIcon icon={faGear} style={{color: "#848484"}} /></Categories>
					}
					{ role === 'admin_manager' &&
						<a href={process.env.REACT_APP_AUDIT_URL}>
							<Categories style={{cursor: "pointer"}} title="Audit Logs"><FontAwesomeIcon icon={faSquareList} style={{color: "#848484"}} /></Categories>
						</a>
					}
					<Categories style={{cursor: "pointer"}} title="CAR Register" onClick={this.redirectTo('register')}><FontAwesomeIcon icon={faClipboardList} style={{color: "#848484"}} /></Categories>
				<Categories style={{cursor: "pointer"}} title="Approval Queues" onClick={this.redirectTo('approval')}><FontAwesomeIcon icon={faStamp} style={{color: "#848484"}} /></Categories>
					{ Store.getStoreData('show_faq') &&
						<Categories style={{cursor: "pointer"}} title="FAQ Register" onClick={this.redirectTo('faqregister')}><FontAwesomeIcon icon={faBookBookmark} style={{color: "#848484"}} /></Categories>
					}
			    <div style={{clear: "both"}}></div>
			</HeadContainer>
		);
	}
}

export default RegisterHeader;