import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import APICall from '../../Common/APICall.js';
import Utils from '../../Common/Utils.js';
import CSLTable from '../Common/CSLTable';
import AddFAQModal from './AddFAQModal';
import ShowFAQModal from './ShowFAQModal';
import FaqHeader from './FaqHeader';
import WelcomeBanner from '../WelcomeBanner';
import CSLLoader from '../Common/CSLLoader';
import CSLPageloader from '../Common/CSLPageloader';

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #01B0A2;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 100px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    margin-right:3px;
    cursor:pointer;
`;

const SubmitButton = styled.button`
  display: inline-block;
    box-sizing: border-box;
    background-color: #223B5A;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    padding-left: 12px;
    margin-top: 40px;
    cursor:pointer;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1178px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1000;
  opacity: 0.6;
`;

const NoPermission = styled.div`
  display: block;
  box-sizing: border-box;
  height: 40px;
  font-size:20px;
  font-weight: 700;
  text-align: center;
  background-color: #ffffff;
  position: absolute;
  top: 100px;
  left: 10px;
  right:10px;
  bottom : 10px;
  border: 2px solid #37ada7;
  border-radius:10px;
  color: #37ada7;
  z-index: 1000;
`;

const UnselectedPill = styled.div`
    background-color:#EEF2F7;
    color: #243E57;
    padding: 8px;
    border-radius: 30px;
    display: inline-block;
    margin-left: 7px;
    font-size: 10px;
    font-weight: 400;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
    width: 170px;
    border: 1px solid #243E57;
`;
const SelectedPill = styled.div`
    background-color: #243E57;
    color: #EEF2F7;
    padding: 8px;
    border-radius: 30px;
    display: inline-block;
    margin-left: 7px;
    font-size: 10px;
    font-weight: 400;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
    width: 170px;
    border: 1px solid #243E57;
`;

const ErrorBar = styled.div`
    background-color: #F6DBDB;
    color: #DE8D8E;
    font-weight: 600;
    padding: 10px 20px;
`;


class FaqregisterIndex extends React.Component
{
      state = {
        ready: false,
        faqdata: [],
        status_count:{},
        page_view: "faqregister_index",
        show_modal: '',
        overlay_height: null,
        filter: {
          All: 1
        },
        faq_id: 0,
        show_filter: [],
        faq_register: true,
        allfilter: {},
        prefered_columns: null

    };

    constructor(props) {
        super(props);
    }

    refreshState = () => {
        this.setState({ overlay_height: null, show_modal: ''});
    }

    refreshCarRegister = () => {
      this.props.refreshCarRegister();
    }

    refreshFaqRegister = () => {
      this.fetchFaqRegisterDate();
    }

    componentDidMount() {        
        this.fetchFaqRegisterDate();
    }

    processSaveFilter = (result) => {
      console.log("result", result);
      // let filter = this.state.filter;
      // this.setState({filter})
    }



    setShowTable = (flag, table) => {
      let allfilter = this.state.allfilter;
      let show_table_filter = allfilter.show_table_filter;
      show_table_filter[table.toString()] = flag
      allfilter.show_table_filter = show_table_filter;
      let postData = { "command": "car_registry_save", "filter":  allfilter};
      console.log("postData", postData);
      let api = new APICall();
      api.command(postData, this.processSaveFilter);
      this.setState({faq_register: flag})
    }

    fetchFaqRegisterDate(){
      let postData = { "command": "get_faq_registry" };
      let api = new APICall();
      api.command(postData, this.processRegisterdata);
    }

    processRegisterdata = (result) => {
      console.log("result", result);
      let filter =  result.result.filter
      let rawtags = Store.getStoreData('tags');
      let otags = [];
      rawtags.forEach((top) => {
            if(filter[top.ID.toString()] ===  1) {
                otags.push({
                    name: top.TagName,
                    id: top.ID,
                    styledcomponent: UnselectedPill,
                    selected: false
                });
            }
          
      })
      let stagnames = [];
      otags.forEach((item) => {
          stagnames.push(item.name);
      })
      stagnames.sort();
      let ftags = [{
                    name: "All",
                    id: 'all',
                    styledcomponent: SelectedPill,
                    selected: true
                }];
      for (let i=0; i<stagnames.length; i++) {
          for (let j=0; j<otags.length; j++) {
              if (stagnames[i] === otags[j].name) {
                  ftags.push(otags[j]);
              }
          }
      }

      let allfilter = result.faq_filter;

      let faq_register = "show_table_filter" in result.faq_filter ? result.faq_filter.show_table_filter['faq_register'] : this.state.faq_register;

      Store.updateStore('faqregdata', result.result.rows);  
      let prefered_columns ={};
      prefered_columns['faq_id'] = 'ID';
      prefered_columns['question'] = 'Question';
      prefered_columns['name_tags'] = 'Tags';
      prefered_columns['answer'] = 'Answer';
      this.setState({overlay_height: null, show_modal: '','ready': true, 'faqdata' : result.result.rows, 'status_count': result.result.status_count, show_filter:ftags, faq_register: faq_register, allfilter: allfilter, prefered_columns: prefered_columns });
    }

    showRegisterFaqModal = (faq_id) => (event) => {
      event.preventDefault();
      this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'showfaq_modal', faq_id:faq_id });
    }

    processSingleFaqdata = (result) => {
      // console.log("result", result.result['faq'])
      // Store.updateStore('singlefaq', result.result['faq']);
      // this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'showfaq_modal'});
    }

    getDate = (input_date) => {
      if(input_date != null){
        let dateStr = input_date.split('T')[0];
        let dateParts = dateStr.split('-'); 
        let ret = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
        return ret;
      }else{
        return '';
      }
     
    }

    processFaqData = (Data) => {
     var task_arr = []
     let taskData = this.state.faqdata;
     var task_arr = []
     var filter_arr = []
     for(var k = 0 ; k < Object.keys(taskData).length; k++)
     {
         task_arr.push(taskData[Object.keys(taskData)[k]].row)
         filter_arr.push(taskData[Object.keys(taskData)[k]].tags)
     }
     let ret = {data: [], columns: []};
     let i = 1;
     console.log("filter_arr==>",filter_arr)

     const has_gc = Store.getStoreData('has_gc');

     ret.columns =[ 
                 {Header: 'ID', accessor: 'faq_id', minWidth: 70, headerStyle: {textAlign: 'left'}},         
                 {Header: 'Question', accessor: 'question', minWidth: 400, headerStyle: {textAlign: 'left'}},
                 {Header: 'Company', accessor: 'company_name', minWidth: 100, headerStyle: {textAlign: 'left'}, show: has_gc},
                 {Header: 'Published Date', accessor: 'published_date', minWidth: 100, headerStyle: {textAlign: 'left'}},
                 {Header: 'Published By', accessor: 'published_by', minWidth: 100, headerStyle: {textAlign: 'left'}},
                 {Header: 'Tags', accessor: 'tags', minWidth: 50, headerStyle: {textAlign: 'left'}},
                 {'Header' : 'Action', Cell: row => (
                     <div>
                       <MRButton style={{cursor: 'pointer'}} onClick={this.showRegisterFaqModal(row.original.id)}>View</MRButton>                                                   
                     </div>
                 ), width: 150, headerStyle: {textAlign: 'left'}}];

     let gc_assoc = Store.getStoreData('gc_assoc');
     let gc_company = "";

    for(var j = 0 ; j< task_arr.length ; j++) 
     {         
        let property_json = JSON.parse(task_arr[j].property_json);
        let ref_id = "";
        if('ref_id' in property_json) {
           ref_id = property_json.ref_id;
        }
        let dateStr = this.getDate(task_arr[j].published_date);
        let published_by = ('published_by' in property_json) ? Store.getStoreData('contacts')[property_json.published_by] : Store.getStoreData('contacts')[task_arr[j].contact_id]
        // let published_by = task_arr[j].contact_id === 0 ? "Api" : Store.getStoreData('contacts')[task_arr[j].contact_id]


         gc_company = parseInt(task_arr[j].company_id) < 100000 ? Store.getStoreData('company').CompanyName : gc_assoc[(parseInt(task_arr[j].company_id)%100000).toString()].company_name;
        
        /*let answer = property_json.answer;
        if(Utils.isBase64(answer)){
          answer = window.decodeURIComponent(atob(answer))
        }*/

        let name_tags = "";
        for (var key in property_json.tags) {
            console.log(property_json.tags[key]);
            name_tags = name_tags + "|" + property_json.tags[key];
        }
        name_tags = name_tags.substring(1)


        console.log("name_tags", name_tags.substring(1))
        
        let elem = {'faq_id':ref_id,
                  'question': property_json.query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'"),
                  'company_name': gc_company,
                  'published_date': dateStr,
                  'published_by' : published_by,
                  'tags': Object.keys(property_json.tags).length,
                  'id': task_arr[j].id,
                  'name_tags':  name_tags                                             
        };
        ret.data.push(elem);

     }   
     return ret;   
    }


    addfaqModal = () => {
      this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'addfaq_modal'});
    }

    selectOrUnselectTopic = (tagid) => (event) => {
        event.preventDefault();
        let show_filter = this.state.show_filter;
        let faqdata = Store.getStoreData('faqregdata');

        Utils.log("show filter faqdata", faqdata);
        show_filter.forEach((tag) => {
            if (tag.id === tagid) {
                if (tag.selected) {
                    tag.selected = false;
                    tag.styledcomponent = UnselectedPill;
                } else {
                    tag.selected = true;
                    tag.styledcomponent = SelectedPill;
                }
            }
        })

        let faq = {};
        Object.keys(faqdata).forEach(function(key) {
          show_filter.map((tag, index) => {

            if (tag.selected) {
              if(tag.id !=='all'){
                  if(faqdata[key].tags[tag.id.toString()] === 1) {
                    faq[key] = faqdata[key];
                  }
                }else{
                  faq = faqdata;
                }
                
            }

          })
        });
        Utils.log("faqdata ===>", faq);

       this.setState({ show_filter: show_filter, faqdata: faq });
    }

    setHomeView = (event) => {
        event.preventDefault();
        let url = "/"
        window.location.assign(url);
    }

    render() {
        if (!Store.getStoreData('show_faq')) {
            return (
                <ErrorBar>
                    <div style={{float: "left"}}>You are not authorised to access this page, please speak to your system administrator if you believe access should be granted.</div>
                    <div style={{float: "right", fontSize: "16px", cursor: "pointer"}} onClick={this.setHomeView}>x</div>
                    <div style={{clear: "both"}}></div>
                </ErrorBar>
            );
        }
        if(Store.getStoreData('car-get_faq_registry') === null) {
          return <div><CSLPageloader /></div>
        }
        if(!this.state.ready) {
            return <div><CSLLoader /></div>
        }
        return(
            <div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
            <FaqHeader />
            <WelcomeBanner welcome={`Welcome back, ` + Store.getStoreData('contact')} />
                <div style={{position: "relative", paddingTop:"10px",paddingLeft:"10px",paddingBottom:"10px"}}>
                    {
                        this.state.show_filter.map((tag, index) => {
                          let t = tag.selected ? <span>&#10003;    </span> : '';
                            return (<tag.styledcomponent key={index} onClick={this.selectOrUnselectTopic(tag.id)}>
                                {t}{tag.name}<span style={{float: 'right', color: "#04ADA8"}}> {this.state.status_count[tag.id.toString()]} </span>
                              </tag.styledcomponent>);
                        })
                    }
                    <div style={{clear: "both"}}>&nbsp;</div>
                    {
                      (() => {
                        if(Store.getStoreData('role') === "admin_manager" || Store.getStoreData('role') === "ca" || Store.getStoreData('role') === "ca_module_admin"){
                          return(<div>
                            <SubmitButton onClick={this.addfaqModal}>Add FAQ</SubmitButton>
                            </div>)
                        }

                      })()
                    }
                    
                  <CSLTable add={false} 
                  export={true} 
                  processData={this.processFaqData} 
                  headerText={'Frequently Asked Questions'} 
                  tableRows="10" setShowTable={this.setShowTable} 
                  show_filter={this.state.faq_register} 
                  table="faq_register" 
                  prefered_columns={this.state.prefered_columns}
                  />
                  {
                    (() => {
                      if (this.state.overlay_height !== null) {
                        switch(this.state.show_modal) {
                          case 'addfaq_modal' : return <div><InactiveOverlay /><AddFAQModal refreshParentCallback={this.refreshState} refreshCarRegister={this.refreshCarRegister} refreshFaqRegister={this.refreshFaqRegister}/></div>; break;
                          case 'showfaq_modal' : return <div><InactiveOverlay /><ShowFAQModal refreshParentCallback={this.refreshState} refreshFaqRegister={this.refreshFaqRegister} faq_id={this.state.faq_id} is_car={false}/></div>; break;
                          default: return <NoPermission>Sorry, you do not have permission to view this page. </NoPermission>;
                        }
                      }
                    })()
                  }   
                </div>
            </div>
        )
    }

}
export default FaqregisterIndex