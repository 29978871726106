import React from 'react';
import styled from 'styled-components';
import * as Style from '../Common/StyledComponents';
import {FaTimes} from 'react-icons/fa';
import Store from '../../Common/Store.js';
import APICall from '../../Common/APICall.js';
import Dropzone from '../Common/Dropzone.js'
import Utils from '../../Common/Utils';
import CSLLoader from '../Common/CSLLoader';
import AlertBox from '../Common/AlertBox';
import CSLMultiSelect from '../Common/CSLMultiSelect';
import IndexLoader from '../../Common/IndexLoader.js';

const UnselectedPill = styled.div`
    background-color: #ededed;
    color: #a6a6a6;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;
const SelectedPill = styled.div`
    background-color: #37ada7;
    color: #ffffff;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;

class AskQuestionModal extends React.Component
{
    state = {
        query: '',
        binFiles: [],
        cur_files_json: null,
        index: -1,
        form_data: null,
        Ref_ID: '0',
        gc_companies: null,
        company_name: '',
        is_gc: false,
        cur_gc_id:null,
        ready: false,
        tags:[],
        is_disabled: false,
        cur_recever_id: 0,
        addnew: false,
        toggle: true,
        list_answrere: [],
        custom_fields: {},
        enabled_topics: false,
        topic_id: "0",
        topic: {},
        other_topic: '',
        alert_param: null,
        teams: {},
        is_loading: false,
        is_readonly: false,
        individuls: {},
        selected_individuls:{}
    };

    constructor(props) {
        super(props);
    }

    componentWillUnmount() {
        Store.deRegisterCallback('changeTaskaction', this.component_id);
    }

    componentDidMount()
    {
        this.processAskquestionData();
    }

    componentDidUpdate(prevProps)
    {
        if (prevProps !== this.props) {
            this.processAskquestionData();
        }
        if(this.state.toggle) {
            this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
        }  
        
    }


    closeModal = (event) => {
        event.preventDefault();
        this.props.closeModal();
    }
    handleChangeCurGc = (evt) => {
        let gc_companies = Store.getStoreData('gc_company');
        let moduleconfig = Store.getStoreData('moduleconfig');
        let is_gc = false;
        for(let gc of gc_companies) {
            if(parseInt(gc.id) === parseInt(evt.target.value)){
                is_gc = gc.is_gc;
            }
        }
        
        let list_answrere = Utils.showListAssignedUser(evt.target.value);
        let individuls = moduleconfig.rigce ? this.state.individuls : Utils.listIndividulsonGc(evt.target.value);
        Utils.log("individuls", individuls)
        this.setState({ cur_gc_id: evt.target.value, is_gc:is_gc, list_answrere: list_answrere, individuls:individuls});
    }

    processAddQuery = async(result) => 
    {
        Utils.log("processAddQuery result", result);
        let stack = {};
        stack['task_id'] = result.error_code === 0 ? result.task_id : 0
        stack['task'] = result.error_code === 0 ? result.task : {};
        let msg = "The advice request has been saved."
        let title = "Task save"
        let refer = Store.getStoreData('refer');
        this.setState({is_loading: false})
        if(result.submit === 1) {
            msg = "Your advice request has been submitted to the compliance team.";
            title = "Alert";
            let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: stack}
            this.setState({alert_param: alert_param})
        }else if(result.submit === 2){
            if(result.error_code === 0) {
                this.props.openAnswerModal(result.task_id, this.state.cur_gc_id);
                let tasks = Store.getStoreData('tasks')
                let role = Store.getStoreData('role')
                let contact_id = Store.getStoreData('contact_id')
                if(stack!==null){
                  tasks[stack.task_id.toString()] = stack.task;
                  Store.updateStore('tasks', tasks)
                  IndexLoader.divideAllTasks(tasks, role, contact_id);
                }
            }else{
                msg = "An error occured while submitting the advice request";
                title = "Warning";
                // alert('Error occurred during submit the advice request');
                let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
                this.setState({alert_param: alert_param})
            }
            
        }else{
            msg = "The advice request has been saved.";
            title = "Task save";
            let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: stack}
            this.setState({alert_param: alert_param})
        }

    }

    askCARQuestion(submit_form){
        if(this.state.query === '' && submit_form > 0) {
            let msg = 'The advice request cannot be blank.';
            let title = 'Warning';
            let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.sameAlertremoveHandler, stack: {id: 0}}
            this.setState({alert_param: alert_param})
            return false;
        }

        let custom_fields = {}
        if(this.state.custom_fields.enabled) {
            custom_fields = this.state.custom_fields;
            let is_alert = false;
            let is_numeric = false;
            let msg = 'Please insert proper value.';
            let title = 'Warning'
            Utils.log("custom_fields",custom_fields )
            custom_fields['additional_fields'].forEach((item) => {
                if(item.isActive) {
                    if(item.required){
                        if(item.value === ""){
                            is_alert = true;
                        }
                    }
                    if(item.value){
                        if(item.type === 'Numeric') {
                            if(item.value.length > 0) {
                                if(isNaN(item.value)){
                                    is_numeric = true;
                                }
                            }
                        }
                    }
                }
            })
            custom_fields['custom_terminology_fields'].forEach((item) => {
                    if(item.required){
                        if(item.value === ""){
                            is_alert = true;
                        }
                    }
                    if(item.value){
                        if(item.type === 'Numeric') {
                            if(item.value.length > 0) {
                                if(isNaN(item.value)){
                                    is_numeric = true;
                                }
                            }
                        }
                    }
            })

            if(is_numeric) {
                msg = 'Please enter numeric value.';
                title = 'Warning';
                let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.sameAlertremoveHandler, stack: {id: 0}}
                this.setState({'is_disabled': false, alert_param: alert_param})
                return false;
            }
            if(is_alert && submit_form > 0) {
                msg = 'Mandatory Fields must be populated to progress.';
                title = 'Warning';
                let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.sameAlertremoveHandler, stack: {id: 0}}
                this.setState({'is_disabled': false, alert_param: alert_param})
                return false;
            }
        }

        let confirm = true;
        if(submit_form === 1) {
            let msg = 'Changes are not possible after submission, so please ensure your question is complete.';
            let title = 'Confirm';
            let parms = {};
            parms["submit"] = submit_form;
            parms["custom_fields"] = custom_fields;
            let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: true, alertHandler: this.confirmAlertHandler, stack: {parms: parms}}
            this.setState({alert_param: alert_param})
            return false;
        
        }else{
            let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
            
            let moduleconfig = Store.getStoreData('moduleconfig');
            let postData = { "command": "add_query", 
                    query:this.state.query, 
                    submit:submit_form, 
                    bin_files: bin_files, 
                    index: this.state.index, 
                    task_id: this.state.form_data === null ? 0 : this.state.form_data.id,
                    is_gc: this.state.is_gc,
                    gc_company_id: this.state.cur_gc_id,
                    cur_recever_id: this.state.cur_recever_id,
                    tags: {},
                    custom_fields: custom_fields
                };
                if(this.state.enabled_topics) {
                    postData['topic'] = this.state.topic;
                }
                if(this.state.cur_recever_id !== 0) {
                    postData['teams'] = Utils.getTeamUsers(this.state.cur_recever_id);
                }
                postData['rcv_mails'] = Utils.getMailUsers(this.state.cur_recever_id, this.state.cur_gc_id);
                if(moduleconfig.ruie){
                    let selected_users = {};
                    let selected_individuls = this.state.selected_individuls;
                    for(let si in selected_individuls){
                        selected_users[si] = 1;
                    }
                    postData['individuls'] = selected_users;
                }
            let api = new APICall();
            Utils.log("postData", postData)
            api.indexCommand(postData, this.processAddQuery);
            this.setState({is_loading: true})
        }

    }

    confirmAlertHandler = (result, stack) => {
        if(!result || stack.prevent) {
            this.setState({alert_param: null})
            return
        }
        this.setState({alert_param: null})
        let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
        let parms = stack.parms;
        let moduleconfig = Store.getStoreData('moduleconfig');
        let postData = { "command": "add_query", 
                query:this.state.query, 
                submit:parms.submit, 
                bin_files: bin_files, 
                index: this.state.index, 
                task_id: this.state.form_data === null ? 0 : this.state.form_data.id,
                is_gc: this.state.is_gc,
                gc_company_id: this.state.cur_gc_id,
                cur_recever_id: this.state.cur_recever_id,
                tags: {},
                custom_fields: parms.custom_fields
            };
            if(this.state.enabled_topics) {
                postData['topic'] = this.state.topic;
            }
            if(this.state.cur_recever_id !== 0) {
                postData['teams'] = Utils.getTeamUsers(this.state.cur_recever_id);
            }
            if(moduleconfig.ruie){
                let selected_users = {};
                let selected_individuls = this.state.selected_individuls;
                for(let si in selected_individuls){
                    selected_users[si] = 1;
                }
                postData['individuls'] = selected_users;
            }
        postData['rcv_mails'] = Utils.getMailUsers(this.state.cur_recever_id, this.state.cur_gc_id);
        let api = new APICall();
        Utils.log("postData", postData)
        api.indexCommand(postData, this.processAddQuery);
        this.setState({is_loading: true})
    }

    handleChange = (event) => {
        this.setState({ [event.target.name] : event.target.value})
    }

    handleinputChange =(event) => {
           this.setState({ [event.target.name] : event.target.value })
           const input = event.target;
           input.style.height = "auto";
           input.style.height = input.scrollHeight + "px";
       }

    handleChangeTopic = (event) => {
        event.preventDefault();
        let topic = this.state.topic; 
        for(let t of Store.getStoreData('topics')) {
            if(t.id===event.target.value){
                topic = t;
            }
        }
        
        let moduleconfig = Store.getStoreData('moduleconfig');
        let custom_fields = ("custom_fields" in moduleconfig) ? moduleconfig.custom_fields : {};
        
        custom_fields.additional_fields.forEach((item) => {
            if(item.type==="Dropdown"){
                item['value'] = item.dropdown_options[0]
            }else{
                item['value'] = ''
            }
            if(item.other){
                item.dropdown_options.push('Other')
            }
        });


        let additional_fields = [];
        for(let ad of custom_fields.additional_fields){
            console.log("custom_fields", ad)
            if('topics' in ad && ad.topics[event.target.value.toString()]){
                additional_fields.push(ad)
            }
            if(!('topics' in ad)){
                additional_fields.push(ad)
            }
        }
        custom_fields.additional_fields = additional_fields;

        this.setState({ [event.target.name] : event.target.value, topic: topic, custom_fields: custom_fields})
    }

    handleChangeOther = (event) => {
        event.preventDefault();
        let topic = this.state.topic; 
        topic.value = event.target.value;
        this.setState({topic: topic})
    }

    isLoadTags = (tags) => {
        let rawtags = Store.getStoreData('tags');
        console.log("rawtags", rawtags);
        let otags = [];
        rawtags.forEach((top) => {
            if(Object.keys(tags).length === 0){
                otags.push({
                    name: top.TagName,
                    id: top.ID,
                    styledcomponent: UnselectedPill,
                    selected: false
                });
            }else {
                if(tags[top.ID.toString()] ===  top.TagName) {
                    otags.push({
                        name: top.TagName,
                        id: top.ID,
                        styledcomponent: SelectedPill,
                        selected: true
                    });
                }else{
                    otags.push({
                        name: top.TagName,
                        id: top.ID,
                        styledcomponent: UnselectedPill,
                        selected: false
                    });
                }
            }
        })
        let stagnames = [];
        otags.forEach((item) => {
            stagnames.push(item.name);
        })
        stagnames.sort();
        let ftags = [];
        for (let i=0; i<stagnames.length; i++) {
            for (let j=0; j<otags.length; j++) {
                if (stagnames[i] === otags[j].name) {
                    ftags.push(otags[j]);
                }
            }
        }
        return ftags;
    }


    processAskquestionData = () => {
        // let index = Store.getStoreData('q_index') === null ? 0 : Store.getStoreData('q_index');
        let contact_id = Store.getStoreData('contact_id');
        let gc_companies = Store.getStoreData('gc_company');
        console.log("gc_companies", gc_companies)
        let gcs = [];
        for(let g in gc_companies){
            if(gc_companies[g].is_active === 1){
                gcs.push(gc_companies[g])
            }
            // gcs.push(gc_companies[g])
        }

        let gc_assoc = Store.getStoreData('gc_assoc');
        let cur_gc_id = gcs[0].id;
        let is_gc = gcs[0].is_gc;
        let ptags = {};
        let tags = this.isLoadTags(ptags);
        let enabled_topics = this.state.enabled_topics;

        //custome fields
        let moduleconfig = Store.getStoreData('moduleconfig');
        let topic = this.state.topic;
        let topic_id = this.state.topic_id;
        // console.log("Store.getStoreData('topics')", moduleconfig, moduleconfig.team_assignment_enabled);

        let t = Store.getStoreData('topics_exclude_other');
        if(moduleconfig.enabled_topics && t.length > 0){
            enabled_topics = moduleconfig.enabled_topics;
            topic_id = t[0].id;
            topic = t[0];
        }
        
        let custom_fields = ("custom_fields" in moduleconfig) ? moduleconfig.custom_fields : {};

        custom_fields.custom_terminology_fields.forEach((item) => {
            if(item.type==="Dropdown"){
                item['value'] = item.dropdown_options[0]
            }{
                item['value'] = ''
            }
        });

        custom_fields.additional_fields.forEach((item) => {
            if(item.type==="Dropdown"){
                item['value'] = item.dropdown_options[0]
            }else{
                item['value'] = ''
            }
            if(item.other){
                item.dropdown_options.push('Other')
            }
        });

        if(enabled_topics){
            let additional_fields = [];
            for(let ad of custom_fields.additional_fields){
                console.log("custom_fields", ad)
                if('topics' in ad && ad.topics[topic_id.toString()]){
                    additional_fields.push(ad)
                }
                if(!('topics' in ad)){
                    additional_fields.push(ad)
                }
            }
            custom_fields.additional_fields = additional_fields;
        }

        


        console.log("custom_fields", custom_fields)
        let list_answrere = Store.getStoreData('list_answrere');

        

       
        let form_data = {};
        let index = -1;
        let individuls = Store.getStoreData('individuls')

        if(Store.getStoreData('cur_q_modal_data') !==null) {
            index = 0;
            form_data = Store.getStoreData('cur_q_modal_data');
            let task_json = JSON.parse(form_data.task_json);
            
            let related_individuls = task_json.object_data?.individuls;
            let selected_individuls = this.state.selected_individuls;
            // console.log("related_individuls", related_individuls)
            if(related_individuls){
                for(let i in  related_individuls){
                    selected_individuls[i] = individuls[i];
                }
                
            }

            let ref_id = '0';
            if('ref_id' in task_json.object_data) {
                ref_id = task_json.object_data.ref_id;
            }
            if('is_gc' in task_json.object_data) {
                is_gc = task_json.object_data.is_gc;
            }
            if('tags' in task_json.object_data) {
                ptags = task_json.object_data.tags;
            }
            
            tags = this.isLoadTags(ptags)
            if (task_json.object_data.bin_files.length !== 0) {
                task_json.object_data.bin_files.forEach((item) => {
                    item.uid = "uid" in item ? item.uid : this.genKey(10);
                })
            }


            if(moduleconfig.enabled_topics){
                if('topic' in task_json.object_data){
                    topic = task_json.object_data.topic;
                    topic_id = topic.id;

                }
            }



            // Utils.log("list_answrere", list_answrere);

            let gc_company = parseInt(form_data.company_id) < 100000 ? form_data.company_id : form_data.company_id%100000;
            // list_answrere = Utils.ShowTeamUsers(gc_company);


            let company_name = parseInt(form_data.company_id) < 100000 ? Store.getStoreData('company').CompanyName : gc_assoc[(parseInt(form_data.company_id)%100000).toString()].company_name;

            let cur_recever_id = ('cur_recever_id' in task_json.object_data) ? task_json.object_data.cur_recever_id : 0;

            
            if('custom_fields' in task_json.object_data && "custom_fields" in moduleconfig && Object.keys(task_json.object_data.custom_fields).length !== 0) {
                custom_fields = task_json.object_data.custom_fields;
            }

            let is_readonly = this.props.is_readonly !== undefined ? this.props.is_readonly : false;

            this.setState({ cur_files_json: task_json.object_data.bin_files, form_data:form_data, query: task_json.object_data.query, Ref_ID:ref_id, gc_companies: gcs, company_name:company_name, ready: true,cur_gc_id:gc_company,is_gc:is_gc, tags: tags, cur_recever_id:cur_recever_id,list_answrere: list_answrere, index: index, custom_fields: custom_fields, enabled_topics:enabled_topics, topic_id: topic_id, topic: topic, is_readonly: is_readonly, individuls:individuls, selected_individuls: selected_individuls });
            
        }else{            
            this.setState({ Ref_ID: '0', gc_companies: gcs, ready: true, cur_gc_id: cur_gc_id, is_gc:is_gc, tags: tags, list_answrere: list_answrere, custom_fields: custom_fields,enabled_topics:enabled_topics, topic_id: topic_id, topic: topic, individuls: individuls});            
        }
    }

    genKey = (length) => {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }


    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }
            
        }
        this.setState({binFiles: files});
    }


    generateReferenceField = (field, data_container) => {
        return (
            <div style={{display:"contents"}}>
                <Style.ModalNameLabelDiv>{field.nickname}
                {
                    (() => {
                        if(field.required) {
                            return <span style={{color: "#ff2f00", padding:"4px"}}>*</span>;
                        }
                    })()
                }
                </Style.ModalNameLabelDiv>
                <Style.ModalNameInputDiv>
                {
                    (() => {
                        if (field.type === "Alpha") {
                            return (<Style.ModalInput type="text" name={field.name} value={field.value} onChange={this.handleAddlField(field.type, data_container)}/>)
                        } else if(field.type === "Dropdown") {
                            return (
                                <Style.MRModalSelect name={field.name} value={field.value} onChange={this.handleAddlField(field.type, data_container)}>
                                    {
                                        field.dropdown_options.map((opt, index) => {
                                            return(
                                                <option key={index} value={opt}>{opt}</option>
                                            );
                                        })
                                    }
                                </Style.MRModalSelect>
                            );
                        }else{
                            return (<Style.ModalInput type="text" name={field.name} value={field.value} onChange={this.handleAddlField(field.type, data_container)}/>)
                        }
                    })()
                }
                </Style.ModalNameInputDiv>
            </div>
        );
    }

    generateadditionalField = (data, data_container) => {
        console.log("generateadditionalField", data_container, data)
        return (
            <div key={data.name} style={{display:"contents"}}>
                <Style.ModalNameLabelDiv>{data.nickname} 
                {
                    (() => {
                        if(data.required) {
                            return <span style={{color: "#ff2f00", padding:"4px"}}>*</span>;
                        }
                    })()
                }
                </Style.ModalNameLabelDiv>
                {
                    (() => {
                        let fieldhtml = <div></div>;
                        switch(data.type) {
                            case "Alpha":
                                fieldhtml = this.createAlphaField(data, data_container);
                                break;
                            case "Numeric":
                                fieldhtml = this.createNumericField(data, data_container);
                                break;
                            case "Dropdown":
                                fieldhtml = this.createDropdownField(data, data_container);
                                break;
                            default:
                                fieldhtml = fieldhtml;
                        }
                        return fieldhtml;
                    })()
                }
            </div>
        );
    }

    createAlphaField = (data, data_container) => {
        return (<Style.ModalNameInputDiv><Style.ModalInput type="text" name={data.name} value={data.value} onChange={this.handleAddlField(data.type, data_container)} /></Style.ModalNameInputDiv>);
    }

    createNumericField = (data, data_container) => {
        return (<Style.ModalNameInputDiv><Style.ModalInput type="text" name={data.name} value={data.value} onChange={this.handleAddlField(data.type, data_container)} min={0}/></Style.ModalNameInputDiv>);
    }
    createDropdownField = (data, data_container) => {
        return (
            <Style.ModalNameInputDiv>
                <Style.MRModalSelect onChange={this.handleAddlField(data.type, data_container)} name={data.name} value={data.value}>
                    {
                        data.dropdown_options.map((opt, index) => {
                            return(
                                <option key={index} value={opt}>{opt}</option>
                            );
                        })
                    }
                </Style.MRModalSelect>
                {
                    (() => {
                        console.log("createDropdownField", data)
                        if(data.other && data.value === "Other") {
                            return (<Style.ModalNameInputDiv style={{fontWeight: "600"}}>Please provide more information <Style.ModalInput style={{marginTop:"5px"}} type="text" name={data.name} value={data.more_info} onChange={this.handleAddmoreInfolField(data.type, data_container)}/></Style.ModalNameInputDiv>);
                        }
                    })()
                }
            </Style.ModalNameInputDiv>
        );
    }

    showAssignedTo = () =>  {
        let lists = Utils.showListAssignedUser(this.state.cur_gc_id);
        return (<div style={{display:"contents"}}>
                    {
                        (() => {
                            if(Store.getStoreData('moduleconfig').specific_user_assignment_enabled || Store.getStoreData('moduleconfig').team_assignment_enabled) {
                                return(
                                    <div style={{display:"contents"}}>
                                        <Style.ModalNameLabelDiv>Assign To</Style.ModalNameLabelDiv>
                                        <Style.ModalNameInputDiv>
                                            <Style.MRModalSelect name="cur_recever_id" value={this.state.cur_recever_id} onChange={this.handleChange}>
                                                {
                                                    lists['ca'].map((item) => {
                                                        {
                                                            return (
                                                                <option key={item.ID} value={item.ID}>{item.contact_name}</option>
                                                            );
                                                        }
                                                    })
                                                }
                                                {
                                                    (() => {
                                                        if(lists['teams'].length > 0 && lists['ca'].length > 0){
                                                            return <option disabled>──────────────────────────────</option>
                                                        }
                                                    })()
                                                }
                                                {
                                                    lists['teams'].map((item) => {
                                                        {
                                                            return (
                                                                <option key={item.ID} value={item.ID}>{item.contact_name}</option>
                                                            );
                                                        }
                                                    })
                                                }  
                                                {
                                                    (() => {
                                                        if(lists['users'].length > 0){
                                                            return <option disabled>──────────────────────────────</option>
                                                        }
                                                    })()
                                                }
                                                {
                                                    lists['users'].map((item) => {
                                                         {
                                                             return (
                                                                 <option key={item.ID} value={item.ID}>{item.contact_name}</option>
                                                             );
                                                         }
                                                    })
                                                }  
                                            </Style.MRModalSelect>
                                        </Style.ModalNameInputDiv>
                                    </div>
                                    )

                            }
                        })()
                    }
        </div>
        )

    }

    handleAddlField = (field_type, data_container) => (event) => {
        event.preventDefault();
        let custom_fields = this.state.custom_fields;
        
        custom_fields[data_container.toString()].forEach((item) => {
            if (item.name === event.target.name) {
                item.value = event.target.value;
                item.more_info = '';
            }
        })  
        this.setState({custom_fields: custom_fields, toggle:false})
    }

    handleAddmoreInfolField = (field_type, data_container) => (event) => {
        event.preventDefault();
        let custom_fields = this.state.custom_fields;
        
        custom_fields[data_container.toString()].forEach((item) => {
            if (item.name === event.target.name) {
                item.more_info = event.target.value;
            }
        })  
        this.setState({custom_fields: custom_fields, toggle:false})
    }

    alertremoveHandler = (result, stack) => {
        this.setState({alert_param: null})
        this.props.refreshParentCallback(stack);
    }
    sameAlertremoveHandler = (result,stack) => {
        this.setState({alert_param: null})
    }

    removeIndividul = (id) => {
        let selected_individuls = this.state.selected_individuls;
        delete selected_individuls[id];
        this.setState({selected_individuls})
    }
    
    addIndividul = (id) => {
        let selected_individuls = this.state.selected_individuls;
        let individuls = this.state.individuls;
        selected_individuls[id] = individuls[id];
        this.setState({selected_individuls})
    }

    render()
    {
         
         if(!this.state.ready) {
             return(<div>Loading...</div>);
         }
         console.log("bin files ==> ", this.state.is_readonly)

        return (
            <div>
            <Style.ModalContainer>
            
            {
                (() => {
                    if(this.state.is_loading) {
                        return <CSLLoader style={{position: 'absolute'}} />
                    }
                })()
            }
                {
                    (() => {
                        if(this.state.index >= 0) {
                            return(<Style.ModalHeaderText>Update Advice Request</Style.ModalHeaderText>)
                        }else{
                            return(<Style.ModalHeaderText>Submit Advice Request</Style.ModalHeaderText>)
                        }
                    })()
                }
                
                <Style.ModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></Style.ModalHeaderCloseBtn>
                <div style={{clear: "both"}}></div>
                <Style.ModalBody>
                    <Style.ModalBodyInner>
                        <Style.ModalContainertop>

                            <Style.ModalNameLabelDiv>CAR Reference</Style.ModalNameLabelDiv>
                            <Style.ModalNameInputDiv>
                            {
                                (() => {
                                    if(this.state.Ref_ID === '0') {
                                        return 'N/A (New Advice request)';
                                    }else{
                                        return this.state.Ref_ID;
                                    }
                                })()
                            }
                            </Style.ModalNameInputDiv>

                            {
                                (() => {
                                    if(this.state.enabled_topics) {
                                        return(
                                            <div style={{display:"contents"}}>
                                                <Style.ModalNameLabelDiv>Select a Topic</Style.ModalNameLabelDiv>
                                                    <Style.ModalNameInputDiv>
                                                        <Style.MRModalSelect name="topic_id" value={this.state.topic_id} onChange={this.handleChangeTopic}>
                                                            {
                                                                Store.getStoreData('topics').map((item) => {
                                                                    {
                                                                        return (
                                                                            <option key={item.id} value={item.id}>{item.topic}</option>
                                                                        );
                                                                    }
                                                                })
                                                            }
                                                        </Style.MRModalSelect>
                                                    </Style.ModalNameInputDiv>
                                            </div>
                                            )
                                    }
                                })()
                            }

                            {
                                (() => {
                                    if(this.state.enabled_topics && this.state.topic_id === "other") {
                                        return <div style={{display:"contents"}}><Style.ModalNameLabelDiv></Style.ModalNameLabelDiv><Style.ModalNameInputDiv><Style.ModalInput type="text" name="other_topic" value={this.state.topic.value.replace(/'+/g,"'")} onChange={this.handleChangeOther} /></Style.ModalNameInputDiv></div>
                                    }
                                })()

                            }

                            {
                                (() => {
                                    if(Store.getStoreData('has_gc')) {
                                        return (<div style={{display:"contents"}}>
                                            {
                                                (() => {
                                                    if(this.state.index >= 0) {
                                                        return(
                                                                <div style={{display:"contents"}}>
                                                                    <Style.ModalNameLabelDiv>Company</Style.ModalNameLabelDiv>
                                                                    <Style.ModalNameInputDiv>{this.state.company_name}</Style.ModalNameInputDiv>
                                                                </div>
                                                            )
                                                    }else{
                                                        return(                            
                                                            <div style={{display:"contents"}}>
                                                                <Style.ModalNameLabelDiv>Select a Company</Style.ModalNameLabelDiv>
                                                                    <Style.ModalNameInputDiv>
                                                                        <Style.MRModalSelect name="cur_gc_id" value={this.state.cur_gc_id} onChange={this.handleChangeCurGc}>
                                                                            {
                                                                                    this.state.gc_companies.map((gc) => {
                                                                                    return(
                                                                                    <option key={gc.id} value={gc.id}>{gc.company_name}</option>
                                                                                    );
                                                                                })
                                                                            }
                                                                        </Style.MRModalSelect>
                                                                    </Style.ModalNameInputDiv>
                                                            </div>
                                                        )
                                                    }
                                                })()
                                            }
                                        </div>)
                                    }
                                })()
                            }

        
                            {
                                (() => {
                                    return this.showAssignedTo();
                                })()
                            }

                            {
                                (() => {
                                    if(Store.getStoreData('ruie')) {
                                        return(<div style={{display:"contents"}}>
                                                <Style.ModalNameLabelDiv>Add Related Individual</Style.ModalNameLabelDiv>
                                                <Style.ModalNameInputDiv>
                                                <CSLMultiSelect 
                                                    selectedItems={this.state.selected_individuls} 
                                                    items={this.state.individuls}
                                                    selectCallback={this.addIndividul}
                                                    deselectCallback={this.removeIndividul}
                                                />
                                                </Style.ModalNameInputDiv>    
                                            </div>)
                                    }
                                })()
                            }



                            <Style.ModalNameLabelDiv>Advice Request <span style={{color: "#ff2f00"}}>*</span></Style.ModalNameLabelDiv>
                            <Style.ModalNameInputDiv>
                                <Style.ModalTextArea style={{height: "auto"}} name="query" value={this.state.query.replace(/'+/g,"'")} onChange={this.handleinputChange} />
                            </Style.ModalNameInputDiv>

                            {
                                (() => {
                                    if(this.state.custom_fields.enabled) {
                                        if (this.state.custom_fields.custom_terminology_fields.length !== 0 ) {
                                            const field = this.state.custom_fields.custom_terminology_fields.find((item) => {
                                                return item.name === "reference_number"
                                            })

                                            return this.generateReferenceField(field, "custom_terminology_fields");
                                        }
                                    }
                                })()
                            }
                            {
                                (() => {
                                    if(this.state.custom_fields.enabled) {
                                        if (this.state.custom_fields.additional_fields.length !== 0) {
                                            let custom_html = [];
                                            this.state.custom_fields.additional_fields.forEach((item) => {
                                                if(item.isActive){
                                                    const field = this.generateadditionalField(item, "additional_fields");
                                                    custom_html.push(field);
                                                }
                                            })
                                            return custom_html;
                                        }
                                    }
                                })()
                            }

                        </Style.ModalContainertop>
                        <div style={{padding: "10px"}}>
                            <Style.ModalNameLabelDiv>Attachments</Style.ModalNameLabelDiv>
                            <Style.ModalNameInputDiv>
                                <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} />
                                {/*<Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.binFiles} />*/}
    
                            </Style.ModalNameInputDiv>
                        </div>
                    </Style.ModalBodyInner>
                </Style.ModalBody>
                <div style={{clear:"both"}} ref={el => { this.el = el; }}></div>
                <Style.CancelSubmitButtonOuterContainer>
                    <Style.CancelButton onClick={this.closeModal}>Cancel</Style.CancelButton>
                    {
                        (() => {
                            if(!this.state.is_readonly){
                                return(<div style={{display:"contents"}}>
                                    <Style.SubmitButton onClick={() => this.askCARQuestion(0)}>Save & Close</Style.SubmitButton>
                                    <Style.SubmitButton onClick={() => this.askCARQuestion(1)}>Submit</Style.SubmitButton>
                                </div>)
                            }
                        })()
                    }

                    {
                        (() => {
                            if(Store.getStoreData('role')!='team' && Store.getStoreData('role')!='jr_ca' && Store.getStoreData('role')!='line_manager') {
                                return <Style.SubmitButton onClick={() => this.askCARQuestion(2)}>Continue</Style.SubmitButton>;
                            }else if(Store.getStoreData('role') === 'jr_ca' && parseInt(this.state.cur_recever_id) === 0) {
                                return <Style.SubmitButton onClick={() => this.askCARQuestion(2)}>Continue</Style.SubmitButton>;
                            }
                        })()
                    }
                </Style.CancelSubmitButtonOuterContainer>
            </Style.ModalContainer>
            <AlertBox alertParam = {this.state.alert_param} />
            </div>
        );
    }
}
export default AskQuestionModal;