		let data = {table_headers: [], data: []};
        data.module = {id: 'faq', display: 'FAQ'}
		data.table_headers = [
            {
                "accessor": "faq_id", "nickname": "ID", type: 'alpha', width: 2,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                "accessor": "question", "nickname": "Question", type: 'alpha', width: 4,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                "accessor": "answer", "nickname": "Answer", type: 'alpha', width: 4,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                "accessor": "published_date", "nickname": "Published Date", type: 'date', width: 2,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                "accessor": "published_month", "nickname": "Month", type: 'month', width: 2,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                "accessor": "published_by", "nickname": "Published By", type: 'alpha', width: 2,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                "accessor": "company_name", "nickname": "Company", type: 'alpha', width: 2,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                "accessor": "ext_advice", "nickname": "Ext Advice", type: 'alpha', width: 2,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                "accessor": "tags", "nickname": "Tags", type: 'alpha', width: 1,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                "accessor": "topic_value", "nickname": "Topic", type: 'alpha', width: 2,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            
        ];

        data.sortby = {
            fields: [
                {"accessor": "faq_id", "nickname": "ID"},
                {"accessor": "question", "nickname": "Question"},
                {"accessor": "answer", "nickname": "Answer"},
                {"accessor": "published_date", "nickname": "Published Date"},
                {"accessor": "published_by", "nickname": "Published By"},
                {"accessor": "published_month", "nickname": "Published Month"},
                {"accessor": "company_name", "nickname": "Company"},
                {"accessor": "ext_advice", "nickname": "Ext Advice"},
                {"accessor": "tags", "nickname": "Tags"},
                {"accessor": "topic_value", "nickname": "Topic"}
            ],
            selected: {"accessor": "none", "order": "Descending"}
        };
        data.groupby = {
            fields: [
                {"accessor": "faq_id", "nickname": "ID"},
                {"accessor": "question", "nickname": "Question"},
                {"accessor": "answer", "nickname": "Answer"},
                {"accessor": "published_date", "nickname": "Published Date"},
                {"accessor": "published_by", "nickname": "Published By"},
                {"accessor": "published_month", "nickname": "Published Month"},
                {"accessor": "company_name", "nickname": "Company"},
                {"accessor": "ext_advice", "nickname": "Ext Advice"},
                {"accessor": "tags", "nickname": "Tags"},
                {"accessor": "topic_value", "nickname": "Topic"}
            ],
            selections: []
        };

        data.font_size_map = {
            section_titles :    {default : {selected : false, size : 16}, small : {selected : false, size : 14}, large : {selected : true, size : 18}},
            introductory_text : {default : {selected : false, size : 12}, small : {selected : false, size : 11}, large : {selected : true, size : 14}},
            header_row_text :   {default : {selected : true, size : 12}, small : {selected : false, size : 11}, large : {selected : false, size : 14}},
            table_data_text :   {default : {selected : true, size : 11}, small : {selected : false, size : 10}, large : {selected : false, size : 12}},       
            footer:             {default : {selected : true, size : 10}, small : {selected : false, size : 9}, large : {selected : false, size : 11}}
        }

module.exports = data