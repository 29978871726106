import React from 'react';
import CSLTablemodule from '../Common/CSLTablemodule';
import styled from 'styled-components';
import Addtag from './Addtag';
import APICall from '../../Common/APICall.js';
import { FaAngleDoubleRight, FaAngleDoubleLeft, FaAngleRight, FaAngleLeft, FaPlusCircle} from "react-icons/fa";
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning } from "react-icons/io";
import ContextMenu from "../Common/ContextMenu"
import AlertBox from '../Common/AlertBox'
import CSLLoader from '../Common/CSLLoader';


const Box = styled.div`
	border: 1px solid #395875;
	padding: 6px;
	height: 13px;
	border-radius: 4px;
	margin-bottom: 20px;
	background-color: #335575;
`
const GeneralContainer = styled.div`
	width: 90%;
`;
const GeneralInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 10px;
`;
const GeneralLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 20px;
`;

const TableHeaderIcon = styled.div`
    float: right;
    margin-right: 40px;
    margin-top: 20px;
    color: #c0c0c0;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 700px;
  padding-left: 20px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 0.8;
`;

const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const RadioBlock = styled.div`
	margin-top: 5px;
`;


class Tags extends React.Component
{

		state = {general: null, show_modal: null, used_tags: {}, alert_param: null, tag:{}, loading: false};
		constructor(props) {
			super(props);
		}
		componentDidMount(){
	        let general = this.props.general;
	        let used_tags = this.props.used_tags;
	        console.log("used_tags=>", used_tags)
	        this.setState({general: general, used_tags: used_tags})
	    }
	
	    selectTags = (event) => {
	    	event.preventDefault();
	    	let general = this.state.general;
	    	for(let tag_id of Object.keys(general.tags)) {
	    		if(!general.tags[tag_id].included && general.tags[tag_id].selected) {
	    			general.tags[tag_id].included = true;
	    			general.tags[tag_id].selected = false;
	    		}
	    	}
	    	// this.props.updateGeneral(general)
	    	this.props.topicIncludedExclude(general);
	    }

	    deSelectTags = (event) => {
	    	event.preventDefault();
	    	let general = this.state.general;
	    	let used_tags = this.state.used_tags;
	    	console.log("used tags", used_tags, general)
	    	let used = false;
	    	for(let tag_id of Object.keys(general.tags)) {
	    		if(general.tags[tag_id].included && general.tags[tag_id].selected) {
	    				if (typeof used_tags[tag_id] === "undefined") {
	    					general.tags[tag_id].included = false;
	    					general.tags[tag_id].selected = false;
	    				}else{
	    					used = true;
	    				}
	    		}
	    	}
	    	if(used){
	    		let msg = 'Tag(s) already in use.';
                let title = 'Alert';
	    		// alert("Tag(s) already in use.");
	    		let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.sameAlertremoveHandler, stack: {id: 0}}
                this.setState({alert_param: alert_param})
	    		return false;
	    	}
	    	// this.props.updateGeneral(general)
	    	this.props.topicIncludedExclude(general);
	    }
	    deSelectAllTags = (event) => {
	    	event.preventDefault();
	    	let general = this.state.general;
	    	let used_tags = this.state.used_tags;
	    	let used = false;
	    	for(let tag_id of Object.keys(general.tags)) {
	    		if (typeof used_tags[tag_id] === "undefined") {
	    			general.tags[tag_id].included = false;
	    			general.tags[tag_id].selected = false;
	    		}else{
	    			used = true;
	    		}
	    	}
	    	if(used){
	    		let msg = 'Tag(s) already in use.';
                let title = 'Alert';
	    		// alert("Tag(s) already in use.");
	    		let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.sameAlertremoveHandler, stack: {id: 0}}
                this.setState({alert_param: alert_param})
	    		return false;
	    	}
	    	// this.props.updateGeneral(general)
	    	
	    }
	    selectAllTags = (event) => {
	    	event.preventDefault();
	    	let general = this.state.general;
	    	for(let tag_id of Object.keys(general.tags)) {
    			general.tags[tag_id].included = true;
    			general.tags[tag_id].selected = false;
	    	}
	    	// this.props.updateGeneral(general)
	    	this.props.topicIncludedExclude(general);
	    }

	    
    	selectTag = (id) => (event) => {
    		event.preventDefault();	
    		let general = this.state.general;
    		general.tags[id].selected = general.tags[id].selected ? false : true;
    		// member.selected = true;
    		this.setState({general: general})	  
    	}

    	handleTopicIncluded = (tag_id) => (event) => {
    		/*let general = this.state.general;
    		general.tags[tag_id].enabled_topic = ('enabled_topic' in general.tags[tag_id] && general.tags[tag_id].enabled_topic) ? false : true;
    		this.props.topicIncludedExclude(general);*/
    		event.preventDefault()
    		let postData = {command: "include_topic", tag_id: tag_id}
    		let api = new APICall();
    		api.indexCommand(postData, this.handletag);
    		this.setState({is_loading: true})
    	}

    	handleTagIncluded = (tag_id) => (event) => {
    		event.preventDefault();	
    		/*let general = this.state.general;
    		general.tags[tag_id].included = ('included' in general.tags[tag_id] && general.tags[tag_id].included) ? false : true;
    		this.props.topicIncludedExclude(general);*/

    		let postData = { command: "include_tag", tag_id: tag_id};
    		let api = new APICall();
    		api.indexCommand(postData, this.handletag);
    		this.setState({is_loading: true})

    	}

    	handletag = (result) => {
    		console.log("handleTagIncluded", result)
    		this.setState({general: JSON.parse(JSON.stringify(result.result.general)), is_loading: false});
    		this.props.updateGeneral(result.result.general)
    	}

    	handleCheckbox = (event) => {
    		event.preventDefault();	
    		let general = this.state.general;
    		general.include_other = general.include_other ? false : true;
    		this.props.updateGeneralSave(general)
    	}

    	editTag = (id) => {
    		let general = this.state.general;
    		let tag = general.tags[id]
    		console.log("edittag", tag)
    		this.setState({show_modal: 'add_tag_modal',tag:tag})
    	}

    	deleteTag = (id) => {
    		let msg = "Are you sure you want to delete this tag?";
    		let title = 'Confirm';
    		let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: true, alertHandler: this.confirmAlertHandler, stack: {tag_id: id}}
    		this.setState({alert_param: alert_param})
    	}

    	confirmAlertHandler = (result, stack) => {
    	  if (!result || stack.prevent) {
    	    this.setState({ alert_param: null, show_modal: null });
    	    return;
    	  }
    	  this.setState({ alert_param: null});
    	  let tag_id = stack.tag_id;
    	  let general = this.state.general;
    	  let tag = general.tags[tag_id]
    	  let postData = { command: "delete_tag", tag_id: tag_id, original_id:tag.original_id };
    	  console.log("confirmAlertHandler", postData, tag, tag_id)
    	  let api = new APICall();
    	  api.indexCommand(postData, this.removeOwncartag);

    	}

    	removeOwncartag = (result) => {
    	    console.log("removeOwncartag", result)
    	    let alert_param = { title: "Success", message: result.error_msg, ok_text: "OK", confirm: false, alertHandler: this.AlertremoveHandler, stack: result };
    	    this.setState({ alert_param: alert_param});
    	}

    	AlertremoveHandler = (result, stack) => {
    		if (!result || stack.prevent) {
    		  this.setState({ alert_param: null});
    		  return;
    		}
    		this.setState({general: JSON.parse(JSON.stringify(stack.result)), alert_param: null});
    	}





    	showallTags = () => {
    		let ret = {data: [], columns: []}
    		const menu_options = [
    		    {name: "edit", nick_name: "Edit", clickHandler: this.editTag},
    		    {name: "remove", nick_name: "Delete", clickHandler: this.deleteTag}
    		]
    		const menu_options1 = [
    		    {name: "edit", nick_name: "Edit", clickHandler: this.editTag}
    		]
    		ret.columns =[{'Header' : 'Tag Name',accessor: 'name', Cell: row => (
    			                	<label  style={{cursor: 'pointer', fontWeight:'400', fontSize: '12px'}}>{row.original.name}</label>
    				         ),headerStyle: {textAlign: 'left'}},
									   {'Header' : 'Show as Topic',accessor: 'enabled_topic', show: this.state.general.enabled_topics, Cell: row => (
									   	<div>
									   				<div style={{cursor: "pointer", textAlign: "center"}}><input type = 'checkbox' onClick={this.handleTopicIncluded(row.original.id)} checked={row.original.enabled_topic}/></div>
									   	</div>
									    ), headerStyle:{textAlign: 'center'}},
									   {'Header' : 'Show as Tag',accessor: 'enabled_tag', Cell: row => (
									   	<div style={{display:"inline"}}>
									   		   <div style={{cursor: "pointer", textAlign: "center"}}><input type = 'checkbox' onClick={this.handleTagIncluded(row.original.id)} checked={row.original.enabled_tag}/></div>
									   	</div>
									    ), headerStyle:{textAlign: 'center'}},
									   {
									           Header: () => null,disableSortBy: true,disableFilters: true,
									           Cell: row => (<div style={{cursor: "pointer", textAlign: "center"}}>
						           									   		   {
						           									   		   	(() => {
						           									   		   		if(row.original.owntag === 0) {
						           									   		   	   	return <ContextMenu row_id={row.original.id} options={menu_options1}/>
						           									   		   		}else{
						           									   		   			return <ContextMenu row_id={row.original.id} options={menu_options}/>
						           									   		   		}

						           									   		   	})()
						           									   		   }
									           							</div>), headerStyle:{textAlign: 'center'}
									         }
    						];
    		
    		for(let tag_id of Object.keys(this.state.general.tags)) {
    			ret.data.push({
    				name: this.state.general.tags[tag_id].TagName, 
    				id: tag_id,
    				enabled_topic:('enabled_topic' in this.state.general.tags[tag_id]) ? this.state.general.tags[tag_id].enabled_topic : false, 
    				enabled_tag: ('included' in this.state.general.tags[tag_id]) ? this.state.general.tags[tag_id].included : false, 
    				owntag: this.state.general.tags[tag_id].owntag})
    		}
    		ret.data.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
    		return ret;
    	}

	    availableTags = () => {
	    	let ret = {data: [], columns: []}

	    	ret.columns =[{'Header' : 'Available Tags',accessor: 'name', Cell: row => (
	    		                <div onClick={this.selectTag(row.original.id)} style={{backgroundColor: row.original.selected ? '#ceeeed': '#ffffff', cursor:'pointer'}}>
	    		                	<label  style={{cursor: 'pointer', fontWeight:'400', fontSize: '12px'}}>{row.original.name}</label>
	    		            	</div>
	    			         ),headerStyle: {textAlign: 'left'}},
	    					];

			for(let tag_id of Object.keys(this.state.general.tags)) {
				if(!this.state.general.tags[tag_id].included ){
					ret.data.push({name: this.state.general.tags[tag_id].TagName, id: tag_id, selected: this.state.general.tags[tag_id].selected})
				}
			}
			ret.data.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );

			return ret;
	    }

	    selectedTags = () => {
	    	let ret = {data: [], columns: []}
	    	if(this.state.general.enabled_topics){
	    		ret.columns =[{'Header' : 'Selected',accessor: 'name', Cell: row => (
	    			                <div onClick={this.selectTag(row.original.id)} style={{backgroundColor: row.original.selected ? '#ceeeed': '#ffffff', cursor:'pointer'}}>
	    			                <label  style={{cursor: 'pointer', fontWeight:'400', fontSize: '12px'}}>{row.original.name}</label>
	    			            	</div>
	    				         ),headerStyle: {textAlign: 'left'}},
	    					{'Header' : 'Enable as Topic', Cell: row => (
	    						<div>
	    							   {
	    								   (()=> {
	    									   if (row.original.enabled_topic) {
	    										   return(<div style={{cursor: "pointer",  float: "right"}} onClick={this.handleTopicIncluded(row.original.id)}><IoIosCheckboxOutline /></div>);
	    									   } else {
	    										   return(<div  style={{cursor: "pointer",  float: "right"}} onClick={this.handleTopicIncluded(row.original.id)}><IoIosSquareOutline /></div>);
	    									   }
	    								   })()
	    							   }
	    						</div>
	    					 ), headerStyle:{textAlign: 'center'}}
	    						];
	    	}else{
	    		ret.columns =[{'Header' : 'Selected',accessor: 'name', Cell: row => (
	    			                <div onClick={this.selectTag(row.original.id)} style={{backgroundColor: row.original.selected ? '#ceeeed': '#ffffff', cursor:'pointer'}}>
	    			                <label  style={{cursor: 'pointer', fontWeight:'400', fontSize: '12px'}}>{row.original.name}</label>
	    			            	</div>
	    				         ),headerStyle: {textAlign: 'left'}}
	    						];
	    	}


			for(let tag_id of Object.keys(this.state.general.tags)) {
				if(this.state.general.tags[tag_id].included ){
					ret.data.push({name: this.state.general.tags[tag_id].TagName, id: tag_id, selected: this.state.general.tags[tag_id].selected, enabled_topic: 'enabled_topic' in this.state.general.tags[tag_id] ? this.state.general.tags[tag_id].enabled_topic : false })
				}
			}
			ret.data.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
			return ret;
	    }

	    topicTags = () => {
	    	    	let ret = {data: [], columns: []}

	    	    	ret.columns =[{'Header' : 'Name',accessor: 'name', headerStyle: {textAlign: 'left'}}];

	    			for(let tag_id of Object.keys(this.state.general.tags)) {
	    				if(this.state.general.tags[tag_id].enabled_topic ){
	    					ret.data.push({name: this.state.general.tags[tag_id].TagName, id: tag_id})
	    				}
	    			}
	    			ret.data.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );

	    			return ret;
	    }

	    AddTag = (event) => {
	        event.preventDefault();
	        let tag = {};
	        tag.TagName = ''
	        tag.TagID = 0
	        this.setState({show_modal: 'add_tag_modal', tag: tag});
	    }

	    closereloadModal = (result) => {
    		let alert_param = {title: 'Alert', message: result.error_msg, ok_text: 'OK', confirm: false, alertHandler: this.sameAlertremoveHandler, stack: {id: 0}}
	    	this.setState({show_modal: null, general: JSON.parse(JSON.stringify(result.result)), alert_param: alert_param});
	    }

	    closeModal = ()=> {
	    	this.setState({show_modal: null});
	    }

	    sameAlertremoveHandler = (result,stack) => {
	    	let general = this.state.general;
	        this.setState({alert_param: null})
	        // this.props.updateGeneral(general)
	        this.props.topicIncludedExclude(general);
	    }

	    switchRadio = (key) => (event) => {
	    	event.preventDefault();
	    	let {general} = this.state;
	    	general[key] = general[key] ? false : true;
	    	// this.props.updateGeneral(general);
	    	this.props.topicIncludedExclude(general)
	    }

		render()
		{
			if(this.state.general === null)return <div>Loading...</div>

			console.log(this.state)
			return (
				<div>
		            <GeneralContainer>
			            {
			            	(() => {
			            		if (this.state.show_modal !== null) {
			            			return (<div><InactiveOverlay style={{top: '66px'}} /><Addtag  closereloadModal={this.closereloadModal} closeModal={this.closeModal} tag={this.state.tag}/></div>);
			            		}
			            	})()
			            }
	                	<TableHeaderIcon><FaPlusCircle onClick={this.AddTag} style={{cursor: "pointer", color: "#04ada8", height:"16px", width:"16px"}}/></TableHeaderIcon>
	                	
	                	<GeneralInnerContainer>
	                		<GeneralLabel>Enable Topics</GeneralLabel>
	                		{
	                			(() => {
	                				if (this.state.general.enabled_topics) {
	                					return (
	                						<RadioBlock>
	                							<SelectedRadio>Yes</SelectedRadio>
	                							<UnselectedRadio onClick={this.switchRadio('enabled_topics')}>No</UnselectedRadio>
	                						</RadioBlock>
	                					);
	                				} else {
	                					return (
	                						<RadioBlock>
	                							<UnselectedRadio onClick={this.switchRadio('enabled_topics')}>Yes</UnselectedRadio>
	                							<SelectedRadio>No</SelectedRadio>
	                						</RadioBlock>
	                					);
	                				}
	                			})()
	                		}
	                	</GeneralInnerContainer>
	                	<GeneralInnerContainer>
	                		
	                		{
	                		    (() => {
	                		        if(this.state.is_loading) {
	                		            return <CSLLoader style={{position: 'absolute'}} />
	                		        }
	                		    })()
	                		}
	                		<CSLTablemodule add={false} processData={this.showallTags} headerText={''} tableRows={10} />
    		    					{
    		    						(() => {
    		    							if(this.state.general.enabled_topics){
    										return(
    												<div style={{marginTop: "2px", backgroundColor: "#ffffff", width: "100%", borderRadius: "3px",padding: "5px", float: "left" }}>		
    						    						<div style={{marginTop: "5px", fontWeight:'600'}}>
    						    							<input type="checkbox" checked={this.state.general.include_other} onChange={this.handleCheckbox}/> Include Other </div>	
    						    					</div>
    										    	)
    		    							}
    		    						})()
    		    					}
	                	</GeneralInnerContainer>
	                	{/*<GeneralInnerContainer>
	    	            	<div style={{marginTop: "2px", backgroundColor: "#ffffff", width: "calc(50% - 25px)", borderRadius: "3px",padding: "5px", float: "left"}} >				
	    	            		<CSLTablemodule add={false} processData={this.availableTags} headerText={''} tableRows={10} />
	    	            	</div>
				    					<div style={{paddingTop: "140px", borderRadius: "3px",float: "left", width: '30px'}}>				
				    						<Box style={{cursor: "pointer"}} onClick={this.selectAllTags}><FaAngleDoubleRight style={{color: "#ffffff"}} /></Box>
				    						<Box style={{cursor: "pointer"}} onClick={this.selectTags}><FaAngleRight style={{color: "#ffffff"}} /><br/><br/></Box>
				    						<Box style={{cursor: "pointer"}} onClick={this.deSelectTags}><FaAngleLeft  style={{color: "#ffffff"}}/><br/><br/></Box>
				    						<Box style={{cursor: "pointer"}} onClick={this.deSelectAllTags}><FaAngleDoubleLeft  style={{color: "#ffffff"}}/><br/><br/></Box>
				    					</div>
				    					<div style={{marginTop: "2px", backgroundColor: "#ffffff", width: "calc(50% - 25px)", borderRadius: "3px",padding: "5px", float: "left" }}>	
				    						<CSLTablemodule add={false} processData={this.selectedTags} headerText={''} tableRows={10} />		
				    					</div>
	    					
				    					{
				    						(() => {
				    							if(this.state.general.enabled_topics){
												return(
														<div style={{marginTop: "2px", backgroundColor: "#ffffff", width: "100%", borderRadius: "3px",padding: "5px", float: "left" }}>	
								    						<div style={{fontWeight:'600', fontSize: '12px'}}>Topics</div>
								    						<CSLTablemodule add={false} processData={this.topicTags} headerText={''} tableRows={10} />	
								    						<div style={{marginTop: "5px", fontWeight:'600'}}>
								    							<input type="checkbox" checked={this.state.general.include_other} onChange={this.handleCheckbox}/> Include Other </div>	
								    					</div>
												    	)
				    							}
				    						})()
				    					}
	    								<div style={{clear: "both"}}></div>
	    							</GeneralInnerContainer>*/}
		            </GeneralContainer>
		            <AlertBox alertParam = {this.state.alert_param} />
		        </div>
			);
		}
}
export default Tags;