import React from 'react';
import Store from '../../Common/Store.js';
import Utils from '../../Common/Utils.js';
import * as Style from '../Common/StyledComponents'

class ClarificationMessage extends React.Component
{
	render() {
		// console.log('this.props.clarification', this.props.clarification)
		let c = this.props.clarification
		// console.log('c', c)
		let cardata = this.props.cardata; //Store.getStoreData('cur_a_modal_data');
		// console.log('cardata', cardata)
		let object_data = JSON.parse(cardata.task_json).object_data
		// console.log('object_data', object_data)
		let contact = Store.getStoreData('contact');
		// console.log('contact', contact)
		let contacts = Store.getStoreData('contacts');
		// console.log('contacts', contacts)
		let date = new Date(c.actiondate)
		let dateStr = date.toLocaleString('en-GB',{year: 'numeric', month: 'numeric', day: 'numeric',hour: '2-digit', minute: '2-digit'}).replace(/,/g, '')
		let bgColor = object_data.asked_by === c.performed_by ? '#F5C163' : '#04ACA7';
		

		return (
				<Style.ModalNameInputDiv>
                    <Style.ModalWiderDiv>
                        <Style.ModalWiderLineDiv style={{width: '90%'}}/>
                        <Style.ModalClearDiv />
                        <Style.ModalWiderHeaderDiv 
                        	style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px', backgroundColor: bgColor, width: '93%'}}>
                        	{contacts[c.performed_by.toString()]}&nbsp;&nbsp;&nbsp;&nbsp;{dateStr}
                        </Style.ModalWiderHeaderDiv>
                        {/*<Style.ModalTextArea style={{width: '93.3%'}} name="answer" defaultValue={c.note.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} editable={false} readOnly={true} />*/}
                        <div style={{whiteSpace:  "break-spaces", width: '93.3%' , wordWrap: "break-word", border: '1px solid #ffffff', backgroundColor:'#ffffff',borderRadius:'3px',boxShadow: '0 0 4px #c4c4c4',padding: '5px',color: '#282828',fontFamily: "'Montserrat', sans-serif"}} name="answer" editable={false} readOnly={true}>{c.note.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} </div>
                    </Style.ModalWiderDiv> 
                </Style.ModalNameInputDiv>
   			)
	}
} 

export default ClarificationMessage;