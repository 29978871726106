import React from 'react';
import {FaTimes} from 'react-icons/fa';
import styled from 'styled-components';
import AlertBox from '../../Common/AlertBox';
import Toggle from 'react-toggle';
import "./ReactToggle.css";
import Utils from '../../../Common/Utils';
import * as Style from '../../Common/StyledComponents';
import CslSimpleDropDown from './CslSimpleDropDown';
import APICall from '../../../Common/APICall.js';
import SearchableSelect from 'react-select';
import './SearchableSelect.css';

const EmailContainer = styled.div`
	width: 596px;
	top:50px;
	position: absolute;
	background-color: #f6f9fd;
    border-radius: 2px;
    box-shadow: 0 0 20px #a7a6a6;
	z-index: 1005;
	padding: 0px;
`;
const EmailInput = styled.input`
    height: 30px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
`;
const EmailTextArea = styled.textarea`
    height: 150px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-family: 'Montserrat', sans-serif;
`;
const VariablePill = styled.div`
	padding: 10px 15px;
	background-color: #ffffff;
	color: #222222;
	font-weight: 600;
	border: 1px solid #222222;
	display: inline-block;
	margin-left: 5px;
	margin-right: 5px;
	margin-top: 10px;
	border-radius: 2em;
	cursor: pointer;
`;
const CancelBtn = styled.button`
    padding: 10px 30px;
	background-color: #ffffff;
	border: 1px solid #EBEBEB;
	border-radius: 2px;
	color: #282828;
	cursor: pointer;	
`;
const SubmitBtn = styled.button`
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 4px;
	padding: 10px 30px;
	cursor: pointer;
	color: #ffffff;
`;
const MRModalLabel = styled.div`
    font-family: "Roboto-Medium", "Roboto Medium", "Roboto", sans-serif;
    font-weight: 600;
    color: #1a3552;
    margin-top: 10px;
    margin-bottom: 10px;
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 95%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    padding-right: 10px;
    padding-left: 10px;
    color: #000000;
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 40px 40px 15px 40px;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    color: #1b3452
    font-weight: 600;
    font-size: 20px;
    font-family: "Roboto-Bold", "Roboto Bold", "Roboto", sans-serif;
    font-weight: 700;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #1b3452
    cursor: pointer;
    font-size: 20px;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 40px 0px 40px;
    height:474px;
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 70%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #727272;
    margin-top: 10px;
    margin-bottom: 10px;
`;
const SaveBtn = styled.button`
	padding: 10px 30px;
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	margin-left: 15px;
`; 

const DocFooter = styled.div`
    position: relative;
    background-color: white;
    border-style: solid;
    border-width: 1px;
    border-color: #e9ecef;
    height: 79px;
    width: 100%;
    margin-left: 0px;
    border-bottom-radius: 2px;
`;
const FooterButtonsContainer = styled.div`
	text-align: right;
	padding-top: 25px;
	width: 95%;
`; 

const SubHeading = styled.div`
	font-style: italic;
	font-size: 12px;
	color: #adacac;
	margin-top: 5px;
`;
const DropdownOptions = styled.textarea`
	border: 1px solid #ffffff;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 0 4px #c4c4c4;
    resize: none;
    padding: 5px;
    width: 98%;
    font-family: 'Montserrat',sans-serif;
    margin-top: 7px;
`;
const ScoreBodyContainer = styled.div`
  padding: 10px;
  text-align: left;
  overflow-y: scroll;
  height: 555px;
`;

const UnselectedPill = styled.div`
    background-color: #ededed;
    color: #a6a6a6;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;
const SelectedPill = styled.div`
    background-color: #37ada7;
    color: #ffffff;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;


class CustomFieldsConfigModal extends React.Component
{
	state = {ready: false,alert_param: null, 
        name: null, 
        nickname: "",
        type: "Alpha",
        dropdown_options: [],
        required: false,
        other: false,
        isActive: true,
        custom_fields: null,
        inner_data: null,
        dropdown_options_new: "" ,
        topics: [],
        selected_topics: [],
        enabled_topics: false
    };

	componentDidMount()
	{        
        
        let rawtags = this.props.topics;
        let enabled_topics = this.props.enabled_topics;
        let st = this.props.content !== null && 'topics' in this.props.content ? this.props.content.topics : {};
        console.log("rawtags", rawtags, st);
        
        // let topics = [];
        let selected_topics = [];
        /*let selected_topics = [];
        rawtags.forEach((top) => {
            topics.push({
                label: top.name,
                value: top.id
            });
            if(this.props.curid === 0){
                selected_topics.push({ 'value': top.id, 'label': top.name , "background-color": "red"})
            }else{
                if(st[top.id]){
                    selected_topics.push({ 'value': top.id, 'label': top.name , "fontWeight": "600"})
                }
            }
        })

        console.log("topics", topics);*/
        let topics = [];
        rawtags.forEach((top) => {
            topics.push({
                name: top.name,
                id: top.id,
                styledcomponent: this.props.curid === 0 ? UnselectedPill : st[top.id] ? SelectedPill : UnselectedPill ,
                selected: this.props.curid === 0 ? false : st[top.id] ? true : false
            });
        })
        topics.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );

        if(this.props.curid !== 0) {
            let dropdown_options_new = "";
            if (this.props.content.dropdown_options.length !== 0) {
                this.props.content.dropdown_options.forEach((item, index) => {
                    if (index !== (this.props.content.dropdown_options.length - 1)) {
                        dropdown_options_new = dropdown_options_new + item + "\n";
                    } else {
                        dropdown_options_new = dropdown_options_new + item;
                    }
                })
            }
            this.setState({ready:true,
                dropdown_options_new, 
                name: this.props.content.name,
                nickname: this.props.content.nickname,
                type: this.props.content.type,
                dropdown_options: this.props.content.dropdown_options,
                required: this.props.content.required,
                other: this.props.content.other,
                isActive: 'isActive' in  this.props.content ? this.props.content.isActive : true,
                custom_fields: this.props.data,
                topics: topics,
                selected_topics: selected_topics,
                enabled_topics: enabled_topics
            })    
        }else{ 
            const randtxt = Utils.genKey(10);            
            this.setState({name: randtxt, ready:true, custom_fields: this.props.data, topics: topics, enabled_topics: enabled_topics, selected_topics: selected_topics});            
        }
	}

	closeModal = (event) => {
		event.preventDefault();
		this.props.closeModal();
    }
    
    handleChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
    }

    submitCustom_validation = (event) => {
        event.preventDefault();
        let problems = false
        let message = ''
        
        if(this.state.dropdown_options.includes('Other')){
            problems = true
            message += 'Please remove Other option from the dropdown.'
        }

        if(this.state.nickname === "")
        {
            problems = true
            message += 'Please provide a custom field name.'
        }
        if(problems) {
            let alert_param = {title: 'ERROR', message: message, ok_text: 'Ok', confirm: false, 
                           alertHandler: this.checkvalidation, stack: {}}

            this.setState({alert_param: alert_param})            
        } else {
            this.checkvalidation(false, {})
        }
    } 

    checkvalidation = (result, stack) => {        
        this.setState({alert_param: null})
        if(!result){
            this.submitCustomField()
        }        
    }

    submitCustomField = () => {
        //event.preventDefault();   
        let c = {}
        c.name = this.state.name;
        c.nickname = this.state.nickname;            
        c.type = this.state.type;            
        c.dropdown_options = this.state.dropdown_options;            
        c.required = this.state.required;            
        c.other = this.state.other;             
        c.isActive = this.state.isActive;    
        let selectedtags = {};
          this.state.topics.forEach((tag) => {
              if (tag.selected) selectedtags[tag.id.toString()] = 1;
          })
        c.topics = selectedtags;


        const postData = { command: "save_customfield", custom_field: c, curid: this.props.curid };
        console.log(postData)
        const api = new APICall();
        api.indexCommand(postData, this.addCustomfield);
    }

    addCustomfield = (result) => {
        console.log("addCustomfield", result)
        this.props.saveCustomeField(result)
    }

    handleToggleChangeother = (arg)=>{
        let {other} = this.state;
        other = other ? false : true;
        this.setState({other})
	}
    handleRequiredToggleChange = (arg)=>{
		let {required} = this.state;
		required = required ? false : true;		
		this.setState({required})
	}
    handleToggleChangeStatus = (arg) => {
        let {isActive} = this.state;
        isActive = isActive ? false : true;
        this.setState({isActive})
    }

    changeFieldType = (selected_type) => {		
        this.setState({type: selected_type})
	}
    changeDisplayFieldType = (selected_type) => {		
        this.setState({displayin: selected_type})
	}

    modifyDropdownOptions = (event) => {
		event.preventDefault();
		let custom_fields = JSON.parse(JSON.stringify(this.state.custom_fields));
		
        const trimedvalue = event.target.value;
        let optarr = trimedvalue.split("\n");
        console.log("optarr", optarr)

        if (optarr.length === 1 && optarr[0] === "") {
            optarr = [];
        }
        let dropdown_options = optarr;
			
		
		let dropdown_options_new = this.state.dropdown_options;
		dropdown_options_new = event.target.value;
		this.setState({dropdown_options_new, dropdown_options});		
	}

    selectOrUnselectTopic = (topic_id) => (event) => {
        event.preventDefault();
        let { topics } = this.state;
        topics.forEach((tag) => {
            if (tag.id === topic_id) {
                if (tag.selected) {
                    tag.selected = false;
                    tag.styledcomponent = UnselectedPill;
                } else {
                    tag.selected = true;
                    tag.styledcomponent = SelectedPill;
                }
            }
        })

        this.setState({ topics });
    }

    handleSelectEmplyee = (data) => {        
      console.log("data==>", data)
      this.setState({selected_topics: data})
      // let se_obj = {}
      // for(let d of data){
      //   se_obj[d.value.toString()] = 1
      // }

      // console.log("se_obj==>", se_obj)
      // let updt_emply = {}
      // for(let k in this.state.employee_object){
      //   if(k.toString() in se_obj){
      //     updt_emply[k.toString()] = 1
      //   }else{
      //     updt_emply[k.toString()] = 0
      //   }
      // }

      // let new_emp = {}
      // for(let k in se_obj){
      //   if(!(k in updt_emply)){
      //     new_emp[k.toString()] = 1
      //   }
      // }
      // let merged = {...updt_emply, ...new_emp};
      // this.setState({selected_employees: data, employee_object: merged, ischange: true})
    }	

	render()
	{
		if (!this.state.ready) {
			return (<div>Loading...</div>);
		}
		return (
            <div>
		
		    <EmailContainer id="root_cause">
                <MRModalHeader>
                    <MRModalHeaderText>{this.props.headerText} Custom Field</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>                
                </MRModalHeader>    
                <ScoreBodyContainer>        
                <MRModalBody>
                    <MRModalLabel style={{display: "block"}}>CUSTOM FIELD NAME <span style={{marginLeft: "5px",color: "red"}}>*</span></MRModalLabel>
                    <MRModalInput name="nickname" value={this.state.nickname} onChange={this.handleChange}/>

                    <MRModalLabel style={{marginTop: "30px",display: "block"}}>FIELD TYPE </MRModalLabel>                    
					<CslSimpleDropDown options={this.state.custom_fields.available_field_types} selected={this.state.type} changeFieldType={this.changeFieldType} />
                    {
                        (() => {
                            if (this.state.type === "Dropdown") {
                                return (
                                    <div style={{padding: "10px 0px"}}>
                                        <SubHeading>Configure the dropdown options. New line separates options.</SubHeading>
                                        <DropdownOptions rows="7" value={this.state.dropdown_options_new} onChange={this.modifyDropdownOptions} />
                                    </div>
                                );
                            }
                        })()
                    }

                    {/*{
                        (() => {
                            if(this.state.enabled_topics){
                                return(<div>
                                    <MRModalLabel style={{marginTop: "30px",display: "block"}}>TOPICS</MRModalLabel>
                                    <SubHeading>Specify which Tiopic(s) this Custom Field applies to</SubHeading>
                                    <div style={{width: "100%", marginTop: "5px"}}>
                                      <SearchableSelect isMulti options={this.state.topics} placeholder="Select Topics" value={this.state.selected_topics} onChange={this.handleSelectEmplyee} />
                                    </div>
                                </div>)
                            }
                        })()
                    }*/}

                    
                    {
                        (() => {
                            if (this.state.type === "Dropdown") {
                                return (
                                    <div style={{padding: "10px 0px"}}>
                                        <MRModalLabel style={{marginTop: "30px",display: "block"}}>Include 'Other' Option?</MRModalLabel>
                                        <SubHeading>User will be required to provide a note or comment.</SubHeading>
                                        <Toggle name="other"
                                            defaultChecked={this.state.other} 
                                            icons={false}
                                            onChange={()=> this.handleToggleChangeother('other')} />  
                                    </div>
                                );
                            }
                        })()
                    }


                    <MRModalLabel style={{marginTop: "30px",display: "block"}}>REQUIRED?</MRModalLabel>
                    <Toggle name="isActive"
					    defaultChecked={this.state.required} 
					    icons={false}
					    onChange={()=> this.handleRequiredToggleChange('required')} />   

                    <MRModalLabel style={{marginTop: "30px",display: "block"}}>STATUS</MRModalLabel>
                    <Toggle name="isActive"
					    defaultChecked={this.state.isActive} 
					    icons={false}
					    onChange={()=> this.handleToggleChangeStatus('isActive')} /> 

                        
                        {
                            (() => {
                                if(this.state.enabled_topics){
                                    return(<div>
                                        <MRModalLabel style={{marginTop: "30px",display: "block"}}>TOPICS</MRModalLabel>
                                        <SubHeading>Specify which Tiopic(s) this Custom Field applies to</SubHeading>
                                        <Style.ModalNameInputDiv style={{ padding:"0px",marginTop:"20px",backgroundColor:'#fff'}}>
                                            {
                                                this.state.topics.map((tag, index) => {
                                                    return (<tag.styledcomponent key={index} onClick={this.selectOrUnselectTopic(tag.id)}>{tag.name}</tag.styledcomponent>);
                                                })
                                            }
                                        </Style.ModalNameInputDiv>
                                    </div>)
                                }
                            })()
                        }




                </MRModalBody>
                </ScoreBodyContainer>                
                <div style={{clear: "both"}}></div> 
                <DocFooter style={{float: "right",marginTop: "10px"}}>
                        <FooterButtonsContainer>   
                        <CancelBtn onClick={this.closeModal}>Cancel</CancelBtn>
                        <SaveBtn onClick={this.submitCustom_validation}>Submit</SaveBtn>
                        </FooterButtonsContainer>   
                </DocFooter>
               
			</EmailContainer>
            <AlertBox alertParam = {this.state.alert_param}/>
            </div>
		);
	}
}

export default CustomFieldsConfigModal;