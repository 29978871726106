import React from 'react';
import styled from 'styled-components';
import {FaTimes} from 'react-icons/fa';
import DatePicker from 'react-date-picker';
import Store from '../../Common/Store.js';
import APICall from '../../Common/APICall.js';
import Utils from '../../Common/Utils';
import Dropzone from '../Common/Dropzone.js'
import * as Style from '../Common/StyledComponents';
import HtmlEditor from '../Common/HtmlEditor';
import ShowFAQModal from '../FAQ/ShowFAQModal';
import CSLLoader from '../Common/CSLLoader';
import AlertBox from '../Common/AlertBox';
import IndexLoader from '../../Common/IndexLoader.js';

const InactiveOverlay = styled.div`
      display: block;
      box-sizing: border-box;
      height: 3000px;
      width: 100%;
      background-color: #ffffff;
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 1000;
      opacity: 0.6;
    `;

const UnselectedPill = styled.div`
    background-color: #ededed;
    color: #a6a6a6;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;
const SelectedPill = styled.div`
    background-color: #37ada7;
    color: #ffffff;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;

const SubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #234062
    color: #ffffff;
    width: auto;
    padding: 10px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #234062;
    margin-left: 5px;
    font-size: 11px;
    cursor:pointer;
`;

const SaveButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #223B5A;;
    color: #ffffff;
    width: auto;
    padding: 10px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor:pointer;
    `;
const CSLDateCover = styled.div`
    margin-top: 8px;
    width: 200px;
    border-radius: 3px;
    border: 1px solid #ffffff;
    box-shadow: 0 0 4px #c4c4c4;
    height: 25px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    `;



class ReopenCloseModal extends React.Component
{
        state = {ready: false, 
                alert_param: null, 
                task_data: null,         
                binFiles: [],
                cur_files_json: null,
                extbinFiles: [],
                ext_cur_files_json: null,
                view_faq: false,
                show_faq: false,
                is_loading: false,
                individuls: {}
            };

    constructor(props) {
        super(props);
    }
    componentDidMount(){
        this.processTask(this.props.task);
    }

    componentDidUpdate(prevProps){
        if (prevProps !== this.props) {
            this.processTask(this.props.task);
        }
    }

    isLoadTags = (tags) => {
        let rawtags = Store.getStoreData('tags');
        let otags = [];
        rawtags.forEach((top) => {
            if(Object.keys(tags).length === 0){
                otags.push({
                    name: top.TagName,
                    id: top.ID,
                    styledcomponent: UnselectedPill,
                    selected: false
                });
            }else {
                if(tags[top.ID.toString()] ===  top.TagName) {
                    otags.push({
                        name: top.TagName,
                        id: top.ID,
                        styledcomponent: SelectedPill,
                        selected: true
                    });
                }else{
                    otags.push({
                        name: top.TagName,
                        id: top.ID,
                        styledcomponent: UnselectedPill,
                        selected: false
                    });
                }
            }
        })
        let stagnames = [];
        otags.forEach((item) => {
            stagnames.push(item.name);
        })
        stagnames.sort();
        let ftags = [];
        for (let i=0; i<stagnames.length; i++) {
            for (let j=0; j<otags.length; j++) {
                if (stagnames[i] === otags[j].name) {
                    ftags.push(otags[j]);
                }
            }
        }
        return ftags;
    }

    processTask = (task) => {
        let contacts = Store.getStoreData('contacts');
        let moduleconfig = Store.getStoreData('moduleconfig');
        let form_data = task;
        let gc_assoc = Store.getStoreData('gc_assoc');
        let gc_company = parseInt(form_data.company_id) < 100000 ? Store.getStoreData('company').CompanyName : gc_assoc[(parseInt(form_data.company_id)%100000).toString()].company_name;
        let gc_company_id = parseInt(form_data.company_id) < 100000 ? form_data.company_id : form_data.company_id%100000;
        let list_answrere = Utils.showCompanyUsers(gc_company_id,'answrer');
        let task_json = JSON.parse(form_data.task_json);
        if (task_json.object_data.bin_files.length !== 0) {
            task_json.object_data.bin_files.forEach((item) => {
                item.uid = "uid" in item ? item.uid : this.genKey(10);
            })
        }
        let cur_files_json = [];
        let ext_cur_files_json = [];
        if(task_json.object_data.bin_files.length !== 0) {
            task_json.object_data.bin_files.forEach((item) => {                 
                if("type" in item && item.type === 'external'){
                    ext_cur_files_json.push(item);
                }else{
                    cur_files_json.push(item);
                }
            });                 
        }

        let enabled_topics = this.state.enabled_topics;
        let topic = this.state.topic;
        if(moduleconfig.enabled_topics && ('topic' in task_json.object_data && 'value' in task_json.object_data.topic)){
            enabled_topics = true;
            topic = task_json.object_data.topic;
        }
        
        let cur_assigned = form_data.cur_assigned_to === 0 ? "Compliance Advisers" : contacts[form_data.cur_assigned_to.toString()];
        let org_teams = Store.getStoreData('org_teams');
        if('cur_recever_id' in task_json.object_data && task_json.object_data.cur_recever_id!==null && form_data.cur_assigned_to === 0 && isNaN(task_json.object_data.cur_recever_id) && Object.keys(org_teams).length > 0) {
          if(task_json.object_data.cur_recever_id in org_teams){
            cur_assigned = org_teams[task_json.object_data.cur_recever_id.toString()].name;
          }
        }


        let answer = ('answer' in task_json.object_data) ? task_json.object_data.answer : '';
        if(Utils.isBase64(answer)){
          answer = window.decodeURIComponent(atob(answer))
        }
        let ptags = {};
        if('tags' in task_json.object_data) {
            ptags = task_json.object_data.tags;
        }
        let tags = this.isLoadTags(ptags)

        let requester_name = "";
        if('requester_name' in task_json.object_data) {
          requester_name = task_json.object_data.requester_name;
        }
        let contact = form_data.from_api === 0 ?  contacts[form_data.contact_id] : requester_name;

        let task_data = {};
        task_data['task_id'] = task.id
        task_data['enabled_topics'] = enabled_topics
        task_data['topic'] = topic
        task_data['ref_id'] = task_json.object_data.ref_id
        task_data['company'] = gc_company
        task_data['cur_assigned'] = cur_assigned
        task_data['query'] = 'query' in task_json.object_data && task_json.object_data.query !=null ? task_json.object_data.query : ''
        task_data['answer'] = answer
        task_data['tags'] = tags
        task_data['contact'] = contact
        task_data['company_id'] = form_data.company_id
        task_data['contact_id'] = form_data.contact_id
        task_data['cur_assigned_to'] = form_data.cur_assigned_to
        task_data['clarifications'] = 'clarifications' in task_json.object_data ? task_json.object_data.clarifications : []
        console.log("data====>", task_data);
        let individuls = task_json.object_data?.individuls ? task_json.object_data.individuls : {};
        this.setState({ready: true, task_data: task_data,cur_files_json: cur_files_json, ext_cur_files_json: ext_cur_files_json, individuls: individuls})
    }

    selectOrUnselectTags = (tagid) => (event) => {
        event.preventDefault();
        let task_data = this.state.task_data;
        let tags  = task_data['tags'];
        console.log("tags are =====>",tags);
        tags.forEach((tag) => {
            if (tag.id === tagid) {
                if (tag.selected) {
                    tag.selected = false;
                    tag.styledcomponent = UnselectedPill;
                } else {
                    tag.selected = true;
                    tag.styledcomponent = SelectedPill;
                }
            }
        })
        task_data['tags'] = tags;
        this.setState({ task_data: task_data});
    }

    closeModal = (event) => {
        event.preventDefault();
        this.props.closeAdviceModal();
    }


    handleinputChange =(event) => {
        let data = this.state.task_data;
        data[event.target.name] = event.target.value;
        this.setState({ task_data: data })
        const input = event.target;
        input.style.height = "auto";
        input.style.height = input.scrollHeight + "px";
        console.log("input", input)
        // this
    } 

    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }
        }
        this.setState({binFiles: files});
    }

    reopenCAR = (event) => {
        event.preventDefault()
        let task_data = this.state.task_data;
        let answer = Store.getStoreData('answer');
        if(answer === null){
            answer = task_data.answer;
        }
        let answer_summary = btoa(window.encodeURIComponent(answer));
        let postData = {};
        postData["command"] = "reopen_task";
        postData["query"] = task_data.query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")
        postData["answer"] = answer_summary
        postData["task_id"] = task_data.task_id
        postData["cur_assigned_to"] = task_data.cur_assigned_to
        let selectedtags = {};
        task_data.tags.forEach((tag) => {
            if (tag.selected) selectedtags[tag.id.toString()] = tag.name;
        })
        postData["tags"] = selectedtags

        let bin_files1 = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
        let bin_files2 = this.state.ext_cur_files_json === null ? this.state.extbinFiles : [...this.state.extbinFiles, ...this.state.ext_cur_files_json];
        let bin_files = [...bin_files1, ...bin_files2];

        postData['bin_files'] = bin_files;

        console.log('postData', postData);
        let api = new APICall();
        api.indexCommand(postData, this.processSaveCAR);
        this.setState({is_loading: true})
    }

    saveCAR = (event) => {
        event.preventDefault()
        let task_data = this.state.task_data;
        let answer = Store.getStoreData('answer');
        if(answer === null){
            answer = task_data.answer;
        }
        let answer_summary = btoa(window.encodeURIComponent(answer));
        let postData = {};
        console.log("save postData ===>  ",postData["query"]);
        postData["command"] = "save_answer";
        postData["query"] = task_data.query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")
        postData["answer"] = answer_summary
        postData["task_id"] = task_data.task_id
        let selectedtags = {};
        task_data.tags.forEach((tag) => {
            if (tag.selected) selectedtags[tag.id.toString()] = tag.name;
        })
        postData["tags"] = selectedtags

        let bin_files1 = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
        let bin_files2 = this.state.ext_cur_files_json === null ? this.state.extbinFiles : [...this.state.extbinFiles, ...this.state.ext_cur_files_json];
        let bin_files = [...bin_files1, ...bin_files2];

        postData['bin_files'] = bin_files;
        console.log("save postData ===>  ",postData["bin_files"]);

        console.log('postData', postData);
        let api = new APICall();
        api.indexCommand(postData, this.processSaveCAR);
        this.setState({is_loading: true})
    }

    processSaveCAR = (result) => {
        console.log("processSaveCAR", result)
        this.setState({is_loading: false});
        let stack = {};
        stack['task_id'] = result.error_code === 0 ? result.task_id : 0
        stack['task'] = result.error_code === 0 ? result.task : {};
        let msg = 'Your request has been updated.' ;
        let title = 'Task save'
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.CARalertremoveHandler, stack: stack}
        this.setState({alert_param: alert_param})
    }

    
    CARalertremoveHandler = (result, stack) => {
        let tasks = Store.getStoreData('tasks')
        let role = Store.getStoreData('role')
        let contact_id = Store.getStoreData('contact_id')
        if(stack!==null){
          if('mode' in stack && stack.mode === "delete"){
                delete tasks[stack.task_id.toString()];
          }else{
                tasks[stack.task_id.toString()] = stack.task;
          }  
          Store.updateStore('tasks', tasks)
          IndexLoader.divideAllTasks(tasks, role, contact_id);
        }
        this.props.refreshAdviceModal();
        this.setState({alert_param: null})
    }

    showRelatedIndividuals = () => {
        if(Object.keys(this.state.individuls).length === 0){
            return ""
        }else{
            let contacts = Store.getStoreData('contacts')
            let xx = []
            let j = 0
            for(let i in this.state.individuls){
                xx.push(contacts[i.toString()])
            }
            xx = xx.sort();
            let y = '';
            for(let i = 0; i < xx.length; i++) {
                y = y + xx[i]
                if(i < xx.length - 1) {
                    y += ', '
                }else{
                    y += '.'
                }
            }
            
            return(<div style={{display:"contents"}}>
                
                <Style.ModalNameLabelDiv>Related Individuals</Style.ModalNameLabelDiv>
                <Style.ModalNameInputDiv>
                {y}
                </Style.ModalNameInputDiv>
            </div>)
        }
    }

    render()
    {
        if(!this.state.ready) {
            return <CSLLoader />;
        }
        return (
            <Style.ModalContainer style={{width: "100%", top: "0px",margin:"0px 0px"}}>
                 {
                     (() => {
                         if(this.state.is_loading) {
                             return <CSLLoader style={{position: 'absolute'}} />
                         }
                     })()
                 }
                 <Style.ModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></Style.ModalHeaderCloseBtn>
                 <Style.ModalHeaderText>Provide Compliance Advice</Style.ModalHeaderText>
                 <div style={{clear: "both"}}></div>
                    <Style.ModalBody>
                         <Style.ModalBodyInner>
                             <Style.ModalContainertop>
                                 {
                                     (() => {
                                         if(Store.getStoreData('has_gc')) {
                                             return (<div style={{display:"contents"}}>
                                                         <Style.ModalNameLabelDiv>Company</Style.ModalNameLabelDiv>
                                                         <Style.ModalNameInputDiv>
                                                             <Style.ModalNameInputDiv>{this.state.task_data['company']}</Style.ModalNameInputDiv>
                                                         </Style.ModalNameInputDiv>
                                                     </div>
                                                     )
                                         }
                                     })()
                                 }
                                 <Style.ModalNameLabelDiv>CAR Reference</Style.ModalNameLabelDiv>
                                 <Style.ModalNameInputDiv>
                                     <Style.ModalNameInputDiv>{this.state.task_data['ref_id']}</Style.ModalNameInputDiv>
                                 </Style.ModalNameInputDiv>

                                 {
                                     (() => {
                                         if(this.state.task_data['enabled_topics']) {
                                             return (
                                                 <div style={{display:"contents"}}>
                                                     <Style.ModalNameLabelDiv>Topic</Style.ModalNameLabelDiv>
                                                         <Style.ModalNameInputDiv>
                                                             <Style.ModalNameInputDiv>{this.state.task_data['topic'].value}</Style.ModalNameInputDiv>
                                                         </Style.ModalNameInputDiv>
                                                 </div>
                                                 )
                                         }

                                     })()
                                 }
                                 
                                 <Style.ModalNameLabelDiv>Currently assigned to</Style.ModalNameLabelDiv>
                                 <Style.ModalNameInputDiv>
                                     <Style.ModalNameInputDiv>{this.state.task_data['cur_assigned']}</Style.ModalNameInputDiv>
                                 </Style.ModalNameInputDiv>
                                 <Style.ModalNameLabelDiv>Advice Request</Style.ModalNameLabelDiv>
                                 <Style.ModalNameInputDiv>
                                 <Style.ModalTextArea name="query" value={this.state.task_data['query'].replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} onChange={this.handleinputChange}/>
                                 
                                 </Style.ModalNameInputDiv>
                                 <Style.ModalNameLabelDiv>Attachments</Style.ModalNameLabelDiv>
                                 <Style.ModalNameInputDiv>
                                     <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} />
                                 </Style.ModalNameInputDiv>

                                 <Style.ModalNameLabelDiv>Requester</Style.ModalNameLabelDiv>
                                 <Style.ModalNameInputDiv>
                                     <Style.ModalInput style={{width: '98%'}}  name="contact" defaultValue={this.state.task_data['contact']} readOnly={true} />
                                 </Style.ModalNameInputDiv>

                                 {
                                     (() => {
                                         return this.showRelatedIndividuals();
                                     })()
                                 }

                                 <Style.ModalNameLabelDiv>Answer <span style={{color: "#ff2f00", padding:"4px"}}>*</span></Style.ModalNameLabelDiv>
                                 <Style.ModalNameInputDiv >
                                     
                                 <HtmlEditor style={{width: '98%', padding: "5px"}} content={this.state.task_data['answer']} />
                                 </Style.ModalNameInputDiv>

                                 <Style.ModalNameLabelDiv>Available Tags</Style.ModalNameLabelDiv>
                                 <Style.ModalNameInputDiv style={{backgroundColor:'#fff'}}>
                                     {
                                         this.state.task_data['tags'].map((tag, index) => {
                                             return (<tag.styledcomponent key={index} onClick={this.selectOrUnselectTags(tag.id)}>{tag.name}</tag.styledcomponent>);
                                         })
                                     }
                                 </Style.ModalNameInputDiv>

                             </Style.ModalContainertop>
                             <Style.CancelSubmitButtonOuterContainer style={{width: '100%'}}>
                                 <div style={{display:"inline"}}>
                                     <Style.CancelButton onClick={this.closeModal}>Close</Style.CancelButton>
                                     <Style.SubmitButton onClick={this.saveCAR}>Save</Style.SubmitButton>
                                     <Style.SubmitButton onClick={this.reopenCAR}>Reopen</Style.SubmitButton>
                                 </div>
                             </Style.CancelSubmitButtonOuterContainer>
                         </Style.ModalBodyInner>
                    </Style.ModalBody>
                <AlertBox alertParam = {this.state.alert_param}/>
            </Style.ModalContainer>
        );
    }
}
export default ReopenCloseModal;