import React from 'react';
import styled from 'styled-components';
import CSLTablemodule from '../Common/CSLTablemodule';
import EmailConfigModal from './EmailConfigModal';
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning } from "react-icons/io";
import { Icon } from 'react-icons-kit';
import {basic_floppydisk} from 'react-icons-kit/linea/basic_floppydisk'

const EmailContainer = styled.div`
	width: 90%;
`;
const EmailInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const EmailBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
const EmailLabel = styled.div`
	color: #595959;
	margin-top: 5px;
`;
const ConfigBtn = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    font-size: 13px;
    border-radius: 4px;
    padding: 5px 20px;
    cursor: pointer;
`;
const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;

const Clearfix = styled.div`
	clear: both;
`;

const TextBox = styled.input`
	height: 20px;
    width: 7%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
    font-size: 15px;
    font-family: 'Montserrat',sans-serif;
    &:focus {
		outline-width: 0;
	}
	margin-left: 5px;
`;

const GeneralsubLabel = styled.div`
	font-weight: 400;
	color: #595959;
	margin-top: 2px;
`;

class Faqrestriction extends React.Component
{
	state = {general: null, days_faq_expire: 0};

	componentDidMount()
	{
		this.setState({ general: this.props.general, days_faq_expire : this.props.general.days_after_faq_expire});
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			this.setState({ general: this.props.general, days_faq_expire : this.props.general.days_after_faq_expire});
		}
	}


	changeExpireDay = (event) => {
		event.preventDefault();
		let {general} = this.state;
		general['days_after_faq_expire'] = this.state.days_faq_expire;
		this.props.updateGeneralSave(general);
	}

	switchFaqexpireRadio = (key) => (event) => {
		event.preventDefault();
		let {general} = this.state;
		general[key] = general[key] ? false : true;
		this.props.updateGeneralSave(general);
	}

	switchRadio = (key) => (event) => {
		event.preventDefault();
		let {general} = this.state;
		general[key] = general[key] ? false : true;
		let restriction_roles = [];
		general.faq_restriction_roles.forEach((item) => {
			item.enabled = true; 
			restriction_roles.push(item);
		})
		general.faq_restriction_roles = restriction_roles;
		this.props.updateGeneralSave(general);
	}

	handlenaSectionIncluded = (section_id) => (event) => {
		console.log("content:", section_id);
		let {general} = this.state;
		console.log("general", general);
		let restriction_roles = [];
		general.faq_restriction_roles.forEach((item) => {
			if (item.id === section_id) {
				item.enabled = item.enabled ? false : true; 
				console.log("matched", item);
			}
			restriction_roles.push(item);
		})
		general.faq_restriction_roles = restriction_roles;
		// general.faq_restriction_roles[section_id].enabled ? false : true;
		this.props.updateGeneralSave(general);
	}

	closeModal = () => {
		this.setState({show_email_config_modal: null, content: null});
	}

	tableData = () => {
        let ret = {data: [], columns: []};
        ret.columns =[
                        {Header: 'Role', accessor: 'role', minWidth: 240, headerStyle: {textAlign: 'left'}}, 
                        {'Header' : 'Enabled', Cell: row => (
                        	<div>
                        		   {
                        			   (()=> {
                        				   if (row.original.enabled) {
                        					   return(<div style={{cursor: "pointer", fontSize:"21px", float: "right"}} onClick={this.handlenaSectionIncluded(row.original.id)}><IoIosCheckboxOutline /></div>);
                        				   } else {
                        					   return(<div  style={{cursor: "pointer", fontSize:"21px", float: "right"}} onClick={this.handlenaSectionIncluded(row.original.id)}><IoIosSquareOutline /></div>);
                        				   }
                        			   })()
                        		   }
                        	</div>
                         ), headerStyle:{textAlign: 'left'}}];
        this.state.general.faq_restriction_roles.forEach((item) => {
        	let elem = {id: item.id, role: item.display_name, enabled: item.enabled};
        	ret.data.push(elem);
        })
        return ret;
	}

	handleChange = (event) => {
		if(event.target.value > 730) {
			alert("Maximum value of FAQ expire not more than 730");
			return false;
		}
	    this.setState({ [event.target.name] : event.target.value })
	}

	render()
	{
		if (this.state.general === null) {
			return (
				<div>Loading...</div>
			);
		}
		return (
			<div>
				<EmailContainer>
					<EmailInnerContainer>
						<EmailBoldLabel style={{display: "inline-block"}}>Restrict access to knowledge base?</EmailBoldLabel>
						{
							(() => {
								if (this.state.general.access_faq) {
									return (
										<RadioBlock>
											<SelectedRadio>Yes</SelectedRadio>
											<UnselectedRadio onClick={this.switchRadio('access_faq')}>No</UnselectedRadio>
										</RadioBlock>
									);
								} else {
									return (
										<RadioBlock>
											<UnselectedRadio onClick={this.switchRadio('access_faq')}>Yes</UnselectedRadio>
											<SelectedRadio>No</SelectedRadio>
										</RadioBlock>
									);
								}
							})()
						}
						{
							(() => {
								if (this.state.general.access_faq) {
									return(<div>
										<CSLTablemodule add={false} processData={this.tableData} headerText={''} tableRows="10"  />
									</div>)
								}
							})()
						}
					</EmailInnerContainer>
					<EmailInnerContainer>
						<EmailBoldLabel style={{display: "inline-block"}}>Enable FAQ expiry</EmailBoldLabel>
						{
							(() => {
								if (this.state.general.enabled_faq_expiry) {
									return (
										<RadioBlock>
											<SelectedRadio>Yes</SelectedRadio>
											<UnselectedRadio onClick={this.switchFaqexpireRadio('enabled_faq_expiry')}>No</UnselectedRadio>
										</RadioBlock>
									);
								} else {
									return (
										<RadioBlock>
											<UnselectedRadio onClick={this.switchFaqexpireRadio('enabled_faq_expiry')}>Yes</UnselectedRadio>
											<SelectedRadio>No</SelectedRadio>
										</RadioBlock>
									);
								}
							})()
						}
						<Clearfix style={{paddingTop:"10px"}}></Clearfix>
						{
							(() => {
								if(this.state.general.enabled_faq_expiry) {
									return(
												<div>
													<EmailBoldLabel style={{display: "inline-block"}}>Days until FAQs expire</EmailBoldLabel>
													<TextBox type = "number" value={this.state.days_faq_expire} name="days_faq_expire" onChange={this.handleChange} max="730" min="1"/>
													<Icon style={{cursor: "pointer", marginLeft: "20px", paddingTop: "5px"}} size={18} icon={basic_floppydisk} onClick={this.changeExpireDay} />
													<GeneralsubLabel>The default may be changed for individual FAQs on creation</GeneralsubLabel>

													<Clearfix style={{paddingTop:"10px"}}></Clearfix>
													<EmailBoldLabel style={{display: "inline-block"}}>Make FAQ inactive on expiry</EmailBoldLabel>
													<GeneralsubLabel>If an FAQs is not made inactive, it will require reapproval or deletion</GeneralsubLabel>
													{
														(() => {
															if (this.state.general.make_faq_inactive_on_expire) {
																return (
																	<RadioBlock>
																		<SelectedRadio>Yes</SelectedRadio>
																		<UnselectedRadio onClick={this.switchFaqexpireRadio('make_faq_inactive_on_expire')}>No</UnselectedRadio>
																	</RadioBlock>
																);
															} else {
																return (
																	<RadioBlock>
																		<UnselectedRadio onClick={this.switchFaqexpireRadio('make_faq_inactive_on_expire')}>Yes</UnselectedRadio>
																		<SelectedRadio>No</SelectedRadio>
																	</RadioBlock>
																);
															}
														})()
													}
												</div>
										)

								}
							})()
						}
					</EmailInnerContainer>
				</EmailContainer>
			</div>
		);
	}
}

export default Faqrestriction;