import React from 'react';
import Store from './Common/Store.js';
import APICall from './Common/APICall.js';
import APIContact from './Common/APIContact';
import ComDecom from './Common/ComDecom.js';
import Utils from './Common/Utils.js';
import IndexLoader from './Common/IndexLoader.js';
import Index from './Components/Index';
import ModuleConfigHOC from './Components/Moduleconfig/ModuleConfigHOC';
import RegisterIndex from './Components/Register/GenericRegister';
import FaqregisterIndex from './Components/FAQ/GenericFaqregister';
import CSLLoader from './Components/Common/CSLLoader';
import AlertBox from './Components/Common/AlertBox';
import CSLPageloader from './Components/Common/CSLPageloader';
import Reload from './Common/Reload.js';
import EventBus from './Common/EventBus.js';
import authService from 'mcc-front-aux/dist/authService'
import siteLayout from 'mcc-front-aux/dist/siteLayout'


import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

/*global localStorage*/

const moment = require('moment');
const last_action_time = moment().unix()
class AppContent extends React.Component
{
  state = {
    OverlayHeight: null,
    cardata: null,
    ready: false,
    OverlayTaskHeight: null,
    role: "noperm",
    refreshCount: 0,
    cur_view:'',
    task_loading: false,
    alert_param: null,
    from_url: false,
    from_register: false,
    newData: null

  };
  constructor(props) {
    super(props);
    Utils.setEnv('car');
    // setTimeout(Store.ajaxCallRunner, 30000)
    // this.callContact();
  }

  callContact() {
    console.log("local store", localStorage.getItem("contactlist"))
    let postData = { command: "contactlist"};
    let api = new APIContact();
    api.command(postData, this.processContactData);
  }
  processContactData = (result) => {
    let cl = ComDecom.deCompress(result.result['contactlist'], true, 'ID');
    result.result['contactlist'] = cl
    localStorage.setItem("contactlist", JSON.stringify(result));

  }

  processIndex = (result) => {
      console.log("serverlog",result)
      if(result.error_code === 0) {
        if(Store.getStoreData('is_admin')) {
        } else {
          Utils.log('tasks', result);
          Utils.serverLog(result.error_code, result.result['role']);
          if(result.result['role'] === "no_access") {
            let alert_param = {title: 'No Access', message: '', ok_text: 'Ok', confirm: false,
                               alertHandler: this.alertHandler, stack: {id: 0}};
            alert_param["message"] = result.error_msg;
            this.setState({ ready: true, alert_param: alert_param});
            return false;
          }

          let tasks = ComDecom.deCompress(result.result['tasks'], true, 'task_id');
          let gc_comps = ComDecom.deCompress(result.result['gc_company'], false, 'id');

          // let tasks = result.result['tasks']
          // console.log("tasks deCompress", tasks)
          console.log("size CAR", JSON.stringify(tasks).length / (1024 * 1024));
          Store.updateStore('tasks', tasks);
          Store.updateStore('role', result.result['role']);
          Store.updateStore('contact_id', result.result['contact_id']);
          Store.updateStore('company', result.result['company']);
          Store.updateStore('gc_company', gc_comps);
          Store.updateStore('emails', result.result['emails']);
          Store.updateStore('moduleconfig', result.result['moduleconfig'].general);
          Store.updateStore('home_filter', result.result.home_filter);
          Store.updateStore('include_other', result.result['moduleconfig'].general.include_other);
          Store.updateStore('org_teams', result.result['teams']);
          Store.updateStore('isaccess_carbyemail', result.result['isaccess_carbyemail'])
          Store.updateStore('drs', result.result['drs'])
          IndexLoader.divideAllTasks(tasks, result.result['role'], result.result['contact_id'], result.result['drs']);
          Store.updateStore('groupings', result.result['groupings']);

          let r = {};
          r['result'] = {};
          let cts = localStorage.getItem("contactlist")!==null ? JSON.parse(localStorage.getItem("contactlist")) : r;
          let user_roles = {};
          let contacts = {}
          let active_contacts = {};
          if('contactlist' in cts.result && 'user_roles' in cts.result) {
            user_roles = cts.result.user_roles['car'];
            for(let k in cts.result.contactlist){
                if(cts.result.user_roles['car'][k.toString()] !== undefined){
                  if(cts.result.contactlist[k.toString()].IsDeleted === false && cts.result.contactlist[k.toString()].IsActive === true){
                    active_contacts[k.toString()] = cts.result.contactlist[k.toString()].ContactName;
                  }
                  contacts[k.toString()] = cts.result.contactlist[k.toString()].IsActive === true ? cts.result.contactlist[k.toString()].ContactName : cts.result.contactlist[k.toString()].ContactName + "(Inactive)";
                }
            }
          }
          Store.updateStore('active_contacts', active_contacts);
          Store.updateStore('contacts', contacts);
          Store.updateStore('user_roles', user_roles);
          Store.updateStore('contact', contacts[result.result['contact_id'].toString()]);

          // console.log("contacts", contacts, contacts[result.result['contact_id'].toString()])
          let loggedincontact = {};
          loggedincontact['contact_id'] = result.result['contact_id'];
          loggedincontact['contact_name'] = contacts[result.result['contact_id'].toString()];
          Store.updateStore('loggedincontact', loggedincontact)




          let tags = [];
          let topics = [];
          for(let id of Object.keys(result.result['moduleconfig'].general.tags)) {
            if(result.result['moduleconfig'].general.tags[id].included) {
              tags.push({ID: id, TagName:result.result['moduleconfig'].general.tags[id].TagName})
            }
            if(result.result['moduleconfig'].general.enabled_topics) {
              if('enabled_topic' in result.result['moduleconfig'].general.tags[id] && result.result['moduleconfig'].general.tags[id].enabled_topic) {
                topics.push({id: id, topic:result.result['moduleconfig'].general.tags[id].TagName, value: result.result['moduleconfig'].general.tags[id].TagName})
              }
            }
          }

          let show_faq = true;
          if(result.result['moduleconfig'].general.access_faq) {
            result.result['moduleconfig'].general.faq_restriction_roles.forEach((item) => {
              if(item.name === result.result['role'] && !item.enabled) {
                show_faq = false;
              }
            })
          }
          let menu = {};
          if(show_faq){
            menu['12faqreg321'] = {"id":"12faqreg321","display":"Knowledge Base","url":"microservice/mstop/car/faqregister","order":2,"menu":"faqregister", source: "car"}
          }
          EventBus.raiseEvent('menu_render', menu)
          Store.updateStore('show_faq', show_faq);
          Store.updateStore('tags', tags);

          let has_gc = gc_comps.length > 1 ? true : false;
          Store.updateStore('has_gc',has_gc);

          let gc_list = [];
          let gc_assoc = {};
          let gc_filters = {};
          for(let gc of gc_comps) {
              gc_list.push(gc);
              gc_assoc[gc.id.toString()] = gc;
              if(gc.is_gc) {
                gc_filters[gc.id.toString()] = 1;
              }
          }

          Store.updateStore('gc_list', gc_list);
          Store.updateStore('gc_assoc', gc_assoc);
          Store.updateStore('gc_filters', gc_filters);
          Utils.log("gc_assoc", gc_assoc)
          Utils.log("gc_list", gc_list)
          Utils.serverLog(gc_list);
          Utils.serverLog(gc_assoc);
           let list_requester = [];
           let list_answrere =  Utils.ShowTeamUsers(result.result['company'].ClientNumber);
           console.log("list_answrere", list_answrere)
           let list_approvar = [];
           let individuls = {}
           Object.entries(contacts).forEach(([key, value]) => {
               if (user_roles[key.toString()] === 'team'  && key in active_contacts) {
                   list_requester.push({"ID" : key, "contact_name": value })
               }
                if(parseInt(key) !== parseInt(result.result['contact_id']) && key in active_contacts && (user_roles[key.toString()] === 'admin_manager' || user_roles[key.toString()] === 'ca' || user_roles[key.toString()] === 'ca_module_admin') && user_roles[key.toString()] !== 'no_access' ) {
                 list_approvar.push({"ID" : key, "contact_name": value })
               }
               if(user_roles[key.toString()] !== 'no_access' && key in active_contacts) {
                let obj = {};
                obj["id"] = key;
                obj["name"] = value;
                individuls[key] = obj;
               }

           })

          console.log("list teams", list_answrere, individuls);
          Utils.serverLog("list_answrere", list_answrere);
          Utils.serverLog("individuls", individuls);
           list_approvar.sort(this.compare);
           list_requester.sort(this.compare);
           topics.sort(function(a,b) {return (a.topic > b.topic) ? 1 : ((b.topic > a.topic) ? -1 : 0);} );
           list_approvar.unshift({"ID": "0", "contact_name":"Compliance Advisers"});
           list_requester.unshift({"ID": "0", "contact_name":"Compliance Advisers"});
           Store.updateStore('topics_exclude_other', topics);
           if(result.result['moduleconfig'].general.include_other){
            topics.push({id: "other", topic:"Other", value: ""});
           }

          Store.updateStore('list_requester', list_requester);
          Store.updateStore('list_answrere', list_answrere);
          Store.updateStore('list_approvar', list_approvar);
          Store.updateStore('individuls', individuls);
          Store.updateStore('topics', topics);
          Store.updateStore('ruie', result.result['moduleconfig'].general.ruie)
          Store.updateStore('rigce', result.result['moduleconfig'].general.rigce)

          Utils.log("role --->",result.result['role'])
          let cur_view = "";
           switch(result.result['role']) {
            case 'ca_module_admin': cur_view= 'ca_module_admin_index';break;
            case 'admin_manager' : cur_view= 'manager_index';break;
            case 'ca' : cur_view= 'ca_index';break;
            case 'jr_ca' : cur_view= 'jrca_index';break;
            case 'line_manager' : cur_view= 'linemgr_index';break;
            default: cur_view= 'team_index';break;
           }
          this.setState({ ready: true, cur_view: cur_view});
        }
      }else{
        let alert_param = {title: 'System Error', message: '', ok_text: 'Ok', confirm: false,
                   alertHandler: this.alertHandler, stack: {id: 0}}
        alert_param["message"] = result.error_msg;
        this.setState({ ready: true, alert_param: alert_param, task_loading: false});
        return
      }
  }

  compare(a, b) {
      const bandA = a.contact_name.toUpperCase();
      const bandB = b.contact_name.toUpperCase();

      let comparison = 0;
      if (bandA > bandB) {
        comparison = 1;
      } else if (bandA < bandB) {
        comparison = -1;
      }
      return comparison;
   }


  componentDidMount() {
    this.callApi();
  }



  callApi(){
      let postData = { command: "index" };
      let api = new APICall();
      Utils.log('sending first call')
      api.indexCommand(postData, this.processIndex)
      this.setState({task_loading: true})
  }

  refreshState = (cur_view) => {
    Utils.log("cur_view", cur_view)
    this.setState({refreshCount : this.state.refreshCount + 1, cur_view: cur_view})
  }

  alertHandler = (result, stack) => {
    this.setState({alert_param: null})
    localStorage.removeItem('car-index');
    // window.location = '/select/ServiceBasedLanding';
    Reload.ReloadPage();
  }

  render()
  {
    console.log("serverlog",localStorage.getItem("serverlog"))
    if (Store.getStoreData("car-index-select") === null) {
      return <div><CSLPageloader /></div>
    } else {
      if (!this.state.ready) {
        return <div><CSLLoader /></div>
      }
    }
    if(this.state.alert_param !== null) {
      return <AlertBox alertParam = {this.state.alert_param} />
    }
    return(
      <Router>
        <Switch>
        <Route path="/moduleconfig" exact >
          <ModuleConfigHOC />
        </Route>
          <Route path="/" exact >
            <RegisterIndex />
          </Route>

          <Route path="/approval">
            <Index CurrentView = {this.state.cur_view} refresfParentState = {this.refreshState}/>
          </Route>
          <Route path="/faqregister">
              <FaqregisterIndex />
          </Route>

          <Route path="/:taskId" component={Index} exact />
          <Route path="/:curLane/:taskId" component={Index} exact />
        </Switch>
      </Router>
    );
  }
}

class App extends React.Component {
  state = { ready: false}

  setComponentState = async () => {
    console.log('this.props', this.props)
    let is_debug =  false
    let api_url = process.env.REACT_APP_API_URL;
    Store.updateStore('is_debug', is_debug)
    Store.updateStore('api_url', api_url)

    await authService.refresh()
    await siteLayout()
    this.setState({ready: true})

  }

  componentDidMount = () => {
    this.setComponentState()
  }

  componentDidUpdate = (prevprops) => {
        if(this.props === prevprops) return
        this.setComponentState()
    }

  render() {
    if(!this.state.ready) {
        return <div>Loading...</div>
    }
    // let appContent = <AppContent />
    //
    // return <ErrorBoundary><SiteLayout AppContent={appContent} /></ErrorBoundary>
    return <AppContent />
  }
}

export default App;
