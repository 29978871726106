import React from 'react';
import styled from 'styled-components';
import {FaTimes} from 'react-icons/fa';
import Store from '../../Common/Store.js';
import APICall from '../../Common/APICall.js';
import Utils from '../../Common/Utils';
import Dropzone from '../Common/Dropzone.js'
import * as Style from '../Common/StyledComponents';
import ClarificationMessage from './ClarificationMessage';
import DatePicker from 'react-date-picker';
import HtmlEditor from '../Common/HtmlEditor';
import ShowFAQModal from '../FAQ/ShowFAQModal';
import CSLLoader from '../Common/CSLLoader';
import AlertBox from '../Common/AlertBox';
import IndexLoader from '../../Common/IndexLoader.js';

const InactiveOverlay = styled.div`
      display: block;
      box-sizing: border-box;
      height: 3000px;
      width: 100%;
      background-color: #ffffff;
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 1000;
      opacity: 0.6;
    `;

const UnselectedPill = styled.div`
    background-color: #ededed;
    color: #a6a6a6;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;
const SelectedPill = styled.div`
    background-color: #37ada7;
    color: #ffffff;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;

const SubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #234062
    color: #ffffff;
    width: auto;
    padding: 10px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #234062;
    margin-left: 5px;
    font-size: 11px;
    cursor:pointer;
`;

const SaveButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #223B5A;;
    color: #ffffff;
    width: auto;
    padding: 10px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor:pointer;
    `;
const CSLDateCover = styled.div`
    margin-top: 8px;
    width: 200px;
    border-radius: 3px;
    border: 1px solid #ffffff;
    box-shadow: 0 0 4px #c4c4c4;
    height: 25px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    `;
const ModeltopText = styled.div`
    display: grid;
    grid-gap: 26px;
    padding: 10px;
    border-radius: 3px;
    padding: 10px;
    border-color: #f6ebd6;
    color: #f6ce76;
    background-color: #f6ebd6;
`;


class AnswerQuestionModal extends React.Component
{
        state = {
        company: '',
        query: '',
        answer: '',
        binFiles: [],
        cur_files_json: null,
        extbinFiles: [],
        ext_cur_files_json: null,
        index: -1,
        form_data: null,
        id:0,
        category_id: 0,
        ext_advice: false,
        ext_advice_txt: '',
        ext_advice_provider: '',
        ext_ref_number: '',
        ext_contact_name: '',
        ref_id: '',
        tags: [],
        ready: false,
        is_self_advicerequest: false,
        show_faq: false,
        faq_query: '',
        faq_answer: '',
        faq_expire_date: new Date(),
        from_api: 0,
        jumping:false,
        cur_recever_id: 0,
        only_assign: false,
        asignment_note: '',
        asignment_notes: [],
        is_disabled:false,
        externla_provides_enabled: false,
        external_advice: [],
        custom_fields: {},
        list_approvar: [],
        enabled_topics: false,
        topic: {},
        topic_id: "0",
        from_faq: false,
        view_faq: false,
        faq_id: 0,
        alert_param: null,
        is_loading: false,
        reopen:{},
        clarifications: [],
        individuls: {}


    };

    constructor(props) {
        super(props);
    }


    closeModal = (event) => {
        event.preventDefault();
        if(Store.getStoreData('answer') !== null){
            Store.updateStore('answer', null)
        }
        this.props.closeModal();
    }

    processAnswerQuery = (result) => {
        let msg = '';
        let title = 'Alert';
        this.setState({is_loading: false})
        let stack = {};
        stack['task_id'] = result.error_code === 0 ? result.task_id : 0
        stack['task'] = result.error_code === 0 ? result.task : {};
        if(result.submit_form === 1)
        {
            if(Store.getStoreData('role') === 'jr_ca') {
                msg = 'Your answer has been sent to a manager for approval.';
                // alert("Your answer has been sent to a manager for approval.")
                let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: stack}
                this.setState({alert_param: alert_param})
            }else{
                if(this.state.from_api === 1 && this.state.cur_recever_id === 0) {
                    msg = 'Your answer to the Advice Request has been submitted and sent to the requester. This request will be closed automatically.'                 
                    // alert("Your answer to the Advice Request has been submitted and sent to the requester. This request will be closed automatically.")
                    let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: stack}
                    this.setState({alert_param: alert_param})
                } else {
                    if(this.state.cur_recever_id > 0) {
                        msg = 'Advice Request has been assigned.';                        
                        // alert("Advice Request has been assigned.")
                        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: stack}
                        this.setState({alert_param: alert_param})
                    }else{
                        msg = 'Your answer has been submitted to the requester for acknowledgement.';
                        // alert("Your answer has been submitted to the requester for acknowledgement.")
                        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: stack}
                        this.setState({alert_param: alert_param})
                    }
                    
                }
                
            }
        }
        else{
            msg = 'Your answer has been saved.';
            title = 'Task save'
            // alert("Your answer has been saved.")
            let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: stack}
            this.setState({alert_param: alert_param})

        }

    }

    answerCARQuestion = (submit_form) => (event) =>{
        event.preventDefault();
        let answer = Store.getStoreData('answer');
        if(answer === null){
            answer = this.state.answer;
        }
        let answer_summary = btoa(window.encodeURIComponent(answer));
        let msg = 'Please insert proper value.';
        let title = 'Warning'
        if(!this.state.only_assign && submit_form > 0) {
            if(answer === '') {
                msg = 'The answer cannot be blank.';
                // alert('ERROR: The answer cannot be blank.');
                let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.sameAlertremoveHandler, stack: {id: 0}}
                this.setState({alert_param: alert_param})
                return
            }
            if(this.state.ext_advice && this.state.ext_advice_txt === '' && submit_form > 0) {
                msg = 'External Advice cannot be blank.';
                // alert('ERROR: External Advice cannot be blank.');
                let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.sameAlertremoveHandler, stack: {id: 0}}
                this.setState({alert_param: alert_param})
                return
            }
        }
        this.setState({'is_disabled': true})
        let selectedtags = {};
        this.state.tags.forEach((tag) => {
            if (tag.selected) selectedtags[tag.id.toString()] = tag.name;
        })
        
        let bin_files1 = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
        let bin_files2 = this.state.ext_cur_files_json === null ? this.state.extbinFiles : [...this.state.extbinFiles, ...this.state.ext_cur_files_json];
        let bin_files = [...bin_files1, ...bin_files2];
        let postData = { "command": "add_answer", query_id:this.state.id, answer: answer_summary, submit:submit_form, 
                         bin_files: bin_files, category_id:this.state.category_id,
                        externla_provides_enabled: this.state.externla_provides_enabled,
                        external_advice: this.state.external_advice,
                        ext_advice: this.state.ext_advice, ext_advice_txt: this.state.ext_advice_txt, 
                        ext_advice_provider: this.state.ext_advice_provider,
                        ext_ref_number: this.state.ext_ref_number,ext_contact_name: this.state.ext_contact_name,
                        index: this.state.index, task_id: this.state.form_data === null ? 0 : this.state.form_data.id,
                        asker_id: this.state.form_data.contact_id, tags: selectedtags, from_api: this.state.from_api, 
                        cur_recever_id: this.state.cur_recever_id, only_assign: this.state.only_assign,
                        asignment_note: this.state.asignment_note, asignment_notes: this.state.asignment_notes,
                        topic: this.state.topic,
                        clarifications: this.state.clarifications
                    };

        if(this.state.enabled_topics) {
            postData['topic'] = this.state.topic;
            postData['topic_value'] = this.state.topic['value'];
        }

        if(Object.keys(this.state.individuls).length > 0) {
            postData['individuls'] = this.state.individuls;
        }
        
        Utils.log("postData", postData);
        
        let api = new APICall();
        api.indexCommand(postData, this.processAnswerQuery);
        this.setState({is_loading: true})
    }

    handleChange = (event) => {
        this.setState({ [event.target.name] : event.target.value, jumping:false })
        // console.log(this.state.query)
    }

    handleinputChange =(event) => {
        this.setState({ [event.target.name] : event.target.value})
        const input = event.target;
        input.style.height = "auto";
        input.style.height = input.scrollHeight + "px";
    }

    handleAssignChange = (event) => {
        let only_assign = false;
        let answer = this.state.answer;
        if( parseInt(event.target.value) > 0 && (Store.getStoreData('role') === 'admin_manager' || Store.getStoreData('role') === 'ca_module_admin'))
        {
            only_assign = true;
            answer = "";
        }
        this.setState({ [event.target.name] : event.target.value, jumping:false, only_assign: only_assign, answer:answer })
    }



    selectOrUnselectTags = (tagid) => (event) => {
        event.preventDefault();
        let { tags } = this.state;
        tags.forEach((tag) => {
            if (tag.id === tagid) {
                if (tag.selected) {
                    tag.selected = false;
                    tag.styledcomponent = UnselectedPill;
                } else {
                    tag.selected = true;
                    tag.styledcomponent = SelectedPill;
                }
            }
        })

        this.setState({ tags: tags, jumping:false  });
    }

    isLoadTags = (tags) => {
        let rawtags = Store.getStoreData('tags');
        // console.log("rawtags", rawtags);
        let otags = [];
        rawtags.forEach((top) => {
            if(Object.keys(tags).length === 0){
                otags.push({
                    name: top.TagName,
                    id: top.ID,
                    styledcomponent: UnselectedPill,
                    selected: false
                });
            }else {
                if(tags[top.ID.toString()] ===  top.TagName) {
                    otags.push({
                        name: top.TagName,
                        id: top.ID,
                        styledcomponent: SelectedPill,
                        selected: true
                    });
                }else{
                    otags.push({
                        name: top.TagName,
                        id: top.ID,
                        styledcomponent: UnselectedPill,
                        selected: false
                    });
                }
            }
        })
        let stagnames = [];
        otags.forEach((item) => {
            stagnames.push(item.name);
        })
        stagnames.sort();
        let ftags = [];
        for (let i=0; i<stagnames.length; i++) {
            for (let j=0; j<otags.length; j++) {
                if (stagnames[i] === otags[j].name) {
                    ftags.push(otags[j]);
                }
            }
        }
        return ftags;
    }
    


    processOpenanswerData = (result) => {
        // let utils = new Utils();
        let index = Store.getStoreData('a_index') === null ? 0 : Store.getStoreData('a_index');
        let contact_id = Store.getStoreData('contact_id');
        let form_data = {};
        form_data = result;
        console.log('index in componentDidMount', form_data);
        let task_json = JSON.parse(form_data.task_json);
        let ptags = {};
        if('tags' in task_json.object_data) {
            ptags = task_json.object_data.tags;
        }
        if (task_json.object_data.bin_files.length !== 0) {
            task_json.object_data.bin_files.forEach((item) => {
                item.uid = "uid" in item ? item.uid : this.genKey(10);
            })
        }


        let cur_files_json = [];
        let ext_cur_files_json = [];
        if(task_json.object_data.bin_files.length !== 0) {
            task_json.object_data.bin_files.forEach((item) => {                 
                if("type" in item && item.type === 'external'){
                    ext_cur_files_json.push(item);
                }else{
                    cur_files_json.push(item);
                }
            });                 
        }

        let gc_assoc = Store.getStoreData('gc_assoc');
        let gc_company = parseInt(form_data.company_id) < 100000 ? Store.getStoreData('company').CompanyName : gc_assoc[(parseInt(form_data.company_id)%100000).toString()].company_name;
        let gc_company_id = parseInt(form_data.company_id) < 100000 ? form_data.company_id : form_data.company_id%100000;
        let list_approvar = Utils.showCompanyUsers(gc_company_id,'approvar');
        let tags = this.isLoadTags(ptags)
        let is_self_advicerequest = parseInt(form_data.contact_id) === parseInt(Store.getStoreData('contact_id')) ? true : false;
        // let cur_recever_id = ('cur_recever_id' in task_json.object_data) ? task_json.object_data.cur_recever_id : 0;
        // console.log("cur_recever_id", cur_recever_id);
        let cur_recever_id = this.state.cur_recever_id;
        let moduleconfig = Store.getStoreData('moduleconfig');
        let externla_provides_enabled = ("externla_provides_enabled" in moduleconfig ) ? moduleconfig.externla_provides_enabled : false;
        let external_advice = ("externla_provides" in moduleconfig) ? moduleconfig.externla_provides : [];
        let ext_advice_provider = '';
        if(externla_provides_enabled){
            ext_advice_provider = external_advice[0];
        }

        let days_after_faq_expire = 0;
        if(moduleconfig.enabled_faq_expiry){
            days_after_faq_expire = parseInt(moduleconfig.days_after_faq_expire);
        }
        let faq_expire_date = new Date(Utils.adddaystoDate(days_after_faq_expire));


        if(result.cur_lane === 'COMP_CAR_ANSWER_SAVED') {
            cur_recever_id = ('cur_recever_id' in task_json.object_data) ? task_json.object_data.cur_recever_id : cur_recever_id;
            external_advice = ("external_advice" in task_json.object_data) ? task_json.object_data.external_advice : []
            externla_provides_enabled = ("externla_provides_enabled" in task_json.object_data && external_advice.length > 0) ? task_json.object_data.externla_provides_enabled : false;
        }
        let only_assign = ('answer_assign' in task_json.object_data) ? task_json.object_data.answer_assign : false;
        if(result.cur_lane === 'COMP_CAR_ANSWER') {
            only_assign = false;
        }
        let asignment_notes = ('asignment_notes' in task_json.object_data) ? task_json.object_data.asignment_notes : [];

        Utils.log("custom_fields", task_json.object_data.custom_fields)
        

        let custom_fields = ("custom_fields" in moduleconfig) ? moduleconfig.custom_fields : {};
        custom_fields.custom_terminology_fields.forEach((item) => {
            if(item.type==="Dropdown"){
                item['value'] = item.dropdown_options[0]
            }{
                item['value'] = ''
            }
        });

        custom_fields.additional_fields.forEach((item) => {
            if(item.type==="Dropdown"){
                item['value'] = item.dropdown_options[0]
            }else{
                item['value'] = ''
            }
        });

        if('custom_fields' in task_json.object_data && "custom_fields" in moduleconfig) {
            custom_fields = task_json.object_data.custom_fields;
        }


        Utils.log("custom_fields =====>", custom_fields);
        Utils.log("faq_expire_date =====>", faq_expire_date);

        let answer = ('answer' in task_json.object_data) ? task_json.object_data.answer : '';
        if(Utils.isBase64(answer)){
          answer = window.decodeURIComponent(atob(answer))
        }
        
        /*let enabled_topics = this.state.enabled_topics;
        let topic = this.state.topic;
        let topic_id = this.state.topic_id;
        if(moduleconfig.enabled_topics && ('topic' in task_json.object_data && 'value' in task_json.object_data.topic)){
            enabled_topics = true;
            topic = task_json.object_data.topic;
            topic_id = topic.id;
        }*/

        // let moduleconfig = Store.getStoreData('moduleconfig');

        let enabled_topics = this.state.enabled_topics;
        let topic = this.state.topic;
        let topic_id = this.state.topic_id;
        let store_topic = Store.getStoreData('topics_exclude_other');
        if(moduleconfig.enabled_topics && store_topic.length > 0){
          enabled_topics = true;
          if('topic' in task_json.object_data && 'value' in task_json.object_data.topic){
              topic = task_json.object_data.topic;
              topic_id = topic.id;
          }else{
              topic_id = store_topic[0].id;
              topic = store_topic[0];
          }
        }

        console.log("topic", topic)

        let from_faq = ('from_faq' in task_json.object_data) ? task_json.object_data.from_faq : false;
        let faq_id = ('faq_id' in task_json.object_data) ? task_json.object_data.faq_id : 0;
        
        let reopen = {};
        if('reopen' in task_json.object_data){
            reopen['is_reopen'] = task_json.object_data.reopen
            let reopen_date = task_json.object_data.reopen_date;
            var c_date = new Date(reopen_date);
            let dateStr = c_date.toLocaleDateString('en-GB').replace(/,/g, '')
            let by = ('reopen_by' in task_json.object_data) ? Store.getStoreData('contacts')[task_json.object_data.reopen_by] : Store.getStoreData('contacts')[form_data.contact_id]
            reopen['by'] = by
            reopen['reopen_date'] = dateStr
        }

        let individuls = task_json.object_data?.individuls ? task_json.object_data.individuls : {};
        console.log("individuls", individuls)


        this.setState({ company: gc_company, cur_files_json: cur_files_json, ext_cur_files_json: ext_cur_files_json,
            index: index, form_data:form_data, 
            query: task_json.object_data.query, 
            answer: answer, 
            faq_query: task_json.object_data.query, 
            faq_answer: answer, 
            id: form_data.id, ref_id: ('ref_id' in task_json.object_data) ? task_json.object_data.ref_id: '',
            ext_advice: ('ext_advice' in task_json.object_data) ? task_json.object_data.ext_advice : false,
            ext_advice_txt: ('ext_advice_txt' in task_json.object_data) ? task_json.object_data.ext_advice_txt : '',
            ext_advice_provider: ('ext_advice_provider' in task_json.object_data) ? task_json.object_data.ext_advice_provider : ext_advice_provider,
            ext_ref_number: ('ext_ref_number' in task_json.object_data) ? task_json.object_data.ext_ref_number : '',
            ext_contact_name: ('ext_contact_name' in task_json.object_data) ? task_json.object_data.ext_contact_name : '',
            tags:tags, ready: true,is_self_advicerequest:is_self_advicerequest,
            from_api: result.from_api, cur_recever_id: cur_recever_id, only_assign: only_assign,
            asignment_notes:asignment_notes,
            externla_provides_enabled: externla_provides_enabled,
            external_advice: external_advice,
            custom_fields: custom_fields,
            faq_expire_date: faq_expire_date,
            list_approvar: list_approvar,
            enabled_topics: enabled_topics,
            topic: topic,
            topic_id: topic_id,
            from_faq: from_faq,
            faq_id: faq_id,
            reopen: reopen,
            clarifications: ('clarifications' in task_json.object_data) ? task_json.object_data.clarifications : [],
            individuls: individuls
        });
    }

    genKey = (length) => {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }



    componentDidMount()
    {
        let cur_a_modal_data = Store.getStoreData('cur_a_modal_data');
        this.processOpenanswerData(cur_a_modal_data);
    }

    componentDidUpdate(prevProps)
    {
        if (prevProps !== this.props) {
            let cur_a_modal_data = Store.getStoreData('cur_a_modal_data');
            this.processOpenanswerData(cur_a_modal_data);
        }
        // this.el.scrollIntoView({behavior: "smooth", block: "end"});
        if(this.state.show_faq && this.state.jumping) {
            this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
        }
    }

    extfilesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
                files[i].type = 'external';
            }
        }
        this.setState({extbinFiles: files});
    }

    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }
        }
        this.setState({binFiles: files});
    }


    handleCategoryChange = (evt) => {
        evt.preventDefault();
        let review_id = evt.target.value;
        this.setState({})
    }

    handleCheckboxChange = (event) => {
        this.setState({ ext_advice: event.target.checked })
    }

    showHideFaq = (event) => {
        event.preventDefault();
        let {show_faq,jumping,faq_answer} = this.state;
        show_faq = this.state.show_faq ? false: true;
        jumping = show_faq;
        let answer = Store.getStoreData('answer') === null ? this.state.answer : Store.getStoreData('answer')

        if(show_faq){
            this.setState({show_faq: show_faq, jumping: jumping, faq_answer: answer, answer: answer});
        }else{
            Store.updateStore('answer',null)
            this.setState({show_faq: show_faq, jumping: jumping, faq_answer: answer});
        }

    }

    viewFaq = (event) => {
        let {view_faq} = this.state;
        view_faq = this.state.view_faq ? false: true;
        this.setState({view_faq: view_faq});
    }

    rfereshFAQ = () => {
        let {view_faq} = this.state;
        view_faq = this.state.view_faq ? false: true;
        this.setState({view_faq: view_faq});
    }

    SendforApprovalFAQ = (flag) => (event) => {
        event.preventDefault()
        let answer = Store.getStoreData('answer');
        if(answer === null){
            answer = this.state.faq_answer;
        }
        let answer_summary = btoa(window.encodeURIComponent(answer));
        let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
        // let bin_files = this.state.binFiles;
        let selectedtags = {};
        this.state.tags.forEach((tag) => {
            if (tag.selected) selectedtags[tag.id.toString()] = tag.name;
        })

        let dateobj = new Date(this.state.faq_expire_date);
        let curmonth = parseInt(dateobj.getMonth());
        let currmonth = curmonth + 1;
        var expiry_date = currmonth >= 10 ? dateobj.getFullYear()+'/'+currmonth+'/' : dateobj.getFullYear()+'/0'+currmonth+'/';
        expiry_date += dateobj.getDate() < 10 ? '0' + dateobj.getDate() : dateobj.getDate();

        let postData = { 
                        command: "add_faq", 
                        question: this.state.faq_query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'"),
                        answer: answer_summary, 
                        bin_files: bin_files,
                        task_id: this.state.id, 
                        assign_to: this.state.form_data.contact_id, 
                        tags: selectedtags,
                        car_ref_id: this.state.ref_id,
                        company_id: this.state.form_data.company_id,
                        is_approved: flag,
                        expiry_date: expiry_date,
                    };
        
        if(this.state.enabled_topics) {
            postData['topic'] = this.state.topic;
            postData['topic_value'] = this.state.topic['value'];
        }
        if(this.state.ext_advice){
            postData['ext_advice'] = this.state.ext_advice;
        }
        console.log('postData', postData);
        let api = new APICall();
        api.indexCommand(postData, this.processFAQadd);
        this.setState({is_loading: true})
    }

    processFAQadd =(result) => {
        console.log("result faq", result)
        this.setState({is_loading: false});
        let stack = {};
        stack['task_id'] = result.error_code === 0 ? result.task_id : 0
        stack['task'] = result.error_code === 0 ? result.task : {};
        let msg = 'FAQ progress';
        let title = 'Task save'
        if(Store.getStoreData('role') === 'admin_manager' || Store.getStoreData('role') === 'ca_module_admin'){
            if(result.is_approved === 0) {
                msg = 'Your FAQ has been saved.';
                // alert('Your FAQ has been saved.');
                let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.FAQalertremoveHandler, stack: stack}
                this.setState({alert_param: alert_param})
            }else{
                msg = 'Your FAQ has been published.';
                title = 'Alert'
                // alert("Your FAQ has been published.");
                let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.FAQalertremoveHandler, stack: stack}
                this.setState({alert_param: alert_param})
            }
        }else{
            if(result.is_approved === 0) {
                msg = 'Your FAQ has been saved.'
                // alert('Your FAQ has been saved.');
                let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.FAQalertremoveHandler, stack: stack}
                this.setState({alert_param: alert_param})
            }else {
                msg = 'This FAQ has been sent to a manager for approval.'
                title = 'Alert'
                // alert("This FAQ has been sent to a manager for approval.");
                let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.FAQalertremoveHandler, stack: stack}
                this.setState({alert_param: alert_param})
            }
            
        }
        // let show_faq = this.state.show_faq ? false: true;
        // this.setState({show_faq:show_faq})
    }

    onExpireDateChange = (date) => {
        this.setState({ faq_expire_date: date })
    }


    showCustomFields = (field, data_container) => {
        console.log("field", field)
        return (
            <div style={{display:"contents"}}>
                <Style.ModalNameLabelDiv>{field.nickname.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")}</Style.ModalNameLabelDiv>
                <Style.ModalNameInputDiv>
                    <Style.ModalInput type="text" name={field.name} value={field.value.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")}  readOnly={true} style={{width: '98%'}} />
                {
                                    (() => {
                                        if(field.other && field.type === "Dropdown" && field.value === "Other"){
                                            return (<Style.ModalNameInputDiv>
                                                <Style.ModalInput type="text" name={field.name} value={field.more_info.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")}  readOnly={true} style={{width: '98%'}} />
                                                </Style.ModalNameInputDiv>
                                                )
                                        }

                                    })()
                                }

                </Style.ModalNameInputDiv>
                {/*{
                    (() => {
                        if(field.type === "Dropdown" && field.value === "Other"){
                            return (<Style.ModalInput type="text" name={field.name} value={field.more_info.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")}  readOnly={true} style={{width: '98%'}} />
                                )
                        }

                    })()
                }*/}
            </div>
        );
    }


    generateReferenceField = (field, data_container) => {
        return (
            <div style={{display:"contents"}}>
                <Style.ModalNameLabelDiv>{field.nickname}
                </Style.ModalNameLabelDiv>
                <Style.ModalNameInputDiv>
                {
                    (() => {
                        if (field.type === "Alpha") {
                            return (<Style.ModalInput type="text" name={field.name} value={field.value} onChange={this.handleAddlField(field.type, data_container)}/>)
                        } else if(field.type === "Dropdown") {
                            return (
                                <Style.MRModalSelect name={field.name} value={field.value} onChange={this.handleAddlField(field.type, data_container)}>
                                    {
                                        field.dropdown_options.map((opt, index) => {
                                            return(
                                                <option key={index} value={opt}>{opt}</option>
                                            );
                                        })
                                    }
                                </Style.MRModalSelect>
                            );
                        }else{
                            return (<Style.ModalInput type="text" name={field.name} value={field.value} onChange={this.handleAddlField(field.type, data_container)}/>)
                        }
                    })()
                }
                </Style.ModalNameInputDiv>
            </div>
        );
    }

    generateadditionalField = (data, data_container) => {
        return (
            <div key={data.name} style={{display:"contents"}}>
                <Style.ModalNameLabelDiv>{data.nickname} 
                </Style.ModalNameLabelDiv>
                {
                    (() => {
                        let fieldhtml = <div></div>;
                        switch(data.type) {
                            case "Alpha":
                                fieldhtml = this.createAlphaField(data, data_container);
                                break;
                            case "Numeric":
                                fieldhtml = this.createNumericField(data, data_container);
                                break;
                            case "Dropdown":
                                fieldhtml = this.createDropdownField(data, data_container);
                                break;
                            default:
                                fieldhtml = fieldhtml;
                        }
                        return fieldhtml;
                    })()
                }
            </div>
        );
    }

    createAlphaField = (data, data_container) => {
        return (<Style.ModalNameInputDiv><Style.ModalInput type="text" name={data.name} value={data.value} onChange={this.handleAddlField(data.type, data_container)} /></Style.ModalNameInputDiv>);
    }

    createNumericField = (data, data_container) => {
        return (<Style.ModalNameInputDiv><Style.ModalInput type="text" name={data.name} value={data.value} onChange={this.handleAddlField(data.type, data_container)} min={0}/></Style.ModalNameInputDiv>);
    }
    createDropdownField = (data, data_container) => {
        return (
            <Style.ModalNameInputDiv>
                <Style.MRModalSelect onChange={this.handleAddlField(data.type, data_container)} name={data.name} value={data.value}>
                    {
                        data.dropdown_options.map((opt, index) => {
                            return(
                                <option key={index} value={opt}>{opt}</option>
                            );
                        })
                    }
                </Style.MRModalSelect>
            </Style.ModalNameInputDiv>
        );
    }

    handleAddlField = (field_type, data_container) => (event) => {
        event.preventDefault();
        let custom_fields = this.state.custom_fields;
        console.log(custom_fields[data_container.toString()])
        custom_fields[data_container.toString()].forEach((item) => {
            if (item.name === event.target.name) {
                item.value = event.target.value;
            }
        })    
        this.setState({custom_fields: custom_fields, toggle:false})
    }

    alertremoveHandler = (result, stack) => {
        this.setState({alert_param: null})
        this.props.refreshParentCallback(stack);
    }

    sameAlertremoveHandler = (result,stack) => {
        this.setState({alert_param: null})
    }

    FAQalertremoveHandler = (result, stack) =>{
        let show_faq = this.state.show_faq ? false: true;
        /*let tasks = Store.getStoreData('tasks')
        let role = Store.getStoreData('role')
        let contact_id = Store.getStoreData('contact_id')
        if(stack!==null){
          tasks[stack.task_id.toString()] = stack.task;
          Store.updateStore('tasks', tasks)
          IndexLoader.divideAllTasks(tasks, role, contact_id);
        }*/
        this.props.refreshParentCallback(stack);
        this.setState({show_faq:show_faq, alert_param: null})
    }

    handleChangeTopic = (event) => {
        event.preventDefault();
        let topic = this.state.topic; 
        for(let t of Store.getStoreData('topics')) {
            if(t.id===event.target.value){
                topic = t;
            }
        }
        this.setState({ [event.target.name] : event.target.value, topic: topic})
    }

    handleChangeOther = (event) => {
        event.preventDefault();
        let topic = this.state.topic; 
        topic.value = event.target.value;
        this.setState({topic: topic})
    }

    showRelatedIndividuals = () => {
        if(Object.keys(this.state.individuls).length === 0){
            return ""
        }else{
            let contacts = Store.getStoreData('contacts')
            let xx = []
            let j = 0
            for(let i in this.state.individuls){
                xx.push(contacts[i.toString()])
            }
            xx = xx.sort();
            let y = '';
            for(let i = 0; i < xx.length; i++) {
                y = y + xx[i]
                if(i < xx.length - 1) {
                    y += ', '
                }else{
                    y += '.'
                }
            }
            
            return(<div style={{display:"contents"}}>
                
                <Style.ModalNameLabelDiv>Related Individuals</Style.ModalNameLabelDiv>
                <Style.ModalNameInputDiv>
                {y}
                </Style.ModalNameInputDiv>
            </div>)
        }
    }

    render()
    {
        if(!this.state.ready) {
            return <CSLLoader />;
        }
        // console.log("this.satete ===>", task_json.object_data.cur_recever_id);
        // let contact = Store.getStoreData('contact');
        let contacts = Store.getStoreData('contacts');
        let form_data = Store.getStoreData('cur_a_modal_data');
        let task_json = JSON.parse(form_data.task_json);
        console.log("this.satete ===>", task_json.object_data.cur_recever_id);
        let contact_id = form_data.contact_id;
        let requester_name = "";
        if('requester_name' in task_json.object_data) {
          requester_name = task_json.object_data.requester_name;
        }
        let contact = form_data.from_api === 0 ?  contacts[contact_id] : requester_name;
        let asignment_notes = JSON.parse(JSON.stringify(this.state.asignment_notes))
        asignment_notes.reverse()
        let last_assignment = asignment_notes[0];
        let cur_assigned = form_data.cur_assigned_to === 0 ? "Compliance Advisers" : contacts[form_data.cur_assigned_to.toString()];
        if('cur_recever_id' in task_json.object_data && form_data.cur_assigned_to === 0) {
          cur_assigned = task_json.object_data.cur_recever_id.toString() === "0" ? "Compliance Advisers" : (task_json.object_data.cur_recever_id in Store.getStoreData('org_teams') ? Store.getStoreData('org_teams')[task_json.object_data.cur_recever_id.toString()].name : "Compliance Advisers");
        }
        let clarifications = JSON.parse(JSON.stringify(this.state.clarifications))
        console.log("clarifications", clarifications.length)
        clarifications.reverse();
        return (
            <div>
                <Style.ModalContainer>
                    {
                        (() => {
                            if(this.state.is_loading) {
                                return <CSLLoader style={{position: 'absolute'}} />
                            }
                        })()
                    }
                    {
                        (() => {
                            if(this.state.view_faq && this.state.faq_id > 0) {
                                return <div style={{width:"100%"}}><InactiveOverlay /><ShowFAQModal faq_id={this.state.faq_id} is_car={true} rfereshFAQ={this.rfereshFAQ}/></div>
                            }
                        })()
                    }
                    <Style.ModalHeaderText>
                    {
                        (() => {
                            if(this.state.show_faq) {
                                return 'Add New FAQ'
                            }else{
                                return <div>{this.state.ref_id} - {this.state.query.replace(/'+/g,"'").substring(0, 70) + "..."}</div>
                            }
                        })()
                    }
                    </Style.ModalHeaderText>
                    
                    {
                        (() => {
                            if(this.state.show_faq) {
                                return  <Style.ModalHeaderCloseBtn onClick={this.showHideFaq}><FaTimes /></Style.ModalHeaderCloseBtn>
                            }else {
                                return <Style.ModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></Style.ModalHeaderCloseBtn>
                            }
                        })()
                    }
                    <div style={{clear: "both"}}></div>
                    <Style.ModalBody>
                        
                        {
                            (() => {
                                if(Object.keys(this.state.reopen).length > 0 && this.state.reopen['is_reopen']) {
                                    return <ModeltopText style={{fontWeight:"600",color: "#f6ce76"}}>This record was reopened by {this.state.reopen['by']} on {this.state.reopen['reopen_date']}  </ModeltopText>
                                }
                            })()
                        }
                        <Style.ModalBodyInner>
                            <Style.ModalContainertop>
                                {
                                    (() => {
                                        if(Store.getStoreData('has_gc')) {
                                            return (<div style={{display:"contents"}}>
                                                        <Style.ModalNameLabelDiv>Company</Style.ModalNameLabelDiv>
                                                        <Style.ModalNameInputDiv>
                                                            <Style.ModalNameInputDiv>{this.state.company}</Style.ModalNameInputDiv>
                                                        </Style.ModalNameInputDiv>
                                                    </div>
                                                    )
                                        }
                                    })()
                                }
                                <Style.ModalNameLabelDiv>ID</Style.ModalNameLabelDiv>
                                    <Style.ModalNameInputDiv>
                                    {
                                        (() => {
                                            if(this.state.show_faq) {
                                                return <Style.ModalNameInputDiv>N/A</Style.ModalNameInputDiv>;
                                            }else{
                                                return <Style.ModalNameInputDiv>{this.state.ref_id}</Style.ModalNameInputDiv>
                                            }
                                        })()
                                    }
                                    </Style.ModalNameInputDiv>


                                    {
                                        (() => {
                                            if(this.state.enabled_topics) {
                                                return(
                                                    <div style={{display:"contents"}}>
                                                        <Style.ModalNameLabelDiv>Select a Topic</Style.ModalNameLabelDiv>
                                                            <Style.ModalNameInputDiv>
                                                                <Style.MRModalSelect name="topic_id" value={this.state.topic.id} onChange={this.handleChangeTopic}>
                                                                    {
                                                                        Store.getStoreData('topics').map((item) => {
                                                                            {
                                                                                return (
                                                                                    <option key={item.id} value={item.id}>{item.topic}</option>
                                                                                );
                                                                            }
                                                                        })
                                                                    }
                                                                </Style.MRModalSelect>
                                                            </Style.ModalNameInputDiv>
                                                    </div>
                                                    )
                                            }
                                        })()
                                    }

                                    {
                                        (() => {
                                            if(this.state.enabled_topics && this.state.topic_id === "other") {
                                                return <div style={{display:"contents"}}><Style.ModalNameLabelDiv></Style.ModalNameLabelDiv><Style.ModalNameInputDiv><Style.ModalInput type="text" name="other_topic" value={this.state.topic.value.replace(/'+/g,"'")} onChange={this.handleChangeOther} /></Style.ModalNameInputDiv></div>
                                            }
                                        })()

                                    }



                                    {/*{
                                        (() => {
                                            if(this.state.enabled_topics) {
                                                return (
                                                    <div style={{display:"contents"}}>
                                                        <Style.ModalNameLabelDiv>Topic</Style.ModalNameLabelDiv>
                                                            <Style.ModalNameInputDiv>
                                                                <Style.ModalNameInputDiv>{this.state.topic.value}</Style.ModalNameInputDiv>
                                                            </Style.ModalNameInputDiv>
                                                    </div>
                                                    )
                                            }

                                        })()
                                    }
                                    */}

                                    {
                                        (() => {
                                            if(!this.state.show_faq){
                                                return (<div style={{display:"contents"}}>
                                                            <Style.ModalNameLabelDiv>Currently assigned to</Style.ModalNameLabelDiv>
                                                            <Style.ModalNameInputDiv>
                                                                <Style.ModalNameInputDiv>{cur_assigned}</Style.ModalNameInputDiv>
                                                            </Style.ModalNameInputDiv>
                                                        </div>)
                                            }
                                            
                                        })()
                                    }

                                    {
                                        (() => {
                                            if(Store.getStoreData('moduleconfig').specific_user_assignment_enabled && Store.getStoreData('role') === 'jr_ca') {
                                                return(
                                                    <div style={{display:"contents"}}>
                                                        <Style.ModalNameLabelDiv>Assign to</Style.ModalNameLabelDiv>
                                                        <Style.ModalNameInputDiv>
                                                        <Style.MRModalSelect name="cur_recever_id" value={this.state.cur_recever_id} onChange={this.handleAssignChange}>
                                                        {
                                                            Store.getStoreData('list_approvar').map((item) => {
                                                                {
                                                                    return (
                                                                        <option key={item.ID} value={item.ID}>{item.contact_name}</option>
                                                                    );
                                                                }
                                                            })
                                                        }
                                                        </Style.MRModalSelect>
                                                        </Style.ModalNameInputDiv>
                                                    </div>
                                                    )
                                            }
                                        })()
                                    }

                                    {
                                        (() => {
                                            if(! this.state.show_faq && Store.getStoreData('moduleconfig').specific_user_assignment_enabled && (Store.getStoreData('role') === 'admin_manager' || Store.getStoreData('role') === 'ca_module_admin')) {
                                                return(
                                                    <div style={{display:"contents"}}>
                                                        <Style.ModalNameLabelDiv>Assign to</Style.ModalNameLabelDiv>
                                                        <Style.ModalNameInputDiv>
                                                        <Style.MRModalSelect name="cur_recever_id" value={this.state.cur_recever_id} onChange={this.handleAssignChange}>
                                                        {
                                                            this.state.list_approvar.map((item) => {
                                                                {
                                                                    return (
                                                                        <option key={item.ID} value={item.ID}>{item.contact_name}</option>
                                                                    );
                                                                }
                                                            })
                                                        }
                                                        </Style.MRModalSelect>
                                                        </Style.ModalNameInputDiv>
                                                        {
                                                            (() => {
                                                                if(this.state.only_assign){
                                                                    return (<div style={{display:"contents"}}>
                                                                        <Style.ModalNameLabelDiv>Assignment Note</Style.ModalNameLabelDiv>
                                                                        <Style.ModalNameInputDiv>
                                                                            <Style.ModalTextArea name="asignment_note" value={this.state.asignment_note} onChange={this.handleChange} />
                                                                        </Style.ModalNameInputDiv>
                                                                    </div>)
                                                                }
                                                            })()
                                                        }
                                                    </div>
                                                    )
                                            }
                                        })()
                                    }

                                {
                                    (() => {
                                        if(this.state.show_faq) {
                                            return(                                            
                                                        <div style={{display:"contents"}}>
                                                            <Style.ModalNameLabelDiv>Question</Style.ModalNameLabelDiv>
                                                            <Style.ModalNameInputDiv>
                                                                <Style.ModalTextArea name="faq_query" value={this.state.faq_query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} onChange={this.handleChange} />
                                                            </Style.ModalNameInputDiv>
                                                            <Style.ModalNameLabelDiv>Answer</Style.ModalNameLabelDiv>
                                                            <Style.ModalNameInputDiv>
                                                                {/*<Style.ModalTextArea name="faq_answer" value={this.state.faq_answer.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} onChange={this.handleChange} placeholder="Answer to question." />*/}
                                                                <HtmlEditor style={{width: '98%', padding: "5px"}} content={this.state.faq_answer} />
                                                            </Style.ModalNameInputDiv>
                                                            <Style.ModalNameLabelDiv>Attachments</Style.ModalNameLabelDiv>
                                                            <Style.ModalNameInputDiv>
                                                                {/*<Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} />*/}
                                                                <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} />
                                                            </Style.ModalNameInputDiv>
                                                            <Style.ModalNameLabelDiv>Tags</Style.ModalNameLabelDiv>
                                                            <Style.ModalNameInputDiv>
                                                                {
                                                                    this.state.tags.map((tag, index) => {
                                                                        return (<tag.styledcomponent key={index} onClick={this.selectOrUnselectTags(tag.id)}>{tag.name}</tag.styledcomponent>);
                                                                    })
                                                                }
                                                            </Style.ModalNameInputDiv>
                                                            {
                                                                (() => {
                                                                    if(Store.getStoreData('role') === 'admin_manager' || Store.getStoreData('role') === 'ca_module_admin') {
                                                                        return(
                                                                            <div style={{display:"contents"}}>
                                                                                <Style.ModalNameLabelDiv>Expiry date</Style.ModalNameLabelDiv>
                                                                                    <CSLDateCover>
                                                                                        <DatePicker
                                                                                                onChange={this.onExpireDateChange}
                                                                                                clearIcon={null}
                                                                                                calendarIcon={null}
                                                                                                locale={"en-GB"}
                                                                                                value={this.state.faq_expire_date}
                                                                                                />
                                                                                    </CSLDateCover>
                                                                            </div>
                                                                        )
                                                                    }
                                                                })()
                                                            }
                                                        </div>
                                                        )

                                        }else{
                                            if(!this.state.only_assign){
                                            return(
                                                    <div style={{display:"contents"}}>
                                                    <Style.ModalNameLabelDiv>Advice Request</Style.ModalNameLabelDiv>
                                                    <Style.ModalNameInputDiv>
                                                    {
                                                        (() => {
                                                            if(this.state.is_self_advicerequest) {
                                                                return(<Style.ModalTextArea style={{height: "auto"}} name="query" value={this.state.query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} onChange={this.handleinputChange}/>)
                                                            }else{
                                                                return(<div name="query" style={{whiteSpace:  "break-spaces",wordWrap: "break-word",border: '1px solid #ffffff', backgroundColor:'#ffffff',borderRadius:'3px',boxShadow: '0 0 4px #c4c4c4',padding: '5px',color: '#282828',fontFamily: "'Montserrat', sans-serif"}} readOnly={true}>{this.state.query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")}</div>)
                                                            }
                                                        })()
                                                    }
                                                    {/*{
                                                        (() => {
                                                            if(this.state.is_self_advicerequest) {
                                                                return(<div name="query" style={{wordWrap: "break-word",border: '1px solid #ffffff', backgroundColor:'#ffffff',borderRadius:'3px',boxShadow: '0 0 4px #c4c4c4',padding: '5px',color: '#282828',fontFamily: "'Montserrat', sans-serif"}} onChange={this.handleChange}>{this.state.query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")}</div>)
                                                            }else{
                                                                return(<div name="query" style={{wordWrap: "break-word",border: '1px solid #ffffff', backgroundColor:'#ffffff',borderRadius:'3px',boxShadow: '0 0 4px #c4c4c4',padding: '5px',color: '#282828',fontFamily: "'Montserrat', sans-serif"}} readOnly={true}>{this.state.query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")}</div>)
                                                            }
                                                        })()
                                                    }*/}
                                                    </Style.ModalNameInputDiv>
                                                    <Style.ModalNameLabelDiv>Attachments</Style.ModalNameLabelDiv>
                                                    <Style.ModalNameInputDiv>
                                                        {/*<Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} />*/}
                                                        <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} />
                                                    </Style.ModalNameInputDiv>


                                                    <Style.ModalNameLabelDiv>Requester</Style.ModalNameLabelDiv>
                                                    <Style.ModalNameInputDiv>
                                                        <Style.ModalInput style={{width: '98%'}}  name="contact" defaultValue={contact} readOnly={true} />
                                                    </Style.ModalNameInputDiv>
                                                    {
                                                        (() => {
                                                            return this.showRelatedIndividuals();
                                                        })()
                                                    }

                                                    <Style.ModalNameLabelDiv>Answer <span style={{color: "#ff2f00", padding:"4px"}}>*</span></Style.ModalNameLabelDiv>
                                                    <Style.ModalNameInputDiv >
                                                        {/*<Style.ModalTextArea name="answer"  value={this.state.answer.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} onChange={this.handleChange} placeholder="Answer to question." />*/}
                                                    <HtmlEditor style={{width: '98%', padding: "5px"}} content={this.state.answer} />
                                                    </Style.ModalNameInputDiv>

                                                    <Style.ModalNameLabelDiv>Available Tags</Style.ModalNameLabelDiv>
                                                    <Style.ModalNameInputDiv style={{backgroundColor:'#fff'}}>
                                                        {
                                                            this.state.tags.map((tag, index) => {
                                                                return (<tag.styledcomponent key={index} onClick={this.selectOrUnselectTags(tag.id)}>{tag.name}</tag.styledcomponent>);
                                                            })
                                                        }
                                                    </Style.ModalNameInputDiv>

                                                    {
                                                        (() => {
                                                            if(this.state.custom_fields.enabled) {
                                                                if (this.state.custom_fields.custom_terminology_fields.length !== 0 ) {
                                                                    const field = this.state.custom_fields.custom_terminology_fields.find((item) => {
                                                                        return item.name === "reference_number"
                                                                    })

                                                                    if(('value' in field) && field.value !=='') {
                                                                        return this.showCustomFields(field, "custom_terminology_fields");
                                                                    }
                                                                }
                                                            }
                                                        })()
                                                    }
                                                    {
                                                        (() => {
                                                            if(this.state.custom_fields.enabled) {
                                                                if (this.state.custom_fields.additional_fields.length !== 0) {
                                                                    let custom_html = [];
                                                                    this.state.custom_fields.additional_fields.forEach((item) => {
                                                                        if(('value' in item) && item.value !=='' && item.isActive) {
                                                                            const field = this.showCustomFields(item, "additional_fields");
                                                                            custom_html.push(field);
                                                                        }
                                                                    })
                                                                    return custom_html;
                                                                }
                                                            }
                                                        })()
                                                    }


                                                {/*
                                                    {
                                                        (() => {
                                                            if(this.state.custom_fields.enabled) {
                                                                if (this.state.custom_fields.custom_terminology_fields.length !== 0 ) {
                                                                    const field = this.state.custom_fields.custom_terminology_fields.find((item) => {
                                                                        return item.name === "reference_number"
                                                                    })

                                                                    return this.generateReferenceField(field, "custom_terminology_fields");
                                                                }
                                                            }
                                                        })()
                                                    }
                                                    {
                                                        (() => {
                                                            if(this.state.custom_fields.enabled) {
                                                                if (this.state.custom_fields.additional_fields.length !== 0) {
                                                                    let custom_html = [];
                                                                    this.state.custom_fields.additional_fields.forEach((item) => {
                                                                        const field = this.generateadditionalField(item, "additional_fields");
                                                                        custom_html.push(field);
                                                                    })
                                                                    return custom_html;
                                                                }
                                                            }
                                                        })()
                                                    }
                                                */}

                                                    <Style.ModalNameLabelDiv>External Advice Required<input type="checkbox" name="ext_advice" onChange={this.handleCheckboxChange} checked={this.state.ext_advice} /></Style.ModalNameLabelDiv>
                                                    {
                                                        (() =>{
                                                            if (this.state.ext_advice){
                                                                return(
                                                                    <div>
                                                                    {
                                                                        (() => {
                                                                            if(this.state.externla_provides_enabled){
                                                                                return(<div>
                                                                                    <Style.ModalNameLabelDiv>External Advice Providers</Style.ModalNameLabelDiv>
                                                                                    <Style.ModalNameInputDiv>
                                                                                        <Style.MRModalSelect name="ext_advice_provider" value={this.state.ext_advice_provider} onChange={this.handleChange}>
                                                                                        {
                                                                                            this.state.external_advice.map((item, index) => {
                                                                                                {
                                                                                                    return (
                                                                                                        <option key={index} value={item}>{item}</option>
                                                                                                    );
                                                                                                }
                                                                                            })
                                                                                        }
                                                                                        </Style.MRModalSelect>
                                                                                    </Style.ModalNameInputDiv>
                                                                                </div>)
                                                                            }
                                                                        })()
                                                                    }
                                                                    <Style.ModalNameInputDiv>
                                                                        <Style.ModalTextArea style={{width: '98%'}} name="ext_advice_txt" value={this.state.ext_advice_txt.replace(/'+/g,"'")} onChange={this.handleChange} placeholder="External Advice Required" />
                                                                    </Style.ModalNameInputDiv>
                                                                    <Style.ModalNameLabelDiv>Reference Number</Style.ModalNameLabelDiv>
                                                                    <Style.ModalNameInputDiv>
                                                                    <Style.ModalInput style={{width: '98%'}} name="ext_ref_number" value={this.state.ext_ref_number.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} onChange={this.handleChange} placeholder="Reference Number"/>
                                                                    </Style.ModalNameInputDiv>
                                                                    <Style.ModalNameLabelDiv>Contact Name</Style.ModalNameLabelDiv>
                                                                    <Style.ModalNameInputDiv>
                                                                    <Style.ModalInput style={{width: '98%'}} name="ext_contact_name" value={this.state.ext_contact_name.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} onChange={this.handleChange} placeholder="Contact Name"/>
                                                                    </Style.ModalNameInputDiv>
                                                                    <Style.ModalNameLabelDiv>Attachments</Style.ModalNameLabelDiv>
                                                                    <Style.ModalNameInputDiv>
                                                                        <Dropzone onFilesAdded={this.extfilesLoaded} initFiles={this.state.ext_cur_files_json} />
                                                                    </Style.ModalNameInputDiv>
                                                                    </div>
                                                                )
                                                            }
                                                        })()
                                                    }
                                                    
                                                </div>
                                                )
                                            }
                                        }
                                    })()
                                }
                            </Style.ModalContainertop>
                    <Style.CancelSubmitButtonOuterContainer style={{width: '100%'}}>
                    {
                        (() => {
                            if(this.state.show_faq) {
                                return(<div style={{display:"inline"}}>
                                        <Style.CancelButton onClick={this.showHideFaq}>Close</Style.CancelButton>
                                        <SaveButton onClick={this.SendforApprovalFAQ(0)}>Create FAQ and Save</SaveButton>
                                        {
                                            (() => {
                                                if(Store.getStoreData('role') === 'admin_manager' || Store.getStoreData('role') === 'ca_module_admin') {
                                                    return(<SaveButton onClick={this.SendforApprovalFAQ(1)}>Publish FAQ</SaveButton>)
                                                }else if(Store.getStoreData('role') === 'ca') {
                                                 return(<SaveButton onClick={this.SendforApprovalFAQ(1)}>Create and Send for Approval</SaveButton>)
                                            }
                                            })()
                                        }
                                        
                                    </div>);
                            }else {
                                return(
                                    <div style={{display:"inline"}}>
                                        {
                                            (() => {
                                                if(!this.state.only_assign && (Store.getStoreData('role') === 'admin_manager' || Store.getStoreData('role') === 'ca_module_admin' || Store.getStoreData('role') === 'ca')) {
                                                    return(<div style={{display:"inline"}}>
                                                        {
                                                            (() => {
                                                                if(this.state.from_faq) {
                                                                    return <SubmitButton onClick={this.viewFaq} style={{float:"left"}}>View FAQ</SubmitButton>
                                                                }else{
                                                                    return <SubmitButton onClick={this.showHideFaq} style={{float:"left"}}>Add to FAQ</SubmitButton>
                                                                }
                                                            })()
                                                        }
                                                    </div>)

                                                }
                                            })()
                                        }
                                        <Style.CancelButton onClick={this.closeModal}>Close</Style.CancelButton>
                                        {
                                            (() => {
                                                if(form_data.cur_lane !=='COMP_CAR_USER_ACCEPTANCE' && Store.getStoreData('role')!=='team' && Store.getStoreData('role')!=='line_manager') {
                                                    return(<div style={{display:"inline"}}>
                                                        {
                                                            (() => {
                                                                if(!this.state.only_assign){
                                                                    return <Style.SubmitButton onClick={this.answerCARQuestion(0)}>Save</Style.SubmitButton>
                                                                }
                                                            })()
                                                        }
                                                        <Style.SubmitButton onClick={this.answerCARQuestion(1)}>Submit</Style.SubmitButton>
                                                        </div>)
                                                }
                                            })()
                                        }

                                    </div>
                                    )
                                
                            }
                        })()
                    }
                    <div style={{clear:"both"}} ref={el => { this.el = el; }}></div>
                    </Style.CancelSubmitButtonOuterContainer>
                    {

                        clarifications.map((c,i) => {
                            return (<ClarificationMessage cardata={form_data} clarification={c} />)
                        })

                    }

                    {
                        (() => {
                            if(!this.state.show_faq && asignment_notes.length > 0){
                                return (<ClarificationMessage cardata={form_data} clarification={last_assignment} />)
                            }
                            
                        })()
                    }
                    </Style.ModalBodyInner>
                    </Style.ModalBody>
                </Style.ModalContainer>
                <AlertBox alertParam = {this.state.alert_param}/>
            </div>
        );
    }
}
export default AnswerQuestionModal;