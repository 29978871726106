import React from 'react';
import styled from 'styled-components';
import Store from '../Common/Store.js';
import CAIndex from './CA/CAIndex';
import TeamIndex from './Team/TeamIndex';
import LinemgrIndex from './Team/LinemgrIndex';
import JRCAIndex from './JRCA/JRCAIndex';
import CSLHeader from './CSLHeader';
import WelcomeBanner from './WelcomeBanner';
import RegisterIndex from './Register/GenericRegister';
import FaqregisterIndex from './FAQ/FaqregisterIndex';
import TaskHeader from './TaskHeader';
import Utils from '../Common/Utils.js';



const NoPermission = styled.div`
  display: block;
  box-sizing: border-box;
  height: 40px;
  font-size:20px;
  font-weight: 700;
  text-align: center;
  background-color: #ffffff;
  position: absolute;
  top: 100px;
  left: 10px;
  right:10px;
  bottom : 10px;
  border: 2px solid #37ada7;
  border-radius:10px;
  color: #37ada7;
  z-index: 1000;
`;

class Index extends React.Component
{
  refreshState = (cur_view) => {
    console.log("cur_view ===>", cur_view)
    Utils.serverLog("cur_view ===>", cur_view);
   this.props.refresfParentState(cur_view);
  }
  refreshCarRegister = () => {
    this.props.refresfParentState('register_index');
  }

  
  alertHandler = (result, stack) => {
    // this.setState({alert_param: null})
    window.location = '/select/ServiceBasedLanding';
  }
  
  render()
  {
    let task_id = this.props.match ? this.props.match.params.taskId : 0;
    let cur_lane = this.props.match ? ('curLane' in this.props.match.params ? this.props.match.params.curLane: 0) : 0;

    Utils.log("task_id cur_lane", task_id, cur_lane)
    Utils.serverLog("cur_view ===>", task_id, cur_lane);

    let refer = task_id === 0 ? false : true;
    Store.updateStore('refer', refer);
    Utils.log("task_id index", task_id);
    Utils.log("cur_view index", Store.getStoreData('view'))
    Utils.log("referer url index", document.referrer)
    Utils.log("window.location.href", window.location.href)
    Utils.log("this.props.CurrentView", this.props.CurrentView)
    return (
      <div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
        {
          (() => {
              if(task_id > 0) {
                  return(<TaskHeader />)
              }else {
                return(<div>
                  <CSLHeader currentview={this.props.CurrentView} headerText={'Compliance Advice Register'} refresfParentState = {this.refreshState}/>
                  <WelcomeBanner welcome={`Welcome back, ` + Store.getStoreData('contact')} />
                </div>)

              }
          })()
        }

        {
          (() => {
            if(task_id > 0) {
                return <RegisterIndex refreshParentState={this.refreshState} taskId={task_id} curLane={cur_lane}/>;
            }else{
              switch(this.props.CurrentView) {
                case 'ca_module_admin_index':
                case 'manager_index' : 
                case 'ca_index' : return <CAIndex refreshCallback={this.refreshState} />; break;
                case 'jrca_index': return <JRCAIndex refreshCallback={this.refreshState} />; break;
                case 'team_index' : return <TeamIndex refreshCallback={this.refreshState} />; break;
                case 'linemgr_index' : return <LinemgrIndex refreshCallback={this.refreshState} />; break;
                case 'faqregister_index': return <FaqregisterIndex refreshParentState={this.refreshState} refreshCarRegister={this.refreshCarRegister} refresfParentState = {this.refreshState} />; break;
                default: return <NoPermission>Sorry, you do not have permission to view this page. </NoPermission>;
              }
            }
          })()
        }
      </div>
    );
  }
}

export default Index;
