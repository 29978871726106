import React from 'react';
import styled from 'styled-components';
import {FaTimes} from 'react-icons/fa';
import DatePicker from 'react-date-picker';
import Store from '../../Common/Store.js';
import APICall from '../../Common/APICall.js';
import Utils from '../../Common/Utils';
import Dropzone from '../Common/Dropzone.js'
import * as Style from '../Common/StyledComponents';
import HtmlEditor from '../Common/HtmlEditor';
import ShowFAQModal from '../FAQ/ShowFAQModal';
import CSLLoader from '../Common/CSLLoader';
import AlertBox from '../Common/AlertBox';
import IndexLoader from '../../Common/IndexLoader.js';

const InactiveOverlay = styled.div`
      display: block;
      box-sizing: border-box;
      height: 3000px;
      width: 100%;
      background-color: #ffffff;
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 1000;
      opacity: 0.6;
    `;

const UnselectedPill = styled.div`
    background-color: #ededed;
    color: #a6a6a6;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;
const SelectedPill = styled.div`
    background-color: #37ada7;
    color: #ffffff;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;

const SubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #234062
    color: #ffffff;
    width: auto;
    padding: 10px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #234062;
    margin-left: 5px;
    font-size: 11px;
    cursor:pointer;
`;

const SaveButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #223B5A;;
    color: #ffffff;
    width: auto;
    padding: 10px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor:pointer;
    `;
const CSLDateCover = styled.div`
    margin-top: 8px;
    width: 200px;
    border-radius: 3px;
    border: 1px solid #ffffff;
    box-shadow: 0 0 4px #c4c4c4;
    height: 25px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    `;


class AddFAQModal extends React.Component {
      state = { ready: false, data: null,binFiles:[],cur_files_json: null, faq_expire_date: new Date(), topic_id: "0", alert_param:null,is_loading: false };

      setComponentState = () => {
        console.log("this.props", this.props);
        if (this.props.data) {
          this.setState({ ready: true, data: this.props.data, cur_files_json: this.props.cur_files_json });
        }
      };
      componentDidMount = () => {
        this.setComponentState();
      };
      componentDidUpdate = (prevprops) => {
        if (this.props === prevprops) return;
        this.setComponentState();
      };

      closeModal = (event) => {
        event.preventDefault();
        this.props.closefaqModal();
      }

      SendforApprovalFAQ = (flag) => (event) => {
          event.preventDefault()
          let answer = Store.getStoreData('answer');
          if(answer === null){
              answer = this.state.data.answer;
          }
          let answer_summary = btoa(window.encodeURIComponent(answer));
          let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
          let selectedtags = {};
          this.state.data.tags.forEach((tag) => {
              if (tag.selected) selectedtags[tag.id.toString()] = tag.name;
          })

          let dateobj = new Date(this.state.faq_expire_date);
          let curmonth = parseInt(dateobj.getMonth());
          let currmonth = curmonth + 1;
          var expiry_date = currmonth >= 10 ? dateobj.getFullYear()+'/'+currmonth+'/' : dateobj.getFullYear()+'/0'+currmonth+'/';
          expiry_date += dateobj.getDate() < 10 ? '0' + dateobj.getDate() : dateobj.getDate();

          let postData = { 
                          command: "add_faq", 
                          question: this.state.data.query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'"),
                          answer: answer_summary, 
                          bin_files: bin_files,
                          task_id: this.state.data.task_id, 
                          assign_to: this.state.data.contact_id, 
                          tags: selectedtags,
                          car_ref_id: this.state.data.ref_id,
                          company_id: this.state.data.company_id,
                          is_approved: flag,
                          expiry_date: expiry_date,
                      };
          
          if(this.state.data.enabled_topics) {
              postData['topic'] = this.state.data.topic;
              postData['topic_value'] = this.state.data.topic['value'];
          }

          console.log('postData', postData);
          let api = new APICall();
          api.indexCommand(postData, this.processFAQadd);
          this.setState({is_loading: true})
      }

      processFAQadd =(result) => {
          console.log("result faq", result)
          this.setState({is_loading: false});
          let stack = {};
          stack['task_id'] = result.error_code === 0 ? result.task_id : 0
          stack['task'] = result.error_code === 0 ? result.task : {};
          let msg = result.is_approved === 0 ? 'Your FAQ has been saved.' : 'Your FAQ has been published.';
          let title = 'Task save'
          let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.FAQalertremoveHandler, stack: stack}
          this.setState({alert_param: alert_param})
      }

      FAQalertremoveHandler = (result, stack) =>{
          let tasks = Store.getStoreData('tasks')
          let role = Store.getStoreData('role')
          let contact_id = Store.getStoreData('contact_id')
          if(stack!==null){
            if('mode' in stack && stack.mode === "delete"){
                  delete tasks[stack.task_id.toString()];
            }else{
                  tasks[stack.task_id.toString()] = stack.task;
            }  
            
            Store.updateStore('tasks', tasks)
            IndexLoader.divideAllTasks(tasks, role, contact_id);
          }
          this.props.closefaqModal();
          this.setState({alert_param: null})
      }

      onExpireDateChange = (date) => {
          this.setState({ faq_expire_date: date })
      }
      handleChangeTopic = (event) => {
          event.preventDefault();
          let data = this.state.data;
          let topic = data['topic']; 
          for(let t of Store.getStoreData('topics')) {
              if(t.id===event.target.value){
                  topic = t;
              }
          }
          data['topic'] = topic
          this.setState({ [event.target.name] : event.target.value, data: data})
      }

      handleChange = (event) => {
        // alert(event.target.name)
        let data = this.state.data;
        data[event.target.name] = event.target.value;
        this.setState({ data: data })
          // console.log(this.state.query)
      }

      selectOrUnselectTags = (tagid) => (event) => {
          event.preventDefault();
          let task_data = this.state.data;
          let tags  = task_data['tags'];
          tags.forEach((tag) => {
              if (tag.id === tagid) {
                  if (tag.selected) {
                      tag.selected = false;
                      tag.styledcomponent = UnselectedPill;
                  } else {
                      tag.selected = true;
                      tag.styledcomponent = SelectedPill;
                  }
              }
          })
          task_data['tags'] = tags;
          this.setState({ data: task_data});
      }

      filesLoaded = (files) => {
          let contact_id = Store.getStoreData('contact_id');
          for(let i=0; i < files.length; i++) {
              files[i].name = files[i].name.replace('&', '');
              if(!('user_id' in files[i])) {
                  files[i].user_id = contact_id;
                  files[i].upload_date = Date.now();
              }
          }
          this.setState({binFiles: files});
      }

      render() {
        if (!this.state.ready) return <div></div>;
        return (
          <Style.ModalContainer style={{width: "100%", top: "0px",margin:"0px 0px"}}>
                {
                    (() => {
                        if(this.state.is_loading) {
                            return <CSLLoader style={{position: 'absolute'}} />
                        }
                    })()
                }

                <Style.ModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></Style.ModalHeaderCloseBtn>
                <Style.ModalHeaderText>Add New FAQ</Style.ModalHeaderText>
                <div style={{clear: "both"}}></div>
                <Style.ModalBody>
                    <Style.ModalBodyInner>
                        <Style.ModalContainertop>
                        {
                            (() => {
                                if(Store.getStoreData('has_gc')) {
                                    return (<div style={{display:"contents"}}>
                                                <Style.ModalNameLabelDiv>Company</Style.ModalNameLabelDiv>
                                                <Style.ModalNameInputDiv>
                                                    <Style.ModalNameInputDiv>{this.state.data['company']}</Style.ModalNameInputDiv>
                                                </Style.ModalNameInputDiv>
                                            </div>
                                            )
                                }
                            })()
                        }
                        <Style.ModalNameLabelDiv>ID</Style.ModalNameLabelDiv>
                        <Style.ModalNameInputDiv>
                            <Style.ModalNameInputDiv>N/A</Style.ModalNameInputDiv>
                        </Style.ModalNameInputDiv>
                        {
                            (() => {
                                if(this.state.data['enabled_topics']) {
                                    return(
                                        <div style={{display:"contents"}}>
                                            <Style.ModalNameLabelDiv>Select a Topic</Style.ModalNameLabelDiv>
                                                <Style.ModalNameInputDiv>
                                                    <Style.MRModalSelect name="topic_id" value={this.state.data.topic.id} onChange={this.handleChangeTopic}>
                                                        {
                                                            Store.getStoreData('topics').map((item) => {
                                                                {
                                                                    return (
                                                                        <option key={item.id} value={item.id}>{item.topic}</option>
                                                                    );
                                                                }
                                                            })
                                                        }
                                                    </Style.MRModalSelect>
                                                </Style.ModalNameInputDiv>
                                        </div>
                                        )
                                }
                            })()
                        }
                        {
                            (() => {
                                if(this.state.data['enabled_topics'] && this.state.topic_id === "other") {
                                    return <div style={{display:"contents"}}><Style.ModalNameLabelDiv></Style.ModalNameLabelDiv><Style.ModalNameInputDiv><Style.ModalInput type="text" name="other_topic" value={this.state.topic.value.replace(/'+/g,"'")} onChange={this.handleChangeOther} /></Style.ModalNameInputDiv></div>
                                }
                            })()

                        }
                        <Style.ModalNameLabelDiv>Question</Style.ModalNameLabelDiv>
                        <Style.ModalNameInputDiv>
                            <Style.ModalTextArea name="query" value={this.state.data['query'].replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")}  onChange={this.handleChange} />
                        </Style.ModalNameInputDiv>
                        <Style.ModalNameLabelDiv>Answer</Style.ModalNameLabelDiv>
                        <Style.ModalNameInputDiv>
                            <HtmlEditor style={{width: '98%', padding: "5px"}} content={this.state.data['answer']} />
                        </Style.ModalNameInputDiv>
                        <Style.ModalNameLabelDiv>Attachments</Style.ModalNameLabelDiv>
                        <Style.ModalNameInputDiv>
                            <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} />
                        </Style.ModalNameInputDiv>
                        <Style.ModalNameLabelDiv>Tags</Style.ModalNameLabelDiv>
                        <Style.ModalNameInputDiv style={{backgroundColor:'#fff'}}>
                            {
                                this.state.data['tags'].map((tag, index) => {
                                    return (<tag.styledcomponent key={index} onClick={this.selectOrUnselectTags(tag.id)}>{tag.name}</tag.styledcomponent>);
                                })
                            }
                        </Style.ModalNameInputDiv>
                        <Style.ModalNameLabelDiv>Expiry date</Style.ModalNameLabelDiv>
                            <CSLDateCover>
                                <DatePicker
                                        onChange={this.onExpireDateChange}
                                        clearIcon={null}
                                        calendarIcon={null}
                                        locale={"en-GB"}
                                        value={this.state.faq_expire_date}
                                        />
                            </CSLDateCover>
                        </Style.ModalContainertop>
                        <Style.CancelSubmitButtonOuterContainer style={{width: '100%'}}>
                        <Style.CancelButton onClick={this.closeModal}>Close</Style.CancelButton>
                        <SaveButton onClick={this.SendforApprovalFAQ(0)}>Create FAQ and Save</SaveButton>
                        <SaveButton onClick={this.SendforApprovalFAQ(1)}>Publish FAQ</SaveButton>
                        </Style.CancelSubmitButtonOuterContainer>
                    </Style.ModalBodyInner>
                </Style.ModalBody>
                <AlertBox alertParam = {this.state.alert_param}/>
          </Style.ModalContainer>
        );
      }
    }

class ReopenAnswerModal extends React.Component
{
        state = {ready: false, 
                alert_param: null, 
                task_data: null,         
                binFiles: [],
                cur_files_json: null,
                extbinFiles: [],
                ext_cur_files_json: null,
                view_faq: false,
                show_faq: false,
                is_loading: false,
                enabled_topics: false,
                topic: {},
                topic_id: "0",
            };

    constructor(props) {
        super(props);
    }
    componentDidMount(){
        this.processTask(this.props.task);
    }

    componentDidUpdate(prevProps){
        if (prevProps !== this.props) {
            this.processTask(this.props.task);
        }
    }

    isLoadTags = (tags) => {
        let rawtags = Store.getStoreData('tags');
        let otags = [];
        rawtags.forEach((top) => {
            if(Object.keys(tags).length === 0){
                otags.push({
                    name: top.TagName,
                    id: top.ID,
                    styledcomponent: UnselectedPill,
                    selected: false
                });
            }else {
                if(tags[top.ID.toString()] ===  top.TagName) {
                    otags.push({
                        name: top.TagName,
                        id: top.ID,
                        styledcomponent: SelectedPill,
                        selected: true
                    });
                }else{
                    otags.push({
                        name: top.TagName,
                        id: top.ID,
                        styledcomponent: UnselectedPill,
                        selected: false
                    });
                }
            }
        })
        let stagnames = [];
        otags.forEach((item) => {
            stagnames.push(item.name);
        })
        stagnames.sort();
        let ftags = [];
        for (let i=0; i<stagnames.length; i++) {
            for (let j=0; j<otags.length; j++) {
                if (stagnames[i] === otags[j].name) {
                    ftags.push(otags[j]);
                }
            }
        }
        return ftags;
    }

    processTask = (task) => {
        let contacts = Store.getStoreData('contacts');
        let moduleconfig = Store.getStoreData('moduleconfig');
        let form_data = task;
        let gc_assoc = Store.getStoreData('gc_assoc');
        let gc_company = parseInt(form_data.company_id) < 100000 ? Store.getStoreData('company').CompanyName : gc_assoc[(parseInt(form_data.company_id)%100000).toString()].company_name;
        let gc_company_id = parseInt(form_data.company_id) < 100000 ? form_data.company_id : form_data.company_id%100000;
        let list_answrere = Utils.showCompanyUsers(gc_company_id,'answrer');
        let task_json = JSON.parse(form_data.task_json);
        if (task_json.object_data.bin_files.length !== 0) {
            task_json.object_data.bin_files.forEach((item) => {
                item.uid = "uid" in item ? item.uid : this.genKey(10);
            })
        }
        let cur_files_json = [];
        let ext_cur_files_json = [];
        if(task_json.object_data.bin_files.length !== 0) {
            task_json.object_data.bin_files.forEach((item) => {                 
                if("type" in item && item.type === 'external'){
                    ext_cur_files_json.push(item);
                }else{
                    cur_files_json.push(item);
                }
            });                 
        }

        // let enabled_topics = this.state.enabled_topics;
        // let topic = this.state.topic;
        // if(moduleconfig.enabled_topics && ('topic' in task_json.object_data && 'value' in task_json.object_data.topic)){
        //     enabled_topics = true;
        //     topic = task_json.object_data.topic;
        // }

        let enabled_topics = this.state.enabled_topics;
        let topic = this.state.topic;
        let topic_id = this.state.topic_id;
        let store_topic = Store.getStoreData('topics_exclude_other');
        if(moduleconfig.enabled_topics && store_topic.length > 0){
          enabled_topics = true;
          if('topic' in task_json.object_data && 'value' in task_json.object_data.topic){
              topic = task_json.object_data.topic;
              topic_id = topic.id;
          }else{
              topic_id = store_topic[0].id;
              topic = store_topic[0];
          }
        }
        
        let cur_assigned = form_data.cur_assigned_to === 0 ? "Compliance Advisers" : contacts[form_data.cur_assigned_to.toString()];
        let org_teams = Store.getStoreData('org_teams');
        if('cur_recever_id' in task_json.object_data && task_json.object_data.cur_recever_id!==null && form_data.cur_assigned_to === 0 && isNaN(task_json.object_data.cur_recever_id) && Object.keys(org_teams).length > 0) {
          if(task_json.object_data.cur_recever_id in org_teams){
            cur_assigned = org_teams[task_json.object_data.cur_recever_id.toString()].name;
          }
        }


        let answer = ('answer' in task_json.object_data) ? task_json.object_data.answer : '';
        if(Utils.isBase64(answer)){
          answer = window.decodeURIComponent(atob(answer))
        }
        let ptags = {};
        if('tags' in task_json.object_data) {
            ptags = task_json.object_data.tags;
        }
        let tags = this.isLoadTags(ptags)

        let requester_name = "";
        if('requester_name' in task_json.object_data) {
          requester_name = task_json.object_data.requester_name;
        }
        let contact = form_data.from_api === 0 ?  contacts[form_data.contact_id] : requester_name;
        let from_faq = ('from_faq' in task_json.object_data) ? task_json.object_data.from_faq : false;
        let faq_id = ('faq_id' in task_json.object_data) ? task_json.object_data.faq_id : 0;


        let task_data = {};
        task_data['task_id'] = task.id
        task_data['enabled_topics'] = enabled_topics
        task_data['topic'] = topic
        task_data['ref_id'] = task_json.object_data.ref_id
        task_data['company'] = gc_company
        task_data['cur_assigned'] = cur_assigned
        task_data['query'] = 'query' in task_json.object_data && task_json.object_data.query !=null ? task_json.object_data.query : ''
        task_data['answer'] = answer
        task_data['tags'] = tags
        task_data['contact'] = contact
        task_data['from_faq'] = from_faq
        task_data['faq_id'] = faq_id
        task_data['company_id'] = form_data.company_id
        task_data['contact_id'] = form_data.contact_id
        let external_advice = ("external_advice" in task_json.object_data) ? task_json.object_data.external_advice : [];
        let externla_provides_enabled = ("externla_provides_enabled" in moduleconfig ) ? moduleconfig.externla_provides_enabled : false
        task_data['external_advice'] = external_advice
        task_data['externla_provides_enabled'] = ("externla_provides_enabled" in task_json.object_data && external_advice.length > 0) ? task_json.object_data.externla_provides_enabled : false;

        task_data['ext_advice'] = ('ext_advice' in task_json.object_data) ? task_json.object_data.ext_advice : false;
        task_data['ext_advice_txt'] = ('ext_advice_txt' in task_json.object_data) ? task_json.object_data.ext_advice_txt : '';
        let ext_advice_provider = '';
        if(externla_provides_enabled){
            ext_advice_provider = external_advice[0];
        }
        task_data['ext_advice_provider'] = ('ext_advice_provider' in task_json.object_data) ? task_json.object_data.ext_advice_provider : ext_advice_provider;
        task_data['ext_ref_number'] = ('ext_ref_number' in task_json.object_data) ? task_json.object_data.ext_ref_number : '';
        task_data['ext_contact_name'] = ('ext_contact_name' in task_json.object_data) ? task_json.object_data.ext_contact_name : '';


        this.setState({ready: true, task_data: task_data,cur_files_json: cur_files_json, ext_cur_files_json: ext_cur_files_json, enabled_topics: enabled_topics, topic: topic, topic_id: topic_id})
    }

    selectOrUnselectTags = (tagid) => (event) => {
        event.preventDefault();
        let task_data = this.state.task_data;
        let tags  = task_data['tags'];
        tags.forEach((tag) => {
            if (tag.id === tagid) {
                if (tag.selected) {
                    tag.selected = false;
                    tag.styledcomponent = UnselectedPill;
                } else {
                    tag.selected = true;
                    tag.styledcomponent = SelectedPill;
                }
            }
        })
        task_data['tags'] = tags;
        this.setState({ task_data: task_data});
    }

    closeModal = (event) => {
        event.preventDefault();
        this.props.closeAnswerModal();
    }

    closeFaqModal = () => {
      this.setState({show_faq: false});
    }

    showHideFaq = (event) => {
        event.preventDefault();
        let {show_faq} = this.state;
        show_faq = this.state.show_faq ? false: true;
        this.setState({show_faq: show_faq});

    }

    viewFaq = (event) => {
        let {view_faq} = this.state;
        view_faq = this.state.view_faq ? false: true;
        this.setState({view_faq: view_faq});
    }

    rfereshFAQ = () => {
        let {view_faq} = this.state;
        view_faq = this.state.view_faq ? false: true;
        this.setState({view_faq: view_faq});
    }

    handleinputChange =(event) => {
        let data = this.state.task_data;
        data[event.target.name] = event.target.value;
        this.setState({ task_data: data })
        const input = event.target;
        input.style.height = "auto";
        input.style.height = input.scrollHeight + "px";
        console.log("input", input)
        // this
    } 

    handleChange =(event) => {
        let data = this.state.task_data;
        data[event.target.name] = event.target.value;
        this.setState({ task_data: data });
    } 

    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }
        }
        this.setState({binFiles: files});
    }

    saveCAR = (event) => {
        event.preventDefault()
        // console.log("save answer", this.state.task_data)


        let task_data = this.state.task_data;
        let answer = Store.getStoreData('answer');
        if(answer === null){
            answer = task_data.answer;
        }
        let answer_summary = btoa(window.encodeURIComponent(answer));
        let postData = {};
        postData["command"] = "save_answer";
        postData["query"] = task_data.query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")
        postData["answer"] = answer_summary
        postData["task_id"] = task_data.task_id
        let selectedtags = {};
        task_data.tags.forEach((tag) => {
            if (tag.selected) selectedtags[tag.id.toString()] = tag.name;
        })
        postData["tags"] = selectedtags

        let bin_files1 = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
        let bin_files2 = this.state.ext_cur_files_json === null ? this.state.extbinFiles : [...this.state.extbinFiles, ...this.state.ext_cur_files_json];
        let bin_files = [...bin_files1, ...bin_files2];

        postData['bin_files'] = bin_files;

        if(this.state.enabled_topics) {
            postData['topic'] = this.state.topic;
            postData['topic_value'] = this.state.topic['value'];
        }

        postData['ext_advice'] = task_data.ext_advice;
        postData['ext_advice_provider'] = task_data.ext_advice_provider;
        postData['ext_advice_txt'] = task_data.ext_advice_txt;
        postData['ext_contact_name'] = task_data.ext_contact_name;
        postData['ext_ref_number'] = task_data.ext_ref_number;
        postData['external_advice'] = task_data.external_advice;
        postData['externla_provides_enabled'] = task_data.externla_provides_enabled;
        console.log('postData', postData);
        let api = new APICall();
        api.indexCommand(postData, this.processSaveCAR);
        this.setState({is_loading: true})
    }

    processSaveCAR = (result) => {
        console.log("processSaveCAR", result)
        this.setState({is_loading: false});
        let stack = {};
        stack['task_id'] = result.error_code === 0 ? result.task_id : 0
        stack['task'] = result.error_code === 0 ? result.task : {};
        let msg = 'Your request has been updated.' ;
        let title = 'Task save'
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.CARalertremoveHandler, stack: stack}
        this.setState({alert_param: alert_param})
    }

    
    CARalertremoveHandler = (result, stack) => {
        let tasks = Store.getStoreData('tasks')
        let role = Store.getStoreData('role')
        let contact_id = Store.getStoreData('contact_id')
        if(stack!==null){
          if('mode' in stack && stack.mode === "delete"){
                delete tasks[stack.task_id.toString()];
          }else{
                tasks[stack.task_id.toString()] = stack.task;
          }  
          Store.updateStore('tasks', tasks)
          IndexLoader.divideAllTasks(tasks, role, contact_id);
        }
        this.props.refreshAnswerModal();
        this.setState({alert_param: null})
    }

    handleChangeTopic = (event) => {
        event.preventDefault();
        let topic = this.state.topic; 
        for(let t of Store.getStoreData('topics')) {
            if(t.id===event.target.value){
                topic = t;
            }
        }
        this.setState({ [event.target.name] : event.target.value, topic: topic})
    }

    handleChangeOther = (event) => {
        event.preventDefault();
        let topic = this.state.topic; 
        topic.value = event.target.value;
        this.setState({topic: topic})
    }

    handleCheckboxChange = (event) => {
        console.log("event.target.checked", event.target.checked)
        let data = this.state.task_data;
        data['ext_advice'] = event.target.checked;
        this.setState({ task_data: data })
    }

    extfilesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
                files[i].type = 'external';
            }
        }
        this.setState({extbinFiles: files});
    }

    render()
    {
        if(!this.state.ready) {
            return <CSLLoader />;
        }
        return (
            <Style.ModalContainer style={{width: "100%", top: "0px",margin:"0px 0px"}}>
                 {
                     (() => {
                         if(this.state.is_loading) {
                             return <CSLLoader style={{position: 'absolute'}} />
                         }
                     })()
                 }

                 {
                     (() => {
                         if(this.state.view_faq && this.state.task_data['faq_id'] > 0) {
                             return <div style={{width:"100%"}}><InactiveOverlay /><ShowFAQModal faq_id={this.state.task_data['faq_id']} is_car={true} /></div>
                         }
                     })()
                 }
                 {
                     (() => {
                         if(this.state.show_faq) {
                             return <div style={{width:"100%"}}><InactiveOverlay /><AddFAQModal data={this.state.task_data} cur_files_json={this.state.cur_files_json}  closefaqModal={this.closeFaqModal} /></div>
                         }
                     })()
                 }
                 <Style.ModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></Style.ModalHeaderCloseBtn>
                 <Style.ModalHeaderText>Provide Compliance Advice</Style.ModalHeaderText>
                 <div style={{clear: "both"}}></div>
                    <Style.ModalBody>
                         <Style.ModalBodyInner>
                             <Style.ModalContainertop>
                                 {
                                     (() => {
                                         if(Store.getStoreData('has_gc')) {
                                             return (<div style={{display:"contents"}}>
                                                         <Style.ModalNameLabelDiv>Company</Style.ModalNameLabelDiv>
                                                         <Style.ModalNameInputDiv>
                                                             <Style.ModalNameInputDiv>{this.state.task_data['company']}</Style.ModalNameInputDiv>
                                                         </Style.ModalNameInputDiv>
                                                     </div>
                                                     )
                                         }
                                     })()
                                 }
                                 <Style.ModalNameLabelDiv>CAR Reference</Style.ModalNameLabelDiv>
                                 <Style.ModalNameInputDiv>
                                     <Style.ModalNameInputDiv>{this.state.task_data['ref_id']}</Style.ModalNameInputDiv>
                                 </Style.ModalNameInputDiv>

                                 {/*{
                                     (() => {
                                         if(this.state.task_data['enabled_topics']) {
                                             return (
                                                 <div style={{display:"contents"}}>
                                                     <Style.ModalNameLabelDiv>Topic</Style.ModalNameLabelDiv>
                                                         <Style.ModalNameInputDiv>
                                                             <Style.ModalNameInputDiv>{this.state.task_data['topic'].value}</Style.ModalNameInputDiv>
                                                         </Style.ModalNameInputDiv>
                                                 </div>
                                                 )
                                         }

                                     })()
                                 }*/}

                                 {
                                     (() => {
                                         if(this.state.task_data['enabled_topics']) {
                                             return(
                                                 <div style={{display:"contents"}}>
                                                     <Style.ModalNameLabelDiv>Select a Topic</Style.ModalNameLabelDiv>
                                                         <Style.ModalNameInputDiv>
                                                             <Style.MRModalSelect name="topic_id" value={this.state.topic.id} onChange={this.handleChangeTopic}>
                                                                 {
                                                                     Store.getStoreData('topics').map((item) => {
                                                                         {
                                                                             return (
                                                                                 <option key={item.id} value={item.id}>{item.topic}</option>
                                                                             );
                                                                         }
                                                                     })
                                                                 }
                                                             </Style.MRModalSelect>
                                                         </Style.ModalNameInputDiv>
                                                 </div>
                                                 )
                                         }
                                     })()
                                 }

                                 {
                                     (() => {
                                         if(this.state.enabled_topics && this.state.topic_id === "other") {
                                             return <div style={{display:"contents"}}><Style.ModalNameLabelDiv></Style.ModalNameLabelDiv><Style.ModalNameInputDiv><Style.ModalInput type="text" name="other_topic" value={this.state.topic.value.replace(/'+/g,"'")} onChange={this.handleChangeOther} /></Style.ModalNameInputDiv></div>
                                         }
                                     })()

                                 }
                                 
                                 <Style.ModalNameLabelDiv>Currently assigned to</Style.ModalNameLabelDiv>
                                 <Style.ModalNameInputDiv>
                                     <Style.ModalNameInputDiv>{this.state.task_data['cur_assigned']}</Style.ModalNameInputDiv>
                                 </Style.ModalNameInputDiv>
                                 <Style.ModalNameLabelDiv>Advice Request</Style.ModalNameLabelDiv>
                                 <Style.ModalNameInputDiv>
                                 <Style.ModalTextArea name="query" value={this.state.task_data['query'].replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} onChange={this.handleinputChange}/>
                                 
                                 </Style.ModalNameInputDiv>
                                 <Style.ModalNameLabelDiv>Attachments</Style.ModalNameLabelDiv>
                                 <Style.ModalNameInputDiv>
                                     <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} />
                                 </Style.ModalNameInputDiv>

                                 <Style.ModalNameLabelDiv>Requester</Style.ModalNameLabelDiv>
                                 <Style.ModalNameInputDiv>
                                     <Style.ModalInput style={{width: '98%'}}  name="contact" defaultValue={this.state.task_data['contact']} readOnly={true} />
                                 </Style.ModalNameInputDiv>

                                 <Style.ModalNameLabelDiv>Answer <span style={{color: "#ff2f00", padding:"4px"}}>*</span></Style.ModalNameLabelDiv>
                                 <Style.ModalNameInputDiv >
                                     
                                 <HtmlEditor style={{width: '98%', padding: "5px"}} content={this.state.task_data['answer']} />
                                 </Style.ModalNameInputDiv>

                                 <Style.ModalNameLabelDiv>Available Tags</Style.ModalNameLabelDiv>
                                 <Style.ModalNameInputDiv style={{backgroundColor:'#fff'}}>
                                     {
                                         this.state.task_data['tags'].map((tag, index) => {
                                             return (<tag.styledcomponent key={index} onClick={this.selectOrUnselectTags(tag.id)}>{tag.name}</tag.styledcomponent>);
                                         })
                                     }
                                 </Style.ModalNameInputDiv>

                                 <Style.ModalNameLabelDiv>External Advice Required<input type="checkbox" name="ext_advice" onChange={this.handleCheckboxChange} checked={this.state.task_data['ext_advice']} /></Style.ModalNameLabelDiv>
                                 {
                                     (() =>{
                                         if (this.state.task_data['ext_advice']){
                                             return(
                                                 <div>
                                                 {
                                                     (() => {
                                                         if(this.state.task_data['externla_provides_enabled']){
                                                             return(<div>
                                                                 <Style.ModalNameLabelDiv>External Advice Providers</Style.ModalNameLabelDiv>
                                                                 <Style.ModalNameInputDiv>
                                                                     <Style.MRModalSelect name="ext_advice_provider" value={this.state.task_data['ext_advice_provider']} onChange={this.handleChange}>
                                                                     {
                                                                         this.state.task_data['external_advice'].map((item, index) => {
                                                                             {
                                                                                 return (
                                                                                     <option key={index} value={item}>{item}</option>
                                                                                 );
                                                                             }
                                                                         })
                                                                     }
                                                                     </Style.MRModalSelect>
                                                                 </Style.ModalNameInputDiv>
                                                             </div>)
                                                         }
                                                     })()
                                                 }
                                                 <Style.ModalNameInputDiv>
                                                     <Style.ModalTextArea style={{width: '98%'}} name="ext_advice_txt" value={this.state.task_data['ext_advice_txt'].replace(/'+/g,"'")} onChange={this.handleChange} placeholder="External Advice Required" />
                                                 </Style.ModalNameInputDiv>
                                                 <Style.ModalNameLabelDiv>Reference Number</Style.ModalNameLabelDiv>
                                                 <Style.ModalNameInputDiv>
                                                 <Style.ModalInput style={{width: '98%'}} name="ext_ref_number" value={this.state.task_data['ext_ref_number'].replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} onChange={this.handleChange} placeholder="Reference Number"/>
                                                 </Style.ModalNameInputDiv>
                                                 <Style.ModalNameLabelDiv>Contact Name</Style.ModalNameLabelDiv>
                                                 <Style.ModalNameInputDiv>
                                                 <Style.ModalInput style={{width: '98%'}} name="ext_contact_name" value={this.state.task_data['ext_contact_name'].replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} onChange={this.handleChange} placeholder="Contact Name"/>
                                                 </Style.ModalNameInputDiv>
                                                 <Style.ModalNameLabelDiv>Attachments</Style.ModalNameLabelDiv>
                                                 <Style.ModalNameInputDiv>
                                                     <Dropzone onFilesAdded={this.extfilesLoaded} initFiles={this.state.ext_cur_files_json} />
                                                 </Style.ModalNameInputDiv>
                                                 </div>
                                             )
                                         }
                                     })()
                                 }

                             </Style.ModalContainertop>
                             
                             <Style.CancelSubmitButtonOuterContainer style={{width: '100%'}}>
                                 <div style={{display:"inline"}}>
                                     {
                                         (() => {
                                             if(this.state.task_data['from_faq']) {
                                                 return <SubmitButton onClick={this.viewFaq} style={{float:"left"}}>View FAQ</SubmitButton>
                                             }else{
                                                 return <SubmitButton onClick={this.showHideFaq} style={{float:"left"}}>Add to FAQ</SubmitButton>
                                             }
                                         })()
                                     }
                                     <Style.CancelButton onClick={this.closeModal}>Close</Style.CancelButton>
                                     <Style.SubmitButton onClick={this.saveCAR}>Save</Style.SubmitButton>
                                 </div>
                             </Style.CancelSubmitButtonOuterContainer>
                         </Style.ModalBodyInner>
                    </Style.ModalBody>
                <AlertBox alertParam = {this.state.alert_param}/>
            </Style.ModalContainer>
        );
    }
}
export default ReopenAnswerModal;