		let data = {table_headers: [], data: []};
        data.module = {id: 'car', display: 'CAR'}
		data.table_headers = [
            {
                "accessor": "status", "nickname": "Status", type: 'alpha', width: 2,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                "accessor": "ref_id", "nickname": "ID", type: 'alpha', width: 2.1,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                "accessor": "question", "nickname": "Question", type: 'alpha', width: 4.5,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                "accessor": "topic_value", "nickname": "Topic", type: 'alpha', width: 2.3,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                "accessor": "question_by", "nickname": "Requester", type: 'alpha', width: 2,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                "accessor": "answered_by", "nickname": "Responder", type: 'alpha', width: 2,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                "accessor": "askedDate", "nickname": "Asked Date", type: 'date', width: 1.7,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                "accessor": "answeredDate", "nickname": "Responded Date", type: 'date', width: 1.7,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                "accessor": "company_name", "nickname": "Company", type: 'alpha', width: 0,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                "accessor": "asked_month", "nickname": "Asked Month", type: 'month', width: 0,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
        ];

        data.sortby = {
            fields: [
                {"accessor": "status", "nickname": "Status"},
                {"accessor": "ref_id", "nickname": "ID"},
                {"accessor": "question", "nickname": "Question"},
                {"accessor": "topic_value", "nickname": "Topic"},
                {"accessor": "question_by", "nickname": "Requester"},
                {"accessor": "answered_by", "nickname": "Responder"},
                {"accessor": "askedDate", "nickname": "Asked Date"},
                {"accessor": "answeredDate", "nickname": "Responded Date"},
                {"accessor": "asked_month", "nickname": "Asked Month"},
                {"accessor": "company_name", "nickname": "Company"}
            ],
            selected: {"accessor": "none", "order": "Descending"}
        };
        data.groupby = {
            fields: [
                {"accessor": "status", "nickname": "Status"},
                {"accessor": "ref_id", "nickname": "ID"},
                {"accessor": "question", "nickname": "Question"},
                {"accessor": "topic_value", "nickname": "Topic"},
                {"accessor": "requester", "nickname": "Requester"},
                {"accessor": "answered_by", "nickname": "Responder"},
                {"accessor": "askedDate", "nickname": "Asked Date"},
                {"accessor": "answeredDate", "nickname": "Responded Date"},
                {"accessor": "asked_month", "nickname": "Asked Month"},
                {"accessor": "company_name", "nickname": "Company"}
            ],
            selections: []
        };
        data.font_size_map = {
            section_titles :    {default : {selected : false, size : 16}, small : {selected : false, size : 14}, large : {selected : true, size : 18}},
            introductory_text : {default : {selected : false, size : 12}, small : {selected : false, size : 11}, large : {selected : true, size : 14}},
            header_row_text :   {default : {selected : true, size : 12}, small : {selected : false, size : 11}, large : {selected : false, size : 14}},
            table_data_text :   {default : {selected : true, size : 11}, small : {selected : false, size : 10}, large : {selected : false, size : 12}},       
            footer:             {default : {selected : true, size : 10}, small : {selected : false, size : 9}, large : {selected : false, size : 11}}
        }

module.exports = data