import React from "react";
import Store from '../../Common/Store.js';
import Utils from '../../Common/Utils';
import Iframe from "react-iframe";
import * as jsPDF from "jspdf";
import { FaTimes } from "react-icons/fa";
import styled from "styled-components";
import * as Style from '../Common/StyledComponents';
import moment from 'moment';

const CloseBtn = styled.button`
  box-sizing: border-box;
  background-color: #ffcc00;
  color: #282828;
  width: 100px;
  height: 35px;
  border-radius: 4px;
  border: 2px solid #dadada;
  margin-left: 5px;
  font-size: 11px;
  float: right;
  cursor: pointer;
  font-weight: bold;
  font-family: "Montserrat";
`;

const ModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 97%;
    position: absolute;
    margin: 0 0;
    top: 8px;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
`;

const marginX = 12;
const marginY = 8;
const maxX = 210 - 2 * marginX;
const maxY = 297 - 2 * marginY;
const footer_height = 15;
const para_gap = 9;
const line_gap = 5;
class PrintPreview extends React.Component {
  state = { fck: null, fckPDF: null, pdfTemp: null, pdfURL: null, pageWidth: maxX, pageHeight: maxY, ready: true };

  constructor(props) {
    super(props);
  }
  banner(data){
    let car_id = data.object_data.ref_id;
    this.fckPDF.setFillColor("#FFFFFF");
    this.fckPDF.rect(0, 0, 210, 10, "F");
    this.fckPDF.setFontSize(12);
    this.fckPDF.setTextColor("#4C4C4C");
    this.fckPDF.setFontStyle("bold");
    this.fckPDF.text("Closure Report for "+ car_id, 55, this.current_top);    

  }
  firstRowHeadings() {
    this.current_top = this.current_top + 10;
    //this.fckPDF.setFillColor("#FFFFFF");
    //this.fckPDF.rect(5, 10, 210, 235, "F");
    this.fckPDF.setFontSize(10);
    this.fckPDF.setTextColor("#4C4C4C");
    this.fckPDF.setFontStyle("bold");
    this.fckPDF.text("ID", 5, this.current_top);
    this.fckPDF.text("Topic", 33, this.current_top);
    this.fckPDF.text("Requester", 70, this.current_top);    
    this.fckPDF.text("Requested Date", 125, this.current_top);
    this.fckPDF.text("Status", 160, this.current_top);
    this.fckPDF.text("Tags", 190, this.current_top);       
    //this.fckPDF.text(report_task_json.object_data.headerinfo.file_reference.replace(/'+/g, "'"), 63, this.current_top + 15);
  }
  firstRowValues(data,contacts,cur_lane){
    console.log("contacts,",data);
    let car_id = data.object_data.ref_id; 
    let cnt_id = data.object_data.asked_by;
    let request_date = new Date(data.object_data.asked_date).toLocaleString('en-GB',{year: 'numeric', month: 'numeric', day: 'numeric',hour: '2-digit', minute: '2-digit'}).replace(/,/g, ''); 
    let status = cur_lane === 'COMP_CAR_USER_COMPLETED' ? 'Closed' : 'Rejected';
    let ptags = {};
    if('tags' in data.object_data) {
      ptags = data.object_data.tags;
    }
    let tags_count =  Object.keys(ptags).length;
    //this.fckPDF.setFillColor("#FFFFFF");
    this.current_top = this.current_top + 5;
    //this.fckPDF.rect(0, 10, 210, 12, "F");
    this.fckPDF.setFontSize(10);
    this.fckPDF.setTextColor("#4C4C4C");
    this.fckPDF.setFontStyle("normal");
    this.fckPDF.text(car_id, 5, this.current_top);
    
    if('topic' in data.object_data && Object.keys(data.object_data.topic).length > 0){
      this.fckPDF.text(data.object_data.topic.topic, 33, this.current_top);
    }

    this.fckPDF.text(contacts[cnt_id], 70, this.current_top);    
    this.fckPDF.text(request_date, 125, this.current_top);
    this.fckPDF.text(status, 160, this.current_top);
    this.fckPDF.text(tags_count.toString(), 190, this.current_top);      
  }
  companyHeading(){
    //this.fckPDF.setFillColor("#FFFFFF");
    this.current_top = this.current_top + 15;
    //this.fckPDF.rect(0, 20, 210, 12, "F"); 
    this.fckPDF.setFontSize(10);
    this.fckPDF.setTextColor("#4C4C4C");
    this.fckPDF.setFontStyle("bold");
    this.fckPDF.text("Company", 5, this.current_top);               
  }
  companyName(company){
    //this.fckPDF.setFillColor("#FFFFFF");
    this.current_top = this.current_top + 5;
    //this.fckPDF.rect(0, 40, 210, 12, "F"); 
    this.fckPDF.setFontSize(10);
    this.fckPDF.setTextColor("#4C4C4C");
    this.fckPDF.setFontStyle("normal");
    this.fckPDF.text(company, 5, this.current_top);               
  }
  questionHeading(){
    //this.fckPDF.setFillColor("#FFFFFF");
    this.current_top = this.current_top + 15;
    //this.fckPDF.rect(0, 20, 210, 12, "F"); 
    this.fckPDF.setFontSize(10);
    this.fckPDF.setTextColor("#4C4C4C");
    this.fckPDF.setFontStyle("bold");
    this.fckPDF.text("Question", 5, this.current_top);               
  }
  questionValue(data){
    //this.fckPDF.setFillColor("#FFFFFF");
    this.current_top = this.current_top + 5;
    //this.fckPDF.rect(0, 20, 210, 12, "F"); 
    this.fckPDF.setFontSize(10);
    this.fckPDF.setTextColor("#4C4C4C");
    this.fckPDF.setFontStyle("normal");
    let questionText = data.object_data.query;

    let rcrds = questionText.split("\n");
    console.log("rcrds...",rcrds.length)
    for(let n of rcrds){
      let local_actual_height = this.getboxheight(n)*0.7;
      let local_height_to_show = (local_actual_height < Math.abs(297-this.current_top-20)) ? Math.abs(local_actual_height -this.current_top): Math.abs(297-this.current_top-20);
      this.current_top = this.processLineQuestion(n, this.current_top,local_height_to_show,local_actual_height);
      this.current_top = this.current_top+4;
    }
    
    
    
  }
  answerHeading(){
    //this.fckPDF.setFillColor("#FFFFFF");
    this.current_top = this.current_top + 15;
    //this.fckPDF.rect(0, 20, 210, 12, "F"); 
    this.fckPDF.setFontSize(10);
    this.fckPDF.setTextColor("#4C4C4C");
    this.fckPDF.setFontStyle("bold");
    this.fckPDF.text("Answer", 5, this.current_top);               
  }
  answerValue(answer){
    let rst = answer;
    //let regex = /(<([^>]+)>)/gi;
    //let rst = answer.replace(regex, " "); 
    //rst = rst.replace(/&nbsp;/g, ' ');
    //this.fckPDF.setFillColor("#FFFFFF");
    this.current_top = this.current_top + 5;
    //this.fckPDF.rect(0, 20, 210, 12, "F"); 
    this.fckPDF.setFontSize(10);
    this.fckPDF.setTextColor("#4C4C4C");
    this.fckPDF.setFontStyle("normal");
    
    let rcrds = answer.split("</p>");
    
    for(let n of rcrds){
      let regex = /(<([^>]+)>)/gi;
      let n1 = n.replace(regex, " ");
      n1 = n1.replace(/&nbsp;/g, ' ');
      n1 = n1.replace(/<p>/g, ' ');
      let local_actual_height = this.getboxheight(n1)*0.7;
      
      let local_height_to_show = (local_actual_height < Math.abs(297-this.current_top-20)) ? Math.abs(local_actual_height -this.current_top): Math.abs(297-this.current_top-20);
      this.current_top = this.processLineAnswer(n1, this.current_top,local_height_to_show,local_actual_height);
      this.current_top = this.current_top+4;
    }   
    //this.current_top = this.processLine(rst, this.current_top);
  }
  tagHeading(){
    //this.fckPDF.setFillColor("#FFFFFF");
    this.current_top = this.current_top + 15;
    //this.fckPDF.rect(0, 20, 210, 12, "F"); 
    this.fckPDF.setFontSize(10);
    this.fckPDF.setTextColor("#4C4C4C");
    this.fckPDF.setFontStyle("bold");
    this.fckPDF.text("Tags", 5, this.current_top);               
  }
  tagValues(ptags){

    let rect_hight = 8;    
    //this.fckPDF.setFillColor("#FFFFFF");
    this.current_top = this.current_top + 5;
    let rect_x_initial_value = 5;
    for(let t in ptags){
      let rect_width = ptags[t].length * 1.7 + 10;
      this.fckPDF.setFillColor("#37ADA7");
      this.fckPDF.roundedRect(rect_x_initial_value, this.current_top, rect_width, rect_hight, 1, 1, "F");
      this.fckPDF.setFontSize(10);
      this.fckPDF.setTextColor("#FFFFFF");
      this.fckPDF.text(ptags[t],rect_x_initial_value+1, this.current_top+5);
      rect_x_initial_value = rect_x_initial_value + rect_width + 3;      
    }
    
  }  
  clarification(c,data,contacts) {
   
   let object_data = data.object_data
   let date = new Date(c.actiondate)
   let clarification_date = date.toLocaleString('en-GB',{year: 'numeric', month: 'numeric', day: 'numeric',hour: '2-digit', minute: '2-digit'}).replace(/,/g, '')
   let bgColor = object_data.asked_by === c.performed_by ? '#F5C163' : '#04ACA7';
   bgColor = (c.accept_mode === 'acknowledge') ? 'green' : bgColor;

   if(this.current_top < 260){
      this.current_top = this.current_top + 25;
      this.fckPDF.setFillColor(bgColor);
      this.fckPDF.roundedRect(5, this.current_top, 200, 45 - 35, 1, 1, "F");
      this.fckPDF.setFontSize(10);
      this.fckPDF.setTextColor("#FFFFFF");
      
      //this.fckPDF.setFontStyle("bold");
      this.current_top = this.current_top+5;
      this.fckPDF.text(contacts[c.performed_by]+" "+clarification_date,7, this.current_top);
      this.current_top = this.current_top+5;
      this.fckPDF.setFillColor("#FFFFFF");
      this.fckPDF.roundedRect(5, this.current_top, 200, 45 - 35, 1, 1, "F");
      this.fckPDF.setFontSize(10);
      this.fckPDF.setTextColor("#4C4C4C");
      this.current_top = this.current_top+5;

      //let local_actual_height = this.getboxheight(c.note)*0.7;
      let notes = c.note.split("\n");
      //this.fckPDF.text(note_u, 7, this.current_top);
      for(let n of notes){
        //let n = c.note;
        let local_actual_height = this.getboxheight(n)*0.7;
        let local_height_to_show = (local_actual_height < Math.abs(297-this.current_top-20)) ? Math.abs(local_actual_height -this.current_top): Math.abs(297-this.current_top-20);
          
        //console.log("RRRRRR",this.current_top,local_height_to_show,local_actual_height,Math.abs(297-this.current_top-20));

        if(this.current_top <275){
          this.fckPDF.setFillColor("#FFFFFF");
          let hgt = Math.abs(275 - this.current_top) >  local_actual_height ? local_actual_height : Math.abs(275 - this.current_top);
           this.fckPDF.roundedRect(5, this.current_top, 200, hgt, 1, 1, "F");
           this.current_top = this.current_top+5;
           
           this.current_top = this.processLineClarification(n, this.current_top,local_height_to_show,local_actual_height);        
        }else{
           this.fckPDF.addPage();
           this.fckPDF.setFillColor("#F2F2F2");
           this.fckPDF.rect(0, 0, 210, 297, "F");
           this.current_top = marginY;
           this.current_top = this.current_top+5;
           this.fckPDF.setFillColor("#FFFFFF");
           this.fckPDF.roundedRect(5, this.current_top, 200, local_actual_height, 1, 1, "F");
           this.current_top = this.current_top+5;
           this.current_top = this.processLineClarification(n, this.current_top,local_height_to_show,local_actual_height);                  
        }
      }

//
    }else{
        this.fckPDF.addPage();
        this.fckPDF.setFillColor("#F2F2F2");
        this.fckPDF.rect(0, 0, 210, 297, "F");
        this.current_top = marginY;
        let n = c.note;
        this.fckPDF.setFillColor(bgColor);
        this.fckPDF.roundedRect(5, this.current_top, 200, 45 - 35, 1, 1, "F");
        this.fckPDF.setFontSize(10);
        
        this.fckPDF.setTextColor("#FFFFFF");
        //this.fckPDF.setFontStyle("bold");
        this.current_top = this.current_top+5;
        this.fckPDF.text(contacts[c.performed_by]+" "+clarification_date,7, this.current_top);
        this.current_top = this.current_top+5;
        this.fckPDF.setFillColor("#FFFFFF");
        this.fckPDF.roundedRect(5, this.current_top, 200, 45 - 35, 1, 1, "F");
        this.fckPDF.setFontSize(10);
        this.fckPDF.setTextColor("#4C4C4C");
        this.current_top = this.current_top+5;         

        /*let local_height_to_show = (local_actual_height < Math.abs(297-this.current_top-20)) ? (local_actual_height -20): Math.abs(297-this.current_top-20);
        let hgt = Math.abs(275 - this.current_top) >  local_actual_height ? local_actual_height: Math.abs(275 - this.current_top);
        //console.log("RRRRRR",Math.abs(275 - this.current_top),local_actual_height,hgt);        
        this.fckPDF.roundedRect(5, this.current_top, 200, hgt, 1, 1, "F");

        this.current_top = this.processLineClarification(n, this.current_top,local_height_to_show,local_actual_height);
        */
        let notes = c.note.split("\n");
        for(let n of notes){
          let local_actual_height = this.getboxheight(n)*0.7;
          let local_height_to_show = (local_actual_height < Math.abs(297-this.current_top-20)) ? Math.abs(local_actual_height -this.current_top): Math.abs(297-this.current_top-20); 
          if(this.current_top <275){

            let hgt = Math.abs(275 - this.current_top) >  local_actual_height ? local_actual_height : Math.abs(275 - this.current_top);
            this.fckPDF.setFillColor("#FFFFFF");
            this.fckPDF.roundedRect(5, this.current_top, 200, hgt, 1, 1, "F");
            this.current_top = this.current_top+5;
            //this.fckPDF.setFillColor("#FFFFFF");
            this.current_top = this.processLineClarification(n, this.current_top,local_height_to_show,local_actual_height);        
          }else{
            this.fckPDF.addPage();
            this.fckPDF.setFillColor("#F2F2F2");
            this.fckPDF.rect(0, 0, 210, 297, "F");
            this.current_top = marginY;
            this.current_top = this.current_top+5;
            this.fckPDF.setFillColor("#FFFFFF");
            this.fckPDF.roundedRect(5, this.current_top, 200, local_actual_height, 1, 1, "F");
            this.current_top = this.current_top+5;
            //this.fckPDF.setFillColor("#FFFFFF");
            this.current_top = this.processLineClarification(n, this.current_top,local_height_to_show,local_actual_height);                  
          }          
        }
               
    }     

  }
  cFieldHeading(txt){
    //this.fckPDF.setFillColor("#FFFFFF");
    this.current_top = this.current_top + 15;
    //this.fckPDF.rect(0, 20, 210, 12, "F"); 
    this.fckPDF.setFontSize(10);
    this.fckPDF.setTextColor("#4C4C4C");
    this.fckPDF.setFontStyle("bold");
    this.fckPDF.text(txt, 5, this.current_top);
    this.current_top = this.current_top+3;                
  }
  cFieldHeadingExternal(txt){
    //this.fckPDF.setFillColor("red");
    this.current_top = this.current_top + 15;
    //this.fckPDF.rect(0, 20, 210, 12, "F");
    //'#F5C163' : '#04ACA7';
    this.fckPDF.setFillColor("#04ACA7");
    this.fckPDF.roundedRect(5, this.current_top, 200, 15, 1, 1, "F");
    this.fckPDF.setFontSize(10);
    this.fckPDF.setTextColor("#FFFFFF");
    
    this.current_top = this.current_top+5;
    this.fckPDF.text(txt, 7, this.current_top);
    this.current_top = this.current_top+3;              
  }  
  cFieldValue(value){
    //this.fckPDF.setFillColor("#FFFFFF");
    //this.current_top = this.current_top + 5;
    this.fckPDF.setFillColor("#FFFFFF");
    this.fckPDF.roundedRect(5, this.current_top, 200, 45 - 35, 1, 1, "F");
    this.fckPDF.setFontSize(10);
    this.fckPDF.setTextColor("#4C4C4C");
    this.current_top = this.current_top + 5;

    let vals = value.split("\n");
    for(let v of vals){
      let local_actual_height = this.getboxheight(v)*0.7;
      let local_height_to_show = (local_actual_height < Math.abs(297-this.current_top-20)) ? Math.abs(local_actual_height -this.current_top): Math.abs(297-this.current_top-20);

      if(this.current_top <275){
           this.fckPDF.setFillColor("#FFFFFF");
           let hgt = Math.abs(275 - this.current_top) >  local_actual_height ? local_actual_height : Math.abs(275 - this.current_top);
           this.fckPDF.roundedRect(5, this.current_top, 200, hgt, 1, 1, "F");
           this.current_top = this.current_top+5;
           this.current_top = this.processLineClarification(v, this.current_top,local_height_to_show,local_actual_height);
      }else{
            this.fckPDF.addPage();
            this.fckPDF.setFillColor("#F2F2F2");
            this.fckPDF.rect(0, 0, 210, 297, "F");
            this.current_top = marginY;
            this.current_top = this.current_top+5;
            this.fckPDF.setFillColor("#FFFFFF");
            this.fckPDF.roundedRect(5, this.current_top, 200, local_actual_height, 1, 1, "F");
            this.current_top = this.current_top+5;
            //this.fckPDF.setFillColor("#FFFFFF");
            this.current_top = this.processLineClarification(v, this.current_top,local_height_to_show,local_actual_height);                  
      }
      
    }
                   
  }
  cFieldValueOther(value){
    //this.fckPDF.setFillColor("#FFFFFF");
    this.current_top = this.current_top + 15;
    this.fckPDF.setFillColor("#FFFFFF");
    this.fckPDF.roundedRect(5, this.current_top, 200, 45 - 35, 1, 1, "F");
    this.fckPDF.setFontSize(10);
    this.fckPDF.setTextColor("#4C4C4C");
    this.current_top = this.current_top + 5;

    let vals = value.split("\n");
    for(let v of vals){
      let local_actual_height = this.getboxheight(v)*0.7;
      let local_height_to_show = (local_actual_height < Math.abs(297-this.current_top-20)) ? Math.abs(local_actual_height -this.current_top): Math.abs(297-this.current_top-20);

      if(this.current_top <275){
           this.fckPDF.setFillColor("#FFFFFF");
           let hgt = Math.abs(275 - this.current_top) >  local_actual_height ? local_actual_height : Math.abs(275 - this.current_top);
           this.fckPDF.roundedRect(5, this.current_top, 200, hgt, 1, 1, "F");
           this.current_top = this.current_top+5;
           this.current_top = this.processLineClarification(v, this.current_top,local_height_to_show,local_actual_height);
      }else{
            this.fckPDF.addPage();
            this.fckPDF.setFillColor("#F2F2F2");
            this.fckPDF.rect(0, 0, 210, 297, "F");
            this.current_top = marginY;
            this.current_top = this.current_top+5;
            this.fckPDF.setFillColor("#FFFFFF");
            this.fckPDF.roundedRect(5, this.current_top, 200, local_actual_height, 1, 1, "F");
            this.current_top = this.current_top+5;
            //this.fckPDF.setFillColor("#FFFFFF");
            this.current_top = this.processLineClarification(v, this.current_top,local_height_to_show,local_actual_height);                  
      }
      
    }
                   
  }   
  customFields(custom_fields){
    console.log("custom_fields",custom_fields);
    if(custom_fields.enabled){
      if (custom_fields.custom_terminology_fields.length !== 0 ) {
        for(let field of custom_fields.custom_terminology_fields){
          if(field.name === "reference_number" && ('value' in field) && field.value !==''){
            this.cFieldHeading(field.nickname);
            this.cFieldValue(field.value);
          }
        }
      }
      if(custom_fields.additional_fields.length !== 0 ){
        for(let field of custom_fields.additional_fields){
          if(field.isActive === true && ('value' in field) && field.value !==''){
            console.log("customFields,",field)
            this.cFieldHeading(field.nickname);
            this.cFieldValue(field.value);
          }
          if(field.isActive === true && ('value' in field) && field.value ==='Other' && field.other === true){
            console.log("customFields,",field)
            this.cFieldValueOther(field.more_info);
          }                    
        }
      }
    }
  }  
  processLine(content, currenttop, ud_line_right, ud_Line_start, style) {
    var line_right = 202;
    if (ud_line_right !== undefined) {
      line_right = ud_line_right;
    }
    var cur_right = 5;
    if (ud_Line_start !== undefined) {
      cur_right = ud_Line_start;
    }
    let a = [];
    const line_height = 4;
    if (content !== "") {
      var word_split = content.replace(/\n/g, "").split(" ");
      for (let i = 0; i < word_split.length; i++) {
        var wordLenght = this.fckPDF.getTextWidth(word_split[i]);
        var gapLenght = this.fckPDF.getTextWidth(" ");
        if (cur_right + wordLenght + gapLenght < line_right && word_split[i] !== "<br>") {
          this.fckPDF.setTextColor("#4C4C4C");
          this.fckPDF.setFontStyle("normal");
          if (style !== undefined) {
            this.fckPDF.setFontStyle(style.weight);
            this.fckPDF.setTextColor(style.color);
          }
          if(currenttop > 275){a.push(word_split[i]);continue;}
          this.fckPDF.text(word_split[i], cur_right, currenttop);
          cur_right += wordLenght + gapLenght;
        } else {
          currenttop += line_height;
          cur_right = 5;
          if (ud_Line_start !== undefined) {
            cur_right = ud_Line_start;
          }
          this.fckPDF.setTextColor("#4C4C4C");
          this.fckPDF.setFontStyle("normal");
          if (style !== undefined) {
            this.fckPDF.setFontStyle(style.weight);
            this.fckPDF.setTextColor(style.color);
          }
          if(currenttop > 275){a.push(word_split[i]);continue;}
          if (word_split[i] !== "<br>") {
            this.fckPDF.text(word_split[i], cur_right, currenttop);
            cur_right += wordLenght + gapLenght;
          }
        }
      }
    
    }
    return currenttop;
  }
  processLineQuestion(content, currenttop, local_height_to_show,local_actual_height,ud_line_right, ud_Line_start, style) {
    var line_right = 202;
    if (ud_line_right !== undefined) {
      line_right = ud_line_right;
    }
    var cur_right = 5;
    if (ud_Line_start !== undefined) {
      cur_right = ud_Line_start;
    }
    let a =[];
    const line_height = 4;
    if (content !== "") {
      var word_split = content.replace(/\n/g, "").split(" ");
      for (let i = 0; i < word_split.length; i++) {
        var wordLenght = this.fckPDF.getTextWidth(word_split[i]);
        var gapLenght = this.fckPDF.getTextWidth(" ");
        if (cur_right + wordLenght + gapLenght < line_right && word_split[i] !== "<br>") {
          this.fckPDF.setTextColor("#4C4C4C");
          this.fckPDF.setFontStyle("normal");
          if (style !== undefined) {
            this.fckPDF.setFontStyle(style.weight);
            this.fckPDF.setTextColor(style.color);
          }
          if(currenttop > 275){a.push(word_split[i]);continue;}
          this.fckPDF.text(word_split[i], cur_right, currenttop);
          cur_right += wordLenght + gapLenght;
        } else {
          currenttop += line_height;
          cur_right = 5;
          if (ud_Line_start !== undefined) {
            cur_right = ud_Line_start;
          }
          this.fckPDF.setTextColor("#4C4C4C");
          this.fckPDF.setFontStyle("normal");
          if (style !== undefined) {
            this.fckPDF.setFontStyle(style.weight);
            this.fckPDF.setTextColor(style.color);
          }
          if(currenttop > 275){a.push(word_split[i]);continue;}
          if (word_split[i] !== "<br>") {
            this.fckPDF.text(word_split[i], cur_right, currenttop);
            cur_right += wordLenght + gapLenght;
          }
        }
      }
      if(a.length > 0){
        //console.log("QQQQQQ",a.length);
        currenttop = this.processLineQuestionNewPage(a,cur_right,line_right,line_height,ud_Line_start,style,local_actual_height-local_height_to_show);
      }      
    }
    return currenttop;
  }
  processLineAnswer(content, currenttop, local_height_to_show,local_actual_height,ud_line_right, ud_Line_start, style) {
    var line_right = 202;
    if (ud_line_right !== undefined) {
      line_right = ud_line_right;
    }
    var cur_right = 4;
    if (ud_Line_start !== undefined) {
      cur_right = ud_Line_start;
    }
    let a =[];
    const line_height = 4;
    if (content !== "") {
      var word_split = content.replace(/\n/g, "").split(" ");
      for (let i = 0; i < word_split.length; i++) {
        var wordLenght = this.fckPDF.getTextWidth(word_split[i]);
        var gapLenght = this.fckPDF.getTextWidth(" ");
        if (cur_right + wordLenght + gapLenght < line_right && word_split[i] !== "<br>") {
          this.fckPDF.setTextColor("#4C4C4C");
          this.fckPDF.setFontStyle("normal");
          if (style !== undefined) {
            this.fckPDF.setFontStyle(style.weight);
            this.fckPDF.setTextColor(style.color);
          }
          if(currenttop > 275){a.push(word_split[i]);continue;}
          this.fckPDF.text(word_split[i], cur_right, currenttop);
          cur_right += wordLenght + gapLenght;
        } else {
          currenttop += line_height;
          cur_right = 5;
          if (ud_Line_start !== undefined) {
            cur_right = ud_Line_start;
          }
          this.fckPDF.setTextColor("#4C4C4C");
          this.fckPDF.setFontStyle("normal");
          if (style !== undefined) {
            this.fckPDF.setFontStyle(style.weight);
            this.fckPDF.setTextColor(style.color);
          }
          if(currenttop > 275){a.push(word_split[i]);continue;}
          if (word_split[i] !== "<br>") {
            this.fckPDF.text(word_split[i], cur_right, currenttop);
            cur_right += wordLenght + gapLenght;
          }
        }
      }
      if(a.length > 0){
        //console.log("QQQQQQ",a.length);
        currenttop = this.processLineQuestionNewPage(a,cur_right,line_right,line_height,ud_Line_start,style,local_actual_height-local_height_to_show);
      }      
    }
    return currenttop;
  }  
  processLineQuestionNewPage(a,cur_right,line_right,line_height,ud_Line_start,style,local_actual_height) {
     this.fckPDF.addPage();
     this.fckPDF.setFillColor("#F2F2F2");
     this.fckPDF.rect(0, 0, 210, 297, "F");
     let currenttop = marginY;
     //console.log("QQQQQQ",currenttop,local_actual_height);
      currenttop = currenttop + 5;
      //let local_height_to_show = local_actual_height-2*currenttop;
      //let local_height_to_show = local_actual_height;
      //this.fckPDF.setFillColor("#FFFFFF");
      //let local_height_to_show = (local_actual_height < Math.abs(290-currenttop-20)) ? (local_actual_height -20): Math.abs(290-currenttop-20);
      //console.log("RRRRRR",currenttop,local_height_to_show,local_actual_height,Math.abs(290-currenttop-20));
      //this.fckPDF.roundedRect(5, currenttop, 200, local_height_to_show, 1, 1, "F");      

      currenttop = currenttop + 5;      
      for(let i = 0; i < a.length; i++){
        var wordLenght = this.fckPDF.getTextWidth(a[i]);
        var gapLenght = this.fckPDF.getTextWidth(" ");
        if (cur_right + wordLenght + gapLenght < line_right && a[i] !== "<br>") {
          this.fckPDF.setTextColor("#4C4C4C");
          this.fckPDF.setFontStyle("normal");
          if (style !== undefined) {
            this.fckPDF.setFontStyle(style.weight);
            this.fckPDF.setTextColor(style.color);
          }
          //if(currenttop > (initial_top+local_height_to_show)){a.push(word_split[i]);continue;}
          this.fckPDF.text(a[i], cur_right, currenttop);
          cur_right += wordLenght + gapLenght;
        } else {
          currenttop += line_height;
          cur_right = 5;
          if (ud_Line_start !== undefined) {
            cur_right = ud_Line_start;
          }
          this.fckPDF.setTextColor("#4C4C4C");
          this.fckPDF.setFontStyle("normal");
          if (style !== undefined) {
            this.fckPDF.setFontStyle(style.weight);
            this.fckPDF.setTextColor(style.color);
          }
          //if(currenttop > (initial_top+local_height_to_show)){a.push(word_split[i]);continue;}
          if (a[i] !== "<br>") {
            this.fckPDF.text(a[i], cur_right, currenttop);
            cur_right += wordLenght + gapLenght;
          }
        }
      }

      this.fckPDF.setFontSize(10);
      this.fckPDF.setTextColor("#4C4C4C");      
      //this.fckPDF.roundedRect(5, currenttop, 200, local_height_to_show, 1, 1, "F");
      return currenttop;     
  }
  processLineClarification(content, currenttop, local_height_to_show,local_actual_height,ud_line_right, ud_Line_start, style) {
    var line_right = 202;
    if (ud_line_right !== undefined) {
      line_right = ud_line_right;
    }
    var cur_right = 5;
    if (ud_Line_start !== undefined) {
      cur_right = ud_Line_start;
    }
    let a =[];
    let initial_top = currenttop;
    const line_height = 4;
    if (content !== "") {
      var word_split = content.replace(/\n/g, "").split(" ");
      for (let i = 0; i < word_split.length; i++) {
        
        var wordLenght = this.fckPDF.getTextWidth(word_split[i]);
        var gapLenght = this.fckPDF.getTextWidth(" ");
        if (cur_right + wordLenght + gapLenght < line_right && word_split[i] !== "<br>") {
          this.fckPDF.setTextColor("#4C4C4C");
          this.fckPDF.setFontStyle("normal");
          if (style !== undefined) {
            this.fckPDF.setFontStyle(style.weight);
            this.fckPDF.setTextColor(style.color);
          }
          //if(currenttop > (initial_top+local_actual_height)){a.push(word_split[i]);continue;}
          if(currenttop > 275){a.push(word_split[i]);continue;}
          this.fckPDF.text(word_split[i], cur_right, currenttop);
          cur_right += wordLenght + gapLenght;
        } else {
          currenttop += line_height;
          cur_right = 5;
          if (ud_Line_start !== undefined) {
            cur_right = ud_Line_start;
          }
          this.fckPDF.setTextColor("#4C4C4C");
          this.fckPDF.setFontStyle("normal");
          if (style !== undefined) {
            this.fckPDF.setFontStyle(style.weight);
            this.fckPDF.setTextColor(style.color);
          }
          //if(currenttop > (initial_top+local_actual_height)){a.push(word_split[i]);continue;}
          if(currenttop > 275){a.push(word_split[i]);continue;}
          if (word_split[i] !== "<br>") {
            this.fckPDF.text(word_split[i], cur_right, currenttop);
            cur_right += wordLenght + gapLenght;
          }
        }

      }
      if(a.length > 0){
        //console.log("RRRRRR",a.length);
        currenttop = this.processLineClarificationNewPage(a,cur_right,line_right,line_height,ud_Line_start,style,local_actual_height-local_height_to_show);
      }
    }
    return currenttop;
  }

  processLineClarificationNewPage(a,cur_right,line_right,line_height,ud_Line_start,style,local_actual_height) {
      let b =[];
     this.fckPDF.addPage();
     this.fckPDF.setFillColor("#F2F2F2");
     this.fckPDF.rect(0, 0, 210, 297, "F");
     let currenttop = marginY;
     console.log("RRRRRR",currenttop,local_actual_height);
      currenttop = currenttop + 5;
      //let local_height_to_show = local_actual_height-2*currenttop;
      let local_height_to_show = local_actual_height;
      this.fckPDF.setFillColor("#FFFFFF");
      //let local_height_to_show = (local_actual_height < Math.abs(290-currenttop-20)) ? (local_actual_height -20): Math.abs(290-currenttop-20);
      //console.log("RRRRRR",currenttop,local_height_to_show,local_actual_height,Math.abs(290-currenttop-20));
      this.fckPDF.roundedRect(5, currenttop, 200, local_height_to_show, 1, 1, "F");      

      currenttop = currenttop + 5;      
      for(let i = 0; i < a.length; i++){
        var wordLenght = this.fckPDF.getTextWidth(a[i]);
        var gapLenght = this.fckPDF.getTextWidth(" ");
        if (cur_right + wordLenght + gapLenght < line_right && a[i] !== "<br>") {
          this.fckPDF.setTextColor("#4C4C4C");
          this.fckPDF.setFontStyle("normal");
          if (style !== undefined) {
            this.fckPDF.setFontStyle(style.weight);
            this.fckPDF.setTextColor(style.color);
          }
          //if(currenttop > (initial_top+local_height_to_show)){a.push(word_split[i]);continue;}
          this.fckPDF.text(a[i], cur_right, currenttop);
          cur_right += wordLenght + gapLenght;
        } else {
          currenttop += line_height;
          cur_right = 5;
          if (ud_Line_start !== undefined) {
            cur_right = ud_Line_start;
          }
          this.fckPDF.setTextColor("#4C4C4C");
          this.fckPDF.setFontStyle("normal");
          if (style !== undefined) {
            this.fckPDF.setFontStyle(style.weight);
            this.fckPDF.setTextColor(style.color);
          }
          //if(currenttop > (initial_top+local_height_to_show)){a.push(word_split[i]);continue;}
          if (a[i] !== "<br>") {
            this.fckPDF.text(a[i], cur_right, currenttop);
            cur_right += wordLenght + gapLenght;
          }
        }
      }

      this.fckPDF.setFontSize(10);
      this.fckPDF.setTextColor("#4C4C4C");      
      //this.fckPDF.roundedRect(5, currenttop, 200, local_height_to_show, 1, 1, "F");
      return currenttop;     
  }
  getboxheight(content, ud_line_right, pre_height) {
    var line_right = 202;
    if (ud_line_right !== undefined) {
      line_right = ud_line_right;
    }
    var cur_right = 65;
    const line_height = 4;
    var sentence = "";
    var localtop = 18;
    if (pre_height !== undefined) {
      localtop = pre_height;
    }
    if (content !== "") {
      var word_split = content.replace(/\n/g, "").split(" ");
      for (let i = 0; i < word_split.length; i++) {
        var wordLenght = this.fckPDF.getTextWidth(word_split[i]);
        var gapLenght = this.fckPDF.getTextWidth(" ");
        if (cur_right + wordLenght + gapLenght < line_right && word_split[i] != "<br>") {
          cur_right += wordLenght + gapLenght;
        } else {
          localtop += line_height;
          cur_right = 65;
          cur_right += wordLenght + gapLenght;
        }
      }
    }

    return localtop;
  }
  bodySection() {
    this.fckPDF.setFillColor("#F2F2F2");
    this.fckPDF.rect(0, 12, 210, 297, "F");
    // this.fckPDF.clip();
  }

  externalProvider(externla_provides_enabled, ext_advice, ext_advice_provider, ext_advice_txt, ext_ref_number, ext_contact_name) {
    if(externla_provides_enabled){
      this.cFieldHeadingExternal('External Advice Providers');
      this.cFieldValue(ext_advice_provider);
    }
    if(ext_advice) {
      this.cFieldHeadingExternal('External advice required');
      this.cFieldValue(ext_advice_txt);
      this.cFieldHeadingExternal('External Reference Number');
      this.cFieldValue(ext_ref_number);
      this.cFieldHeadingExternal('External Contact Name');
      this.cFieldValue(ext_contact_name);
    }
  }

  generatePDF() {
    console.log("data", this.props.data);
    //let module_config = Store.getStoreData('moduleconfig');
    let task_json_parsed = JSON.parse(this.props.data.task_json);
    let cur_lane = this.props.data.cur_lane;
    let contacts = Store.getStoreData("contacts");
    let gc_assoc = Store.getStoreData("gc_assoc");
    let gc_company = parseInt(this.props.data.company_id) < 100000 ? Store.getStoreData('company').CompanyName : gc_assoc[(parseInt(this.props.data.company_id)%100000).toString()].company_name;
    let answer = task_json_parsed.object_data.answer;
    let accept_mode = task_json_parsed.object_data.accept_mode;
    if(Utils.isBase64(answer)){
      answer = window.decodeURIComponent(atob(answer))
    }
    let custom_fields = ('custom_fields' in  task_json_parsed.object_data) ? task_json_parsed.object_data.custom_fields : {};
    
    let externla_provides_enabled = "externla_provides_enabled" in task_json_parsed.object_data  ? task_json_parsed.object_data.externla_provides_enabled : false;
    let ext_advice = ('ext_advice' in task_json_parsed.object_data) ? task_json_parsed.object_data.ext_advice : false;
    let ext_advice_provider = externla_provides_enabled ? task_json_parsed.object_data.ext_advice_provider : '';
    let ext_advice_txt = ('ext_advice_txt' in task_json_parsed.object_data) ? task_json_parsed.object_data.ext_advice_txt : '';
    let ext_ref_number = ('ext_ref_number' in task_json_parsed.object_data) ? task_json_parsed.object_data.ext_ref_number : '';
    let ext_contact_name = ('ext_contact_name' in task_json_parsed.object_data) ? task_json_parsed.object_data.ext_contact_name : '';

    let ptags = {};
    if('tags' in task_json_parsed.object_data) {
        ptags = task_json_parsed.object_data.tags;
    }
    let tags_count =  Object.keys(ptags).length;
    this.current_top = marginY;
    // this.current_top = 26;
    this.page_right_start = 63;
    this.currentPageNumber = 0;
    this.fckPDF = new jsPDF({
      orientation: "p",
      unit: "mm",
      format: "a4",
      putOnlyUsedFonts: true,
    });
    this.bodySection();
    this.banner(task_json_parsed);
    this.firstRowHeadings();
    this.firstRowValues(task_json_parsed,contacts,cur_lane);
    this.companyHeading();
    this.companyName(gc_company);
    this.questionHeading();
    this.questionValue(task_json_parsed);
    this.answerHeading();
    this.answerValue(answer);
    if(tags_count>0){
      this.tagHeading();
      this.tagValues(ptags);
    }
    let clarifications = task_json_parsed.object_data.clarifications;
    clarifications.reverse();
    clarifications.map((c,i) => {
      this.clarification(c,task_json_parsed,contacts)
    })
    this.customFields(custom_fields);

    this.externalProvider(externla_provides_enabled, ext_advice, ext_advice_provider, ext_advice_txt, ext_ref_number, ext_contact_name)

    var blob = this.fckPDF.output("blob");
    var binaryData = [];
    binaryData.push(blob);
    var pdfurl = window.URL.createObjectURL(new Blob(binaryData, { type: "application/pdf" }));
    this.setState({ pdfURL: pdfurl, fckPDF: this.fckPDF, ready: true });
  }

  componentDidMount() {
    this.generatePDF();
  }

  closePdf = (event) => {
    event.preventDefault();
    window.location.reload("true");
  };

  render() {
    // window.scrollTo(0, 1000);
    // console.log("TOP ", window.scrollY);
    if (this.fckPDF === null) {
      return <div>Loading...</div>;
    } else {
      if (this.state.ready) {
        return (
          <ModalContainer style={{ padding: "1.1rem 2.6rem"}}>
            <div style={{ paddingRight: "10px" }}>
              <Style.PdfcloseSec>
                <span style={{ cursor: "pointer", color: "#337ab7" }} onClick={this.closePdf}>
                  <FaTimes />
                </span>
              </Style.PdfcloseSec>
              <div style={{ clear: "both" }}></div>
            </div>
            <Iframe url={this.state.pdfURL} width="100%" height="900px" type="application/pdf" />
          </ModalContainer>
        );
      } else {
        return <div>Loading...</div>;
      }
    }
  }
}

export default PrintPreview;