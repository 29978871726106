import React from 'react';
import styled from 'styled-components';
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import wNumb from 'wnumb';
import Store from '../../Common/Store';

const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const GeneralContainer = styled.div`
	width: 90%;
`;
const GeneralInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 10px 30px 10px;
`;
const GeneralLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
const GeneralsubLabel = styled.div`
	font-weight: 400;
	color: #595959;
	margin-top: 5px;
`;
const RadioBlock = styled.div`
	margin-top: 5px;
`;
const ResponseDropdown = styled.select`
	box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    color: #b5b5b5;
`;

const EmailInput = styled.input`
    height: 30px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
`;

class General extends React.Component
{
	state = {general: null, selected_response: 'close'};

	constructor(props) {
	    super(props);

	}

	componentDidMount()
	{
		let selected_response = "close";
		this.props.general.action_after_no_response.forEach((res) => {
			if(res.selected){
				selected_response = res.value;
			}

		})
		this.setState({general: this.props.general, selected_response: selected_response});
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			let selected_response = "close";
			this.props.general.action_after_no_response.forEach((res) => {
				if(res.selected){
					selected_response = res.value;
				}

			})
			this.setState({general: this.props.general, selected_response: selected_response});
		}
	}

	switchRadio = (key) => (event) => {
		event.preventDefault();
		let {general} = this.state;
		general[key] = general[key] ? false : true;
		this.props.updateGeneral(general);
	}

	onChangeIntervalIQ  = (values, handle) => {
		let value = Math.floor(parseFloat(values[handle]));
		let {general} = this.state;
		general['response_to_initial_question'] = value;
		console.log("value", value);
		this.props.updateGeneral(general);
	}

	onChangeIntervalBeforeDue = (values, handle) => {
		let value = Math.floor(parseFloat(values[handle]));
		let {general} = this.state;
		general['send_compliance_adviser_reminder'] = value;
		console.log("value", value);
		this.props.updateGeneral(general);
	}

	onChangeIntervalAnswer = (values, handle) => {
		let {general} = this.state;
		let value = Math.floor(parseFloat(values[handle]));
		general['response_to_answer'] = value;
		this.props.updateGeneral(general);
	}

	onChangeIntervalActionTiggered = (values, handle) => {
		let {general} = this.state;
		let value = Math.floor(parseFloat(values[handle]));
		general['triggered_to_action'] = value;
		this.props.updateGeneral(general);
	}

	onChangeSQIntervalCA = (values, handle) => {
		let {general} = this.state;
		let value = Math.floor(parseFloat(values[handle]));
		general['response_to_subsquent_question'] = value;
		this.props.updateGeneral(general);
	}

	changeDropResponse = (event) => {
		event.preventDefault();
		let {general} = this.state;
		console.log("action_value", event.target.value);
		general.action_after_no_response.forEach((res) => {
				if (res.value === event.target.value) {
					res.selected = true;
				}else{
					res.selected = false;
				}
		});
		console.log("genereal", general.action_after_no_response);
		this.props.updateGeneral(general);
	}

	changeInitialEmail = (event) => {
		event.preventDefault();
		let {general} = this.state;
		console.log("action_value", event.target.value);
		general.car_initiation_by_email = event.target.value
		this.props.updateGeneral(general);
	}

	changeRepliesEmail = (event) => {
		event.preventDefault();
		let {general} = this.state;
		console.log("action_value", event.target.value);
		general.car_replies_by_email = event.target.value
		this.props.updateGeneral(general);
	}

	handleChange = (event) => {
		event.preventDefault();
		let {general} = this.state;
		general[event.target.name] = event.target.value;
		this.props.updateGeneral(general);
	}



	render()
	{
		if (this.state.general === null) {
			return (<div>Loading...</div>);
		}

		let tooltips = [wNumb({decimals: 0, suffix: 'days'})];
		let is_carbyemal = Store.getStoreData('isaccess_carbyemail');
		console.log("is_carbyemal", is_carbyemal)

		let d = [{value: 0, name: "No"},{value: 1, name: "Employees with access to MCC"}, {value: 2, name: "Employees without access to MCC"}, {value: 3, name: "Both"}]

		return (
			<GeneralContainer>
							<GeneralInnerContainer>
								<GeneralLabel>Allow FAQ clarification questions</GeneralLabel>
								{
									(() => {
										if (this.state.general.allow_faq_clarify_question) {
											return (
												<RadioBlock>
													<SelectedRadio>Yes</SelectedRadio>
													<UnselectedRadio onClick={this.switchRadio('allow_faq_clarify_question')}>No</UnselectedRadio>
												</RadioBlock>
											);
										} else {
											return (
												<RadioBlock>
													<UnselectedRadio onClick={this.switchRadio('allow_faq_clarify_question')}>Yes</UnselectedRadio>
													<SelectedRadio>No</SelectedRadio>
												</RadioBlock>
											);
										}
									})()
								}
							</GeneralInnerContainer>

							<GeneralInnerContainer>
								<GeneralLabel>Allow assignment to specific Compliance Adviser</GeneralLabel>
								{
									(() => {
										if (this.state.general.specific_user_assignment_enabled) {
											return (
												<RadioBlock>
													<SelectedRadio>Yes</SelectedRadio>
													<UnselectedRadio onClick={this.switchRadio('specific_user_assignment_enabled')}>No</UnselectedRadio>
												</RadioBlock>
											);
										} else {
											return (
												<RadioBlock>
													<UnselectedRadio onClick={this.switchRadio('specific_user_assignment_enabled')}>Yes</UnselectedRadio>
													<SelectedRadio>No</SelectedRadio>
												</RadioBlock>
											);
										}
									})()
								}
							</GeneralInnerContainer>

							<GeneralInnerContainer>
								<GeneralLabel>Allow assignment to specific company teams</GeneralLabel>
								{
									(() => {
										if (this.state.general.team_assignment_enabled) {
											return (
												<RadioBlock>
													<SelectedRadio>Yes</SelectedRadio>
													<UnselectedRadio onClick={this.switchRadio('team_assignment_enabled')}>No</UnselectedRadio>
												</RadioBlock>
											);
										} else {
											return (
												<RadioBlock>
													<UnselectedRadio onClick={this.switchRadio('team_assignment_enabled')}>Yes</UnselectedRadio>
													<SelectedRadio>No</SelectedRadio>
												</RadioBlock>
											);
										}
									})()
								}
							</GeneralInnerContainer>



							{
								(() => {
									if (this.state.general.specific_user_assignment_enabled) {
										return (
											<GeneralInnerContainer>
												<GeneralLabel>Allow users to select the Compliance Adviser</GeneralLabel>
												{
													(() => {
														if (this.state.general.requesting_user_assignment_enabled) {
															return (
																<RadioBlock>
																	<SelectedRadio>Yes</SelectedRadio>
																	<UnselectedRadio onClick={this.switchRadio('requesting_user_assignment_enabled')}>No</UnselectedRadio>
																</RadioBlock>
															);
														} else {
															return (
																<RadioBlock>
																	<UnselectedRadio onClick={this.switchRadio('requesting_user_assignment_enabled')}>Yes</UnselectedRadio>
																	<SelectedRadio>No</SelectedRadio>
																</RadioBlock>
															);
														}
													})()
												}

											</GeneralInnerContainer>
											);
									}
								})()
							}

							<GeneralInnerContainer>
								<GeneralLabel>Allow users to select Related Individuals</GeneralLabel>
								{
									(() => {
										if (this.state.general.ruie) {
											return (
												<RadioBlock>
													<SelectedRadio>Yes</SelectedRadio>
													<UnselectedRadio onClick={this.switchRadio('ruie')}>No</UnselectedRadio>
												</RadioBlock>
											);
										} else {
											return (
												<RadioBlock>
													<UnselectedRadio onClick={this.switchRadio('ruie')}>Yes</UnselectedRadio>
													<SelectedRadio>No</SelectedRadio>
												</RadioBlock>
											);
										}
									})()
								}
							</GeneralInnerContainer>

							{
								(() => {
									if (this.state.general.ruie) {
										return (
											<GeneralInnerContainer>
												<GeneralLabel>Related Individuals can be from any Group Company</GeneralLabel>
												{
													(() => {
														if (this.state.general.rigce) {
															return (
																<RadioBlock>
																	<SelectedRadio>Yes</SelectedRadio>
																	<UnselectedRadio onClick={this.switchRadio('rigce')}>No</UnselectedRadio>
																</RadioBlock>
															);
														} else {
															return (
																<RadioBlock>
																	<UnselectedRadio onClick={this.switchRadio('rigce')}>Yes</UnselectedRadio>
																	<SelectedRadio>No</SelectedRadio>
																</RadioBlock>
															);
														}
													})()
												}

											</GeneralInnerContainer>
											);
									}
								})()
							}


							<GeneralInnerContainer>
								<GeneralLabel>Enable Reminders</GeneralLabel>
								{
									(() => {
										if (this.state.general.remainder_enabled) {
											return (
												<RadioBlock>
													<SelectedRadio>Yes</SelectedRadio>
													<UnselectedRadio onClick={this.switchRadio('remainder_enabled')}>No</UnselectedRadio>
												</RadioBlock>
											);
										} else {
											return (
												<RadioBlock>
													<UnselectedRadio onClick={this.switchRadio('remainder_enabled')}>Yes</UnselectedRadio>
													<SelectedRadio>No</SelectedRadio>
												</RadioBlock>
											);
										}
									})()
								}
							</GeneralInnerContainer>
							{
								(() => {
									if (this.state.general.remainder_enabled) {
										return(<div>
											<GeneralInnerContainer>
												<GeneralLabel>Number of days for Compliance Adviser(s) to respond to the initial question</GeneralLabel>
												<GeneralsubLabel>A reminder will be sent after the number of days chosen below</GeneralsubLabel>
												<div style={{paddingTop: '40px'}}>
													<Nouislider range={{ min: 1, max: 30 }} start={this.state.general.response_to_initial_question} step = {1} connect={[true, false]} tooltips={tooltips} onChange={this.onChangeIntervalIQ} />
												</div>
											</GeneralInnerContainer>

											<GeneralInnerContainer>
												<GeneralLabel>Send Compliance Adviser(s) a reminder</GeneralLabel>
												<GeneralsubLabel>The number of days chosen below dictates how far in advance of the due date the reminder is sent</GeneralsubLabel>
												<div style={{paddingTop: '40px'}}>
													<Nouislider range={{ min: 0, max: parseInt(this.state.general.response_to_initial_question) }} start={this.state.general.send_compliance_adviser_reminder} step = {1} connect={[true, false]} tooltips={tooltips} onChange={this.onChangeIntervalBeforeDue} />
												</div>
											</GeneralInnerContainer>

											<GeneralInnerContainer>
												<GeneralLabel>Days for requester to accept/respond to answer provided</GeneralLabel>
												<GeneralsubLabel>A reminder will be sent after the number of days chosen below</GeneralsubLabel>
												<div style={{paddingTop: '40px'}}>
													<Nouislider range={{ min: 1, max: 30 }} start={this.state.general.response_to_answer} step = {1} connect={[true, false]} tooltips={tooltips} onChange={this.onChangeIntervalAnswer} />
												</div>
											</GeneralInnerContainer>
											<GeneralInnerContainer>
												<GeneralLabel>Action after no response</GeneralLabel>
												<div style={{paddingTop: '10px'}}>
													<ResponseDropdown value={this.state.selected_response} onChange={this.changeDropResponse}>
														{
															this.state.general.action_after_no_response.map((opt) => {
																return (<option key={opt.value} value={opt.value}>{opt.name}</option>);
															})
														}
													</ResponseDropdown>
												</div>
											</GeneralInnerContainer>
											<GeneralInnerContainer>
												<GeneralLabel>Days until the action is triggered</GeneralLabel>
												<GeneralsubLabel>The question creator needs to accept the answer to their question or request clarification by the number of days specified above. If they do not do this, the number of days selected below controls how many days later the above action is triggered and an advisory email sent.</GeneralsubLabel>
												<div style={{paddingTop: '40px'}}>
													<Nouislider range={{ min: 1, max: 30 }} start={this.state.general.triggered_to_action} step = {1} connect={[true, false]} tooltips={tooltips} onChange={this.onChangeIntervalActionTiggered} />
												</div>
											</GeneralInnerContainer>
											<GeneralInnerContainer>
												<GeneralLabel>Number of days for Compliance Adviser(s) to respond to subsequent clarifications</GeneralLabel>
												<GeneralsubLabel>A reminder will be sent after the number of days chosen below</GeneralsubLabel>
												<div style={{paddingTop: '40px'}}>
													<Nouislider range={{ min: 1, max: 30 }} start={this.state.general.response_to_subsquent_question} step = {1} connect={[true, false]} tooltips={tooltips} onChange={this.onChangeSQIntervalCA} />
												</div>
											</GeneralInnerContainer>
										</div>);
									}
								})()
							}

			</GeneralContainer>
		);
	}
}

export default General;
