import Store from './Store.js';

class XcelLoader {
	getDate = (input_date) => {
	  if(input_date != null){
	    let dateStr = input_date.split('T')[0];
	    let dateParts = dateStr.split('-'); 
	    let ret = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
	    return ret;
	  }else{
	    return '';
	  }
	 
	}

	car_export_tasks(tasks, answered_by=false,is_assignto=true, is_duedate=false,is_status=false){
		console.log("is_assignto", answered_by)
		let results = [];
		let gc_assoc = Store.getStoreData('gc_assoc');
		let gc_company = "";
		let j = 0;
		for(let d of tasks) {
		  gc_company = parseInt(d.company_id) < 100000 ? Store.getStoreData('company').CompanyName : gc_assoc[(parseInt(d.company_id)%100000).toString()].company_name;
		    j++;

		    let dateStr = this.getDate(d.due_date);
		    let assigned_to = d.cur_assigned_to === 0 ? "Team" : Store.getStoreData('contacts')[d.cur_assigned_to]
		    let answerer_id = d.answered_by === null ? 0 : d.answered_by;
		    let status = 'Pending';
		    switch(d.cur_lane) {
		      case 'COMP_CAR_ANSWER': status = 'Pending'; break;
		      case 'COMP_CAR_ANSWER_SAVED': status ='In Progress'; break;
		    }

		    let elem = {
		            '#' : j, 
		            'ID': d.ref_id,
		            'Topic': d.topic,
		            'query' : d.query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'"),
		            'Company' : gc_company,
		            'Asked BY' : d.from_api === 0 ? Store.getStoreData('contacts')[d.contact_id] : d.requester_name
		           };
		    if(is_assignto)elem['Assigned To'] = assigned_to;
		    if(answered_by)elem['Answered By'] = answerer_id === 0 ? "N/A" : Store.getStoreData('contacts')[answerer_id];
		    if(is_status)elem['Status'] = status;
		    if(is_duedate)elem['Due Date'] = dateStr;
		    results.push(elem)
		}
		return results;
	}
	faq_export_tasks(tasks,is_status=false){
		let results = [];
		let i = 0;
		let gc_assoc = Store.getStoreData('gc_assoc');
		let gc_company = "";
		for(let d of tasks) {
		      let gc_company = parseInt(d.company_id) < 100000 ? Store.getStoreData('company').CompanyName : gc_assoc[(parseInt(d.company_id)%100000).toString()].company_name;
		      i++;
		      let status = 'New';
		      switch(d.cur_lane) {
		        case 'ADD_CAR_FAQ': status = 'New'; break;
		        case 'CAR_FAQ_AWAITING_APPROVAL': status = 'Pending Approval'; break;
		        case 'CAR_FAQ_APPROVED': status = 'Published'; break;
		      }
		      let elem = {'#' : i, 
		              'ID': d.faq_ref_id,
		              'Query' : d.query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'"),
		              'Company' : gc_company
		             };
		      if(is_status)elem['Status'] = status;
		      results.push(elem)
		
		}
		return results;
	}
}

export default new XcelLoader();