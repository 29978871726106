import React from 'react';
import styled from 'styled-components';
import { BiCaretUp, BiCaretDown } from "react-icons/bi";

const DropSelected = styled.div`
	height: 28px;
	width: 140px;
	border: 1px solid #c6c8ca;
	border-radius: 3px;
	padding: 5px 15px 0px 15px;
	background-color: #ffffff;
	cursor: pointer;
`;
const DropOptions = styled.div`
	background-color: #ffffff;
	width: 280px;
	border: 2px solid #ffffff;
	border-radius: 2px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	position: absolute;
	margin-top: 10px;
	right: 50px;
`;
const Options = styled.div`
	padding: 15px 20px;
	cursor: pointer;
`;

class TextDropdown extends React.Component {
	state = {payload: null, show_options: false};

	componentDidMount() {
		this.setState({payload: this.props.payload});
	}

	componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
		this.setState({payload: this.props.payload});
		}
	}

	showOptions = (event) => {
		event.preventDefault();
		this.setState({show_options: true});
	}

	hideOptions = (event) => {
		event.preventDefault();
		this.setState({show_options: false});
	}

	setSelected = (item_id) => (event) => {
		let payload = JSON.parse(JSON.stringify(this.state.payload));
		payload.forEach(item => {
		if (item.id === item_id) {
		item.selected = true;
		} else {
		item.selected = false;
		}
		})
		this.props.setSelected(payload);
	}

	render() {
		if (this.state.payload === null) return <div>Loading...</div>

		const selected_option = this.state.payload.find(item => item.selected === true);
		// console.log("selected_option:", selected_option);
		return (
		<div>
		<DropSelected onClick={this.showOptions} id="drop">
			<div style={{float: "left", color: "#4C4D4E", fontWeight: "600"}}>
				{"'"+selected_option.id+"' "+selected_option.top_text}
			</div>
			<div style={{float: "right"}}>
				<div><BiCaretUp /></div><div style={{marginTop: "-14px"}}><BiCaretDown /></div>
			</div>
			<div style={{clear: "both"}}></div>
		</DropSelected>
		{
			(() => {
				if (this.state.show_options === true) {
				return (
					<DropOptions onMouseLeave={this.hideOptions}>
					{
					this.state.payload.map((item, index) => {
					return(
						<Options onClick={this.setSelected(item.id)} key={index} style={{borderBottom: index === 0 ? "1px solid #c6c8ca" : "none", backgroundColor: item.selected === true ? "#f2f4f7" : "#ffffff"}}>
						<div style={{fontWeight: "600", color: item.selected === true ? "#11ABA6" : "#4C4D4E"}}>{"'"+item.id+"' "+item.top_text}</div>
						<div style={{marginTop: "15px", fontSize: "11px"}}>{item.description}</div>
						</Options>
					)
					})
					}
					</DropOptions>
				);
			}
			})()
		}
		</div>
		);
		}
	}

export default TextDropdown;