import React from 'react';
import styled from 'styled-components';
import {FaTimes} from 'react-icons/fa';
import {IoIosArrowForward} from 'react-icons/io';
import Store from '../../Common/Store';
import General from './General';
import Email from './Email';
import Permissions from './Permissions';
import Tags from './Tags';
import Faqrestriction from './Faqrestriction';
import Extprovides from './Extprovides';
import CustomFields from './CustomField/CustomFields';
import APICall from '../../Common/APICall.js';
import CSLLoader from '../Common/CSLLoader';
import AlertBox from '../Common/AlertBox';
import Utils from '../../Common/Utils.js';
import Reload from '../../Common/Reload.js';

/*global localStorage*/

const ReviewBodyContainer = styled.div`
    padding-top: 25px;
    width: 100%;
    background-color: #fafafa;
`;
const ReviewLeftContainer = styled.div`
    float: left;
    padding-right: 20px;
    width: 20%;
    box-sizing: border-box;
`;
const ReviewRightContainer = styled.div`
    float: right;
    padding-left: 20px;
    width: 80%;
    box-sizing: border-box;
`;
const ReviewTab = styled.div`
    background-color: #ffffff;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    padding: 10px 20px;
    cursor: pointer;
`;
const CloseBtn = styled.div`
    float: right;
    font-size: 20px;
    color: #9c9c9c;
    cursor: pointer;
    padding-top: 10px;
    padding-right: 5px;
`;
const FooterButtonsContainer = styled.div`
	text-align: right;
	padding-top: 25px;
	width: 90%;
`;
const CancelBtn = styled.button`
	padding: 10px 20px;
	background-color: #ffffff;
	border: 1px solid #ffffff;
	border-radius: 2px;
	color: #333333;
	cursor: pointer;
`;
const SaveBtn = styled.button`
	padding: 10px 30px;
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	margin-left: 15px;
`;
const ErrorBar = styled.div`
    background-color: #F6DBDB;
    color: #DE8D8E;
    font-weight: 600;
    padding: 10px 20px;
`;


class ModuleConfigHOC extends React.Component
{
    state = {
		active_section_id: "general",
		module_config: null,
        requester: "",
        dirty: false,
        permissions: {},
        all_company_users: null,
        changed_user_roles: {},
        requester: "",
        used_tags: {},
        alert_param: null,
        is_loading: false,
        ready: false
    };


    constructor(props) {
        super(props);
    }

    componentDidMount() {
      this.callApi()
    }
    
    callApi = () => {
        const postData = { command: "list_company_and_users" };
        const api = new APICall();
        api.indexCommand(postData, this.getInitialPermission);
        this.setState({is_loading: true})
    }

    getInitialPermission = (result) => {
        console.log("result ===>", result)
        let contactlist = localStorage.getItem("contactlist")!==null ? JSON.parse(localStorage.getItem("contactlist")) : {};
        let user_roles = contactlist.result.user_roles['car'];
        let cls = contactlist.result.contactlist;

        let company_users = [];
        for(let key in cls){
            if(cls[key].IsActive === true && cls[key].IsDeleted === false){
                let cu = {};
                cu['email'] = cls[key].EmailAddress;
                cu['user_id'] = key;
                cu['name'] = cls[key].ContactName;
                cu['role'] = key in user_roles ? user_roles[key] : 'no_access';
                company_users.push(cu)
            }
        }
        const permissions = {
            user_roles: user_roles,        
            roles: result.roles, 
            company_users: company_users
                   
        }; 
        let requester = this.props.match ? this.props.match.params.requester : "";
        this.setState({permissions:permissions, module_config: result.moduleconfig, all_company_users: company_users, role: result.role, is_loading: false, ready: true});
    }

    getInitialPermission_obsulate = (result) => {
        console.log("result", result)
        let requester = this.props.match ? this.props.match.params.requester : "";
        const permissions = {
            user_roles: result.user_roles,
            gc_companies: result.gc_companies,
            roles: result.roles,
            company_users: result.company_users
        };
        const all_company_users = JSON.parse(JSON.stringify(result.company_users));
        this.setState({permissions:permissions, module_config: result.moduleconfig, requester, all_company_users: all_company_users, used_tags: result.used_tags});
    }

	returnSection = (section) => (event) => {
		event.preventDefault();
        // this.setState({active_section_id: section});

        let {module_config} = this.state;
        if(module_config['general'].enabled_topics && this.state.active_section_id === "tagmgnt"){
            let is_include = false;
            for(let key in module_config['general'].tags){
                if('enabled_topic' in module_config['general'].tags[key] && module_config['general'].tags[key].enabled_topic){
                    is_include = true;
                }
            }

            if(is_include){
               this.setState({active_section_id: section});
            }else{
                let parms = {}
                parms['is_same'] = true;
                parms['section'] = section;
                let alert_param = {title: 'Confirm', message: 'You must create a topic. If you wish to continue, topics will be disabled.', ok_text: 'Continue', confirm: true, alertHandler: this.confirmAlertHandler, stack: parms}
                this.setState({alert_param: alert_param})
            }
        }else{
            this.setState({active_section_id: section});
        }

	}

	updateGeneral = (obj) => {
        // console.log("Swoham==>",obj);
		let {module_config, dirty} = this.state;
		const old_config = {...module_config};
		module_config.general = obj;
		const new_config = {...module_config};
		if (JSON.stringify(old_config) !== JSON.stringify(new_config)) {
			dirty = true;
		}
        this.setState({module_config, dirty});
    }
    
    afterUpdate = (result) => {
        let alert_param = {title: 'Alert', message: result.error_msg, ok_text: 'OK', confirm: false, alertHandler: this.sameAlertremoveHandler, stack: {}}
        this.setState({alert_param: alert_param, is_loading: false})
        // console.log("Swoham==>",result);
        // alert(result.error_msg);

        this.setState({module_config: result.result, is_loading: false});
    }

    afterRoleUpdate = (result) => {
        let alert_param = {title: 'Alert', message: result.error_msg, ok_text: 'OK', confirm: false, alertHandler: this.sameAlertremoveHandler, stack: {}}
        this.setState({alert_param: alert_param, is_loading: false})
        // alert(result.error_msg);
    }

   updateEmail = (obj) => {
        let {module_config} = this.state;
        module_config.email = obj;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.indexCommand(postData, this.afterUpdate);
        this.setState({is_loading: true})
    }

    includeExcludeEmail = (obj) => {
        let {module_config} = this.state;
        module_config.email = obj;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.indexCommand(postData, this.afterincludeExclude);
        this.setState({is_loading: true})
    }

    afterincludeExclude = (result) => {
        console.log("UpdatetopicIncludedExclude", result)
        this.setState({module_config: result.result, is_loading: false});
    }

    updateEmailRemainder = (obj) =>{
        let {module_config, dirty} = this.state;
        module_config.general = obj;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.indexCommand(postData, this.afterUpdate);
        this.setState({is_loading: true})
    }

    topicIncludedExclude = (obj) => {
        let {module_config, dirty} = this.state;
        module_config.general = obj;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.indexCommand(postData, this.UpdatetopicIncludedExclude);
        this.setState({is_loading: true})
    }

    UpdatetopicIncludedExclude = (result) => {

        this.setState({module_config: result.result, is_loading: false});
    }

    updatePermissions = (obj, single_obj) => {
        // console.log("single_obj:", single_obj);
        let changed_user_roles = JSON.parse(JSON.stringify(this.state.changed_user_roles));
        changed_user_roles[single_obj.user_id] = single_obj.role;
        // console.log("changed_user_roles:", changed_user_roles);
        this.setState({permissions: obj, changed_user_roles});
        // console.log("Updated Permission:", obj);
    }

    updatePermissions = (obj, single_obj) => {
        // console.log("single_obj:", single_obj);
        let changed_user_roles = JSON.parse(JSON.stringify(this.state.changed_user_roles));
        changed_user_roles[single_obj.user_id] = single_obj.role;
        // console.log("changed_user_roles:", changed_user_roles);
        this.setState({permissions: obj, changed_user_roles});
        // console.log("Updated Permission:", obj);
    }

    searchUsers = (search_string) => {
        if (search_string === "") {
            let permissions = JSON.parse(JSON.stringify(this.state.permissions));
            permissions.company_users = this.state.all_company_users;
            this.setState({permissions});
        } else {
            let all_company_users = JSON.parse(JSON.stringify(this.state.all_company_users));
            let filtered_company_users = [];
            all_company_users.forEach((user) => {
                if (user.name.toLowerCase().includes(search_string.toLowerCase()) || user.role.includes(search_string.toLowerCase())) {
                    filtered_company_users.push(user);
                }
            })
            let permissions = JSON.parse(JSON.stringify(this.state.permissions));
            permissions.company_users = filtered_company_users;
            // console.log("in search:", permissions.company_users);
            this.setState({permissions});
        }
    }

    changeAllRoles = (role) => {
        let permissions = JSON.parse(JSON.stringify(this.state.permissions));
        if (role !== "0") {
            permissions.company_users.forEach((user) => {
                user.role = role;
            })
        }
        let changed_user_roles = {};
        permissions.company_users.forEach((user) => {
            changed_user_roles[user.user_id] = user.role;
        })
        // console.log("change all roles:", permissions.company_users);
        this.setState({permissions, changed_user_roles});
    }

    updateModuleconfig = () => {
        let {module_config, changed_user_roles, dirty} = this.state;
        const api = new APICall();
        let postData = {command: "save_module_config", module_config: module_config};
        postData['is_dirty'] = dirty;
        if (this.state.active_section_id === "permissions") {
            postData = {command: "save_user_role", changed_user_roles: changed_user_roles};
            api.indexCommand(postData, this.afterRoleUpdate);
            Reload.RestoreStorage(changed_user_roles);
        } else {
            api.indexCommand(postData, this.afterUpdate);
        }
        this.setState({is_loading: true})
    }

    updateCustomFields = (obj) => {
        let {module_config} = this.state;
        module_config.general.custom_fields = obj;
        this.setState({module_config});
    }

    /*closePage = (event) => {
        event.preventDefault();
        window.close();
    }*/

    closePage = (event) => {
        event.preventDefault();
        let url = "/"
        let {module_config} = this.state;
        Utils.log("module_config", module_config)
        if(module_config && module_config['general'].enabled_topics && Store.getStoreData('role') === 'admin_manager'){
            let is_include = false;
            for(let key in module_config['general'].tags){
                if('enabled_topic' in module_config['general'].tags[key] && module_config['general'].tags[key].enabled_topic){
                    is_include = true;
                }
            }

            if(is_include){
                window.location.replace(url);
            }else{
                let parms = {}
                parms['is_same'] = false;
                let alert_param = {title: 'Confirm', message: 'You must create a topic. If you wish to continue, topics will be disabled.', ok_text: 'Continue', confirm: true, alertHandler: this.confirmAlertHandler, stack: parms}
                this.setState({alert_param: alert_param})
            }
        }else{
            window.location.replace(url);
        }
    }

    confirmAlertHandler = (result, stack) => {
        if(!result || stack.prevent) {
            this.setState({alert_param: null})
            return
        }
        let {module_config} = this.state;
        module_config['general'].enabled_topics = false;
        const api = new APICall();
        let postData = {command: "save_module_config", module_config: module_config};
        this.setState({alert_param: null})
        if(stack.is_same){
            if('section' in stack){
                this.setState({active_section_id: stack.section});
            }
            api.indexCommand(postData, this.aftersaveRefreshModule);
        }else{

            api.indexCommand(postData, this.aftersaveRefresh);
        }
        this.setState({is_loading: true})
        
        
    }

    aftersaveRefresh = (result) => {
        // this.setState({alert_param: null})
        let url = "/"
        window.location.replace(url);
    }

    aftersaveRefreshModule = (result) => {
        
        this.setState({module_config: result.result, is_loading: false});
    }

    sameAlertremoveHandler = (result,stack) => {            
        this.setState({alert_param: null, is_loading: false})
    }

    // alertremoveHandler = (result,stack) => {           
    //         this.setState({alert_param: null})
    //     }

	render()
	{
        let use_site_layout = false
        if(localStorage.getItem('site_layout')!==null){
          let site_layout = JSON.parse(localStorage.getItem('site_layout'));
          use_site_layout = site_layout.result.use_site_layout;
        }
        
        if (Store.getStoreData('role') !== 'admin_manager') {
            return (
                <ErrorBar>
                    <div style={{float: "left"}}>You are not authorised to access this page. If you think this is in error, please contact your administrator.</div>
                    {use_site_layout ? "" : <div style={{float: "right", fontSize: "16px", cursor: "pointer"}} onClick={this.closePage}>x</div>}
                    <div style={{clear: "both"}}></div>
                </ErrorBar>
            );
        }
        if(this.state.module_config===null) {
            return <CSLLoader />
        }
		return (
			<div style={{padding: "10px 10px 20px 10px"}}>
                {use_site_layout ? "" : <CloseBtn onClick={this.closePage}><FaTimes /></CloseBtn>}
                <div style={{clear: "both"}}></div>
                <ReviewBodyContainer>
                	<ReviewLeftContainer style={{position: "sticky", top: "2px"}}>
                		<ReviewTab onClick={this.returnSection("general")}>
                            {
                                (() => {
                                    if (this.state.active_section_id === "general") {
                                        return (<div style={{float: "left", fontWeight: "600"}}>General</div>);
                                    } else {
                                        return (<div style={{float: "left"}}>General</div>);
                                    }
                                })()
                            }
                            <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                            <div style={{clear: "both"}}></div>
                        </ReviewTab>
                        <ReviewTab onClick={this.returnSection("tagmgnt")} style={{marginTop: "2px"}}>
                            {
                                (() => {
                                    if (this.state.active_section_id === "tagmgnt") {
                                        return (<div style={{float: "left", fontWeight: "600"}}>Tag Management</div>);
                                    } else {
                                        return (<div style={{float: "left"}}>Tag Management</div>);
                                    }
                                })()
                            }
                            <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                            <div style={{clear: "both"}}></div>
                        </ReviewTab>

                        <ReviewTab onClick={this.returnSection("faqrst")} style={{marginTop: "2px"}}>
                            {
                                (() => {
                                    if (this.state.active_section_id === "faqrst") {
                                        return (<div style={{float: "left", fontWeight: "600"}}>FAQ/Knowledge Base</div>);
                                    } else {
                                        return (<div style={{float: "left"}}>FAQ/Knowledge Base</div>);
                                    }
                                })()
                            }
                            <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                            <div style={{clear: "both"}}></div>
                        </ReviewTab>

                        <ReviewTab onClick={this.returnSection("email")} style={{marginTop: "2px"}}>
                            {
                                (() => {
                                    if (this.state.active_section_id === "email") {
                                        return (<div style={{float: "left", fontWeight: "600"}}>Email</div>);
                                    } else {
                                        return (<div style={{float: "left"}}>Email</div>);
                                    }
                                })()
                            }
                            <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                            <div style={{clear: "both"}}></div>
                        </ReviewTab>
                        <ReviewTab onClick={this.returnSection("ext_providers")} style={{marginTop: "2px"}}>
                            {
                                (() => {
                                    if (this.state.active_section_id === "ext_providers") {
                                        return (<div style={{float: "left", fontWeight: "600"}}>External Providers</div>);
                                    } else {
                                        return (<div style={{float: "left"}}>External Providers</div>);
                                    }
                                })()
                            }
                            <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                            <div style={{clear: "both"}}></div>
                        </ReviewTab>

                        <ReviewTab onClick={this.returnSection("custom_fields")} style={{marginTop: "2px"}}>
                            {
                                (() => {
                                    if (this.state.active_section_id === "custom_fields") {
                                        return (<div style={{float: "left", fontWeight: "600"}}>Custom Fields</div>);
                                    } else {
                                        return (<div style={{float: "left"}}>Custom Fields</div>);
                                    }
                                })()
                            }
                            <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                            <div style={{clear: "both"}}></div>
                        </ReviewTab>
                    {/*
                        <ReviewTab onClick={this.returnSection("permissions")} style={{marginTop: "2px"}}>
                            {
                                (() => {
                                    if (this.state.active_section_id === "permissions") {
                                        return (<div style={{float: "left", fontWeight: "600"}}>Permissions</div>);
                                    } else {
                                        return (<div style={{float: "left"}}>Permissions</div>);
                                    }
                                })()
                            }
                            <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                            <div style={{clear: "both"}}></div>
                        </ReviewTab>
                    */}
                	</ReviewLeftContainer>
                	<ReviewRightContainer>
                		{
                			(() => {
                				if (this.state.active_section_id === "general") {
                					return (<General general={JSON.parse(JSON.stringify(this.state.module_config.general))} updateGeneral={this.updateGeneral} />);
                				}
                                if (this.state.active_section_id === "tagmgnt") {
                                    return (<Tags general={JSON.parse(JSON.stringify(this.state.module_config.general))} updateGeneral={this.updateGeneral} used_tags={this.state.used_tags} updateGeneralSave={this.updateEmailRemainder} topicIncludedExclude={this.topicIncludedExclude}/>);
                                }
                                if (this.state.active_section_id === "faqrst") {
                                    return (<Faqrestriction general={JSON.parse(JSON.stringify(this.state.module_config.general))} updateGeneralSave={this.updateEmailRemainder}/>);
                                }
                                if (this.state.active_section_id === "email") {
                                    return (<Email variables={this.state.module_config.variables} email={JSON.parse(JSON.stringify(this.state.module_config.email))} updateEmail={this.updateEmail} includeExcludeEmail={this.includeExcludeEmail} general={JSON.parse(JSON.stringify(this.state.module_config.general))} updateGeneralSave={this.updateEmailRemainder}/>);
                                }
                                if (this.state.active_section_id === "ext_providers") {
                                    return (<Extprovides general={JSON.parse(JSON.stringify(this.state.module_config.general))} updateGeneral={this.updateGeneral}/>);
                                }
                                if (this.state.active_section_id === "custom_fields") {
                                    return (<CustomFields general={JSON.parse(JSON.stringify(this.state.module_config.general))} updateGeneral={this.updateGeneral} />);
                                }
                                // if (this.state.active_section_id === "permissions") {
                                //     return (<Permissions permissions={JSON.parse(JSON.stringify(this.state.permissions))} updatePermissions={this.updatePermissions} searchUsers={this.searchUsers} changeAllRoles={this.changeAllRoles} />);
                                // }
                			})()
                		}
                        {
                            (() => {
                                if (this.state.active_section_id !== "faqrst" && this.state.active_section_id !== "email" && this.state.active_section_id !=="tagmgnt" && this.state.active_section_id !=="custom_fields") {
                                    return (
                                        <FooterButtonsContainer>
                                            <CancelBtn onClick={this.closePage}>Cancel</CancelBtn><SaveBtn onClick={() => this.updateModuleconfig()}>Save</SaveBtn>
                                        </FooterButtonsContainer>
                                    );
                                }
                            })()
                        }
                	</ReviewRightContainer>

                	<div style={{clear: "both"}}></div>
                </ReviewBodyContainer>
                <AlertBox alertParam = {this.state.alert_param} />
			</div>
		);
	}
}

export default ModuleConfigHOC;