import React from 'react';
import styled from 'styled-components';
import Store from '../../../Common/Store.js';
import Utils from '../../../Common/Utils.js';

const TableData = styled.div`
	float: left;
    background-color: #ffffff;
    padding: 10px 0px 10px 20px;
    font-weight: 600;
    font-size: smaller;
    box-sizing: border-box;
`;

const GroupName = styled.div`
	padding-top: 5px;
	padding-bottom: 5px;
	font-weight: 600;
	position: absolute;
	top: 0px;
`;
const GroupValue = styled.div`
	padding-top: 5px;
	padding-bottom: 5px;
	font-weight: 600;
	position: absolute;
	top: 0px;
`;

const GroupHeaderOdd = styled.div`
	position: relative;
	min-width: 100%;
	height: 25px;
	background-color: #EEF0F1;
`;
const GroupHeaderEven = styled.div`
	position: relative;
	min-width: 100%;
	height: 25px;
	background-color: #EDF0F1;
`;

const Table = styled.table`
	min-width: 100%;
	background-color: #ffffff;
`;

const TableRow = styled.tr`
	min-width: 100%;
	background-color: #ffffff;
`;

const Cell = styled.td`
	float: left;
	padding: 10px 0px 10px 20px;
	box-sizing: border-box;
	background-color: #ffffff;
`;

class CSLPreviewTable extends React.Component
{
	state = {preview_data: null, heading: null, data: null, ready: false};

	componentDidMount = () => {
		console.log('this.props', this.props)
		this.setState({preview_data: this.props.previewData, fields: this.props.fields, groupby_fields: this.props.groupbyFields, ready: true})
	}

	componentDidUpdate = (prevprops) => {
		if(this.props === prevprops)return
		this.setState({preview_data: this.props.previewData, fields: this.props.fields, groupby_fields: this.props.groupbyFields, ready: true})
	}

	render()
	{
		if(!this.state.ready)return <div></div>
		console.log('prepareData => this.props', this.props)
	// console.log('prepareData => I am in raw data print')
		let widths=this.props.widths;
		console.log('prepareData widths', widths)
		if(this.state.preview_data.buckets.length === 0) {
			let item = this.state.preview_data
			return (								
					<Table>
						{
							item.data_rows.map((r, key) => {
								if(key < 2){
									return (
										<TableRow key={key}>
										{
											this.props.tableHeaders.map((f, index) => {

												if(!(f.accessor in this.props.groupbyFields) && f.accessor !== 'task_id' && f.width > 0) {
													console.log('prepareData f, f.accessor', f, f.accessor)
													return (<Cell key={index} style={{width: widths[f.accessor]}}>{r[f.accessor]}</Cell>)
												}
											})
										}
										</TableRow>
									)
								}
							})
						}
					</Table>
				)			
		}
		return (<div style={{width:'100%'}}>
			{
				this.state.preview_data.buckets.map((item, key) => {
					if(key > 1)return <div></div>
					console.log('item this.props.tableHeaders', this.props.tableHeaders)
					if(item.is_grouping) {
						return (
							<div>
								{
									(() => {
										if(item.level > 0){
											if(item.level % 2 === 0) {

												return (
													<GroupHeaderOdd>
														<GroupName style={{left: item.level*10}}>{item.fieldvalue === 'undefined' ? 'N/A' : item.fieldvalue}</GroupName>
														<GroupName style={{right: 0}}>Grouped by: {item.fieldname}</GroupName>
													</GroupHeaderOdd>
													)
											} else {
												return (
													<GroupHeaderEven>
														<GroupName style={{left: item.level*10}}>{item.fieldvalue === 'undefined' ? 'N/A' : item.fieldvalue}</GroupName>
														<GroupName style={{right: 0}}>Grouped by: {item.fieldname}</GroupName>
													</GroupHeaderEven>
													)
											}
										}
									})()
								}

								<CSLPreviewTable 
									key={key}
									previewData={item}
									fields={this.state.fields}
									groupbyFields= {this.state.groupby_fields}
									cellWidth={this.props.cellWidth}
									tableHeaders={this.props.tableHeaders}
									widths={widths}
								/>
							</div>
							)
					} else {
							return (<div style={{width:'100%'}}>
								{
									(() => {
										if(item.level > 0){
											if(item.level % 2 === 0) {
												return (
													<GroupHeaderOdd>
														<GroupName style={{left: item.level*10}}>{item.fieldvalue === 'undefined' ? 'N/A' : item.fieldvalue}</GroupName>
														<GroupName style={{right: 0}}>Grouped by: {item.fieldname}</GroupName>
													</GroupHeaderOdd>
													)
											} else {
												return (
													<GroupHeaderEven>
														<GroupName style={{left: item.level*10}}>{item.fieldvalue === 'undefined' ? 'N/A' : item.fieldvalue}</GroupName>
														<GroupName style={{right: 0}}>Grouped by: {item.fieldname}</GroupName>
													</GroupHeaderEven>
													)
											}
										}
									})()
								}
								<Table>
								{
									item.data_rows.map((r, key) => {
										if(key < 2) {
											return (
												<TableRow key={key}>
												{
													this.props.tableHeaders.map((f, index) => {
														if(!(f.accessor in this.props.groupbyFields) && f.accessor !== 'task_id' && f.width > 0) {
															console.log('prepareData row f', f)
															return (<Cell key={index} style={{width: widths[f.accessor]}}>{r[f.accessor]}</Cell>)
														}
													})
												}
												</TableRow>
												)
										}
									})
								}
								</Table>
							</div>)
					}
				} 
				)
			}
		</div>)
	}
}

export default CSLPreviewTable;