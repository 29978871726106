import React from 'react';
import styled from 'styled-components';
import {FaTimes} from 'react-icons/fa';
import Store from '../../Common/Store.js';
import APICall from '../../Common/APICall.js';
import Dropzone from '../Common/Dropzone.js'
import * as Style from '../Common/StyledComponents';
import ClarificationMessage from '../CA/ClarificationMessage';
import CSLLoader from '../Common/CSLLoader';
import AlertBox from '../Common/AlertBox';

const ModeltopRevertText = styled.div`
    display: grid;
    grid-gap: 5px;
    padding: 10px;
    border-radius: 3px;
    padding: 10px;
    border-color: #f6d6d6;
    color: #e27575;
    background-color: #f6d6d6;
`;

const ModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 97%;
    position: absolute;
    margin: 0 0;
    top: 8px;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
`;

const ModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const ModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 15px;
    background-color: #ffffff;
`;

const ModalContainertop = styled.div`
    display: grid;
    grid-gap: 15px;
    padding: 20px;
    background-color:#edf0f1;
    margin-top: 10px;
    border-radius: 3px;
    border-color: #edf0f1;
    margin-top: 20px;
`;
const ModalHeaderText = styled.div`
    float: left;
    box-sizing: border-box;
    margin-top: 16px;
    margin-left: 20px;
    color: #333;
    font-weight: 600;
`;
const ModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 7px;
    font-weight: 600;
    color: #333;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
`;


class ClarificationRevertModal extends React.Component
{
    state = {
        company: '',
        query: '',
        answer: '',
        clarification: '',
        binFiles: [],
        cur_files_json: null,
        index: -1,
        form_data: null,
        id:0,
        category_id: 0,
        ext_advice: false,
        ext_advice_txt: '',
        ext_advice_provider:'',
        ext_ref_number: '',
        ext_contact_name: '',
        clarifications: [],
        answered_by: 0,
        show_ext_readonly: false,
        revert_reason: '',
        tags_count: '',
        ref_id: '',
        revert_by: 0,
        header_text: '',
        ready:false,
        custom_fields: {},
        enabled_topics: false,
        topic: {},
        alert_param: null,
        is_loading: false
    };

    constructor(props) {
        super(props);
    }

    componentWillUnmount() {
        Store.deRegisterCallback('changeTaskaction', this.component_id);
    }

    closeModal = (event) => {
        event.preventDefault();
        this.props.closeModal();
    }
    


    processAnswerQuery = (result) => {
        let msg = 'Your answer has been submitted to the requester for acknowledgement.';
        let title = 'Alert'
        let stack = {};
        stack['task_id'] = result.error_code === 0 ? result.task_id : 0
        stack['task'] = result.error_code === 0 ? result.task : {};
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: stack}
        this.setState({alert_param: alert_param, is_loading: true})
    }

    answerCARQuestion(submit_form){
        Store.updateStore('submit_form', submit_form);
        let msg = 'The answer cannot be blank.'
        let title = 'Warning'
        if(this.state.clarification === '' && submit_form === 1) {
            // alert('ERROR: The Answer cannot be blank.');
            let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.sameAlertremoveHandler, stack: {id: 0}}
            this.setState({'is_disabled': false, alert_param: alert_param})
            return
        }
        else{
        //console.log('clarification', this.state.clarification);
        //console.log('ext_advice', this.state.ext_advice);
        
        let bin_files = this.state.binFiles;
        let postData = { "command": "add_clarification_reply", query_id:this.state.id, clarification: this.state.clarification, submit:submit_form, 
                         bin_files: bin_files, category_id:this.state.category_id,
                        ext_advice: this.state.ext_advice,
                        ext_advice_txt: this.state.ext_advice_txt.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'"),
                        clarifications: this.state.clarifications,
                        index: this.state.index, task_id: this.state.form_data === null ? 0 : this.state.form_data.id, asker_id: this.state.form_data.contact_id,
                        acceptance_recever_id: this.state.revert_by
                         };
        console.log('postData', postData);
        let api = new APICall();
        api.indexCommand(postData, this.processAnswerQuery);
        this.setState({is_loading: true})
        }
    }

    handleChange = (event) => {
        this.setState({ [event.target.name] : event.target.value })
    }

    componentDidMount() {
        let index = Store.getStoreData('a_index') === null ? 0 : Store.getStoreData('a_index');
        let form_data = {};
        form_data = Store.getStoreData('cur_revert_modal_data');
        
        let gc_assoc = Store.getStoreData('gc_assoc');
        let gc_company = parseInt(form_data.company_id) < 100000 ? Store.getStoreData('company').CompanyName : gc_assoc[(parseInt(form_data.company_id)%100000).toString()].company_name;
        let task_json = JSON.parse(form_data.task_json);
        if (task_json.object_data.bin_files.length !== 0) {
            task_json.object_data.bin_files.forEach((item) => {
                item.uid = "uid" in item ? item.uid : this.genKey(10);
            })
        }
        let ptags = {};
        if('tags' in task_json.object_data) {
            ptags = task_json.object_data.tags;
        }
        let tags_count =  Object.keys(ptags).length;
        let answered_by = ('answered_by' in task_json.object_data) ? task_json.object_data.answered_by : 0;
        let ref_id = '';
        if('ref_id' in task_json.object_data) {
            ref_id = task_json.object_data.ref_id;
        }
        let custom_fields = this.state.custom_fields;
        custom_fields.enabled = false;
        custom_fields = ('custom_fields' in  task_json.object_data) ? task_json.object_data.custom_fields : custom_fields;

        let revert_by = ('reverted_by' in task_json.object_data) ? task_json.object_data.reverted_by : 0;
        let header_text = task_json.object_data.query;
        let moduleconfig = Store.getStoreData('moduleconfig');
        let topic = this.state.topic;
        let enabled_topics = this.state.enabled_topics;
        if(moduleconfig.enabled_topics && ('topic' in task_json.object_data && 'value' in task_json.object_data.topic)){
            enabled_topics = true;
            topic = task_json.object_data.topic;
        }
        this.setState({company: gc_company, cur_files_json: task_json.object_data.bin_files, 
            binFiles: task_json.object_data.bin_files, 
            index: index, form_data:form_data, query: task_json.object_data.query,
            answered_by: answered_by,
            clarifications: ('clarifications' in task_json.object_data) ? task_json.object_data.clarifications : [],
            answer: ('answer' in task_json.object_data) ? task_json.object_data.answer : '', 
            ext_advice: ('ext_advice' in task_json.object_data) ? task_json.object_data.ext_advice : '',
            ext_advice_txt: ('ext_advice_txt' in task_json.object_data) ? task_json.object_data.ext_advice_txt : '',
            ext_advice_provider: ('ext_advice_provider' in task_json.object_data) ? task_json.object_data.ext_advice_provider : '',
            ext_ref_number: ('ext_ref_number' in task_json.object_data) ? task_json.object_data.ext_ref_number : '',
            ext_contact_name: ('ext_contact_name' in task_json.object_data) ? task_json.object_data.ext_contact_name : '',
            id: form_data.id, 'revert_reason' : ('revert_reason' in task_json.object_data) ? task_json.object_data.revert_reason : '', 
            tags_count: tags_count, ref_id: ref_id, revert_by: revert_by, header_text: header_text, 
            custom_fields: custom_fields, topic: topic, enabled_topics:enabled_topics, ready: true });
    }

    genKey = (length) => {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

        filesLoaded = (jsonObj) => {
            let contact_id = Store.getStoreData('contact_id');
            let files_to_show = JSON.parse(JSON.stringify(jsonObj.files));
            let binFiles = this.state.binFiles;

            let file_exists = {};
            let curBinFiles = [];

            if(jsonObj.command === 'add'){
                for(let i=0; i < files_to_show.length; i++) {
                    if(typeof files_to_show[i].name !== "undefined"){
                        files_to_show[i].name = jsonObj.files[i].name.replace('&', '');
                        if(!('user_id' in files_to_show[i])) {
                            files_to_show[i].user_id = contact_id;
                            files_to_show[i].upload_date = Date.now();
                        }
                        if(typeof file_exists[files_to_show[i].name] === "undefined"){
                            binFiles.push(files_to_show[i]);
                            file_exists[files_to_show[i].name] = true;
                        }
                    }

                }
                curBinFiles = binFiles.filter((binFiles, index, self) => index === self.findIndex((t) => (t.name === binFiles.name && t.uid === binFiles.uid)));
                this.setState({binFiles: curBinFiles});
            }else{
                for(let i=0; i < binFiles.length; i++) {
                    if(binFiles[i].uid.toString() === jsonObj.uid.toString()){
                        binFiles.splice(i,1);
                    }
                }
                this.setState({binFiles: binFiles});
            }
        }


    handleCheckboxChange = (event) => {
        this.setState({ ext_advice: event.target.checked })
    }

    showCustomFields = (field, data_container) => {
        return (
            <div style={{display:"contents"}}>
                <Style.ModalNameLabelDiv>{field.nickname.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")}</Style.ModalNameLabelDiv>
                <Style.ModalNameInputDiv style={{width: '93%'}}>
                    <Style.ModalInput type="text" name={field.name} value={field.value.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")}  readOnly={true}/>
                {
                    (() => {
                        if(field.other && field.type === "Dropdown" && field.value === "Other"){
                            return (<Style.ModalNameInputDiv>
                                <Style.ModalInput type="text" name={field.name} value={field.more_info.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")}  readOnly={true} style={{width: '98%'}} />
                                </Style.ModalNameInputDiv>
                                )
                        }

                    })()
                }

                </Style.ModalNameInputDiv>
            </div>
        );
    }
    
    sameAlertremoveHandler = (result,stack) => {
        this.setState({alert_param: null})
    }

    alertremoveHandler = (result, stack) => {
        this.setState({alert_param: null})
        this.props.refreshParentCallback(stack);
    }

    render()
    {
        if(!this.state.ready) {
            return(<div>Loading...</div>);
        }
        // let contact = Store.getStoreData('contact');
        let contacts = Store.getStoreData('contacts');
        let thisContact = Store.getStoreData('contact');
        let form_data = Store.getStoreData('cur_revert_modal_data');
        let object_data = JSON.parse(form_data.task_json).object_data
        let clarifications = JSON.parse(JSON.stringify(this.state.clarifications))
        let clLength = clarifications.length
        let last_answer_utime = clarifications.length === 0 ? object_data.answer_date : clarifications[clLength -1].actiondate
        let last_answer_time_obj = new Date(last_answer_utime)
        // let last_answer_time = last_answer_time_obj.toLocaleString('en-GB').replace(/,/g, '');
        
        let last_answer_time = last_answer_time_obj.toLocaleString('en-GB',{year: 'numeric', month: 'numeric', day: 'numeric',hour: '2-digit', minute: '2-digit'}).replace(/,/g, '')

        clarifications.reverse()
        let orig_answer = {actiondate: object_data.answer_date, note: object_data.answer, performed_by: object_data.answered_by }
        let contact_id = form_data.contact_id;
        let contact = contacts[contact_id];
        //console.log('contact_id, contacts', contact_id, contacts);
        let date = new Date()
        let dateStr = date.toLocaleString('en-GB').replace(/,/g, '')
        return (
            <div>
                <ModalContainer>
                    {
                        (() => {
                            if(this.state.is_loading) {
                                return <CSLLoader style={{position: 'absolute'}} />
                            }
                        })()
                    }
                    <ModalHeaderText>{this.state.ref_id} - {this.state.header_text.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'").substring(0, 70) + "..."}</ModalHeaderText>
                    <Style.ModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></Style.ModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>
                    <ModalBody>
                        <ModalBodyInner>
                            <ModeltopRevertText>
                                <div style={{fontWeight:"600",color: "#f6d6d6;"}}>{ Store.getStoreData('contacts')[this.state.revert_by]} reviewed your Advice Request response and has reverted it back with the following comments.</div>
                                <div style={{clear: "both"}}></div>
                                <div style={{fontWeight:"400",color: "#f6d6d6;"}}>{this.state.revert_reason.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")}</div>
                            </ModeltopRevertText>
                            <div style={{clear: "both"}}></div>
                            <ModalContainertop>
                                <table style={{width: "100%"}}>
                                <thead>
                                    <tr>
                                        <th style={{textAlign: "left",color: "#243e57"}}><strong>ID</strong></th>
                                        {
                                            (() => {
                                                if(this.state.enabled_topics) {
                                                    return <th style={{textAlign: "left",color: "#243e57"}}><strong>Topic</strong></th>
                                                }
                                            })()
                                        }
                                        <th style={{textAlign: "left", color: "#243e57"}}><strong>Requester</strong></th>
                                        <th style={{textAlign: "left", color: "#243e57"}}><strong>Requested Date</strong></th>
                                        <th style={{textAlign: "left", color: "#243e57"}}><strong>Status</strong></th>
                                        <th style={{textAlign: "left", color: "#243e57"}}><strong>Tags</strong></th>
                                    </tr>
                                  </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{textAlign: "left",color: "#243e57"}}>{this.state.ref_id}</td>
                                            {
                                                (() => {
                                                    return <td style={{textAlign: "left", color: "#243e57"}}>{this.state.topic.value}</td>
                                                })()
                                            }
                                            <td style={{textAlign: "left", color: "#243e57"}}>{ Store.getStoreData('contacts')[this.state.revert_by]}</td>
                                            <td style={{textAlign: "left", color: "#243e57"}}>{last_answer_time}</td>
                                            <td style={{textAlign: "left", color: "#243e57"}}>Reverted</td>
                                            <td style={{textAlign: "left", color: "#243e57"}}>{this.state.tags_count}</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <ModalNameLabelDiv>Question</ModalNameLabelDiv>
                                <ModalNameInputDiv style={{whiteSpace:  "break-spaces"}}>
                                    {this.state.query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")}
                                </ModalNameInputDiv>
                                <ModalNameLabelDiv>Attachments</ModalNameLabelDiv>
                                <ModalNameInputDiv>
                                    <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.binFiles} addnew={false} />
                                </ModalNameInputDiv>
                            </ModalContainertop>

                            <div style={{clear: "both"}}></div>
                            <ModalContainertop>
                                <Style.ModalNameInputDiv>
                                    <Style.ModalWiderDiv>
                                        <Style.ModalWiderLineDiv style={{width: '90%'}}/>
                                        <Style.ModalClearDiv />
                                        <Style.ModalWiderHeaderDiv 
                                            style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px',width: '93%'}}>
                                            {thisContact}&nbsp;&nbsp;&nbsp;&nbsp;{dateStr}
                                        </Style.ModalWiderHeaderDiv>
                                        <Style.ModalTextArea style={{width: '93.3%'}} name="clarification" value={this.state.clarification.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} onChange={this.handleChange} placeholder="Reply notes..." />
                                    </Style.ModalWiderDiv>
                                </Style.ModalNameInputDiv>
                            
                                <Style.CancelSubmitButtonOuterContainer style={{width: '93%', marginTop:'10px'}}>
                                    <Style.CancelButton onClick={this.closeModal}>Close</Style.CancelButton>
                                    <Style.SubmitButton style={{width: "auto"}} onClick={() => this.answerCARQuestion(1)}>Send for Approved</Style.SubmitButton>
                                </Style.CancelSubmitButtonOuterContainer>
                            </ModalContainertop>

                            <div style={{clear: "both"}}></div>
                            <ModalContainertop>

                                {
                                    clarifications.map((c,i) => {
                                        return (<ClarificationMessage cardata={form_data} clarification={c} />)
                                    })
                                }
                                <div>
                                {
                                    (() => {
                                        return (<ClarificationMessage cardata={form_data} clarification={orig_answer} />)
                                    })()
                                }
                                </div>
           
                                <Style.ModalNameInputDiv>
                                    <Style.ModalWiderDiv>
                                        {
                                            (() => {
                                                if(this.state.custom_fields.enabled) {
                                                    if (this.state.custom_fields.custom_terminology_fields.length !== 0 ) {
                                                        const field = this.state.custom_fields.custom_terminology_fields.find((item) => {
                                                            return item.name === "reference_number"
                                                        })

                                                        if(field.required && ('value' in field)) {
                                                            return this.showCustomFields(field, "custom_terminology_fields");
                                                        }
                                                    }
                                                }
                                            })()
                                        }
                                        {
                                            (() => {
                                                if(this.state.custom_fields.enabled) {
                                                    if (this.state.custom_fields.additional_fields.length !== 0) {
                                                        let custom_html = [];
                                                        /*this.state.custom_fields.additional_fields.forEach((item) => {
                                                            if(item.required && ('value' in item)) {
                                                                const field = this.showCustomFields(item, "additional_fields");
                                                                custom_html.push(field);
                                                            }
                                                        })*/
                                                        this.state.custom_fields.additional_fields.forEach((item) => {
                                                            if(('value' in item) && item.value!=='' && item.isActive) {
                                                                const field = this.showCustomFields(item, "additional_fields");
                                                                custom_html.push(field);
                                                            }
                                                        })
                                                        return custom_html;
                                                    }
                                                }
                                            })()
                                        }
                                    </Style.ModalWiderDiv>
                                </Style.ModalNameInputDiv>
                                {
                                    (() =>{
                                        if (this.state.ext_advice){
                                            return(
                                                <Style.ModalNameInputDiv>
                                                    <Style.ModalWiderDiv>
                                                        <Style.ModalWiderLineDiv style={{width: '90%'}}/>
                                                        <Style.ModalClearDiv />
                                                        {
                                                            (() => {
                                                                if(this.state.ext_advice_provider != ""){
                                                                    return(<div>
                                                                        <Style.ModalWiderHeaderDiv style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px',width: '93%' }}>
                                                                            External Advice Providers
                                                                        </Style.ModalWiderHeaderDiv>
                                                                        <div style={{width: '95%'}}>
                                                                            <Style.ModalInput style={{width: '98%'}} value={this.state.ext_advice_provider.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} readOnly={true}/>
                                                                        </div>
                                                                    </div>)
                                                                }
                                                            })()
                                                        }
                                                        <Style.ModalClearDiv />
                                                        <Style.ModalWiderHeaderDiv style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px',width: '93%' }}>
                                                            External advice required
                                                        </Style.ModalWiderHeaderDiv>
                                                        <div style={{ width: '95%'}}>
                                                            <Style.ModalTextArea style={{width: '98%'}} value={this.state.ext_advice_txt.replace(/'+/g,"'").replace(/(&rsquo;)/g, "\'")} readOnly={true}/>
                                                        </div>
                                                           <Style.ModalClearDiv />
                                                           {
                                                               (() => {
                                                                   if(this.state.ext_ref_number != ""){
                                                                       return(<div>
                                                                           <Style.ModalWiderHeaderDiv style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px',width: '93%' }}>
                                                                               External Reference Number
                                                                           </Style.ModalWiderHeaderDiv>
                                                                           <div style={{backgroundColor: '#FFFFFF', width: '95%'}}>
                                                                               <Style.ModalInput style={{width: '98%'}} value={this.state.ext_ref_number.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} readOnly={true}/>
                                                                           </div>
                                                                       </div>)
                                                                   }
                                                               })()
                                                           }

                                                           <Style.ModalClearDiv />
                                                           {
                                                               (() => {
                                                                   if(this.state.ext_contact_name != ""){
                                                                       return(<div>
                                                                           <Style.ModalWiderHeaderDiv style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px',width: '93%' }}>
                                                                               External Contact Name
                                                                           </Style.ModalWiderHeaderDiv>
                                                                           <div style={{backgroundColor: '#FFFFFF', width: '95%'}}>
                                                                               <Style.ModalInput style={{width: '98%'}} value={this.state.ext_contact_name.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} readOnly={true}/>
                                                                           </div>
                                                                       </div>)
                                                                   }
                                                               })()
                                                           }
                                                       </Style.ModalWiderDiv>
                                                </Style.ModalNameInputDiv>
                                            )
                                        }
                                    })()
                                }
                            </ModalContainertop>
                        </ModalBodyInner>
                    </ModalBody>
                    </ModalContainer>
                <AlertBox alertParam = {this.state.alert_param} />    
            </div>
        );
    }
}
export default ClarificationRevertModal;

