import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import APICall from '../../Common/APICall.js';
import Utils from '../../Common/Utils.js';
import AddFAQModal from './AddFAQModal';
import ShowFAQModal from './ShowFAQModal';
import FaqHeader from './FaqHeader';
import WelcomeBanner from '../WelcomeBanner';
import CSLLoader from '../Common/CSLLoader';
import CSLPageloader from '../Common/CSLPageloader';

import RegisterReporting from '../Register/CSLRegister/FaqRegisterReporting';
import reportData from './reportData.js';
import filters from './reportFilters.js';
import pill_filters from './pillFilters.js'
import TextDropdown from './TextDropdown.js';
import CSLHeader from "../CSLHeader";

const months = {
                  '01': 'January',
                  '02': 'February',
                  '03': 'March',
                  '04': 'April',
                  '05': 'May',
                  '06': 'June',
                  '07': 'July',
                  '08': 'August',
                  '09': 'September',
                  '10': 'October',
                  '11': 'November',
                  '12': 'December'
               }

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #01B0A2;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 100px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    margin-right:3px;
    cursor:pointer;
`;

const SubmitButton = styled.button`
  display: inline-block;
    box-sizing: border-box;
    background-color: #223B5A;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    padding-left: 12px;
    margin-top: 40px;
    cursor:pointer;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1178px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1000;
  opacity: 0.6;
`;

const NoPermission = styled.div`
  display: block;
  box-sizing: border-box;
  height: 40px;
  font-size:20px;
  font-weight: 700;
  text-align: center;
  background-color: #ffffff;
  position: absolute;
  top: 100px;
  left: 10px;
  right:10px;
  bottom : 10px;
  border: 2px solid #37ada7;
  border-radius:10px;
  color: #37ada7;
  z-index: 1000;
`;


const UnselectedPill = styled.div`
    background-color:#EEF2F7;
    color: #243E57;
    padding: 8px;
    border-radius: 30px;
    display: inline-block;
    margin-left: 7px;
    font-size: 10px;
    font-weight: 400;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
    width: 170px;
    border: 1px solid #243E57;
`;
const SelectedPill = styled.div`
    background-color: #243E57;
    color: #EEF2F7;
    padding: 8px;
    border-radius: 30px;
    display: inline-block;
    margin-left: 7px;
    font-size: 10px;
    font-weight: 400;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
    width: 170px;
    border: 1px solid #243E57;
`;

const ErrorBar = styled.div`
    background-color: #F6DBDB;
    color: #DE8D8E;
    font-weight: 600;
    padding: 10px 20px;
`;


const ContentBody = styled.div`
    background-color: #F5F9FF;
    padding: 15px 15px;
`;
const HR = styled.div`
  background-color: #C7D1D9;
  width: 100%;
  height: 1px;
  margin-top: 25px;
`;

class GenericFaqregister extends React.Component
{
      state = {
        ready: false,
        faqdata: [],
        status_count:{},
        page_view: "faqregister_index",
        show_modal: '',
        overlay_height: null,
        filter: {
          All: 1
        },
        faq_id: 0,
        show_filter: [],
        faq_register: true,
        allfilter: {},
        prefered_columns: null,
        filters: filters,
        pill_filters: {},
        view_count: 0,
        filtertype: "1",
        payload: []

    };

    constructor(props) {
        super(props);
    }

    refreshState = () => {
        this.setState({ overlay_height: null, show_modal: ''});
    }

    refreshCallback = (data) => {
      this.setState({view_count: this.state.view_count + 1});
    }

    refreshCarRegister = () => {
      this.props.refreshCarRegister();
    }

    refreshFaqRegister = () => {
      this.fetchFaqRegisterDate();
    }

    componentDidMount() {        
        this.fetchFaqRegisterDate();
    }

    processSaveFilter = (result) => {
      Store.updateStore('home_filter', JSON.parse(result))
    }



    setShowTable = (flag, table) => {
      let allfilter = this.state.allfilter;
      let show_table_filter = allfilter.show_table_filter;
      show_table_filter[table.toString()] = flag
      allfilter.show_table_filter = show_table_filter;
      this.setState({faq_register: flag, allfilter: allfilter})
      let car_cached_data = JSON.parse(localStorage.getItem("car-index-select"))
      // Utils.log("filter ===>", car_cached_data)
      if('result' in car_cached_data && 'home_filter' in car_cached_data.result && 'show_table_filter' in car_cached_data.result.home_filter){
        car_cached_data.result.home_filter.show_table_filter = show_table_filter;
        localStorage.setItem("car-index-select", JSON.stringify(car_cached_data));
      }

      let postData = { "command": "car_registry_save", "filter":  allfilter};
      console.log("postData", postData);
      let api = new APICall();
      api.command(postData, this.processSaveFilter);
      
    }

    fetchFaqRegisterDate(){
      let postData = { "command": "get_faq_registry" };
      let api = new APICall();
      api.indexCommand(postData, this.processRegisterdata);
    }

    processRegisterdata = (result) => {
      console.log("result 78787", result)
      let filter =  result.result.filter
      let rawtags = Store.getStoreData('tags');
      let otags = [];
      rawtags.forEach((top) => {
            if(filter[top.ID.toString()] ===  1) {
                otags.push({
                    name: top.TagName,
                    id: top.ID,
                    styledcomponent: UnselectedPill,
                    selected: false
                });
            }
          
      })
      let stagnames = [];
      otags.forEach((item) => {
          stagnames.push(item.name);
      })
      stagnames.sort();
      let ftags = [{
                    name: "All",
                    id: 'all',
                    styledcomponent: SelectedPill,
                    selected: true
                }];
      for (let i=0; i<stagnames.length; i++) {
          for (let j=0; j<otags.length; j++) {
              if (stagnames[i] === otags[j].name) {
                  ftags.push(otags[j]);
              }
          }
      }
      let fis = this.state.filters;
      // for(let i=0; i<stagnames.length; i++) {
      //   filters.used_tags.options[stagnames[i]] = {name: stagnames[i]}
      // }



      console.log("filters ====> 12345generic", fis)

      // let allfilter = result.faq_filter;

      // let faq_register = "show_table_filter" in result.faq_filter ? result.faq_filter.show_table_filter['faq_register'] : this.state.faq_register;

      let home_filter = Store.getStoreData('home_filter');
      // let show_table_filter = ("show_table_filter" in home_filter) ? home_filter.show_table_filter : this.state.show_table_filter;
      let faq_register = "show_table_filter" in home_filter ? home_filter.show_table_filter['faq_register'] : this.state.faq_register;
      


      Store.updateStore('faqregdata', result.result.rows);  
      let prefered_columns ={};
      prefered_columns['faq_id'] = 'ID';
      prefered_columns['question'] = 'Question';
      prefered_columns['answer'] = 'Answer';
      prefered_columns['name_tags'] = 'Tags';
      prefered_columns['topic_value'] = 'Topic';
      let payload = [
                        {
                          id: "OR",
                          top_text: "Filtering",
                          description: "Set by default. Shows FAQs that have been tagged with any of the selected topics.",
                          selected: true
                        },
                        {
                          id: "AND",
                          top_text: "Filtering",
                          description: "Shows FAQs that have been tagged with all the selected topics.",
                          selected: false
                        }
                    ]
      
      this.setState({overlay_height: null, show_modal: '','ready': true, 'faqdata' : result.result.rows, 'status_count': result.result.status_count, show_filter:ftags, faq_register: faq_register, allfilter: home_filter, prefered_columns: prefered_columns, pill_filters:pill_filters,filters:filters, payload: payload});
    }

    showRegisterFaqModal = (faq_id) => (event) => {
      event.preventDefault();
      this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'showfaq_modal', faq_id:faq_id });
    }

    processSingleFaqdata = (result) => {
      // console.log("result", result.result['faq'])
      // Store.updateStore('singlefaq', result.result['faq']);
      // this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'showfaq_modal'});
    }

    getDate = (input_date) => {
      if(input_date != null){
        let dateStr = input_date.split('T')[0];
        let dateParts = dateStr.split('-'); 
        let ret = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
        return ret;
      }else{
        return '';
      }
     
    }

    processFaqData = (Data) => {
     var task_arr = []
     let taskData = this.state.faqdata;
      console.log("taskData==>",taskData)
     var filter_arr = []
     for(var k = 0 ; k < Object.keys(taskData).length; k++)
     {
         task_arr.push(taskData[Object.keys(taskData)[k]].row)
         filter_arr.push(taskData[Object.keys(taskData)[k]].tags)
     }
     console.log("filter_arr==>",filter_arr)
     let ret = {data: [], columns: [], raw_data: [], row_filters: {}};
     let i = 1;
    

     const has_gc = Store.getStoreData('has_gc');

     ret.columns =[ 
                 {Header: 'ID', accessor: 'faq_id', minWidth: 70, headerStyle: {textAlign: 'left'}},         
                 {Header: 'Question', accessor: 'question', minWidth: 400, headerStyle: {textAlign: 'left'}},
                 {Header: 'Answer', accessor: 'answer', minWidth: 400, headerStyle: {textAlign: 'left'}},
                 {Header: 'Company', accessor: 'company_name', minWidth: 100, headerStyle: {textAlign: 'left'}, show: has_gc},
                 {Header: 'Topic', accessor: 'topic_value', minWidth: 100, headerStyle: {textAlign: 'left'}, show: Store.getStoreData('moduleconfig')['enabled_topics']},
                 {Header: 'Published Date', accessor: 'published_date', minWidth: 100, headerStyle: {textAlign: 'left'}},
                 {Header: 'Published By', accessor: 'published_by', minWidth: 100, headerStyle: {textAlign: 'left'}},
                 {Header: 'Tags', accessor: 'tags', minWidth: 50, headerStyle: {textAlign: 'left'}},
                 {'Header' : 'Action', Cell: row => (
                     <div>
                       <MRButton style={{cursor: 'pointer'}} onClick={this.showRegisterFaqModal(row.original.id)}>View</MRButton>                                                   
                     </div>
                 ), width: 150, headerStyle: {textAlign: 'left'}}];

     let gc_assoc = Store.getStoreData('gc_assoc');
     let gc_company = "";

    for(var j = 0 ; j< task_arr.length ; j++) 
     {         
        let property_json = JSON.parse(task_arr[j].property_json);
        console.log("property_json", property_json)
        let ref_id = "";
        if(property_json.ref_id!==null) {
           ref_id = property_json.ref_id;
        }
        let dateStr = this.getDate(task_arr[j].published_date);
        var asked_month = dateStr === "" ? "01" : dateStr.split("/")[1];
        let published_by = (property_json.published_by !==null) ? Store.getStoreData('contacts')[property_json.published_by] : Store.getStoreData('contacts')[task_arr[j].contact_id]
        // let published_by = task_arr[j].contact_id === 0 ? "Api" : Store.getStoreData('contacts')[task_arr[j].contact_id]


         gc_company = parseInt(task_arr[j].company_id) < 100000 ? Store.getStoreData('company').CompanyName : gc_assoc[(parseInt(task_arr[j].company_id)%100000).toString()].company_name;
        
        
        let answer = property_json.answer!==null ?  property_json.answer : 'no answer';
        if(Utils.isBase64(answer)){
          answer = window.decodeURIComponent(atob(property_json.answer))
        }



        console.log("answer", answer)

        let name_tags = "";
        
        if(property_json.tags!==null){
          for (var key in property_json.tags) {
              name_tags = name_tags + "|" + property_json.tags[key];
          }
          name_tags = name_tags.substring(1)
        }



        // let tags = property_json.tags !== null ? property_json.tags : {};
        let topic_value = property_json.topic_value !== null ? property_json.topic_value : '';

        console.log("name_tags", name_tags.substring(1))
        
        let elem = {'faq_id':ref_id,
                  'question': property_json.query.replace(/(&nbsp;)/g, "").replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'").replace(/\n/g, ""),
                  'company_name': gc_company,
                  'published_date': dateStr,
                  'published_month': months[asked_month.toString()], 
                  'published_by' : published_by,
                  'tags': Object.keys(property_json.tags).length,
                  'id': task_arr[j].id,
                  'answer' : answer.replace( /(<([^>]+)>)/ig, '').replace(/(&nbsp;)/g, "").replace(/\n/g, ""),
                  'ext_advice': (property_json.ext_advice!==null && property_json.ext_advice) ? 'Yes' : 'No',
                  'name_tags':  name_tags,
                  'topic_value':  topic_value,
                  'query_answer':  property_json.query.replace(/(&nbsp;)/g, "").replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'").replace(/\n/g, "") + " "+ answer.replace( /(<([^>]+)>)/ig, '').replace(/(&nbsp;)/g, "").replace(/\n/g, "")                                          
        };
        ret.data.push(elem);
        ret.raw_data.push(elem);

     }   
     console.log("ret.data", ret.row_filters)
     return ret;   
    }


    addfaqModal = () => {
      this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'addfaq_modal'});
    }

    selectOrUnselectTopic = (tagid) => (event) => {
        event.preventDefault();
        let obj = this.state.payload;
        let show_filter = this.state.show_filter;
        let faqdata = Store.getStoreData('faqregdata');

        console.log("show filter faqdata", faqdata);
        show_filter.forEach((tag) => {
            if (tag.id === tagid) {
                if (tag.selected) {
                    tag.selected = false;
                    tag.styledcomponent = UnselectedPill;
                } else {
                    tag.selected = true;
                    tag.styledcomponent = SelectedPill;
                }
            }
        })

        let tagss = {};
        for(let k in show_filter){
          if(show_filter[k].selected){
            tagss[show_filter[k].id.toString()] = 1;
          }
        }
        delete tagss['all']

        let faq = {};
        Object.keys(faqdata).forEach(function(key) {
          let and_score = Object.keys(tagss).length === 0 ? 0 : 1
          let or_score = 0
          for(let k in tagss) {
            or_score += faqdata[key].tags[k] === 1 ? 1 : 0;
            and_score *= faqdata[key].tags[k] === 1 ? 1 : 0;
          }




          let or_filter = false;
          let and_filter = false;
          obj.forEach(item => {
            if(item.selected){
              or_filter = (item.id === "OR" && item.selected) ? true : false;
              and_filter = (item.id === "AND" && item.selected) ? true : false;
            }
          })
          
          console.log("and score", and_score, and_filter)

          if(or_filter && or_score > 0){
            faq[key] = faqdata[key];
          }
          if(and_filter && and_score === 1){
            faq[key] = faqdata[key];
          }
          if(Object.keys(tagss).length <= 1){
            show_filter.map((tag, index) => {

              if (tag.selected) {
                if(tag.id !=='all'){
                    if(faqdata[key].tags[tag.id.toString()] === 1) {
                      faq[key] = faqdata[key];
                    }
                  }else{
                    faq = faqdata;
                  }
                  
              }

            })
          }

        });
        
       this.setState({ show_filter: show_filter, faqdata: faq });
    }

    setHomeView = (event) => {
        event.preventDefault();
        let url = "/"
        window.location.assign(url);
    }



    setSelected = (obj) => {
      console.log("obj", obj)

      let show_filter = this.state.show_filter;
      // let filtertype = evt.target.value;
      let faqdata = Store.getStoreData('faqregdata');

      let tagss = {};
      for(let k in show_filter){
        if(show_filter[k].selected){
          tagss[show_filter[k].id.toString()] = 1;
        }
      }
      delete tagss['all']

      let faq = {};
      Object.keys(faqdata).forEach(function(key) {
        let and_score = Object.keys(tagss).length === 0 ? 0 : 1
        let or_score = 0
        for(let k in tagss) {
          or_score += faqdata[key].tags[k] === 1 ? 1 : 0;
          and_score *= faqdata[key].tags[k] === 1 ? 1 : 0;
        }


        let or_filter = false;
        let and_filter = false
        obj.forEach(item => {
          if(item.selected){
            or_filter = (item.id === "OR" && item.selected) ? true : false;
            and_filter = (item.id === "AND" && item.selected) ? true : false;
          }
        })
        

        if(or_filter && or_score > 0){
          faq[key] = faqdata[key];
        }
        if(and_filter && and_score === 1){
          faq[key] = faqdata[key];
        }
        if(Object.keys(tagss).length <= 1){
          show_filter.map((tag, index) => {

            if (tag.selected) {
              if(tag.id !=='all'){
                  if(faqdata[key].tags[tag.id.toString()] === 1) {
                    faq[key] = faqdata[key];
                  }
                }else{
                  faq = faqdata;
                }
                
            }

          })
        }

      });

      this.setState({ show_filter: show_filter,payload:obj,  faqdata: faq });
    }

    render() {
      console.log("this.state ===>", this.state.filters)
      // let opt = {"id": "0", "name": "Select"};
      let options = [{"id": "1", "name": "OR Filtering"},{"id": "2", "name": "AND Filtering"}];
      // options.push(opt);
      if (!Store.getStoreData('show_faq')) {
          return (
              <ErrorBar>
                  <div style={{float: "left"}}>You are not authorised to access this page, please speak to your system administrator if you believe access should be granted.</div>
                  <div style={{float: "right", fontSize: "16px", cursor: "pointer"}} onClick={this.setHomeView}>x</div>
                  <div style={{clear: "both"}}></div>
              </ErrorBar>
          );
      }
      if(Store.getStoreData('car-get_faq_registry-select') === null) {
        return <div><CSLPageloader /></div>
      }
      if(!this.state.ready) {
          return <div><CSLLoader /></div>
      }
        return(
            <div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
            <CSLHeader headerText={'Compliance Advice Register'}/>
            <WelcomeBanner welcome={`Welcome back, ` + Store.getStoreData('contact')} />
            <ContentBody>
              <div style={{float: "left", fontWeight: "600", color: "#575859"}}>Quick Filters</div>
              <div style={{float: "right"}}>
              <div style={{float: "left", fontWeight: "600", color: "#575859", marginTop: "5px"}}>Filter Type:</div>
              <div style={{float: "left", paddingLeft: "20px"}}>
                <TextDropdown payload={this.state.payload} setSelected={this.setSelected} />
              </div>
              <div style={{clear: "left"}}></div>
              </div>
              <div style={{clear: "both"}}></div>
              <HR></HR>
            </ContentBody>
                

                <div style={{ paddingTop:"10px",paddingLeft:"10px",paddingBottom:"10px"}}>
                    {
                        this.state.show_filter.map((tag, index) => {
                          let t = tag.selected ? <span>&#10003;    </span> : '';
                            return (<tag.styledcomponent key={index} onClick={this.selectOrUnselectTopic(tag.id)}>
                                {t}{tag.name}<span style={{float: 'right', color: "#04ADA8"}}> {this.state.status_count[tag.id.toString()]} </span>
                              </tag.styledcomponent>);
                        })
                    }
                    <div style={{clear: "both"}}>&nbsp;</div>
                    {
                      (() => {
                        if(Store.getStoreData('role') === "admin_manager" || Store.getStoreData('role') === "ca" || Store.getStoreData('role') === "ca_module_admin"){
                          return(<div>
                            <SubmitButton onClick={this.addfaqModal}>Add FAQ</SubmitButton>
                            </div>)
                        }else{
                          return <div style={{height:"35px", marginTop:"40px"}}></div>
                        }

                      })()
                    }
                    
                  {/*<CSLTable add={false} export={true} processData={this.processFaqData} 
                  headerText={'Frequently Asked Questions'} tableRows="10" setShowTable={this.setShowTable} 
                  show_filter={this.state.faq_register} table="faq_register" 
                  prefered_columns={this.state.prefered_columns}
                  />*/}
                  <RegisterReporting
                          headerText='Frequently Asked Questions'
                          moduleId='car'
                          data={reportData}
                          processTableData={this.processFaqData}
                          preferedColumns={this.state.prefered_columns}
                          filters={this.state.filters}
                          pillFilters={this.state.pill_filters}
                          refreshCallback={this.refreshCallback}
                          viewCount={this.state.view_count}
                          setShowTable={this.setShowTable} 
                          show_filter={this.state.faq_register}
                          table="faq_register"

                  />
                  {
                    (() => {
                      if (this.state.overlay_height !== null) {
                        switch(this.state.show_modal) {
                          case 'addfaq_modal' : return <div><InactiveOverlay /><AddFAQModal refreshParentCallback={this.refreshState} refreshCarRegister={this.refreshCarRegister} refreshFaqRegister={this.refreshFaqRegister}/></div>; break;
                          case 'showfaq_modal' : return <div><InactiveOverlay /><ShowFAQModal refreshParentCallback={this.refreshState} refreshFaqRegister={this.refreshFaqRegister} faq_id={this.state.faq_id} is_car={false}/></div>; break;
                          default: return <NoPermission>Sorry, you do not have permission to view this page. </NoPermission>;
                        }
                      }
                    })()
                  }   
                </div>
            </div>
        )
    }

}
export default GenericFaqregister