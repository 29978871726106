let filters = {
    question: {name: 'question', 'nickname': 'Query Text', type: 'text', options: {},selections: {}, size: 2},
    answer: {name: 'answer', 'nickname': 'Answer Text', type: 'text', options: {},selections: {}, size: 2},
    acknowledge: {name: 'acknowledge', 'nickname': 'Acknowledgement', type: 'text', options: {},selections: {}, size: 2},
    clarification_text: {name: 'clarification_text', 'nickname': 'Clarification Text', type: 'text', options: {},selections: {}, size: 2},
    tag: {name: 'tag', 'nickname': 'Tags', type: 'multi', options: {},selections: {}, size: 2},
    askedDate: {name: 'askedDate', 'nickname': 'Asked Date', type: 'date_range', options: {},selections: {}, size: 2},
    asked_month: {name: 'asked_month', 'nickname': 'Asked Month', type: 'multi', options: {},selections: {}, size: 2},
    company_name: {name: 'company_name', 'nickname': 'Company', type: 'multi', options: {},selections: {}, size: 2},
    topic_value: {name: 'topic_value', 'nickname': 'Topic', type: 'multi', options: {},selections: {}, size: 2}
}

module.exports = filters