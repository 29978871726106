import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import APICall from '../../Common/APICall.js';
import Utils from '../../Common/Utils.js';
import CSLTable from '../Common/CSLTable';
import AcceptanceModal from './AcceptanceModal';
import AskQuestionModal from '../Team/AskQuestionModal';
import CSLLoader from '../Common/CSLLoader';
import ShowClosecarModal from '../Modals/ShowClosecarModal';
import ShowAnsweredModal from '../Modals/ShowAnsweredModal';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import XcelLoader from '../../Common/XcelLoader.js';
import IndexLoader from '../../Common/IndexLoader.js';
import PrintPreview from '../Print/PrintPreview';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';


const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 3000px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1000;
  opacity: 0.6;
`;

const NoPermission = styled.div`
  display: block;
  box-sizing: border-box;
  height: 40px;
  font-size:20px;
  font-weight: 700;
  text-align: center;
  background-color: #ffffff;
  position: absolute;
  top: 100px;
  left: 10px;
  right:10px;
  bottom : 10px;
  border: 2px solid #37ada7;
  border-radius:10px;
  color: #37ada7;
  z-index: 1000;
`;

const SubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #223B5A;
    color: #ffffff;
    width: 160px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    padding-left: 10px;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #01B0A2;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    margin-right:3px;
    cursor:pointer;
`;


const IndexContainer = styled.div`
    padding-top: 8px;
    padding-left: 5px;
`;


class LinemgrIndex extends React.Component
{
    state = {
        isLoaded: null,
        pending_tasks: [],
        show_modal: '',
        overlay_height: null,
        view_count: 0,
        initial_response_time: 0,
        response_to_answer: 0,
        response_to_subsquent_question: 0,
        include_other: false,
        show_table_filter:{
          open_advice: true,
          pending_advice: true,
          pending_advice_approval: true,
          acceptence_advice: true,
          save_advice: true,
          reverted_advice: true,
          answer_advice: true,
          close_advice: true,
          faq_approval: true,
          faq_reapproval : true,
          faq_save: true,
          faq_publish: true,
          faq: true,
          faq_revert: true,
          faq_register: true,
          car_register: true
        },
        filters: {},
        applied_filters: {},
        task_loading: false,
        is_readonly: true,
        data: null
    };
    constructor(props) {
        super(props);
        
    }

    refreshCallback = () => {
        this.props.refreshCallback();
    }

    processSaveFilter = (result) => {
      Utils.log("acceptance_tasks", result)
      Store.updateStore('home_filter', JSON.parse(result));
    }

    setShowTable = (flag, table) =>{
      let filter = Store.getStoreData('home_filter');
      let show_table_filter = this.state.show_table_filter;
      show_table_filter[table.toString()] = flag;
      this.setState({show_table_filter: show_table_filter})
      filter.show_table_filter = show_table_filter;
      Utils.log("filter ===>", filter)
      let car_cached_data = JSON.parse(localStorage.getItem("car-index-select"))
      // Utils.log("filter ===>", car_cached_data)
      if('result' in car_cached_data && 'home_filter' in car_cached_data.result){
        car_cached_data.result.home_filter['show_table_filter'] = show_table_filter;
        localStorage.setItem("car-index-select", JSON.stringify(car_cached_data));
      }
      let postData = { "command": "car_registry_save", "filter":  filter};
      let api = new APICall();
      api.command(postData, this.processSaveFilter);
    }


    processAcceptancetasks = (result) => {
      Utils.log("acceptance_tasks", result)
      Store.updateStore('my_acceptance_tasks', result);
      this.setState({view_count: this.state.view_count + 1});
    }

    processSavequeries = (result) => {
      Utils.log("acceptance_tasks", result)
      Store.updateStore('my_saved_queries', result);
      this.setState({view_count: this.state.view_count + 1});
    }

    processOpentasks = (result) => {
      Utils.log("my_open_task", result)
      Store.updateStore('my_open_tasks', result);
      this.setState({view_count: this.state.view_count + 1});
    }

    processDataRefresh = () => {
      let home_filter = Store.getStoreData('home_filter');
      let show_table_filter = ("show_table_filter" in home_filter) ? home_filter.show_table_filter : this.state.show_table_filter;
      let moduleconfig = Store.getStoreData('moduleconfig');
      let initial_response_time = moduleconfig.response_to_initial_question;
      let response_to_answer = moduleconfig.response_to_answer;
      let response_to_subsquent_question = moduleconfig.response_to_subsquent_question;
      let include_other = moduleconfig.enabled_topics;
      
      this.setState({isLoaded: true, overlay_height: null, show_modal: '', initial_response_time: initial_response_time, response_to_answer:response_to_answer, show_table_filter, response_to_subsquent_question: response_to_subsquent_question,include_other:include_other });
    }

    refreshState = (stack=null) => {
      let tasks = Store.getStoreData('tasks')
      let role = Store.getStoreData('role')
      let contact_id = Store.getStoreData('contact_id')
      if(stack!==null){
        tasks[stack.task_id.toString()] = stack.task;
        Store.updateStore('tasks', tasks)
        IndexLoader.divideAllTasks(tasks, role, contact_id);
      }

      this.setState({view_count : this.state.view_count + 1, overlay_height: null, show_modal: ''});
      this.processDataRefresh()
    }

    closeModal = () => {
      Store.updateStore('cur_q_modal_data', null)
      this.setState({view_count : this.state.view_count + 1, overlay_height: null, show_modal: ''});
    }

    getDate = (input_date) => {
      if(input_date != null){
        let dateStr = input_date.split('T')[0];
        let dateParts = dateStr.split('-'); 
        let ret = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
        return ret;
      }else{
        return '';
      }
     
    }

    processAcceptanceQueueData = () => {
        let cardata = Store.getStoreData('my_acceptance_tasks') === null ? [] : Store.getStoreData('my_acceptance_tasks');
        const has_gc = Store.getStoreData('has_gc');
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, 
                     {Header: 'ID', accessor: 'ref_id', minWidth: 100, headerStyle: {textAlign: 'left'}},
                     {Header: 'Topic', accessor: 'topic', minWidth: 100, headerStyle: {textAlign: 'left'}, show: this.state.include_other},
                     {Header: 'Query', accessor: 'query', minWidth: 300, headerStyle: {textAlign: 'left'}}, 
                     {Header: 'Company', accessor: 'company_name', minWidth: 220, headerStyle: {textAlign: 'left'}, show: has_gc ? true : false}, 
                     {Header: 'Answered By', accessor: 'contact_name', width: 220, headerStyle: {textAlign: 'left'}}, 
                     {'Header' : 'Action', Cell: row => (
            <div>
                <MRButton onClick={() => this.showAcceptAnswerModal(row.original.id, row.original.company_id)}>View</MRButton>
            </div>
        ), width: 210, headerStyle: {textAlign: 'left'}}];

        let gc_assoc = Store.getStoreData('gc_assoc')
        let gc_company = "";
        for(let d of cardata) {
          i++;
          gc_company = parseInt(d.company_id) < 100000 ? Store.getStoreData('company').CompanyName : gc_assoc[(parseInt(d.company_id)%100000).toString()].company_name;
          let elem = {'index' : i, 
                    'ref_id': d.ref_id,
                    'topic': d.topic,
                    'query' : d.query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'"),
                    'company_name' : gc_company,
                    'contact_name' : d.answered_by === 0 ? 'N/A' : Store.getStoreData('contacts')[d.answered_by],
                    'id' : d.id,
                    'company_id' : d.company_id
                   };
          ret.data.push(elem);
        }
        return ret;      
    }

    processSavedTasksData = () => {
        const has_gc = Store.getStoreData('has_gc');
        let cardata = Store.getStoreData('my_saved_queries') === null ? [] : Store.getStoreData('my_saved_queries');
        // console.log("cardata", cardata);
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}},
                     {Header: 'ID', accessor: 'ref_id', minWidth: 100, headerStyle: {textAlign: 'left'}}, 
                     {Header: 'Topic', accessor: 'topic', minWidth: 100, headerStyle: {textAlign: 'left'}, show: this.state.include_other},
                     {Header: 'Query', accessor: 'query', minWidth: 300, headerStyle: {textAlign: 'left'}}, 
                     {Header: 'Company', accessor: 'company_name', minWidth: 220, headerStyle: {textAlign: 'left'}, show: has_gc ? true : false}, 
                      {'Header' : 'Action', Cell: row => (
            <div>
                <MRButton onClick={() => this.showAskQuestionModal(row.original.id, row.original.company_id)}>Modify</MRButton>
            </div>
        ), width: 210, headerStyle: {textAlign: 'left'}}];


        let gc_assoc = Store.getStoreData('gc_assoc')
        let gc_company = "";
        for(let d of cardata) {
          i++;
          gc_company = parseInt(d.company_id) < 100000 ? Store.getStoreData('company').CompanyName : gc_assoc[(parseInt(d.company_id)%100000).toString()].company_name;

          let elem = {'index' : i, 
                  'ref_id': d.ref_id,
                  'topic' : d.topic,
                  'query' : d.query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'"),
                  'company_name' : gc_company,
                  'id' : d.id,
                  'company_id': d.company_id
                 };
          ret.data.push(elem);
        }
        return ret;
    }

    showSaveAnswerModal(query_id, company_id){
         let api = new APICall();
         let postData = {command: 'get_newtask', task_id: query_id, company_id: company_id };
         // postData['gcs'] = Store.getStoreData('gc_filters');
         api.indexCommand(postData, this.processsaveAnswerModalData);
         this.setState({task_loading: true})  
     }

     processsaveAnswerModalData = (result) => {
       Store.updateStore('cur_accept_modal_data', result.result.parenttask);
       this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'save_answer_modal', task_loading: false});
     }

    processOpenTasksData = () => {
        const has_gc = Store.getStoreData('has_gc');
        let cardata = Store.getStoreData('my_open_tasks') === null ? [] : Store.getStoreData('my_open_tasks');
        let ret = {data: [], columns: []};
        let i = 0;

        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, 
                   {Header: 'ID', accessor: 'ref_id', minWidth: 100, headerStyle: {textAlign: 'left'}}, 
                   {Header: 'Topic', accessor: 'topic', minWidth: 100, headerStyle: {textAlign: 'left'}, show: this.state.include_other},
                   {Header: 'Query', accessor: 'query', minWidth: 300, headerStyle: {textAlign: 'left'}}, 
                   {Header: 'Company', accessor: 'company_name', minWidth: 220, headerStyle: {textAlign: 'left'}, show: has_gc ? true : false},  
                   {Header: 'Assigned To', accessor: 'assigned_to', width: 220, headerStyle: {textAlign: 'left'}}, 
                   {Header: 'Due Date', accessor: 'due_date', minWidth: 220, headerStyle: {textAlign: 'left'}, sortMethod: (a, b) => {
                       if (a === b) {
                           return 0;
                       }
                       let adue = a.split("/");
                       const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                       let  bbdue = b.split("/");
                       const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                       return aReverse > bReverse ? 1 : -1;
                   }},
                     {'Header' : 'Status',  Cell: row => (
          <div>
              <MRButton style={{backgroundColor : `${row.original.status.color}`, width: '120px', cursor: 'pointer'}} onClick={() => this.showSaveAnswerModal(row.original.id, row.original.company_id)}>{row.original.status.text}</MRButton>
          </div>
        ), width: 210, headerStyle: {textAlign: 'left'}}];

        let gc_assoc = Store.getStoreData('gc_assoc');
        let gc_company = "";
        for(let d of cardata) {
          gc_company = parseInt(d.company_id) < 100000 ? Store.getStoreData('company').CompanyName : gc_assoc[(parseInt(d.company_id)%100000).toString()].company_name;
            i++;
            let dateStr = this.getDate(d.due_date);
            let assigned_to = d.cur_assigned_to === 0 ? "Compliance Advisers" : Store.getStoreData('contacts')[d.cur_assigned_to];
            let org_teams = Store.getStoreData('org_teams');
            if(d.cur_recever_id!==null && d.cur_assigned_to === 0 && isNaN(d.cur_recever_id) && Object.keys(org_teams).length > 0) {
              // assigned_to = d.cur_recever_id.toString() === "0" ? "Compliance Advisers" : org_teams[d.cur_recever_id.toString()].name;
              if(d.cur_recever_id in org_teams){
                assigned_to = org_teams[d.cur_recever_id.toString()].name;
              }
            }

            let status = {color: '#55a233', text: 'Pending'};
            switch(d.cur_lane) {
              case 'COMP_CAR_ANSWER': status = {color: '#55a233', text: 'Pending'}; break;
              case 'COMP_CAR_ANSWER_SAVED': status = {color: '#11E311', text: 'In Progress'}; break;
            }
            let elem = {'index' : i, 
                    'ref_id': d.ref_id,
                    'topic' : d.topic,
                    'query' : d.query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'"),
                    'company_name' : gc_company,
                    'assigned_to' :   assigned_to,
                    'due_date' :   dateStr,
                    'status': status,
                    'id': d.id,
                    'company_id': d.company_id

                   };
            ret.data.push(elem);
            ret.data = ret.data.sort((a,b) => {
              if (a.due_date === b.due_date) {
                return 0;
              }
              let adue = a.due_date.split("/");
              const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
              // console.log("parseInt(adue[0]) ==>", aReverse, a.due_date);
              let  bbdue = b.due_date.split("/");
              const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
              return aReverse < bReverse ? 1 : -1;
            });
        }
        return ret;
    }

    processClosedTasksData = () => {
       const has_gc = Store.getStoreData('has_gc');
       let cardata = Store.getStoreData('cmpleted_tsks') === null ? [] : Store.getStoreData('cmpleted_tsks');
       let ret = {data: [], columns: []};
       let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, 
                    {Header: 'ID', accessor: 'ref_id', minWidth: 100, headerStyle: {textAlign: 'left'}},
                    {Header: 'Topic', accessor: 'topic', minWidth: 100, headerStyle: {textAlign: 'left'}, show: this.state.include_other},
                    {Header: 'Query', accessor: 'query', minWidth: 300, headerStyle: {textAlign: 'left'}}, 
                    {Header: 'Company', accessor: 'company_name', minWidth: 300, headerStyle: {textAlign: 'left'}, show: has_gc ? true : false}, 
                        {Header: 'Authored By', accessor: 'contact_name', width: 220, headerStyle: {textAlign: 'left'}}, 
                      {'Header' : 'Action', Cell: row => (
           <div>
               <MRButton onClick={() => this.showCloseAnswerModal(row.original.id, row.original.company_id)}>View</MRButton>
           </div>
       ), width: 210, headerStyle: {textAlign: 'left'}}];

       let gc_assoc = Store.getStoreData('gc_assoc');
       let gc_company = "";
       for(let d of cardata) {
            console.log("d.company_id", d.company_id)
            gc_company = parseInt(d.company_id) < 100000 ? Store.getStoreData('company').CompanyName : gc_assoc[(parseInt(d.company_id)%100000).toString()].company_name;
            i++;
            let elem = {'index' : i, 
                   'ref_id': d.ref_id,
                   'topic' : d.topic,
                   'query' : d.query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'"),
                   'company_name' : gc_company,
                   'contact_name' : d.contact_id === 0 ? 'N/A' : Store.getStoreData('contacts')[d.contact_id],
                   'id': d.id,
                   'company_id': d.company_id
                  };
            ret.data.push(elem);
       }
       return ret;
    }

    processGetTaskData = (result) => {
      
      Store.updateStore('cur_q_modal_data', result.result.parenttask);
      let c = Store.getStoreData('loggedincontact')
      let is_readonly = result.result.parenttask['contact_id'] === c.contact_id ? false : true;
      console.log('task result', result.result.parenttask['contact_id'], c.contact_id, is_readonly);
      this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'ask_modal', task_loading: false, is_readonly: is_readonly});
    }
    processAcceptanceTaskData = (result) => {
      console.log('task result acceptance', result);
      Store.updateStore('cur_accept_modal_data', result.result.parenttask);
      let c = Store.getStoreData('loggedincontact')
      let is_readonly = result.result.parenttask['contact_id'] === c.contact_id ? false : true;
      console.log('task result', result.result.parenttask['contact_id'], c.contact_id, is_readonly);
      this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'accept_modal', task_loading: false, is_readonly: is_readonly});
    }

    showAcceptAnswerModal(id, company_id){
        let postData = { "command" : "get_newtask", "task_id" : id, company_id: company_id }
        // postData['gcs'] = Store.getStoreData('gc_filters');
        postData['mode'] = "acceptance";
        let api = new APICall();
        api.indexCommand(postData, this.processAcceptanceTaskData);
        this.setState({task_loading: true})
    }


    showAskQuestionModal(id, company_id){
        if(id > 0) {
          let postData = { "command" : "get_newtask", "task_id" : id, company_id: company_id }
          // postData['gcs'] = Store.getStoreData('gc_filters');
          let api = new APICall();
          api.indexCommand(postData, this.processGetTaskData);
          this.setState({task_loading: true})
        } else {
          this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'ask_modal'});
        }
    }
    showCloseAnswerModal(query_id, company_id){
         let postData = { "command" : "get_newtask", "task_id" : query_id, company_id: company_id }
         // postData['gcs'] = Store.getStoreData('gc_filters');
         let api = new APICall();
         api.indexCommand(postData, this.processClosedTaskData);
         this.setState({task_loading: true})
     }

     processClosedTaskData = (result) => {
       Store.updateStore('cur_question_answer_modal_data', result.result.parenttask);
       this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'question_answer_modal', task_loading: false});
     }
    componentDidMount(){
        this.processDataRefresh();
    }
    componentDidUpdate(prevProps)
    {
        if (prevProps !== this.props) {
            this.processDataRefresh()
        }
    }


    applyFilter = (filters) => {
      let applied_filters = JSON.parse(JSON.stringify(filters))
      console.log("applied_filters", applied_filters)
      this.setState({applied_filters})
    }

    exportToExcel = () => {
      let fileName = "team";
      let wb = {SheetNames:[], Sheets:{}};
      let acceptance_tasks = Store.getStoreData('my_acceptance_tasks') === null ? [] : Store.getStoreData('my_acceptance_tasks');
      let open_tasks = Store.getStoreData('my_open_tasks') === null ? [] : Store.getStoreData('my_open_tasks');
      let save_tasks = Store.getStoreData('my_saved_queries') === null ? [] : Store.getStoreData('my_saved_queries');
      let closed_tasks = Store.getStoreData('cmpleted_tsks') === null ? [] : Store.getStoreData('cmpleted_tsks');


      wb.SheetNames.push("Acceptance Queue"); wb.Sheets["Acceptance Queue"] = XLSX.utils.json_to_sheet(XcelLoader.car_export_tasks(acceptance_tasks, true)); 

      wb.SheetNames.push("Open Advice Requests"); wb.Sheets["Open Advice Requests"] = XLSX.utils.json_to_sheet(XcelLoader.car_export_tasks(open_tasks));

      wb.SheetNames.push("Saved Advice Requests"); wb.Sheets["Saved Advice Requests"] = XLSX.utils.json_to_sheet(XcelLoader.car_export_tasks(save_tasks, false, true, true));
      
      wb.SheetNames.push("Closed Advice Requests"); wb.Sheets["Closed Advice Requests"] = XLSX.utils.json_to_sheet(XcelLoader.car_export_tasks(closed_tasks,false, false));
      

      const format = "DD-MM-YYYY HH:mm:ss"
      let tm = moment().format(format);

      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], {type: fileType});
      FileSaver.saveAs(data, fileName + '-' + tm + fileExtension);

    }

    openPdfPrintView = (result) => {
      this.setState({show_modal: 'pdfprintview', data: result});
    };

    render()
    {
      if(!this.state.isLoaded) {
          return <div>Loading....</div>
      }
        return (
            <IndexContainer>
                <SubmitButton onClick={() => this.showAskQuestionModal(0, 0)} id="create_advice_request">Submit Advice Request</SubmitButton>
                <SubmitButton onClick={() => this.exportToExcel()} id="export_excel_request" style={{float: 'right', marginRight: '10px', width:"100px"}}>Export</SubmitButton>
                
                <div  style={{position: 'relative'}}>
                  <CSLTable add={false} processData={this.processAcceptanceQueueData} headerText={'My Acceptance Queue'} refreshCallback={this.refreshCallback} tableRows="5" setShowTable={this.setShowTable} show_filter={this.state.show_table_filter.acceptence_advice} table="acceptence_advice"/>
                </div>
                <div  style={{position: 'relative'}}>
                  <div style={{clear: "both", height: '3px'}}></div>
                  <CSLTable add={false} processData={this.processSavedTasksData} headerText={'My Saved Advice Requests'} refreshCallback={this.refreshCallback} tableRows="5" setShowTable={this.setShowTable} show_filter={this.state.show_table_filter.save_advice} table="save_advice"/>
                </div>
                <div  style={{position: 'relative'}}>

                  <div style={{clear: "both", height: '3px'}}></div>
                  <CSLTable add={false} processData={this.processOpenTasksData} headerText={'My Open Advice Requests'} refreshCallback={this.refreshCallback} tableRows="5" setShowTable={this.setShowTable} show_filter={this.state.show_table_filter.open_advice} table="open_advice"/>
                </div>
                <div  style={{position: 'relative'}}>
                  <div style={{clear: "both", height: '3px'}}></div>
                  <CSLTable add={false} processData={this.processClosedTasksData} headerText={'Closed Advice Requests'} refreshCallback={this.refreshCallback} tableRows="5" setShowTable={this.setShowTable} show_filter={this.state.show_table_filter.close_advice} table="close_advice"/>
                </div>
                  {
                    (() => {
                      if (this.state.overlay_height !== null) {
                        switch(this.state.show_modal) {
                          case 'ask_modal' : return <div><InactiveOverlay /><AskQuestionModal refreshCallback={this.props.refreshCallback} refreshParentCallback={this.refreshState} closeModal={this.closeModal} is_readonly={this.state.is_readonly}  /></div>; break;
                          case 'accept_modal' : return <div><InactiveOverlay /><AcceptanceModal refreshCallback={this.props.refreshCallback} refreshParentCallback={this.refreshState} closeModal={this.closeModal} is_readonly={this.state.is_readonly}/></div>; break;
                          case 'question_answer_modal' : return <div style={{left:'10%', top:'50px', overflow: "auto"}}><InactiveOverlay /><ShowClosecarModal refreshCallback={this.props.refreshCallback} refreshParentCallback={this.refreshState} closeModal={this.closeModal} is_readonly={this.state.is_readonly} openPrintView={this.openPdfPrintView}/></div>; break;
                          case 'save_answer_modal' : return <div><InactiveOverlay /><ShowAnsweredModal refreshCallback={this.props.refreshCallback} refreshParentCallback={this.refreshState} closeModal={this.closeModal} is_readonly={this.state.is_readonly}/></div>; break;
                          case 'pdfprintview': return <div style={{left:'10%', top:'50px', overflow: "auto"}}><PrintPreview closeModal={this.closeModal} data={this.state.data} /></div>; break;
                          default: return <NoPermission>Sorry, you do not have permission to view this page. </NoPermission>;
                        }
                      }
                    })()
                  }
                  {
                    (() => {
                      if(this.state.task_loading) {
                        return <CSLLoader/>
                      }
                    })()
                  }

            </IndexContainer>
        );
    }
}
export default LinemgrIndex;