import React from 'react'
import styled from 'styled-components'
import {FaPlus} from 'react-icons/fa'
import {IoIosArrowUp, IoIosArrowDown} from 'react-icons/io';
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import Pagination from "./Pagination";
import Store from '../../Common/Store';

import "./table_styles.css";

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 95px;
    font-size: 13px;
    height: 25px;
    border-radius: 0px;
    margin-right:1px;
`;
const HeaderContent = styled.div`
    padding: 7px;
    margin-top: 7px;
    background-color: #1d3d61;
   
`;
const HeaderText = styled.div`
    float:left;
    font-weight: 600;
    letter-spacing: 1px;
    padding-top: 3px;
    color: #fff;
`;
const HeaderTextRight = styled.div`
    float:right;
    color: #fff;
    font-weight: 600;
    letter-spacing: 1px;
    padding-top: 7px;
`;
const TableHeaderIcon = styled.div`
    float: right;
    margin-right: 20px;
    margin-top: 15px;
    color: #c0c0c0;
`;

const TextPara = styled.div`
    padding: 15px 15px 15px 15px;
    color: #c0c0c0;
`
const TableContainer = styled.div`
    background-color: #ffffff;
    width:100%
`
const RadioBlock = styled.div`
    float:right;
    margin-top: -5px;
`;
const SelectedRadio = styled.div`
    background-color: #37ADA7;
    border: 1px solid #1f615e;
    padding: 5px 10px;
    color: #ffffff;
    display: inline-block;
    cursor: pointer;
`;
const UnselectedRadio = styled.div`
    background-color: #F4F4F4;
    border: 1px solid #8c8c8c;
    padding: 5px 10px;
    color: #262626;
    display: inline-block;
    cursor: pointer;
`;
const FilterChevron = styled.div`
    float: right;
    font-size: 16px;
    cursor: pointer;
    color: #ffffff;
`;
const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213E61;
    color: #F2F2F2;
    border: 1px solid #213E61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin: 12px;
    cursor: pointer;
`;
class CSLTablemodule extends React.Component
{
    state = {
        only_assign_me:false
    }
    constructor(props) {
        super(props);
    }

    filterMethod = (filter, row, column) => {
        const id = filter.pivotId || filter.id
        console.log('id',id);
        return row[id] !== undefined ? String(row[id]).includes(filter.value) : true
    }

    switchRadio = (event) => {
        event.preventDefault();
        let only_assign_me = this.props.only_assign_me ? false : true;
        this.props.showOnlyAssign(only_assign_me);
    }

    exportToCSV = () => (event) => {
        event.preventDefault();
        let fileName = "export";
        if('export_file_name' in this.props) {
            fileName = this.props.export_file_name;
        }
        let tableData = this.props.processData();
        let headerCSV = this.props.prefered_columns;
        // let headerCSV = Store.getStoreData('prefered_columns');
        console.log("headerCSV",headerCSV)
        console.log("tableData",tableData)
        let data= tableData.data;
        let columns= tableData.columns;
        let csv_data_arr = [];
        
        csv_data_arr.unshift(headerCSV);
        for(let k = 0; k < data.length; k++) {
            let cont = {};

            Object.entries(headerCSV).forEach(([key, value]) => {
                console.log("data[k][key]",data[k][key])
                    cont[key] = data[k][key].replace(/,/g, ' ').replace(/--/g, ' ').replace(/[\r\n]+/g," ").replace(/&nbsp;/g, '').replace(/#/g, '').replace(/'/g,"'").replace(/‘/g, "'").replace(/’/g, "'");
            })            
            csv_data_arr.push(cont);
        }
        console.log("csv_data_arr",csv_data_arr) 
        var csv_data_final_arr = [];
        for(let j = 0; j<  csv_data_arr.length; j++){
          var container = [];
          container = Object.values(csv_data_arr[j])
          if(container.length > 0); 
          csv_data_final_arr.push(container)
        }
        console.log("csv_data_final_arr",csv_data_final_arr)
        let csvContent = "data:text/csv;charset=utf-8," + csv_data_final_arr.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", fileName + ".csv");
        document.body.appendChild(link); 
        link.click();
    
    }



	render() {
		let tableData = this.props.processData();
        let row_count = this.props.tableRows === null ? 10 : this.props.tableRows;
		return (
			<TableContainer>
                <HeaderContent>
    			    <HeaderText>{this.props.headerText}</HeaderText>  
                    {
                        (() => {
                            if(this.props.showHF) {
                                return(<div style={{marginRight:"20px"}}><HeaderTextRight>{this.props.HeaderFilter} &nbsp;&nbsp;
                                {
                                    (() => {
                                        if (this.props.only_assign_me) {
                                            return(
                                                    <RadioBlock>
                                                        <SelectedRadio >Yes</SelectedRadio>
                                                        <UnselectedRadio onClick={this.switchRadio}>No</UnselectedRadio>
                                                    </RadioBlock>
                                                    )
                                        }else {
                                            return(<RadioBlock>
                                                <UnselectedRadio onClick={this.switchRadio}>Yes</UnselectedRadio>
                                                <SelectedRadio>No</SelectedRadio>
                                            </RadioBlock>)
                                        }
                                    })()
                                }

                                    </HeaderTextRight></div>)
                            }
                        })()
                    }

                <div style={{clear: "both"}}></div>
                </HeaderContent>                  
                {
                    (() => {
                        if (this.props.add) {
                            return (<TableHeaderIcon><FaPlus style={{cursor: "pointer"}} onClick={this.showAddAskModal} title={this.props.addTitle} /></TableHeaderIcon>);
                        }
                    })()
                } 

                <div style={{marginTop: '5px', width:'100%'}}>
                    <ReactTable
                        PaginationComponent={Pagination}
                        data={tableData.data}
                        columns={tableData.columns}
                        defaultPageSize={parseInt(row_count)}
                        filterable={true}
                        defaultFilterMethod={(filter, row, column) => {
                            const id = filter.pivotId || filter.id
                            console.log('id ===>',id, filter.value);
                            return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
                        }}
                    />
                </div>   

                {
                    (() => {
                        if ('export' in this.props && this.props.export === true) {
                            return (<ExportButton  onClick={this.exportToCSV()}>Export</ExportButton>);                                                        
                        }
                    })()
                }                

			</TableContainer>
			);
	}
}

export default CSLTablemodule;