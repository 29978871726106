import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store'
import { FaHome, FaCog, FaThLarge, FaBook, FaQuora, FaWrench } from "react-icons/fa";
import { TiArrowBack } from "react-icons/ti";
import * as Style from '../Common/StyledComponents';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouseBlank} from "@fortawesome/pro-thin-svg-icons";
/*global localStorage*/

const HeadContainer = styled.div`
    display: block;
    width: 100%;
    height: 35px;
    background-color: #ffffff;
    padding-left: 10px;
    padding-right: 10px;
`;
const HomeLogo = styled.div`
    float: left;
    margin-top: 7px;
    font-size: 16px;
    color: #282828;
`;
const HomeText = styled.div`
    float: left;
    margin-top: 7px;
    color: #282828;
    margin-left: 10px;
    font-size: 13px;
`;
const Categories = styled.div`
    float: right;
    font-size: 17px;
    background-color: #ebebeb;
    color: #c4c4c4;
    width: 25px;
    height: 25px;
    margin-left: 5px;
    margin-top: 4px;
    padding-left: 4px;
    padding-top: 3px;
    border-radius: 2px;
`;

class FaqHeader extends React.Component
{
	redirectTo = (location) => (event) => {
		event.preventDefault();
		let url = "";
		switch(location) {
			case "home":
				url =  "/"
				break;
			case "faqregister":
				url = "/faqregister"
				break;
			case "register":
				url = "/register"
				break;
			case "moduleconfig":
				url = "/moduleconfig"
				break;
			default:
				url = "/"
		}
		window.location.assign(url);
	}

	render()
	{
		let use_site_layout = false
		if(localStorage.getItem('site_layout')!==null){
		  let site_layout = JSON.parse(localStorage.getItem('site_layout'));
		  use_site_layout = site_layout.result.use_site_layout;
		}
		if(use_site_layout) return (<div></div>)
		return (
			<HeadContainer style={{boxSizing:"border-box"}}>
			    <HomeLogo style={{cursor: "pointer"}} onClick={this.redirectTo('home')}><FontAwesomeIcon icon={faHouseBlank} /></HomeLogo>
			    <HomeText style={{cursor: "pointer"}} onClick={this.redirectTo('home')}><span style={{fontWeight: "600"}}>Compliance Advice Register</span></HomeText>
			    
			    {
			        (() => {
			            if(Store.getStoreData('is_debug')){
			                return(<div>
			                	<Categories style={{cursor: "pointer"}} title="CAR Register" onClick={this.redirectTo('register')}><FaBook style={{color: "#337ab7"}} /></Categories>
			                    <Categories style={{cursor: "pointer"}} title="Module Config" onClick={this.redirectTo('moduleconfig')}><FaWrench style={{color: "#337ab7"}}/></Categories>
			                    <Categories style={{cursor: "pointer"}} title="FAQ Register" onClick={this.redirectTo('faqregister')}><FaQuora style={{color: "#337ab7"}} /></Categories>
			                    <Categories style={{cursor: "pointer"}} onClick={this.redirectTo('home')}><TiArrowBack style={{color: "#337ab7"}}/></Categories>
			                </div>)
			            }
			        })()
			    }
			    
			    <div style={{clear: "both"}}></div>
			</HeadContainer>
		);
	}
}

export default FaqHeader;