import React from 'react';
import styled from 'styled-components';
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import wNumb from 'wnumb';

const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const GeneralContainer = styled.div`
	width: 90%;
`;
const GeneralInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 20px;
`;
const GeneralLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 20px;
`;
const GeneralsubLabel = styled.div`
	font-weight: 400;
	color: #595959;
	margin-top: 20px;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;
const ResponseDropdown = styled.select`
	box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    color: #b5b5b5;
`;

const DropdownOptions = styled.textarea`
	border: 1px solid rgb(255, 255, 255);
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(196 196 196) 0px 0px 4px;
    resize: none;
    padding: 5px;
    width: 98%;
    font-family: Montserrat, sans-serif;
    margin-top: 7px;
`;
const SubHeading = styled.div`
	font-style: italic;
	font-size: 12px;
	color: #adacac;
	margin-top: 5px;
`;

class Extprovides extends React.Component
{
	state = {general: null, dropdown_options:""};

	componentDidMount()
	{
		let dropdown_options = "";
		if (this.props.general.externla_provides.length !== 0) {
			this.props.general.externla_provides.forEach((item, index) => {
				if (index !== (this.props.general.externla_provides.length - 1)) {
					dropdown_options = dropdown_options + item + "\n";
				} else {
					dropdown_options = dropdown_options + item;
				}
			})
		}
		this.setState({general: this.props.general,dropdown_options: dropdown_options});
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			let dropdown_options = "";
			if (this.props.general.externla_provides.length !== 0) {
				this.props.general.externla_provides.forEach((item, index) => {
					if (index !== (this.props.general.externla_provides.length - 1)) {
						dropdown_options = dropdown_options + item + "\n";
					} else {
						dropdown_options = dropdown_options + item;
					}
				})
			}
			this.setState({general: this.props.general, dropdown_options: dropdown_options});
		}
	}

	switchRadio = (key) => (event) => {
		event.preventDefault();
		let {general} = this.state;
		general[key] = general[key] ? false : true;
		this.props.updateGeneral(general);
	}

	modifyDropdownOptions = (event) => {
		event.preventDefault();
		let {general} = this.state;
		const trimedvalue = event.target.value;
		let dropdown_options = "";
		let optarr = trimedvalue.split("\n");
		if (optarr.length === 1 && optarr[0] === "") {
			optarr = [];
		}
		general.externla_provides = optarr;


		if (optarr.length !== 0) {
			optarr.forEach((item, index) => {
				if (index !== (optarr.length - 1)) {
					dropdown_options = dropdown_options + item + "\n";
				} else {
					dropdown_options = dropdown_options + item;
				}
			})
		}
		
		console.log("optarr",dropdown_options)
		this.setState({dropdown_options: dropdown_options});
		this.props.updateGeneral(general);
	}


	render()
	{
		if (this.state.general === null) {
			return (<div>Loading...</div>);
		}
		return (
			<GeneralContainer>
							<GeneralInnerContainer>
								<GeneralLabel>Enable `External Advice` providers dropdown?</GeneralLabel>
								{
									(() => {
										if (this.state.general.externla_provides_enabled) {
											return (
												<RadioBlock>
													<SelectedRadio>Yes</SelectedRadio>
													<UnselectedRadio onClick={this.switchRadio('externla_provides_enabled')}>No</UnselectedRadio>
												</RadioBlock>
											);
										} else {
											return (
												<RadioBlock>
													<UnselectedRadio onClick={this.switchRadio('externla_provides_enabled')}>Yes</UnselectedRadio>
													<SelectedRadio>No</SelectedRadio>
												</RadioBlock>
											);
										}
									})()
								}
							</GeneralInnerContainer>
							
							{
								(() => {
									if (this.state.general.externla_provides_enabled) {
										return (
											<GeneralInnerContainer>
												<SubHeading>Configure the dropdown options. New line separates options.</SubHeading>
												<DropdownOptions rows="7" value={this.state.dropdown_options} onChange={this.modifyDropdownOptions}/>
												
											</GeneralInnerContainer>
											);
									}
								})()
							}
							
			</GeneralContainer>
		);
	}
}

export default Extprovides;