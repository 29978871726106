import Utils from '../../Common/Utils.js';
import Store from '../../Common/Store.js';
const completeMap = {COMPLETE: 1, COMPLETED: 1, DISMISSED : 1, REJECTED: 1, DISSMISS: 1, REJECT: 1, ANSWER: 1,TEAMSAVE: 1, CLARIFICATION: 1, ACCEPTANCE: 1, SAVED: 1, CLARIFIED: 1, PENDING: 1}
class RegisterHelper {
	getRegisterTask(){
		let tasks = Store.getStoreData('tasks');
		let role = Store.getStoreData('role')
		let contact_id = Store.getStoreData('contact_id')
		let drs = Store.getStoreData('drs')
		// console.log("drs", tasks[t].individuls[contact_id])
		let task_result = {}; 
		task_result.rows = {};
		for(let t in tasks) {
			if(!tasks[t].individuls){
				tasks[t].individuls = {}
			}
		    if ((tasks[t].cur_lane === "COMP_CAR_TEAMSAVE" || tasks[t].cur_lane === "COMP_CAR_ANSWER_SAVED") && 
		    	(parseInt(tasks[t].cur_assigned_to) === parseInt(contact_id) || drs.hasOwnProperty(tasks[t].contact_id) || tasks[t].individuls[contact_id])) {
		        if(!(tasks[t].id in task_result.rows)){
		            let filter =  this.getFilter(tasks[t]);
		            task_result.rows[tasks[t].id] = {row: tasks[t], filter: filter}
		        }   
		    }
		    if((tasks[t].cur_lane === "COMP_CAR_ANSWER" || tasks[t].cur_lane === "COMP_CAR_ANSWER_SAVED" || tasks[t].cur_lane === "COMP_CAR_USER_CLARIFICATION") && (tasks[t].contact_id === parseInt(contact_id) || drs.hasOwnProperty(tasks[t].contact_id) || role === "ca_module_admin" || role === "admin_manager")) {
		        if(!(tasks[t].id in task_result.rows)){
		            let filter = this.getFilter(tasks[t]);
		            task_result.rows[tasks[t].id] = {row: tasks[t], filter: filter}
		        }  
		    }
		    if(tasks[t].cur_lane === "COMP_CAR_USER_ACCEPTANCE" && (tasks[t].contact_id === parseInt(contact_id)  || drs.hasOwnProperty(tasks[t].contact_id) ||  role === "ca_module_admin" || role === "admin_manager")){
		        if(!(tasks[t].id in task_result.rows)){
		            let filter = this.getFilter(tasks[t]);
		            task_result.rows[tasks[t].id] = {row: tasks[t], filter: filter}
		        }  
		    }
		    if(tasks[t].cur_lane === "COMP_CAR_USER_CLARIFICATION" && (parseInt(tasks[t].cur_assigned_to) ===parseInt(contact_id) || drs.hasOwnProperty(tasks[t].contact_id) || role === "ca_module_admin" || role === "admin_manager")) {
		        if(!(tasks[t].id in task_result.rows)){
		            let filter = this.getFilter(tasks[t]);
		            task_result.rows[tasks[t].id] = {row: tasks[t], filter: filter}
		        }  
		    }
		    if((tasks[t].cur_lane === "COMP_CAR_ANSWER" || tasks[t].cur_lane === "COMP_CAR_ANSWER_REVERT") && (tasks[t].cur_assigned_to === 0 || tasks[t].cur_assigned_to === parseInt(contact_id) || drs.hasOwnProperty(tasks[t].contact_id) || role === "ca_module_admin" || role === "admin_manager")) {
		        if(!(tasks[t].id in task_result.rows)){
		            let filter = this.getFilter(tasks[t]);
		            task_result.rows[tasks[t].id] = {row: tasks[t], filter: filter}
		        }  
		    }
		    if(tasks[t].cur_lane === "COMP_CAR_USER_COMPLETED") {
		       if(!(tasks[t].id in task_result.rows)){
		           let filter = this.getFilter(tasks[t]);
		           task_result.rows[tasks[t].id] = {row: tasks[t], filter: filter}
		       }  
		    }
		    if(tasks[t].cur_lane === "COMP_CAR_USER_ACCEPTANCE_PENDING" && (tasks[t].cur_assigned_to === parseInt(contact_id) || drs.hasOwnProperty(tasks[t].contact_id) || tasks[t].cur_assigned_to === 0 || role === "ca_module_admin" || role === "admin_manager")) {
		        if(!(tasks[t].id in task_result.rows)){
		            let filter = this.getFilter(tasks[t]);
		            task_result.rows[tasks[t].id] = {row: tasks[t], filter: filter}
		        }  
		    }
		    if(tasks[t].cur_lane === "COMP_CAR_USER_ACCEPTANCE_PENDING" && (tasks[t].contact_id === parseInt(contact_id) || drs.hasOwnProperty(tasks[t].contact_id) || tasks[t].answered_by === parseInt(contact_id) || role === "ca_module_admin" || role === "admin_manager")) {
		        if(!(tasks[t].id in task_result.rows)){
		            let filter = this.getFilter(tasks[t]);
		            task_result.rows[tasks[t].id] = {row: tasks[t], filter: filter}
		        }  
		    }
		}
		return task_result;
	}

	getFilter(data){   
	    var arr_fil = {Saved:0, All: 1,  Closed: 0, Requested:0, Inprogress: 0, Answered: 0, AwaitingClarification: 0, ClarificationProvided: 0, awaitingApproval: 0}
	    var status = this.getStatus(data);   
	    if(status == 'Closed')
	    {
	        arr_fil['Closed'] = 1;
	    }
	    else if(status == 'Requested')
	    {
	        arr_fil['Requested'] = 1;
	    }
	    else if(status == 'Saved')
	    {
	        arr_fil['Saved'] = 1;
	    }
	    else if(status == 'Answered')
	    {
	        arr_fil['Answered'] = 1;
	    }
	    else if(status == 'Clarification Provided')
	    {
	        arr_fil['ClarificationProvided'] = 1;
	    }
	    else if(status == 'Awaiting Clarification')
	    {
	        arr_fil['AwaitingClarification'] = 1;
	    }
	    else if(status == 'Awaiting Approval')
	    {
	        arr_fil['awaitingApproval'] = 1;
	    }
	    else if(status == 'Inprogress')
	    {
	        arr_fil['Inprogress'] = 1;
	    }        
	    return arr_fil;
	}

	getStatus(row) {
	    let lane_parts = row.cur_lane.split('_');
	    let last_action = row.last_action.split('_');
	    let action_end = last_action[last_action.length - 1]
	    let lane_end = lane_parts[lane_parts.length - 1]
	    let status = 'Requested';
	    if(lane_end in completeMap)
	    {
	        if(lane_end == 'ANSWER')
	        {
	            status = 'Requested';
	        } 
	        else if(lane_end == 'TEAMSAVE')
	        {
	            status = 'Saved';
	        }
	        else if(lane_end == 'SAVED')
	        {
	            status = 'Inprogress';
	        }
	        else if(lane_end == 'ACCEPTANCE')
	        { 
	            if(action_end == 'CLARIFIED')
	            { 
	                status = 'Clarification Provided';
	            }
	            else if(action_end == 'ANSWER' || action_end == 'APPROVED')
	            {
	              status = 'Answered';
	            }
	           
	        }
	        else if(lane_end == 'CLARIFICATION')
	        {
	            status = 'Awaiting Clarification';
	        }
	        else if(lane_end == 'PENDING') {
	            status = 'Awaiting Approval';
	        }
	        else
	        {
	            status = 'Closed';
	        }
	    } 
	
	    return status; 
	}
}

export default new RegisterHelper();