import React from 'react';
import Store from '../../Common/Store.js';
import Utils from '../../Common/Utils.js';
import * as Style from '../Common/StyledComponents'

class Originalanswer extends React.Component
{
	render() {
		// console.log('this.props.clarification', this.props.clarification)
		let c = this.props.clarification
		// console.log('c', c)
		let cardata = this.props.cardata; //Store.getStoreData('cur_a_modal_data');
		// console.log('cardata', cardata)
		let object_data = JSON.parse(cardata.task_json).object_data
		// console.log('object_data', object_data)
		let contact = Store.getStoreData('contact');
		// console.log('contact', contact)
		let contacts = Store.getStoreData('contacts');
		// console.log('contacts', contacts)
		let date = new Date(c.actiondate)
		let dateStr = date.toLocaleString('en-GB',{year: 'numeric', month: 'numeric', day: 'numeric',hour: '2-digit', minute: '2-digit'}).replace(/,/g, '')
		let bgColor = object_data.asked_by === c.performed_by ? '#F5C163' : '#04ACA7';

		let last_answer = c.note;
		if(Utils.isBase64(last_answer)){
		    last_answer = window.decodeURIComponent(atob(last_answer))
		}
		

		return (
				<Style.ModalNameInputDiv>
                    <Style.ModalWiderDiv>
                        <Style.ModalWiderLineDiv style={{width: '90%'}}/>
                        <Style.ModalClearDiv />
                        <Style.ModalWiderHeaderDiv style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px', backgroundColor: bgColor, width: '93%'}}>
                        	{contacts[c.performed_by.toString()]}&nbsp;&nbsp;&nbsp;&nbsp;{dateStr}
                        </Style.ModalWiderHeaderDiv>
                        <div style={{backgroundColor: '#FFFFFF', width: '94.5%'}}>
                        	<div dangerouslySetInnerHTML={{__html: last_answer}} style={{whiteSpace:  "break-spaces",textAlign: "justify", padding: "5px", wordWrap: "break-word", width: '93.3%'}}></div>
                        </div>
                    </Style.ModalWiderDiv>
                </Style.ModalNameInputDiv>
   			)
	}
} 

export default Originalanswer;