import React from 'react';
import {FaTimes, FaTrashAlt, FaQuestionCircle} from 'react-icons/fa';
import APICall from '../../Common/APICall.js';
import styled from 'styled-components';
import AlertBox from '../Common/AlertBox';

const EmailContainer = styled.div`
	width: 67%;
	position: absolute;
	background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
	z-index: 1;
	padding: 20px;
`;
const EmailInput = styled.input`
    height: 30px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
`;

const CancelBtn = styled.button`
	background-color: #ffffff;
	border: 1px solid #EBEBEB;
	border-radius: 4px;
	padding: 10px 20px;
	cursor: pointer;
`;
const SubmitBtn = styled.button`
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 4px;
	padding: 10px 30px;
	cursor: pointer;
	color: #ffffff;
`;


class Addtag extends React.Component
{
	state = {name: "", alert_param: null, tag:null };

	componentDidMount = () => {
	    this.setComponentState()
	}

	componentDidUpdate = (prev_props) => {
	    if(prev_props === this.props) return
	        
	    this.setComponentState()
	}
	

	setComponentState = () => {
		console.log("this.props.tag", this.props)
		this.setState({tag: this.props.tag})
	}

	closeModal = (event) => {
		event.preventDefault();
		this.props.closeModal();
	}

	handleChange = (event) => {
		event.preventDefault();
		let tag = this.state.tag;
		tag.TagName = event.target.value;
		this.setState({tag});
	}


	AddNewTag = (event) => {
		event.preventDefault();
		const postData = { command: "save_tag", tag: this.state.tag };
		const api = new APICall();
		api.indexCommand(postData, this.addCartag);
	}

	addCartag = (result) => {
		this.props.closereloadModal(result);
	}

	render()
	{
		if (this.state.tag === null) {
			return (<div>Loading...</div>);
		}

		return (
			<EmailContainer>
				<div style={{float: "left", fontWeight: "600", fontSize: "16px"}}>Add/Edit Tag Name</div>
				<div style={{float: "right"}}><FaTimes style={{cursor: "pointer"}} onClick={this.closeModal} /></div>
				<div style={{clear: "both"}}></div>
				<div style={{marginTop: "20px"}}>
					<div style={{float: "left", width: "20%", fontWeight: "600", color: "#222222", fontSize: "14px", paddingTop: "8px"}}>Tag Name</div>
					<div style={{float: "left", width: "80%", paddingRight: "20px", boxSizing: "border-box"}}>
						<EmailInput name="name" value={this.state.tag.TagName} onChange={this.handleChange} />
					</div>
					<div style={{clear: "both"}}></div>
				</div>
				<div style={{width: "100%", height: "2px", backgroundColor: "#EBEBEB", marginTop: "20px"}}></div>
				<div style={{textAlign: "right", marginTop: "20px"}}>
					<CancelBtn onClick={this.closeModal}>Cancel</CancelBtn>
					<SubmitBtn style={{marginLeft: "15px"}} onClick={this.AddNewTag}>Submit</SubmitBtn>
				</div>
			</EmailContainer>
		);
	}
}

export default Addtag;