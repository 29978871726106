import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import APICall from '../../Common/APICall.js';
import Utils from '../../Common/Utils.js';
import AskQuestionModal from '../Team/AskQuestionModal';
import ShowAskModal from '../Team/ShowAskModal';
import AcceptanceModal from '../Team/AcceptanceModal';
import AnswerQuestionModal from '../CA/AnswerQuestionModal';
import ClarificationModal from '../CA/ClarificationModal';
import ShowClosecarModal from '../Modals/ShowClosecarModal';
import ShowAnsweredModal from '../Modals/ShowAnsweredModal';
import ShowApprovalModal from '../Modals/ShowApprovalModal';
import ShowAnsweredRevretModal from '../Modals/ShowAnsweredRevretModal';
import ShowReapprovalModal from '../Modals/ShowReapprovalModal';
import PrintPreview from '../Print/PrintPreview';
import RegisterHeader from './RegisterHeader';
import WelcomeBanner from '../WelcomeBanner';
import CSLLoader from '../Common/CSLLoader';
import RegisterReporting from './CSLRegister/RegisterReporting';

import RegisterHelper from './RegisterHelper.js'
import reportData from './reportData.js';
import filters from './reportFilters.js';
import pill_filters from './pillFilters.js';
import AlertBox from '../Common/AlertBox';
import CSLPageloader from '../Common/CSLPageloader';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, thin } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

const months = {
                  '01': 'January',
                  '02': 'February',
                  '03': 'March',
                  '04': 'April',
                  '05': 'May',
                  '06': 'June',
                  '07': 'July',
                  '08': 'August',
                  '09': 'September',
                  '10': 'October',
                  '11': 'November',
                  '12': 'December'
               }

const InactiveOverlay = styled.div`
    display: block;
    box-sizing: border-box;
    height: 1178px;
    width: 100%;
    background-color: #ffffff;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 1000;
    opacity: 0.6;
`;

const NoPermission = styled.div`
    display: block;
    box-sizing: border-box;
    height: 40px;
    font-size:20px;
    font-weight: 700;
    text-align: center;
    background-color: #ffffff;
    position: absolute;
    top: 100px;
    left: 10px;
    right:10px;
    bottom : 10px;
    border: 2px solid #37ada7;
    border-radius:10px;
    color: #37ada7;
    z-index: 1000;
  `;

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #01B0A2;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 100px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    margin-right:3px;
`;
 
const Pending = styled.span`
    background-color: #5656AA;
    height: 15px;
    width:15px;
    margin-right:15px;
    opacity: 1 ; 
`;
const Complete = styled.span`
    background-color: #00ff00;
    height: 15px;
    width:15px;
    margin-right:15px;
    opacity: 1 ;
`;

const SubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #223B5A;
    color: #ffffff;
    width: 160px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    padding-left: 12px;
    cursor: pointer;
`;

const completeMap = {COMPLETE: 1, COMPLETED: 1, DISMISSED : 1, REJECTED: 1, DISSMISS: 1, REJECT: 1, ANSWER: 1,TEAMSAVE: 1, CLARIFICATION: 1, ACCEPTANCE: 1, SAVED: 1, CLARIFIED: 1, PENDING: 1}

class GenericRegister extends React.Component
{

    state = {
        filter: {
          All: 0,  
          Saved: 0,   
          Requested: 0,
          Inprogress: 0,
          Answered: 0,
          AwaitingClarification: 0,
          ClarificationProvided: 0,
          Closed: 0,
          awaitingApproval: 0,
          show_assigned: false
        },
        ready: false,
        show_modal: '',
        overlay_height: null,
        show_table_filter:{
          open_advice: true,
          pending_advice: true,
          pending_advice_approval: true,
          acceptence_advice: true,
          save_advice: true,
          answer_advice: true,
          close_advice: true,
          reverted_advice: true,
          faq_approval: true,
          faq_save: true,
          faq_publish: true,
          faq: true,
          faq_revert: true,
          faq_register: true,
          car_register: true

        },
        prefered_columns: {},
        applied_filters: {},
        filters: filters,
        pill_filters: {},
        view_count: 0,
        alert_param: null, 
        only_task: false,
        cur_lane: 0,
        include_topic: false,
        loading: false,
        is_readonly: false,
        data: null
    };
    constructor(props) {
        super(props);
    }

    refreshCallback = (data) => {
      console.log("this.state pill filter", data)
      let filter = this.state.filter;
      for(let k in data) {
        filter[k] = data[k].is_active;
      }
      // filter['All'] = 1;
      this.setState({view_count: this.state.view_count + 1, filter: filter});
      let car_cached_data = JSON.parse(localStorage.getItem("car-index-select"))
      Utils.log("filter ===>", car_cached_data)
      if('result' in car_cached_data && 'home_filter' in car_cached_data.result){
        car_cached_data.result.home_filter = filter;
        localStorage.setItem("car-index-select", JSON.stringify(car_cached_data));
      }
      let postData = { "command": "car_registry_save", "filter":  filter};
      let api = new APICall();
      api.indexCommand(postData, this.processSaveFilter);
    }

    componentDidMount() {        
        let task_id = this.props.taskId;
        let cur_lane = this.props.curLane;
        if(task_id > 0) {
          this.goToTaskUrlBare(task_id, cur_lane)
        }else{
          this.goTotasksRegister()
        }

    }

    goTotasksRegister = () => {
        let task_result = RegisterHelper.getRegisterTask()
        Store.updateStore('taskdata', task_result.rows);
        let prefered_columns ={};
        prefered_columns['status'] = 'Status';
        prefered_columns['ref_id'] = 'ID';
        prefered_columns['company_name'] = 'Group Company Name';
        prefered_columns['question'] = 'Question';
        prefered_columns['question_by'] = 'Requester';
        prefered_columns['askedDate'] = 'Requested Date';
        prefered_columns['answer'] = 'Answer';
        prefered_columns['answered_by'] = 'Responder';
        prefered_columns['answeredDate'] = 'Responded Date';
        prefered_columns['clarification'] = 'Clarification Request';
        prefered_columns['clarification_text'] = 'Clarification text';
        prefered_columns['topic_value'] = 'Topic';
        prefered_columns['tags'] = 'Tags';
        prefered_columns['first_response_date'] = 'First Response Date';
        prefered_columns['completed_date'] = 'Closed Date';
        prefered_columns['closer_method'] = 'Closure Method';
        prefered_columns['no_days_closure'] = 'No of Days to Closure';
        prefered_columns['edit_date'] = 'Edit Date';
        prefered_columns['edited_by'] = 'Edited By';
        prefered_columns['reopen_date'] = 'Reopen Date';
        prefered_columns['reopen_by'] = 'Reopened By';
        let home_filter = Store.getStoreData('home_filter');
        console.log("home_filter", home_filter)
        let pf = pill_filters;
        for(let k in pf) {
          pf[k].is_active = home_filter[k];
        }

        let moduleconfig = Store.getStoreData('moduleconfig');
        let include_topic = moduleconfig.enabled_topics;
        let show_table_filter = ("show_table_filter" in home_filter) ? home_filter.show_table_filter : this.state.show_table_filter;
        this.setState({filter: home_filter, ready: true, prefered_columns: prefered_columns, role: Store.getStoreData("role"), pill_filters:pf, show_modal: '', overlay_height: null, show_table_filter: show_table_filter, include_topic: include_topic});
    }

    goToTaskUrlBare = (id, cur_lane) => {
        console.log('taskId==>123', id);
        this.showQuestionAnswerModal(id, cur_lane)
      }

      showAskQuestionModal = (id) => (event) => {
          event.preventDefault();
          Store.updateStore('cur_q_modal_data',null);
          this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'ask_modal'});
      }

      processSaveFilter = (result) => {
        Store.updateStore('home_filter', JSON.parse(result));
      }

      showOnlyAssign = (flag) =>{
        let filter = this.state.filter
        filter.show_assigned = flag;
        this.setState({filter: filter})
        let car_cached_data = JSON.parse(localStorage.getItem("car-index-select"))
        if('result' in car_cached_data && 'home_filter' in car_cached_data.result && 'show_assigned' in car_cached_data.result.home_filter){
          car_cached_data.result.home_filter.show_assigned = flag;
          localStorage.setItem("car-index-select", JSON.stringify(car_cached_data));
        }
        let postData = { "command": "car_registry_save", "filter":  filter};
        let api = new APICall();
        api.command(postData, this.processSaveFilter);
      }

      setShowTable = (flag, table) => {
        let filter = this.state.filter;
        let show_table_filter = this.state.show_table_filter;
        show_table_filter[table.toString()] = flag
        filter.show_table_filter = show_table_filter;
        this.setState({show_table_filter: show_table_filter, filter: filter})
        let car_cached_data = JSON.parse(localStorage.getItem("car-index-select"))
        if('result' in car_cached_data && 'home_filter' in car_cached_data.result){
          car_cached_data.result.home_filter.show_table_filter = show_table_filter;
          localStorage.setItem("car-index-select", JSON.stringify(car_cached_data));
        }
        let postData = { "command": "car_registry_save", "filter":  filter};
        let api = new APICall();
        api.command(postData, this.processSaveFilter);
        
      }

      FilteTask = (data) => {
        let flag = false;
        console.log("data.cur_assigned_to", Store.getStoreData('loggedincontact'))
        if(this.state.filter.show_assigned) {
          // console.log("data.cur_assigned_to", data.cur_assigned_to)
            if(parseInt(data.cur_assigned_to) === parseInt(Store.getStoreData('loggedincontact').contact_id)) {
                // console.log("data.cur_assigned_to", data.cur_assigned_to)
                flag = true;
            }else{
                flag = false;
            }
        }else{
            flag = true;
        }
        return flag;
      }

      getMonth = (due_date) => {
        let dateStr = due_date.split('T')[0];
        let dateParts = dateStr.split('-'); 
        let ret = months[dateParts[1]];
        console.log('getMonth due_date, ret', due_date, ret)
        return ret;
      }

      processAcceptanceQueueData = () => {
        let taskData = Store.getStoreData('taskdata') === null ? {} : Store.getStoreData('taskdata');
        console.log("taskData==>",taskData)
        const gc_assoc = Store.getStoreData('gc_assoc');
        var task_arr = []
        var filter_arr = []
        for(var k = 0 ; k < Object.keys(taskData).length; k++)
        {
            let assgn_task = this.FilteTask(taskData[Object.keys(taskData)[k]].row);

            if(assgn_task) {
                task_arr.push(taskData[Object.keys(taskData)[k]].row)
                filter_arr.push(taskData[Object.keys(taskData)[k]].filter)
            }
            
        }
        
        let ret = {data: [], columns: [], raw_data: [], row_filters: {}};
        let i = 1;
        console.log("filter_arr==>",filter_arr)

        ret.columns =[{'Header' : 'Status', accessor: 'status', Cell: row => (
                        <div>
                        {
                        (() => {
                        console.log("row.original.status", row.original.status)
                        switch(row.original.status) {
                            // case 'Overdue': return <Overdue><span style={{paddingLeft: "25px"}}>{row.original.status}</span></Overdue>; break;
                            case 'Save': return <div><Pending><span style={{paddingLeft: "15px"}}></span></Pending> {row.original.status}</div>; break;                            
                            case 'Closed': return <div><Complete><span style={{paddingLeft: "15px"}}></span></Complete>{row.original.status}</div>; break;
                            case 'Requested' : return <div><Pending><span style={{paddingLeft: "15px"}}></span></Pending>{row.original.status}</div>; break;
                            case 'Answered' : return <div><Pending><span style={{paddingLeft: "15px"}}></span></Pending>{row.original.status}</div>; break;
                            case 'AwaitingClarification' : return <div><Pending><span style={{paddingLeft: "15px"}}></span></Pending>{row.original.status}</div>; break;
                            case 'ClarificationProvided' : return <div><Pending><span style={{paddingLeft: "15px"}}></span></Pending>{row.original.status}</div>; break;
                            default: return  <div><Pending><span style={{paddingLeft: "15px"}}></span></Pending>{row.original.status}</div>
                        }
                        })()
                        }
                        
                        </div>

                    ), width: 150, headerStyle: {textAlign: 'left'}},  
                    {Header: 'ID', accessor: 'ref_id', minWidth: 150, headerStyle: {textAlign: 'left'}},         
                    {Header: 'Question', accessor: 'question', minWidth: 300, headerStyle: {textAlign: 'left'}},
                    {Header: 'Topic', accessor: 'topic_value', width: 120, headerStyle: {textAlign: 'left'}, show: this.state.include_topic}, 
                    {Header: 'Requester', accessor: 'question_by', width: 150, headerStyle: {textAlign: 'left'}}, 
                    {Header: 'Responder', accessor: 'answered_by', width: 150, headerStyle: {textAlign: 'left'}},
                    {Header: 'Requested Date', accessor: 'askedDate', width: 150, headerStyle: {textAlign: 'left'}
                    , sortMethod: (a, b) => {
                                                  if (a === b) {
                                                    return 0;
                                                  }
                                                  let aReverse = 0;
                                                  let bReverse = 0;
                                                  let adue = a.split("/");
                                                  aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                                                  // console.log("aReverse===>", aReverse)
                                                  let  bbdue = b.split("/");
                                                  bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                                                  return aReverse > bReverse ? 1 : -1;

                                                  
                                                }
                    },
                    {Header: 'Responded Date', accessor: 'answeredDate', width: 150, headerStyle: {textAlign: 'left'}
                    , sortMethod: (a, b) => {
                                                  if (a === b) {
                                                    return 0;
                                                  }
                                                  let aReverse = 0;
                                                  let bReverse = 0;
                                                  let adue = a.split("/");
                                                  aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                                                  // console.log("aReverse===>", aReverse)
                                                  let  bbdue = b.split("/");
                                                  bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                                                  return aReverse > bReverse ? 1 : -1;

                                                  
                                                }
                    },
                    {'Header' : 'Action', Cell: row => (
                        <div>
                          <MRButton style={{cursor: 'pointer', width: '80px', marginRight: '5px'}} onClick={this.goToTaskUrl(row.original.id, row.original.company_id)}>View</MRButton> {
                            (() => {
                                if (Store.getStoreData('role') === "admin_manager") {
                                    return <FontAwesomeIcon icon={solid("trash")} style={{cursor: 'pointer',height: "18px"}} onClick={this.deleteRecord(row.original.id, row.original.company_id)} />
                                }
                            })()
                            }
                        </div>
                    ), width: 120, headerStyle: {textAlign: 'left'}}];

       for(var j = 0 ; j< task_arr.length ; j++) 
        {          
          let status = RegisterHelper.getStatus(task_arr[j])          
          let question = task_arr[j].query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'").replace(/\n/g, "")
          let answered_by_id = task_arr[j].answered_by
          let contacts = Store.getStoreData('contacts')
        
          let completed_date = new Date(task_arr[j].completed_date).toLocaleDateString("en-GB").replace(/,/g, '')
          if(completed_date == 'Invalid Date' || task_arr[j].cur_lane !== 'COMP_CAR_USER_COMPLETED')
          {
            completed_date = "";
          }
          let askedDate = new Date(task_arr[j].created_at).toLocaleDateString("en-GB").replace(/,/g, '')
          if(askedDate == 'Invalid Date')
          {
            askedDate = "";
          }
          var asked_month = askedDate.split("/")[1];
          let answeredDate= new Date(task_arr[j].answer_date).toLocaleDateString("en-GB").replace(/,/g, '')
          if(answeredDate == 'Invalid Date' || answeredDate == '01/01/1970')
          {
            answeredDate = "";
          }
          let answered_by = "";
          if(answered_by_id != 0) {
            answered_by = contacts[answered_by_id.toString()];
          }else{
            answered_by = "N/A";
          }
          let asked_by_id= task_arr[j].contact_id
          let question_by = "";
          if(asked_by_id != 0) {
            question_by = contacts[asked_by_id.toString()];
          }else{
            question_by = "N/A";
          }

          let requester_name = task_arr[j].requester_name;
          let ref_id = task_arr[j].ref_id;

          let answer = task_arr[j].answer;
          if(Utils.isBase64(answer)){
            answer = window.decodeURIComponent(atob(answer))
          }
          let clarifications = task_arr[j].clarifications;
          let topic_value = task_arr[j].topic;


          let CompanyName = '';
          if(parseInt(task_arr[j].company_id) < 100000){
            CompanyName = Store.getStoreData('company')?.CompanyName;
          }else{
            const calc_company_id = parseInt(task_arr[j].company_id) % 100000;
            CompanyName = gc_assoc[calc_company_id.toString()]?.company_name;
          }

          let name_tags = "";
          
          if(Object.keys(task_arr[j].tags).length > 0){
            for (let key in task_arr[j].tags) {
                name_tags = name_tags + "|" + task_arr[j].tags[key];
            }
            name_tags = name_tags.substring(1)
          }
          let closer_method = ''
          if(task_arr[j].cur_lane === 'COMP_CAR_USER_COMPLETED') {
            if(task_arr[j].closure_method === null || task_arr[j].closure_method === 0) {
              closer_method = 'By User'
            }else{
              closer_method = 'Automated'
            }
          }
          let no_days_closure = '';
          if(task_arr[j].cur_lane === 'COMP_CAR_USER_COMPLETED') {
            no_days_closure = Utils.DayestoComplete(task_arr[j].created_at, task_arr[j].completed_date).toString();
          }

          console.log("task_arr[j].tags", task_arr[j].tags)

          // if(this.getInclusion(filter_arr[j])){
            let elem = {'index':j,
                        'status': status,
                        'ref_id': ref_id,
                        'question': question,
                        'question_by':task_arr[j].from_api === 0 ? question_by : requester_name,
                        'answered_by': answered_by,
                        'id' : task_arr[j].id,
                        'askedDate': askedDate,
                        'asked_month' : months[asked_month.toString()],    
                        'answeredDate': answeredDate,
                        'first_response_date': answeredDate,
                        'completed_date': completed_date,
                        'answer' : answer.replace( /(<([^>]+)>)/ig, '').replace(/\n/g, ""),  
                        'clarification' :  clarifications === "" ?   clarifications : (clarifications.accept_mode === '' ? 'Clarify' : clarifications.accept_mode),                               
                        'clarification_text' :  task_arr[j].clarifications === "" ? '' : clarifications.note ,
                        'company_id' : task_arr[j].company_id,
                        'topic_value' : topic_value,
                        'accept_mode' : task_arr[j].accept_mode,
                        'acknowledge': task_arr[j].reason,
                        'company_name': CompanyName,
                        'tags':name_tags,
                        'closer_method':closer_method,
                        'no_days_closure': no_days_closure,
                        'edit_date': task_arr[j].edit_date === null ? '' : task_arr[j].edit_date,
                        'edited_by': task_arr[j].edited_by === null ? '' : contacts[task_arr[j].edited_by.toString()],
                        'reopen_by': task_arr[j].reopen_by === null ? '' : contacts[task_arr[j].reopen_by.toString()],
                        'reopen_date': task_arr[j].reopen_date === null ? '' : task_arr[j].reopen_date
                                                    
            };
            console.log("elem --->", elem)
              ret.data.push(elem);
              ret.raw_data.push(elem);
              ret.row_filters[elem.id] = taskData[elem.id].filter  
            // }
        }   
        console.log('ret.data', ret.data)
        return ret;   
      }

      deleteRecord = (id, company_id) => (event) => {
        event.preventDefault();
        let alert_param = {title: 'Confirm', message: 'Are you sure you want to delete this record?', cancel_text: 'No', ok_text: 'Yes', confirm: true, alertHandler: this.alertRecordHandler, stack: {task_id: id, company_id:company_id}}
        console.log("alert_param", alert_param)
        this.setState({alert_param: alert_param})
      }

      alertRecordHandler = (result,stack) => { 
        if(!result || stack.prevent) {
            this.setState({alert_param: null, loading: false})
            return
        }
        this.setState({alert_param: null, loading: true})
        let postData = { "command": 'delete_car',task_id: stack.task_id, company_id: stack.company_id };
        let api = new APICall();
        api.indexCommand(postData, this.processDeleteRecords);
      }

      processDeleteRecords = (result) => {
        console.log('result', result)
        let msg = result.error_msg;
        let title = 'Alert';
        let parms = result;
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.AlertRefreshTask, stack: {parms: parms}}
        this.setState({alert_param: alert_param, loading: false})
      }

      AlertRefreshTask = (result, stack) => {
        this.setState({alert_param: null})
        let parms = stack.parms;
        if(parms.error_code === 0) {
          let task_id = parms.result.task_id;
          let tasks = Store.getStoreData('tasks')
          delete tasks[task_id.toString()];
          Store.updateStore('tasks', tasks)
        }
        this.goTotasksRegister()
      }


      goToTaskUrl = (id, company_id) => (event) => {
        event.preventDefault();
        // console.log('taskId==>', id)
        // this.showQuestionAnswerModal(id) 
        let postData = { "command" : "get_newtask", "task_id" : id, company_id: company_id }
        console.log("postData", postData)
        let api = new APICall();
        api.indexCommand(postData, this.processQuestionAnswerTaskData);
        this.setState({only_task: true, loading: true})

      }

      showQuestionAnswerModal(id, cur_lane){
        let postData = { "command" : "get_task", "task_id" : id }
        postData['gcs'] = Store.getStoreData('gc_filters');
        postData['lane'] = cur_lane;
        console.log("postData", postData)
        let api = new APICall();
        api.indexCommand(postData, this.processQuestionAnswerTaskData);
        this.setState({only_task: true, loading: true})
    }

    openAnswer = (id, company_id) => {
      let api = new APICall();
      let postData = {command: 'get_newtask', task_id: id, company_id: company_id };
      api.indexCommand(postData, this.processQuestionAnswerTaskData);
      this.setState({loading: true})
    }

    alertremoveHandler = (result,stack) => {            
        this.setState({alert_param: null})
    }

    homeAlertremoveHandler = (result, stack) => {
      // this.setState({alert_param: null})
      let url = "/"
      window.location = url;
    }

    processQuestionAnswerTaskData = (result) => {
      console.log("result", result)
      this.setState({loading: false})
      if(result.error_code > 0) {
        if(result.error_code === 1003){
          let msg = result.error_msg;
          let title = "Alert";
          let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
          console.log("alert_param", alert_param)
          this.setState({alert_param: alert_param, ready: true})
          
        }else if(result.error_code === 1004) {
          
          let contacts = Store.getStoreData('contacts');
          let rs = result.result;
          let alert_answer = {title: rs['alt_ref_id'], message: 'This Compliance Advice Request was answered by '+ contacts[rs['answered_by']]+' on '+ Utils.timeToDate(rs['answer_date']), ok_text: 'OK', confirm: false, alertHandler: this.homeAlertremoveHandler, stack: {id: 0}}
          console.log("alert_param", alert_answer)
          this.setState({alert_param: alert_answer, ready: true})
        }
        return false;
      }
      let task = result.result.parenttask;
      if(task.is_delete === 1) {
        // alert("This task was deleted");
        // return;
        let msg = "This task was already deleted";
        let title = "Alert";
        // alert('Your advice request has been submitted to the compliance team.');
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
        this.setState({alert_param: alert_param, ready: true})
        return false;
      }
      let task_json = JSON.parse(task.task_json);
      let cur_lane = result.result.parenttask.cur_lane;
      let contact_id = Store.getStoreData('contact_id')
      let role = Store.getStoreData("role");
      let drs = Store.getStoreData('drs')
      let individuls = task_json.object_data?.individuls ? task_json.object_data.individuls : {}
      console.log("cur_lane role==>", cur_lane, role, contact_id, task.id, cur_lane);
      let answered_by = ('answered_by' in task_json.object_data) ? task_json.object_data.answered_by : 0;

      let c = Store.getStoreData('loggedincontact')
      // let is_readonly = task.contact_id === c.contact_id ? false : true;
      let is_readonly =  false;
      

      if(cur_lane === 'COMP_CAR_TEAMSAVE' && (individuls.hasOwnProperty(contact_id) || parseInt(task.contact_id) === parseInt(contact_id) || drs.hasOwnProperty(task.contact_id) || role === "ca_module_admin" || role === "admin_manager")) {
          
          Store.updateStore('cur_q_modal_data', result.result.parenttask);
          this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'ask_modal', ready: true, is_readonly: is_readonly});
      }else if((cur_lane === 'COMP_CAR_ANSWER' || cur_lane === 'COMP_CAR_ANSWER_SAVED' || cur_lane === 'COMP_CAR_USER_ACCEPTANCE_PENDING' || cur_lane ==='COMP_CAR_USER_CLARIFICATION') && (parseInt(task.contact_id) === parseInt(contact_id) || drs.hasOwnProperty(task.contact_id))  && (role === 'team' || role === "line_manager")){
          // alert("Waiting for Compliance Advisor reply");
          // return false;
          let msg = "Waiting for Compliance Advisor reply";
          let title = "Alert";
          let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
          this.setState({alert_param: alert_param, ready: true})
          return false;
      }else if(individuls.hasOwnProperty(contact_id) || (cur_lane === 'COMP_CAR_ANSWER' && role !== 'team')) {
        // alert("Waiting for Compliance Advisor reply");
          Store.updateStore('cur_a_modal_data',result.result.parenttask);
          this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'answer_modal', ready: true, is_readonly: is_readonly});
      }else if(individuls.hasOwnProperty(contact_id) || (cur_lane === 'COMP_CAR_USER_CLARIFICATION' && role !== 'team')) {
          Store.updateStore('cur_a_modal_data',result.result.parenttask);
          this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'clarify_modal', ready: true, is_readonly: is_readonly});
      }else if(cur_lane === 'COMP_CAR_ANSWER_SAVED' && (individuls.hasOwnProperty(contact_id) || parseInt(task.cur_assigned_to) === parseInt(contact_id) ||  role === "ca_module_admin" || role === "admin_manager")) {
          Store.updateStore('cur_a_modal_data',result.result.parenttask);
          this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'answer_modal', ready: true, is_readonly: is_readonly});
      }else if(cur_lane === 'COMP_CAR_USER_ACCEPTANCE_PENDING' && role === 'jr_ca') {
          let msg = "Waiting for Compliance Advisor approval";
          let title = "Alert";
          let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
          this.setState({alert_param: alert_param, ready: true})
      } else if(cur_lane === 'COMP_CAR_USER_ACCEPTANCE_PENDING' && (individuls.hasOwnProperty(contact_id) || role === 'ca' || role==='admin_manager' || role === 'ca_module_admin')) {
          Store.updateStore('cur_approval_data', result.result.parenttask);
          this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'approved_modal', ready: true});
      } else if(cur_lane === 'COMP_CAR_USER_ACCEPTANCE' && (individuls.hasOwnProperty(contact_id) || parseInt(task.contact_id) === parseInt(contact_id)  || drs.hasOwnProperty(task.contact_id) ||  role === "ca_module_admin" || role === "admin_manager" || role === "ca" || role === "jr_ca")) {
          Store.updateStore('cur_accept_modal_data', result.result.parenttask);
          this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'accept_modal', ready: true, is_readonly: is_readonly});
      } else if(cur_lane == 'COMP_CAR_USER_COMPLETED' || individuls.hasOwnProperty(contact_id)) {
          Store.updateStore('cur_question_answer_modal_data', result.result.parenttask);
          this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'question_answer_modal', ready: true});
      } /*else if(cur_lane === 'COMP_CAR_USER_ACCEPTANCE' && (parseInt(answered_by) === parseInt(contact_id) || role === "ca_module_admin" || role === "admin_manager" || role === "ca" || role === "jr_ca")) {
          Store.updateStore('cur_accept_modal_data', result.result.parenttask);
          this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'save_answer_modal', ready: true});
      }*/ else if(cur_lane === 'COMP_CAR_ANSWER_REVERT' && (individuls.hasOwnProperty(contact_id) || parseInt(answered_by) === parseInt(contact_id) || role === "ca_module_admin" || role === "admin_manager")) {
        Store.updateStore('cur_revert_modal_data',result.result.parenttask);
        this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'answer_revert_modal', ready: true});
      } else if(cur_lane === 'CAR_FAQ_AWAITING_REAPPROVAL' && (individuls.hasOwnProperty(contact_id) || parseInt(answered_by) === parseInt(contact_id) || role === "ca_module_admin" || role === "admin_manager")) {
        Store.updateStore('cur_faq_reapproval_date',result.result.parenttask);
        this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'faq_reapproval_modal', ready: true});
      }else{
        let msg = "ERROR: Sorry. You do not have permissions to access this task. Please contact your manager if you think this error is being shown in error.";
        let title = "Alert";
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
        this.setState({alert_param: alert_param, ready: true})
      }
    }

      getInclusion = (filter) => {
        let count = 0;
        let keys = Object.keys(filter)
        for(let i=0; i < keys.length; i++) {
          count += parseInt(filter[keys[i]]) * parseInt(this.state.filter[keys[i]])
        }

        return (count > 0)
      }

      refreshState = (stack=null) => {
           let task_id = this.props.match ? this.props.match.params.taskId : 0;
           if(task_id === 0) {
            let tasks = Store.getStoreData('tasks')
            console.log("tasks", tasks)
            if(stack!==null){
              if('mode' in stack && stack.mode === "delete"){
                    delete tasks[stack.task_id.toString()];
              }else{
                    tasks[stack.task_id.toString()] = stack.task;
              }
              Store.updateStore('tasks', tasks)
            }
            this.goTotasksRegister()
           }else{
             const homeurl = "/"
             window.location.assign(homeurl);
           }
       }


    closeModal = () => {
      this.setState({overlay_height: null, show_modal: ''});
    }

    closeFAQModal = () => {
      this.setState({overlay_height: null, show_modal: ''});
    }

    openPdfPrintView = (result) => {
      this.setState({show_modal: 'pdfprintview', data: result});
    };


    render() {
        if(Store.getStoreData('car-index-select') === null && !this.state.only_task) {
          return <div><CSLPageloader /></div>
        }
        if(!this.state.ready) {
            return <div><CSLLoader /></div>
        }
        let task_id = this.props.taskId ? this.props.taskId : 0;
        let button_set = {}
        button_set['showOnlyAssign'] = {id: 'showOnlyAssign', display: 'Only show requests assigned to me', stack: '', button_callback: this.showOnlyAssign, is_visible: true }
        button_set['setShowTable'] = {id: 'setShowTable', display: '', stack: '', button_callback: this.setShowTable, is_visible: true }

        console.log("this.state ===>", this.state)
        
        return(
            <div style={{backgroundColor: "#f2f2f3", position: "relative"}}>

                {
                  (() => {
                    if(task_id === 0) {
                      return <RegisterHeader />
                    }
                  })()
                }
                <WelcomeBanner welcome={`Welcome back, ` + Store.getStoreData('contact')} />
                <SubmitButton style={{marginTop:"7px",marginLeft:"15px"}} onClick={this.showAskQuestionModal(0)} id="create_advice_request">Submit Advice Request</SubmitButton>
                <div style={{position: "relative", paddingTop:"10px",paddingLeft:"10px",paddingBottom:"0px"}}>
                <RegisterReporting
                        headerText='Support Requests'
                        HeaderFilter={'Only show requests assigned to me'}
                        moduleId='car'
                        data={reportData}
                        processTableData={this.processAcceptanceQueueData}
                        preferedColumns={this.state.prefered_columns}
                        filters={filters}
                        pillFilters={this.state.pill_filters}
                        filter={this.state.filter}
                        refreshCallback={this.refreshCallback}
                        viewCount={this.state.view_count}
                        showOnlyAssign = {this.showOnlyAssign} 
                        only_assign_me={this.state.filter.show_assigned} 
                        setShowTable={this.setShowTable} 
                        show_filter={this.state.show_table_filter.car_register} 
                        table="car_register"

                />

                </div>
                {
                    (() => {
                        if(this.state.loading) {
                            return <div><CSLLoader /></div>
                        }
                    })()
                }
                {
                  (() => {
                    if (this.state.overlay_height !== null) {
                        console.log("this.state.show_modal", this.state.show_modal)
                      switch(this.state.show_modal) {
                        case 'show_ask_modal':return <div><InactiveOverlay /><ShowAskModal refreshCallback={this.props.refreshCallback} refreshParentCallback={this.refreshState} is_readonly={this.state.is_readonly} /></div>; break;
                        case 'ask_modal' : return <div><InactiveOverlay /><AskQuestionModal refreshCallback={this.props.refreshCallback} refreshParentCallback={this.refreshState} openAnswerModal={this.openAnswer} closeModal={this.closeModal} is_readonly={this.state.is_readonly}/></div>; break;
                        case 'accept_modal' : return <div><InactiveOverlay /><AcceptanceModal refreshCallback={this.props.refreshCallback} refreshParentCallback={this.refreshState} closeModal={this.closeModal} is_readonly={this.state.is_readonly}/></div>; break;
                        case 'answer_modal' : return <div><InactiveOverlay /><AnswerQuestionModal refreshCallback={this.props.refreshCallback} refreshParentCallback={this.refreshState} closeModal={this.closeModal} is_readonly={this.state.is_readonly}/></div>; break;
                        case 'clarify_modal' : return <div><InactiveOverlay /><ClarificationModal refreshCallback={this.props.refreshCallback} refreshParentCallback={this.refreshState} closeModal={this.closeModal} is_readonly={this.state.is_readonly}/></div>; break;
                        case 'question_answer_modal' : return <div><InactiveOverlay /><ShowClosecarModal refreshCallback={this.props.refreshCallback} refreshParentCallback={this.refreshState} closeModal={this.closeModal} is_readonly={this.state.is_readonly} openPrintView={this.openPdfPrintView}/></div>; break;                            
                        case 'save_answer_modal' : return <div><InactiveOverlay /><ShowAnsweredModal refreshCallback={this.props.refreshCallback} refreshParentCallback={this.refreshState} closeModal={this.closeModal} is_readonly={this.state.is_readonly} /></div>; break;                            
                        case 'approved_modal' : return <div><InactiveOverlay /><ShowApprovalModal refreshCallback={this.props.refreshCallback} refreshParentCallback={this.refreshState} closeModal={this.closeModal} is_readonly={this.state.is_readonly}/></div>; break; 
                        case 'answer_revert_modal' : return <div><InactiveOverlay /><ShowAnsweredRevretModal refreshCallback={this.props.refreshCallback} refreshParentCallback={this.refreshState} closeModal={this.closeModal} is_readonly={this.state.is_readonly}/></div>; break;                           
                        case 'faq_reapproval_modal' : return <div><InactiveOverlay /><ShowReapprovalModal refreshCallback={this.props.refreshCallback} refreshParentCallback={this.refreshState} closeModal={this.closeFAQModal} is_readonly={this.state.is_readonly}/></div>; break;                           
                        case 'pdfprintview': return <div style={{left:'10%', top:'50px', overflow: "auto"}}><PrintPreview closeModal={this.closeModal} data={this.state.data} /></div>; break;
                        default: return <NoPermission>Sorry, you do not have permission to view this page. </NoPermission>;
                      }
                    }
                  })()
                } 
                
                <AlertBox alertParam = {this.state.alert_param} />
            </div>
        )
    }
}
export default GenericRegister