import React from 'react';
import styled from 'styled-components';
import Toggle from 'react-toggle';
import "./ReactToggle.css";
import { FaPlusCircle, FaEllipsisV } from "react-icons/fa";
import CSLTable from '../../Common/CSLTablemodule';
import CustomFieldsConfigModal from './CustomFieldsConfigModal';
import ContextMenu from "./ContextMenu";
import AlertBox from '../../Common/AlertBox';
import CSLLoader from '../../Common/CSLLoader';
import APICall from '../../../Common/APICall.js';

const EmailContainer = styled.div`
	width: 100%;
`;
const EmailInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const FooterButtonsContainer = styled.div`
	text-align: right;
	padding-top: 25px;
	width: 97%;
    margin-bottom: 25px;
`;
const CancelBtn = styled.button`
	padding: 10px 20px;
	background-color: #ffffff;
	border: 1px solid #ffffff;
	border-radius: 2px;
	color: #333333;
	cursor: pointer;
`;
const SaveBtn = styled.button`
	padding: 10px 30px;
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	margin-left: 15px;
`;

const MRModalLabel = styled.div`
	color: #1a3552;
	font-family: "DMSans-Medium", "DM Sans Medium", "DM Sans", sans-serif;
	font-weight: 600;
	margin-top: 10px;
	display: inline-block;
`;
const EmailTopHeader = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
	width:100%;
	height: 58px;
	padding: 20px;
	border-radius: 4px;
	border: 1px solid #e0e0e0;
	background-color: #ffffff;
	box-sizing: border-box;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 210%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0%;
  top: -46px;
  z-index: 1000;
  opacity: 0.8;
`;

class CustomFields extends React.Component
{
	state = {
		general: null,
		custom_fields: null, 
		final_custom_field: null, 
		additional_fields: [], 
		show_custom_fields_modal: false, 
		curid: 0, 
		headerText: "", 
		content: null, 
		alert_param: null, 
		isLoadedIcon: false,
		topics: []
	};

	setComponentState = () => {
		let general = this.props.general;
		let tags = general.tags
		let topics = [];
		for(let id of Object.keys(tags)) {
		  if(general.enabled_topics) {
		    if('enabled_topic' in tags[id] && tags[id].enabled_topic) {
		      topics.push({id: id, name:tags[id].TagName, selected: true})
		    }
		  }
		}
		this.setState({
			general: general, 
			custom_fields: general.custom_fields, 
			final_custom_field: general.custom_fields, 
			additional_fields: general.custom_fields.additional_fields,
			topics: topics
		});
	}

	componentDidMount()
	{		
		this.setComponentState()
	}

	componentDidUpdate(prevProps)
	{
		if (this.props !== prevProps) {			
			this.setComponentState()
		}
	}

	switchRadio = (key) => {
		// event.preventDefault();
		let {general} = this.state;
		// general.custom_fields[key] = general.custom_fields[key] ? false : true;
		let status = general.custom_fields[key] ? false : true;
		this.props.updateGeneral(general);

		const postData = { command: "include_customfield", status: status};
		const api = new APICall();
		api.indexCommand(postData, this.includeCustomeField);
	}	

	includeCustomeField = (result) => {
		let alert_param = {title: 'Alert', message: result.error_msg, ok_text: 'OK', confirm: false, alertHandler: this.sameAlertremoveHandler, stack: {id: 0}}
    	let general = this.state.general;
    	general.custom_fields['enabled'] = result.result;

    	this.setState({
    		general: general, 
    		custom_fields: general.custom_fields, 
    		alert_param: alert_param
    	});
	}

	handleToggleChange = (arg)=>{
		let custom_fields = JSON.parse(JSON.stringify(this.state.custom_fields));
		custom_fields[arg] = custom_fields[arg] ? false : true;
		this.setState({custom_fields, final_custom_field: custom_fields});
		this.props.updateCustomFieldsToggle(custom_fields);
	}

	addAdditionalField = (event) => {
		event.preventDefault();			
		this.setState({show_custom_fields_modal: true, curid: 0, headerText: "Add"})	
	}

	closeModal = () => {		
		this.setState({show_custom_fields_modal: false})
    }


	removeCustomField = (id) => {
		let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this Custom Field from the list?', ok_text: 'Yes', cancel_text: 'No', confirm: true, 
		           alertHandler: this.updatedeleteCustomField, stack: {id: id}}
		this.setState({alert_param: alert_param})
	}

	editCustomField = (id) => {
		const content = this.state.additional_fields.find((item) => {
		    return item.name === id;
		})
		this.setState({content,curid: id, show_custom_fields_modal: true, headerText: "Edit"}); 
	}

	updatedeleteCustomField = (result, stack) => {  
		if (!result || stack.prevent) {
		  this.setState({ alert_param: null});
		  return;
		}
        
		const postData = { command: "remove_customfield", field_id: stack.id};
		const api = new APICall();
		api.indexCommand(postData, this.saveCustomeField);
		this.setState({alert_param: null}) 
    }

	

	updateafternodelete = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})        
    }

	handlenaSectionIncludedIsRequired = (section_id) => (event) => {
		let content = this.state.additional_fields.find((item) => {
		    return item.name === section_id;
		})
		content.required = content.required ? false : true;

		const postData = { command: "save_customfield", custom_field: content, curid: section_id };
		const api = new APICall();
		api.indexCommand(postData, this.saveCustomeField);

	}

	handlenaSectionIncluded = (section_id) => (event) => {
		let content = this.state.additional_fields.find((item) => {
		    return item.name === section_id;
		})
		content.isActive = content.isActive ? false : true;

		const postData = { command: "save_customfield", custom_field: content, curid: section_id };
		const api = new APICall();
		api.indexCommand(postData, this.saveCustomeField);
	}

	tableData = () => {
		const menu_options = [
		    {name: "edit", nick_name: "Edit", clickHandler: this.editCustomField},
		    {name: "delete", nick_name: "Delete", clickHandler: this.removeCustomField}
		]
        let ret = {data: [], columns: []};
        ret.columns =[
                        {Header: 'CUSTOM FIELD NAME', accessor: 'cname', minWidth: 150, headerStyle: {textAlign: 'left'}},					
                        {Header: 'TYPE', accessor: 'ctype', minWidth: 100, headerStyle: {textAlign: 'left'}},							
						{Header: 'TOPICS', accessor: 'topics', minWidth: 100, headerStyle: {textAlign: 'left'}, show: this.state.general['enabled_topics'], Cell: cellInfo => (<div style={{color: cellInfo.value === 'Multiple' ? '#00b0f0' : '',  fontWeight: cellInfo.value === 'Multiple' ? '600' : '100'}}>{cellInfo.value}</div>)},

						{'Header' : 'REQUIRED', Cell: row => (
							<div> 
							<Toggle
								style={{float:'left'}}
								checked={row.original.isRequired ? true : false}
								icons={false}	  
								onChange={this.handlenaSectionIncludedIsRequired(row.original.id)}                              						
							/>
							</div>
						 ), headerStyle:{textAlign: 'center'},style: {textAlign: 'center'}},	
						 {'Header' : 'STATUS', accessor: 'cstatus', Cell: row => (
							<div> 
							<Toggle
								style={{float:'left'}}
								checked={row.original.status}
								icons={false}	       
								onChange={this.handlenaSectionIncluded(row.original.id)}                         						
							/>
							</div>
						 ), headerStyle:{textAlign: 'center'},style: {textAlign: 'center'}},						
                        {'Header' : '', accessor: 'cblnk', Cell: row => (
                            <div>                                
                                  <ContextMenu row_id={row.original.id} options={menu_options} />	
                            </div>
                         ), minWidth: 40, headerStyle:{textAlign: 'left'},style: {textAlign: 'center'}}
                        ];

        let additional_fields_sort_array = this.state.additional_fields.sort(function(a,b) {return (a.nickname > b.nickname) ? 1 : ((b.nickname > a.nickname) ? -1 : 0);} );
		additional_fields_sort_array.forEach((item) => {
			let statusValue = 'isActive' in item ? item.isActive : true
			let topic = ''
			if(this.state.general['enabled_topics']){
				topic = 'All Topics';
				if('topics' in item && Object.keys(item.topics).length === 0) topic = 'None';
				if('topics' in item && Object.keys(item.topics).length > 0 && Object.keys(item.topics).length !== this.state.topics.length) topic = 'Multiple';
				if('topics' in item && Object.keys(item.topics).length === 1) topic = this.state.topics.find(x => x.id === Object.keys(item.topics)[0]).name;
			}
			let elem = {
				id: item.name, 
				cname: item.nickname, 
				ctype: item.type, 
				topics: topic, 
				isRequired: item.required, 
				status: statusValue, 
				isdelete: item.isDelete,
				show_topic: this.state.general['enabled_topics']
			};
			ret.data.push(elem);
        })
        return ret;
    }   


	saveCustomeField = (result) => {
		let alert_param = {title: 'Alert', message: result.error_msg, ok_text: 'OK', confirm: false, alertHandler: this.sameAlertremoveHandler, stack: {id: 0}}
    	let general = this.state.general;
    	general.custom_fields.additional_fields = JSON.parse(JSON.stringify(result.result))

    	this.setState({
    		general: general, 
    		custom_fields: general.custom_fields, 
    		additional_fields: JSON.parse(JSON.stringify(result.result)),
    		alert_param: alert_param
    	});
	}

	sameAlertremoveHandler = (result,stack) => {
		let general = this.state.general;
	    this.setState({alert_param: null, show_custom_fields_modal: false})
	    this.props.updateGeneral(general);
	}



	render()
	{
		if (this.state.custom_fields === null) {
			return (<div>Loading...</div>);
		}
		return (
			<div>
				{
					(() => {
						if (this.state.show_custom_fields_modal) {
							return (<div ><InactiveOverlay style={{height: "105%"}}/><CustomFieldsConfigModal headerText={this.state.headerText} data={this.state.custom_fields} closeModal={this.closeModal} curid={this.state.curid} topics={this.state.topics} content={JSON.parse(JSON.stringify(this.state.content))} enabled_topics={this.state.general['enabled_topics']} saveCustomeField={this.saveCustomeField}/></div>);
						}
					})()
				}
				{
					(() => {
						if(this.state.isLoadedIcon){
							return <div><CSLLoader style={{position: 'absolute'}}/></div>
						}
					})()
				} 
				<EmailContainer>
					<EmailInnerContainer>						
						<div style={{marginBottom:"10px", display: "block"}}>
							<MRModalLabel style={{display: "block", color: "#1d3957a8",marginBottom:"10px"}}>Enable Custom Fields?</MRModalLabel>							
							<Toggle					    
								icons={false}	
								onChange={()=> this.switchRadio('enabled')}										
								defaultChecked={this.state.custom_fields.enabled ? true : false} 								
							/>
						</div>						
						{
							(() => {
								if (this.state.custom_fields.enabled) {
									return(<div>
									<EmailTopHeader style={{display: "inline-block" , fontWeight: 700}}>
										Custom Fields                              
										<div style={{float:'right'}}>
											<div onClick={this.addAdditionalField}  style={{float: "left", cursor: "pointer",fontSize: "25px",color: "#04ADA8"}}>
												<FaPlusCircle  /> 
											</div>                            
											<div style={{float: "right", cursor: "pointer",fontSize: "25px",color: "#e4e4e4"}}>
												<FaEllipsisV  /> 
											</div>
										</div>
									</EmailTopHeader>
									<CSLTable add={false} processData={this.tableData} tableRows={10} />
									</div>)
								}
							})()
						}						
					</EmailInnerContainer>
				</EmailContainer>
				<AlertBox alertParam = {this.state.alert_param}/>
			</div>
		);
	}
}

export default CustomFields;