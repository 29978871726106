import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import CSLTable from '../Common/CSLTable';
import APICall from '../../Common/APICall.js';
import Utils from '../../Common/Utils.js';
import AnswerQuestionModal from '../CA/AnswerQuestionModal';
import ClarificationModal from '../CA/ClarificationModal';

import ShowClosecarModal from '../Modals/ShowClosecarModal';
import ShowAnsweredModal from '../Modals/ShowAnsweredModal';
import AcceptanceModal from '../Team/AcceptanceModal';
import AskQuestionModal from '../Team/AskQuestionModal';

import ShowAnsweredRevretModal from '../Modals/ShowAnsweredRevretModal';
import ClarificationRevertModal from '../Modals/ClarificationRevertModal';

import CSLLoader from '../Common/CSLLoader';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import XcelLoader from '../../Common/XcelLoader.js';
import IndexLoader from '../../Common/IndexLoader.js';

import AlertBox from '../Common/AlertBox'

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 3000px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1000;
  opacity: 0.6;
`;

const NoPermission = styled.div`
  display: block;
  box-sizing: border-box;
  height: 40px;
  font-size:20px;
  font-weight: 700;
  text-align: center;
  background-color: #ffffff;
  position: absolute;
  top: 100px;
  left: 10px;
  right:10px;
  bottom : 10px;
  border: 2px solid #37ada7;
  border-radius:10px;
  color: #37ada7;
  z-index: 1000;
`;

const SubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #223B5A;
    color: #ffffff;
    width: 160px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    padding-left: 12px;
    cursor: pointer;
`;

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #01B0A2;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    margin-right:3px;
    cursor: pointer;
`;

const IndexContainer = styled.div`
    padding-top: 8px;
    padding-left: 5px;
`;

const HeaderFilter = styled.div`
    float:right;
    margin-right: 20px;
    color: #282828;
    font-weight: 600;
    letter-spacing: 1px;
    padding-top: 6px;
`;

const RadioBlock = styled.div`
    float:right;
    margin-top: -5px;
`;
const SelectedRadio = styled.div`
    background-color: #37ADA7;
    border: 1px solid #1f615e;
    padding: 5px 10px;
    color: #ffffff;
    display: inline-block;
    cursor: pointer;
`;
const UnselectedRadio = styled.div`
    background-color: #F4F4F4;
    border: 1px solid #8c8c8c;
    padding: 5px 10px;
    color: #262626;
    display: inline-block;
    cursor: pointer;
`;


class JRCAIndex extends React.Component
{
  state = {
      isLoaded: false,
      pending_tasks: [],
      show_modal: '',
      overlay_height: null,
      view_count: 0,
      only_assign_me:false,
      initial_response_time: 0,
      response_to_answer: 0,
      response_to_subsquent_question:0,
      include_other: false,
      show_table_filter:{
        open_advice: true,
        pending_advice: true,
        pending_advice_approval: true,
        acceptence_advice: true,
        save_advice: true,
        reverted_advice: true,
        answer_advice: true,
        close_advice: true,
        faq_approval: true,
        faq_reapproval : true,
        faq_save: true,
        faq_publish: true,
        faq: true,
        faq_revert: true,
        faq_register: true,
        car_register: true
      },
      task_loading: false,
      alert_param: null,
  };
  constructor(props) {
      super(props);

  }

  processSaveFilter = (result) => {
    Store.updateStore('home_filter', JSON.parse(result))
  }

 switchRadio(){
  let {only_assign_me} = this.state;
  only_assign_me = only_assign_me ? false : true;
  this.setState({only_assign_me: only_assign_me})
  let filter = Store.getStoreData('home_filter');
  filter.show_assigned_home = only_assign_me;
  let car_cached_data = JSON.parse(localStorage.getItem("car-index-select"))
  Utils.log("filter ===>", car_cached_data)
  if('result' in car_cached_data && 'home_filter' in car_cached_data.result && 'show_assigned_home' in car_cached_data.result.home_filter){
    car_cached_data.result.home_filter.show_assigned_home = only_assign_me;
    localStorage.setItem("car-index-select", JSON.stringify(car_cached_data));
  }
  let postData = { "command": "car_registry_save", "filter":  filter};
  let api = new APICall();
  api.indexCommand(postData, this.processSaveFilter);
  }

processScrolloffFilter = (result) => {
  Store.updateStore('home_filter', JSON.parse(result))
}

 setShowTable = (flag, table) => {
    let filter = Store.getStoreData('home_filter');
    let show_table_filter = this.state.show_table_filter;
    show_table_filter[table.toString()] = flag;
    this.setState({show_table_filter: show_table_filter})
    filter.show_table_filter = show_table_filter;
    Utils.log("filter ===>", filter)
    let car_cached_data = JSON.parse(localStorage.getItem("car-index-select"))
    // Utils.log("filter ===>", car_cached_data)
    if('result' in car_cached_data && 'home_filter' in car_cached_data.result){
      car_cached_data.result.home_filter['show_table_filter'] = show_table_filter;
      localStorage.setItem("car-index-select", JSON.stringify(car_cached_data));
    }
    let postData = { "command": "car_registry_save", "filter":  filter};
    let api = new APICall();
    api.command(postData, this.processScrolloffFilter);
  }

  FilterCardata = (data) => {
    let result = [];
    if(this.state.only_assign_me){
      for(let d of data) {
        if(parseInt(d.cur_assigned_to) === parseInt(Store.getStoreData('contact_id'))){
          result.push(d);
        }
      }
    }else{
      for(let d of data) {
        result.push(d)
      }
    }
    return result;

  }

  refreshCallback = () => {
      this.props.refreshCallback('jrca_index');
  }

  closeModal = () => {
    this.setState({view_count : this.state.view_count + 1, overlay_height: null, show_modal: ''});
  }

  processDataRefresh = () => {
    let home_filter = Store.getStoreData('home_filter');
    let show_table_filter = ("show_table_filter" in home_filter) ? home_filter.show_table_filter : this.state.show_table_filter;
    let only_assign_me = ("show_assigned_home" in home_filter) ? home_filter.show_assigned_home : false;
    let moduleconfig = Store.getStoreData('moduleconfig');
    let initial_response_time = moduleconfig.response_to_initial_question;
    let response_to_answer = moduleconfig.response_to_answer;
    let response_to_subsquent_question = moduleconfig.response_to_subsquent_question;
    let include_other = moduleconfig.enabled_topics;
    

    this.setState({ isLoaded: true, overlay_height: null, show_modal: '',only_assign_me, initial_response_time: initial_response_time, response_to_answer: response_to_answer, show_table_filter: show_table_filter, response_to_subsquent_question: response_to_subsquent_question, include_other:include_other});
  }

  componentDidMount(){
      this.processDataRefresh();
  }

  componentDidUpdate(prevProps)
  {
      if (prevProps !== this.props) {
         this.processDataRefresh();
      }
  }


 refreshState = (stack=null) => {
    console.log("stack", stack)
    let tasks = Store.getStoreData('tasks')
    let role = Store.getStoreData('role')
    let contact_id = Store.getStoreData('contact_id')
    if(stack!==null){
      tasks[stack.task_id.toString()] = stack.task;
      Store.updateStore('tasks', tasks)
      IndexLoader.divideAllTasks(tasks, role, contact_id);
    }

    this.setState({view_count : this.state.view_count + 1, overlay_height: null, show_modal: ''});
    this.processDataRefresh()
  }


  showAskQuestionModal = (id) => (event) => {
      event.preventDefault();
      this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'ask_modal'});
  }

  processGetTaskData = (result) => {
      Store.updateStore('cur_q_modal_data', result.result.parenttask);
      this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'ask_modal'});
    }

  getDate = (input_date) => {
    if(input_date != null){
      let dateStr = input_date.split('T')[0];
      let dateParts = dateStr.split('-'); 
      let ret = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
      return ret;
    }else{
      return '';
    }
   
  }

  processMyTasksData = () => {
      const has_gc = Store.getStoreData('has_gc');
      let cardata = Store.getStoreData('my_open_tasks') === null ? [] : Store.getStoreData('my_open_tasks');
      cardata = this.FilterCardata(cardata);
      let ret = {data: [], columns: []};
      let i = 0;
      ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, 
                {Header: 'ID', accessor: 'ref_id', minWidth: 100, headerStyle: {textAlign: 'left'}},
                {Header: 'Topic', accessor: 'topic', minWidth: 100, headerStyle: {textAlign: 'left'}, show: this.state.include_other},
                {Header: 'Query', accessor: 'query', minWidth: 300, headerStyle: {textAlign: 'left'}}, 
                {Header: 'Company', accessor: 'company_name', minWidth: 220, headerStyle: {textAlign: 'left'}, show: has_gc ? true : false},  
                {Header: 'Asked By', accessor: 'contact_name', width: 220, headerStyle: {textAlign: 'left'}}, 
                {Header: 'Assigned To', accessor: 'assigned_to', width: 220, headerStyle: {textAlign: 'left'}}, 
                {Header: 'Due Date', accessor: 'due_date', minWidth: 220, headerStyle: {textAlign: 'left'}, sortMethod: (a, b) => {
                    if (a === b) {
                        return 0;
                    }
                    let adue = a.split("/");
                    const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                    let  bbdue = b.split("/");
                    const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                    return aReverse > bReverse ? 1 : -1;
                }},
                  {'Header' : 'Action', Cell: row => (
       <div>
           <MRButton onClick={() => this.showAnswerModal(row.original.id, row.original.company_id)}>Answer</MRButton>
       </div>
      ), width: 180, headerStyle: {textAlign: 'left'}}];


      let gc_assoc = Store.getStoreData('gc_assoc');
      let gc_company = "";
      for(let d of cardata) {
        i++;
        gc_company = parseInt(d.company_id) < 100000 ? Store.getStoreData('company').CompanyName : gc_assoc[(parseInt(d.company_id)%100000).toString()].company_name;
        let assigned_to = d.cur_assigned_to === 0 ? "Compliance Advisers" : Store.getStoreData('contacts')[d.cur_assigned_to];
        let org_teams = Store.getStoreData('org_teams');
        if(d.cur_recever_id!==null && d.cur_assigned_to === 0 && isNaN(d.cur_recever_id) && Object.keys(org_teams).length > 0) {
          // assigned_to = d.cur_recever_id.toString() === "0" ? "Compliance Advisers" : org_teams[d.cur_recever_id.toString()].name;
          if(d.cur_recever_id in org_teams){
            assigned_to = org_teams[d.cur_recever_id.toString()].name;
          }
        }
        let dateStr = this.getDate(d.due_date); 
        let elem = {'index' : i, 
                'ref_id': d.ref_id,
                'topic' : d.topic,
                'query' : d.query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'"),
                'company_name' : gc_company,
                'contact_name' : d.from_api === 0 ? Store.getStoreData('contacts')[d.contact_id] : d.requester_name,
                'assigned_to' :   assigned_to,
                'due_date' :   dateStr,
                'id' : d.id,
                'company_id' : d.company_id 
               };
        ret.data.push(elem);
        ret.data = ret.data.sort((a,b) => {
          if (a.due_date === b.due_date) {
            return 0;
          }
          let adue = a.due_date.split("/");
          const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
          // console.log("parseInt(adue[0]) ==>", aReverse, a.due_date);
          let  bbdue = b.due_date.split("/");
          const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
          return aReverse < bReverse ? 1 : -1;
        });
      }
      return ret;
  }

  processMyRevertedTasksData = () => {
       const has_gc = Store.getStoreData('has_gc');
       let cardata = Store.getStoreData('my_reverted_tasks') === null ? [] : Store.getStoreData('my_reverted_tasks');
       cardata = this.FilterCardata(cardata);
       let ret = {data: [], columns: []};
       let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, 
                    {Header: 'ID', accessor: 'ref_id', minWidth: 100, headerStyle: {textAlign: 'left'}},
                    {Header: 'Topic', accessor: 'topic', minWidth: 100, headerStyle: {textAlign: 'left'}, show: this.state.include_other},
                    {Header: 'Query', accessor: 'query', minWidth: 300, headerStyle: {textAlign: 'left'}}, 
                    {Header: 'Company', accessor: 'company_name', minWidth: 220, headerStyle: {textAlign: 'left'}, show: has_gc},  
                        {Header: 'Reverted By', accessor: 'contact_name', width: 220, headerStyle: {textAlign: 'left'}}, 
                      {'Header' : 'Action', Cell: row => (
           <div>
               <MRButton onClick={() => this.showMyrevertedModal(row.original.id, row.original.company_id)}>View</MRButton>
           </div>
        ), width: 180, headerStyle: {textAlign: 'left'}}];


      let gc_assoc = Store.getStoreData('gc_assoc');
      let gc_company = "";
      for(let d of cardata) {
        i++;
         gc_company = parseInt(d.company_id) < 100000 ? Store.getStoreData('company').CompanyName : gc_assoc[(parseInt(d.company_id)%100000).toString()].company_name;
         let elem = {'index' : i, 
                 'ref_id': d.ref_id,
                 'topic' :d.topic,
                 'query' : d.query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'"),
                 'company_name' : gc_company,
                 'contact_name' : d.reverted_by === 0 ? "" :  Store.getStoreData('contacts')[d.reverted_by],
                 'id' : d.id,
                 'company_id' : d.company_id 
                };
         ret.data.push(elem);
      }
      return ret;
  }

   showMyrevertedModal(query_id, company_id){
      let api = new APICall();
      let postData = {command: 'get_newtask', task_id: query_id, company_id: company_id };
      // postData['gcs'] = Store.getStoreData('gc_filters');
      api.indexCommand(postData, this.processMyrevertedModalData);   
      this.setState({task_loading: true})  
  }

  processMyrevertedModalData = (result) => {
    this.setState({task_loading: false})
    Store.updateStore('cur_revert_modal_data',result.result.parenttask);
    if(result.result.parenttask.cur_lane === 'COMP_CAR_ANSWER_REVERT') {
      this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'answer_revert_modal'});
    }else {
      this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'clarify_revert_modal'});
    }
  }

  processSavedQueriesData = () => {
      const has_gc = Store.getStoreData('has_gc');
      let cardata = Store.getStoreData('my_saved_queries') === null ? [] : Store.getStoreData('my_saved_queries');
      cardata = this.FilterCardata(cardata);
      let ret = {data: [], columns: []};
      let i = 0;
       ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}},
                    {Header: 'ID', accessor: 'ref_id', minWidth: 100, headerStyle: {textAlign: 'left'}},
                    {Header: 'Topic', accessor: 'topic', minWidth: 100, headerStyle: {textAlign: 'left'}, show: this.state.include_other},
                    {Header: 'Query', accessor: 'query', minWidth: 300, headerStyle: {textAlign: 'left'}}, 
                    {Header: 'Company', accessor: 'company_name', minWidth: 220, headerStyle: {textAlign: 'left'}, show: has_gc},
                    {Header: 'Asked By', accessor: 'contact_name', width: 220, headerStyle: {textAlign: 'left'}}, 
                    {'Header' : 'Action', Cell: row => (
          <div>
              <MRButton onClick={() => this.showSavedAnswerModal(row.original.id, row.original.company_id)}>Modify</MRButton>
          </div>
      ), width: 180, headerStyle: {textAlign: 'left'}}];

      let gc_assoc = Store.getStoreData('gc_assoc');
      let gc_company = "";
      for(let d of cardata) {
        i++;
        gc_company = parseInt(d.company_id) < 100000 ? Store.getStoreData('company').CompanyName : gc_assoc[(parseInt(d.company_id)%100000).toString()].company_name;
        let elem = {'index' : i, 
                  'ref_id': d.ref_id,
                  'topic' : d.topic,
                  'query' : d.query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'"),
                  'company_name' : gc_company,
                  'contact_name' : Store.getStoreData('contacts')[d.contact_id],
                  'id' : d.id,
                  'company_id' : d.company_id
                 };
        ret.data.push(elem);
      }
      return ret;
  }

  processAnsweredTasksData = () => {
      const has_gc = Store.getStoreData('has_gc');
      let cardata = Store.getStoreData('my_answered_tasks') === null ? [] : Store.getStoreData('my_answered_tasks') ;
      cardata = this.FilterCardata(cardata);
      let ret = {data: [], columns: []};
      let i = 0;
      ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, 
                   {Header: 'ID', accessor: 'ref_id', minWidth: 100, headerStyle: {textAlign: 'left'}},
                   {Header: 'Topic', accessor: 'topic', minWidth: 100, headerStyle: {textAlign: 'left'}, show: this.state.include_other},
                   {Header: 'Query', accessor: 'query', minWidth: 300, headerStyle: {textAlign: 'left'}}, 
                   {Header: 'Company', accessor: 'company_name', minWidth: 220, headerStyle: {textAlign: 'left'}, show: has_gc}, 
                       {Header: 'Answered By', accessor: 'contact_name', width: 220, headerStyle: {textAlign: 'left'}}, 
                     {'Header' : 'Action', Cell: row => (
          <div>
              <MRButton onClick={() => this.showSaveAnswerModal(row.original.id, row.original.company_id)}>View</MRButton>
          </div>
      ), width: 210, headerStyle: {textAlign: 'left'}}];

      let gc_assoc = Store.getStoreData('gc_assoc');
      let gc_company = "";
      for(let d of cardata) {
        i++;
        gc_company = parseInt(d.company_id) < 100000 ? Store.getStoreData('company').CompanyName : gc_assoc[(parseInt(d.company_id)%100000).toString()].company_name;
        let elem = {'index' : i, 
                  'ref_id': d.ref_id,
                  'topic' : d.topic,
                  'query' : d.query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'"),
                  'company_name' : gc_company,
                  'contact_name' : d.answered_by === 0 ? 'N/A' : Store.getStoreData('contacts')[d.answered_by],
                  'id': d.id,
                  'company_id' : d.company_id
                 };
          ret.data.push(elem);
      }
      return ret;
  }


  processClosedTasksData = () => {
      const has_gc = Store.getStoreData('has_gc');
      let cardata = Store.getStoreData('cmpleted_tsks') === null ? [] : Store.getStoreData('cmpleted_tsks');
      cardata = this.FilterCardata(cardata);
      let ret = {data: [], columns: []};
      let i = 0;
      ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, 
              {Header: 'ID', accessor: 'ref_id', minWidth: 100, headerStyle: {textAlign: 'left'}},
              {Header: 'Topic', accessor: 'topic', minWidth: 100, headerStyle: {textAlign: 'left'}, show: this.state.include_other},
              {Header: 'Query', accessor: 'query', minWidth: 300, headerStyle: {textAlign: 'left'}}, 
              {Header: 'Company', accessor: 'company_name', minWidth: 220, headerStyle: {textAlign: 'left'}, show: has_gc}, 
              {Header: 'Authored By', accessor: 'contact_name', width: 220, headerStyle: {textAlign: 'left'}}, 
              {'Header' : 'Action', Cell: row => (
      <div>
        <MRButton onClick={() => this.showCloseAnswerModal(row.original.id, row.original.company_id)}>View</MRButton>
      </div>
      ), width: 210, headerStyle: {textAlign: 'left'}}];

      let gc_assoc = Store.getStoreData('gc_assoc');
      let gc_company = "";
      for(let d of cardata) {
        i++;
        gc_company = parseInt(d.company_id) < 100000 ? Store.getStoreData('company').CompanyName : gc_assoc[(parseInt(d.company_id)%100000).toString()].company_name;
      let elem = {'index' : i, 
           'ref_id': d.ref_id,
           'topic' : d.topic,
           'query' : d.query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'"),
           'company_name' : gc_company,
           'contact_name' : d.contact_id === 0 ? 'N/A' : Store.getStoreData('contacts')[d.contact_id],
           'id': d.id,
           'company_id': d.company_id

          };
      ret.data.push(elem);
      }
      return ret;
  }

  processAnswerModalData = (result) => {
      console.log('result.result.parenttask', result)
      if(result.error_code === 0) {
        if(result.result.parenttask.cur_lane === 'COMP_CAR_TEAMSAVE') {
          Store.updateStore('cur_q_modal_data',result.result.parenttask);
          this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'ask_modal', task_loading: false});
        }else {
          Store.updateStore('cur_a_modal_data',result.result.parenttask);
          if(result.result.parenttask.cur_lane !== 'COMP_CAR_USER_CLARIFICATION') {
              this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'answer_modal', task_loading: false});
          } else {
              this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'clarify_modal', task_loading: false});
          }
        }
      }else{
        let alert_param = {title: 'No Access', message: result.error_msg, ok_text: 'Ok', confirm: false, 
                           alertHandler: this.alertHandler, stack: {id: 0}};
        this.setState({ ready: true, alert_param: alert_param, task_loading: false});
        return false;
      }

  }

  alertHandler = (result, stack) => {
    this.setState({alert_param: null})
  }

   showSavedAnswerModal(id, company_id){ 
      let api = new APICall();
      let postData = {command: 'get_newtask', task_id: id, company_id: company_id };
      // postData['gcs'] = Store.getStoreData('gc_filters');
      api.indexCommand(postData, this.processAnswerModalData); 
      this.setState({task_loading: true})     
  }

   showSaveAnswerModal(query_id, company_id){
      let api = new APICall();
      let postData = {command: 'get_newtask', task_id: query_id, 'company_id': company_id };
      // postData['gcs'] = Store.getStoreData('gc_filters');
      // console.log("postData", postData);
      api.indexCommand(postData, this.processsaveAnswerModalData);
      this.setState({task_loading: true}) 
  }

  processsaveAnswerModalData = (result) => {
    Store.updateStore('cur_accept_modal_data', result.result.parenttask);
    this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'save_answer_modal', task_loading: false});
  }

   showAnswerModal(query_id, company_id){
      let api = new APICall();
      let postData = {command: 'get_newtask', task_id: query_id, company_id: company_id };
      // postData['gcs'] = Store.getStoreData('gc_filters');
      api.indexCommand(postData, this.processAnswerModalData);
      this.setState({task_loading: true})     
  }


  showCloseAnswerModal(query_id, company_id){
      let postData = { "command" : "get_newtask", "task_id" : query_id, company_id: company_id }
      // postData['gcs'] = Store.getStoreData('gc_filters');
      // console.log(postData);
      let api = new APICall();
      api.indexCommand(postData, this.processClosedTaskData);
      this.setState({task_loading: true}) 
  }

  processClosedTaskData = (result) => {
    Store.updateStore('cur_question_answer_modal_data', result.result.parenttask);
    this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'question_answer_modal', task_loading: false});
  }

  openAnswe = (id, company_id) => {
    let api = new APICall();
    let postData = {command: 'get_newtask', task_id: id, company_id: company_id };
    // postData['gcs'] = Store.getStoreData('gc_filters');
    // console.log("postData", postData);
    api.indexCommand(postData, this.processAnswerModalData); 
    this.setState({task_loading: true})
  }



  processAcceptanceQueueData = () => {
      const has_gc = Store.getStoreData('has_gc');
      let cardata = Store.getStoreData('my_acceptance_tasks') === null ? [] : Store.getStoreData('my_acceptance_tasks');
      cardata = this.FilterCardata(cardata);
      let ret = {data: [], columns: []};
      let i = 0;
      ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, 
                {Header: 'ID', accessor: 'ref_id', minWidth: 100, headerStyle: {textAlign: 'left'}},
                {Header: 'Topic', accessor: 'topic', minWidth: 100, headerStyle: {textAlign: 'left'}, show: this.state.include_other},
                {Header: 'Query', accessor: 'query', minWidth: 300, headerStyle: {textAlign: 'left'}}, 
                {Header: 'Company', accessor: 'company_name', minWidth: 220, headerStyle: {textAlign: 'left'}, show: has_gc}, 
                {Header: 'Answered By', accessor: 'contact_name', width: 220, headerStyle: {textAlign: 'left'}}, 
                {'Header' : 'Action', Cell: row => (
       <div>
           <MRButton onClick={() => this.showAcceptAnswerModal(row.original.id, row.original.company_id)}>View</MRButton>
       </div>
      ), width: 210, headerStyle: {textAlign: 'left'}}];

      let gc_assoc = Store.getStoreData('gc_assoc')
      let gc_company = "";
      for(let d of cardata) {
        i++;
        gc_company = parseInt(d.company_id) < 100000 ? Store.getStoreData('company').CompanyName : gc_assoc[(parseInt(d.company_id)%100000).toString()].company_name;
        let elem = {'index' : i, 
                'ref_id': d.ref_id,
                'topic' : d.topic,
                'query' : d.query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'"),
                'company_name' : gc_company,
                'contact_name' : d.answered_by === 0 ? 'N/A' : Store.getStoreData('contacts')[d.answered_by],
                'id': d.id,
                'company_id': d.company_id

               };
        ret.data.push(elem);
      }
      return ret;      
  }


  processOpenTasksData = () => {
      const has_gc = Store.getStoreData('has_gc');
      let cardata = Store.getStoreData('my_pending_tasks') === null ? [] : Store.getStoreData('my_pending_tasks');
      cardata = this.FilterCardata(cardata);
      let ret = {data: [], columns: []};
      let i = 0;
      ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, 
                   {Header: 'ID', accessor: 'ref_id', minWidth: 100, headerStyle: {textAlign: 'left'}},
                   {Header: 'Topic', accessor: 'topic', minWidth: 100, headerStyle: {textAlign: 'left'}, show: this.state.include_other}, 
                   {Header: 'Query', accessor: 'query', minWidth: 300, headerStyle: {textAlign: 'left'}}, 
                   {Header: 'Company', accessor: 'company_name', minWidth: 220, headerStyle: {textAlign: 'left'}, show: has_gc},  
                   {Header: 'Assigned To', accessor: 'assigned_to', width: 220, headerStyle: {textAlign: 'left'}}, 
                   {Header: 'Due Date', accessor: 'due_date', minWidth: 220, headerStyle: {textAlign: 'left'}, sortMethod: (a, b) => {
                       if (a === b) {
                           return 0;
                       }
                       let adue = a.split("/");
                       const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                       let  bbdue = b.split("/");
                       const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                       return aReverse > bReverse ? 1 : -1;
                   }},
                     {'Header' : 'Status',  Cell: row => (
          <div>
              <MRButton style={{backgroundColor : `${row.original.status.color}`, width: '120px', cursor: 'pointer'}} onClick={() => this.showSaveAnswerModal(row.original.id, row.original.company_id)}>{row.original.status.text}</MRButton>
          </div>
      ), width: 210, headerStyle: {textAlign: 'left'}}];

      let gc_assoc = Store.getStoreData('gc_assoc');
      let gc_company = "";
      for(let d of cardata) {
        i++;
        gc_company = parseInt(d.company_id) < 100000 ? Store.getStoreData('company').CompanyName : gc_assoc[(parseInt(d.company_id)%100000).toString()].company_name;
        let dateStr = this.getDate(d.due_date);
        let assigned_to = d.cur_assigned_to === 0 ? "Team" : Store.getStoreData('contacts')[d.cur_assigned_to];
        let status = {color: '#55a233', text: 'Pending'};
        switch(d.cur_lane) {
          case 'COMP_CAR_ANSWER': status = {color: '#55a233', text: 'Pending'}; break;
          case 'COMP_CAR_ANSWER_SAVED': status = {color: '#11E311', text: 'In Progress'}; break;
        }
        let elem = {'index' : i, 
                  'ref_id': d.ref_id,
                  'topic' : d.topic,
                  'query' : d.query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'"),
                  'company_name' : gc_company,
                  'assigned_to' :   assigned_to,
                  'due_date' :   dateStr,
                  'status': status,
                  'id': d.id,
                  'company_id': d.company_id
                 };
          ret.data = ret.data.sort((a,b) => {
            if (a.due_date === b.due_date) {
              return 0;
            }
            let adue = a.due_date.split("/");
            const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
            // console.log("parseInt(adue[0]) ==>", aReverse, a.due_date);
            let  bbdue = b.due_date.split("/");
            const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
            return aReverse < bReverse ? 1 : -1;
          });
                 //console.log("contact name", answerer_id);
          ret.data.push(elem);
      }
      return ret;
  }

  processGetTaskData = (result) => {
    //console.log('task result', result);
    Store.updateStore('cur_q_modal_data', result.result.parenttask);
    this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'ask_modal'});
  }
  processAcceptanceTaskData = (result) => {
    console.log('task result acceptance', result);
    Store.updateStore('cur_accept_modal_data', result.result.parenttask);
    this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'accept_modal', task_loading: false});
  }

  showAcceptAnswerModal(id, company_id){
      let postData = { "command" : "get_newtask", "task_id" : id, "company_id": company_id }
      postData['mode'] = "acceptance";
      // postData['gcs'] = Store.getStoreData('gc_filters');
      let api = new APICall();
      api.indexCommand(postData, this.processAcceptanceTaskData);
      this.setState({task_loading: true})
  }

  exportToExcel = () => {
    let fileName = Store.getStoreData('role')
    let wb = {SheetNames:[], Sheets:{}};
    let acceptance_tasks = Store.getStoreData('my_acceptance_tasks') === null ? [] : Store.getStoreData('my_acceptance_tasks');
    let open_tasks = Store.getStoreData('my_open_tasks') === null ? [] : Store.getStoreData('my_open_tasks');
    let save_tasks = Store.getStoreData('my_saved_queries') === null ? [] : Store.getStoreData('my_saved_queries');
    let closed_tasks = Store.getStoreData('cmpleted_tsks') === null ? [] : Store.getStoreData('cmpleted_tsks');
    let my_reverted_tasks = Store.getStoreData('my_reverted_tasks') === null ? [] : Store.getStoreData('my_reverted_tasks');
    let my_pending_tasks = Store.getStoreData('my_pending_tasks') === null ? [] : Store.getStoreData('my_pending_tasks');
    let answered_tasks = Store.getStoreData('my_answered_tasks') === null ? [] : Store.getStoreData('my_answered_tasks');



    wb.SheetNames.push("Open Advice Requests"); wb.Sheets["Open Advice Requests"] = XLSX.utils.json_to_sheet(XcelLoader.car_export_tasks(this.FilterCardata(open_tasks), false, true, true));
    
    wb.SheetNames.push("My Reverted Advice Requests"); wb.Sheets["My Reverted Advice Requests"] = XLSX.utils.json_to_sheet(XcelLoader.car_export_tasks(this.FilterCardata(my_reverted_tasks)));

    wb.SheetNames.push("Pending Advice Requests"); wb.Sheets["Pending Advice Requests"] = XLSX.utils.json_to_sheet(XcelLoader.car_export_tasks(this.FilterCardata(my_pending_tasks),false, true, true, true)); 

    wb.SheetNames.push("Acceptance Queue"); wb.Sheets["Acceptance Queue"] = XLSX.utils.json_to_sheet(XcelLoader.car_export_tasks(this.FilterCardata(acceptance_tasks),true, true)); 
    
    wb.SheetNames.push("Saved Advice Requests"); wb.Sheets["Saved Advice Requests"] = XLSX.utils.json_to_sheet(XcelLoader.car_export_tasks(this.FilterCardata(save_tasks), false, true));
    
    wb.SheetNames.push("Answered Advice Requests"); wb.Sheets["Answered Advice Requests"] = XLSX.utils.json_to_sheet(XcelLoader.car_export_tasks(this.FilterCardata(answered_tasks), true, true));
    
    wb.SheetNames.push("Closed Advice Requests"); wb.Sheets["Closed Advice Requests"] = XLSX.utils.json_to_sheet(XcelLoader.car_export_tasks(this.FilterCardata(closed_tasks), false, false));
    

    const format = "DD-MM-YYYY HH:mm:ss"
    let tm = moment().format(format);

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + '-' + tm + fileExtension);

  }

    render()
    {
        if(!this.state.isLoaded) {
            return <div><CSLLoader style={{position: 'absolute'}}/></div>
        }
        if(this.state.alert_param !== null) {
          return <AlertBox alertParam = {this.state.alert_param} />
        }
        return (
            <IndexContainer>
                <SubmitButton onClick={this.showAskQuestionModal(0)} id="create_advice_request">Submit Advice Request</SubmitButton>
                <SubmitButton onClick={() => this.exportToExcel()} id="export_excel_request" style={{float: 'right', marginRight: '10px', width:"100px"}}>Export</SubmitButton>
                <HeaderFilter>Only show Advice Requests assigned to me&nbsp;&nbsp;
                  {
                      (() => {
                          if (this.state.only_assign_me) {
                              return(
                                      <RadioBlock>
                                          <SelectedRadio >Yes</SelectedRadio>
                                          <UnselectedRadio onClick={() => this.switchRadio()}>No</UnselectedRadio>
                                      </RadioBlock>
                                      )
                          }else {
                              return(<RadioBlock>
                                  <UnselectedRadio onClick={() => this.switchRadio()}>Yes</UnselectedRadio>
                                  <SelectedRadio>No</SelectedRadio>
                              </RadioBlock>)
                          }
                      })()
                  }
                </HeaderFilter>
                <div  style={{position: 'relative'}}>
                  <div style={{clear: "both", height: '3px'}}></div>
                  <CSLTable add={false} processData={this.processMyTasksData} headerText={'Open Advice Requests'} refreshCallback={this.refreshCallback} tableRows="5" setShowTable={this.setShowTable} show_filter={this.state.show_table_filter.open_advice} table="open_advice"/>
                </div>
                <div  style={{position: 'relative'}}>
                  <div style={{clear: "both", height: '3px'}}></div>
                  <CSLTable add={false} processData={this.processMyRevertedTasksData} headerText={'My Reverted Advice Requests'} refreshCallback={this.refreshCallback} tableRows="5" setShowTable={this.setShowTable} show_filter={this.state.show_table_filter.reverted_advice} table="reverted_advice"/>
                </div>
                <div  style={{position: 'relative'}}>
                  <div style={{clear: "both", height: '3px'}}></div>
                  <CSLTable add={false} processData={this.processOpenTasksData} headerText={'My Pending Advice Requests'} refreshCallback={this.refreshCallback} tableRows="5" setShowTable={this.setShowTable} show_filter={this.state.show_table_filter.pending_advice} table="pending_advice"/>
                </div>
                <div  style={{position: 'relative'}}>
                  <div style={{clear: "both", height: '3px'}}></div>
                  <CSLTable add={false} processData={this.processAcceptanceQueueData} headerText={'My Acceptance Queue'} refreshCallback={this.refreshCallback} tableRows="5" setShowTable={this.setShowTable} show_filter={this.state.show_table_filter.acceptence_advice} table="acceptence_advice"/>
                </div>
                <div  style={{position: 'relative'}}>
                  <div style={{clear: "both", height: '3px'}}></div>
                  <CSLTable add={false} processData={this.processSavedQueriesData} headerText={'Saved Advice Requests'} refreshCallback={this.refreshCallback} tableRows="5" setShowTable={this.setShowTable} show_filter={this.state.show_table_filter.save_advice} table="save_advice"/>
                </div>
                <div  style={{position: 'relative'}}>
                  <div style={{clear: "both", height: '3px'}}></div>
                  <CSLTable add={false} processData={this.processAnsweredTasksData} headerText={'Answered Advice Requests'} refreshCallback={this.refreshCallback} tableRows="5" setShowTable={this.setShowTable} show_filter={this.state.show_table_filter.answer_advice} table="answer_advice"/>
                </div>
                <div  style={{position: 'relative'}}>
                  <div style={{clear: "both", height: '3px'}}></div>
                  <CSLTable add={false} processData={this.processClosedTasksData} headerText={'Closed Advice Requests'} refreshCallback={this.refreshCallback} tableRows="5" setShowTable={this.setShowTable} show_filter={this.state.show_table_filter.close_advice} table="close_advice"/>
                </div>

                {
                  (() => {
                    if (this.state.overlay_height !== null) {
                      switch(this.state.show_modal) {
                        case 'ask_modal' : return <div><InactiveOverlay /><AskQuestionModal refreshCallback={this.props.refreshCallback} refreshParentCallback={this.refreshState} openAnswerModal={this.openAnswe} closeModal={this.closeModal} /></div>; break;
                        case 'accept_modal' : return <div><InactiveOverlay /><AcceptanceModal refreshCallback={this.props.refreshCallback} refreshParentCallback={this.refreshState} closeModal={this.closeModal} /></div>; break;
                        case 'answer_modal' : return <div><InactiveOverlay /><AnswerQuestionModal refreshCallback={this.props.refreshCallback} refreshParentCallback={this.refreshState} closeModal={this.closeModal} /></div>; break;
                        case 'clarify_modal' : return <div><InactiveOverlay /><ClarificationModal refreshCallback={this.props.refreshCallback} refreshParentCallback={this.refreshState} closeModal={this.closeModal} /></div>; break;
                        case 'answer_revert_modal' : return <div><InactiveOverlay /><ShowAnsweredRevretModal refreshCallback={this.props.refreshCallback} refreshParentCallback={this.refreshState} closeModal={this.closeModal} /></div>; break;
                        case 'clarify_revert_modal' : return <div><InactiveOverlay /><ClarificationRevertModal refreshCallback={this.props.refreshCallback} refreshParentCallback={this.refreshState} closeModal={this.closeModal} /></div>; break;
                        case 'question_answer_modal' : return <div style={{left:'10%', top:'50px', overflow: "auto"}} ><InactiveOverlay /><ShowClosecarModal refreshCallback={this.props.refreshCallback} refreshParentCallback={this.refreshState} closeModal={this.closeModal} /></div>; break;
                        case 'save_answer_modal' : return <div><InactiveOverlay /><ShowAnsweredModal refreshCallback={this.props.refreshCallback} refreshParentCallback={this.refreshState} closeModal={this.closeModal} /></div>; break;
                        default: return <NoPermission>Sorry, you do not have permission to view this page. </NoPermission>;
                      }
                    }
                  })()
                }
                {
                  (() => {
                    if(this.state.task_loading) {
                      return <CSLLoader/>
                    }
                  })()
                }

            </IndexContainer>
        );
    }
}
export default JRCAIndex;