import React from 'react';
import Utils from '../../Common/Utils.js';
import Store from '../../Common/Store.js';
import styled from 'styled-components';
import ClipLoader from "react-spinners/ClipLoader";

const InactiveOverlay = styled.div`
display: block;
box-sizing: border-box;
height: 100vh;
width: 100vw;
background-color: #9dc3e838;
position: fixed;
left: 0px;
top: 0px;
z-index: 40000;
opacity: 0.8;
`;

const Loader = styled.div`
position: fixed;
left: 40vw;
top: 30vh;
width: 30vw;
z-index: 40001;
font-size: 28px;
font-weight: 700;
color: #ffffff;
text-align: center;
border-style: solid;
border-width: 2px;
border-color: #ffffff;
border-radius: 10px;
background-color: white;
padding: 15px;
`

const LoaderText = styled.div`
font-size: 12px;
font-weight: 600;
color: #222222;
font-family: 'Montserrat',sans-serif;
padding-bottom: 25px;
padding-top: 30px;
`



const CSLPageloader = (props) => {
return (
<div>
<InactiveOverlay />

<Loader>



	{
		(() => {
			if(Store.getStoreData('isemptylocalstore') === null) {
				return (<LoaderText>
						<img src={Utils.baseImage()} />
						<br /><br /><br />
						<span style={{fontSize : 20, fontWeight: 600}}>Sit tight...</span><br /><br /><br />
						{/* <GoAlert style={{color:"#fabd03", fontSize: "70px"}}/><br /><br /><br /><br /> */}
						Please wait while we get things set up for you. It shouldn't take long!
					</LoaderText>
				)
			}else{
				return (<LoaderText>
						<img src={Utils.baseImage()} />
						<br /><br /><br />
						<span style={{fontSize : 20, fontWeight: 600}}>Sit tight...</span><br /><br /><br />
						{/* <GoAlert style={{color:"#fabd03", fontSize: "70px"}}/><br /><br /><br /><br /> */}
						Please wait while we get things set up for you. It shouldn't take long!
					</LoaderText>
				)
			}

		})()
	}

<ClipLoader size={25} color={"#04ada8"} loading={true} />
</Loader>
</div>
);
};

export default CSLPageloader;